import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';

export default class AppInsights
{

    initialize(reactPluginConfig) {
        let INSTRUMENTATION_KEY = process.env.REACT_APP_INSIGHTS_KEY; // Enter your instrumentation key here
        
        this.appInsights = new ApplicationInsights({
            config: {
                instrumentationKey: INSTRUMENTATION_KEY,
                maxBatchInterval: 0,
                disableFetchTracking: false,
                extensions: [this.reactPlugin],
                extensionConfig: {
                    [this.reactPlugin.identifier]: reactPluginConfig
                }
            }
        });
        this.appInsights.loadAppInsights();
    }
    
    constructor(props)
    {
        this.reactPlugin = new ReactPlugin();
    }
}