﻿﻿import React, { useState, useEffect, Fragment } from "react";
import Flag from "./Flag";
import countries from '../data/countries.json'

export default function FlagContainer(props) {
    const setFlag = (flag) => {
        props.setFlag(flag);
    }
    
    const setCountry = (country) => {
        props.setCountry(country);
    }
    
    const setLastFocus = (element) => {
        props.setLastFocus(element);
    }

    // Map Country to img in local folder
    // Note: For this to wok the image in the flags folder has to be named ${Country}.png where Country is the name of the country with a capital
    const flags = countries.map((country, index) => {
        // This checks if a country has multiple languages, if so map the same flag to multiple languages
            if (country.languages.length > 1) {
                let tempList = [];

                for (let i=0; i < country.languages.length; i++) {
                    tempList.push(<Flag src={require(`./../images/flags/${country.country}.png`)} 
                                        country={country.country} 
                                        index={index}
                                        code={country.code} 
                                        language={country.languages[i]} 
                                        close={props.close} 
                                        setFlag={setFlag}
                                        setCountry={setCountry}
                                        setLastFocus={setLastFocus}/>)
                }

                return tempList;
            }

            return (<Flag src={require(`./../images/flags/${country.country}.png`)} 
                          country={country.country} 
                          index={index}
                          code={country.code} 
                          language={country.languages} 
                          close={props.close} 
                          setFlag={setFlag} 
                          setCountry={setCountry}
                          setLastFocus={setLastFocus}/>)
    })
    
    return (
        <div className="flag-container">
            {flags}
        </div>
    )
}
