import React, { Fragment, useEffect, useState } from 'react';
import DailyTotalsChartContainer from './DailyTotalsChartContainer';
import ExportUnit from './ExportUnit';
import DailyTotalsSummary from './DailyTotalsSummary';
import _ from 'lodash';
import {FormattedMessage} from "react-intl";
import DailyFuelLevelTotalsSummary from "./DailyFuelLevelTotalsSummary";

export default function DailyFuelLevelTotalStats(props) {
    const [dailyTotals, setDailyTotals] = useState(null);

    useEffect(() => {
        let dailyTotals = props.dailyTotals;

        dailyTotals = _.orderBy(dailyTotals, ['date'], ['asc']);

        setDailyTotals(dailyTotals);
    }, []);


    return (
        <Fragment>
            {
                props.dailyTotals !== null && dailyTotals !== null &&
                <Fragment>
                    <div className="pumperTitle">
                        <FormattedMessage id="dailyFuelLevelTotals.label" defaultMessage="Daily Fuel Level Totals" />
                    </div>
                    <DailyTotalsChartContainer bifuel={props.bifuel} dailyTotals={dailyTotals} project={props.project} type="fuelLevel" />
                    <DailyFuelLevelTotalsSummary project={props.project} dailyTotals={dailyTotals} />
                </Fragment>
            }
        </Fragment>
    )

}