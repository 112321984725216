import fetchWithTimeout from '../helpers/fetchWithTimeout';

const requestProducersType = 'REQUEST_PRODUCERS';
const receiveProducersType = 'RECEIVE_PRODUCERS';
const requestProducersError = 'PRODUCERS_ERROR';
const UnauthorizedError = "UNAUTHORIZED";

const initialState = { producers: [], isLoading: false };

export const actionCreators = {
	requestProducers: (token) => async (dispatch, getState) => {
		
		dispatch({ type: requestProducersType });

		const url = global.apiEndpoint + 'energyproducer/all';
		if (global.apiEndpoint === undefined) {
			window.location.replace('/');
		}
		const requestOptions = {
			method: 'GET', headers: {
				Authorization: `Bearer ${token}`
			}
		};
		let response;

		var producers;
		
		try{
			producers = JSON.parse(sessionStorage.getItem('producers'));
		}catch(err)
		{
			producers = null;
		}

		if (producers === null || producers[0] === undefined)
		{
			response = await fetchWithTimeout(url, requestOptions);

			if (response.status === 401 || response.status === 403) {
				dispatch({ type: UnauthorizedError })
			}
			if (response.status !== 200) {
				dispatch({ type: requestProducersError });
			}
			producers = await response.json();
			sessionStorage.setItem('producers', JSON.stringify(producers));
		}

		try {
			//for listing projects in header menu
			producers.forEach(producer => {
				let projects_menu = {}
				producer.projects.forEach(project => {
					if (project.name in projects_menu) {
						projects_menu[project.name].push({ startTime: project.startTime, startDateURL: project.startDateURL })
					} else {
						projects_menu[project.name] = [{ startTime: project.startTime, startDateURL: project.startDateURL }]
					}
				})
				producer.projects_menu = []
				Object.keys(projects_menu).forEach(project => {
					producer.projects_menu.push({ name: project, startTimes: projects_menu[project] })
				})
			})

			dispatch({ type: receiveProducersType, producers });
		}
		catch (err) {
			dispatch({ type: requestProducersError });
		}
	}
};

export const reducer = (state, action) => {
	state = state || initialState;

	if (action.type === requestProducersType) {
		return {
			...state,
			producers: [],
			isLoading: true,
			isError: false
		};
	}

	if (action.type === receiveProducersType) {
		return {
			...state,
			producers: action.producers,
			isLoading: false,
			isError: false
		};
	}

	if (action.type === requestProducersError) {
		return {
			...state,
			isError: true,
			isLoading: false
		}
	}
	if (action.type === UnauthorizedError) {
		return {
			...state,
			isError: true,
			isLoading: false,
			isUnauthorized: true
		}
	}

	return state;
};
