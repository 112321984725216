﻿﻿import React, {useCallback, useEffect, useRef, useState} from "react";
import Popup from "reactjs-popup";
import FlagContainer from "../FlagContainer";
import ConfirmationPopup from "./ConfirmationPopup";
import NullPopup from "./NullPopup";
import {Button} from "reactstrap";
import {last} from "rxjs/operators";

const CountryPopup = (props) => {
    const [selectedFlag, setSelectedFlag] = useState('null');
    const [selectedCountry, setSelectedCountry] = useState('null');
    const [seen, setSeen] = useState(false);
    const [lastFocus, setLastFocus] = useState(null);

    // Callback function for children to set status of the popup
    const setConfirmationStatus = (status) => {
        setSeen(status);
    }
    
    // Callback function for children to set the selected flag (locale; ex: fr-cn).
    const setFlag = (flag) => {
        setSelectedFlag(flag);
    }
    
    // Callback function for children to set the selected country.
    const setCountry = (country) => {
        setSelectedCountry(country);
    }
    
    const handleOnClick = () => {
        setSeen(true);
        
        // This is to check if the user clicked a flag, if they did, refocus it on confirm
        if (lastFocus !== null) {
            lastFocus.focus();
        }
    }
    
    return (<Popup open={props.firstLogin} closeOnEscape={false} position="center center" className="country-popup">
        {close => (
            // Fix pointer event stuff here, based on state of second popup
            <div className="country-popup-container" style={{"pointer-events": seen ? "none" : "all"}}>
                <div className="country-popup-text-container"> 
                    <div className="country-popup-title">Welcome to Enertrail!</div>
                    <div className="country-popup-text">Please select a country:</div>
                </div>
                <FlagContainer close={close} setFlag={setFlag} setCountry={setCountry} setLastFocus={setLastFocus}/>

                <Button className="confirm-button" 
                        onClick={handleOnClick} 
                        color="warning" 
                        outline 
                        size="md">
                    Confirm
                </Button>
                {seen && selectedFlag !== 'null' &&
                    <ConfirmationPopup setStatus={setConfirmationStatus}
                                       status={seen}
                                       close={close}
                                       lastFocus={lastFocus}
                                       selectedFlag={selectedFlag} 
                                       selectedCountry={selectedCountry}/>
                }
                
                {seen && selectedFlag === 'null' &&
                    <NullPopup setStatus={setConfirmationStatus} status={seen}/>
                }
            </div>
        )}
        
    </Popup>
    )
}

export default CountryPopup;