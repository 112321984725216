import React from 'react';
import Error from '../Error/index';
import { useIntl } from "react-intl";

const NotFound = () => {
	const intl = useIntl();
	return (
		<Error title={intl.formatMessage({ id: "nothingHere.label", defaultMessage: "Nothing Here"})}>
			{intl.formatMessage({id: "notFound.message", defaultMessage: "Unfortunately, you have requested utter nonsense and we can’t do anything for you"})}
		</Error>
	)
}

export default NotFound