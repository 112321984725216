import React, { Fragment } from 'react';
import {FormattedMessage} from "react-intl";

export default function FracShackEquipment(props)
{
    return (
        <div className="dashboard-connected-equipment">
            {props.shacks.length > 0 &&
            <Fragment>
                <div className="dashboardMultiTitle connectedEquipmentTitle">
                    <FormattedMessage id="fracShackEquipment.label" defaultMessage="Frac Shack Equipment" />
                </div>
                <div className="clearfix"></div>
                <ul className="dashboard-fracshack-equipment-container">
                {props.shacks.map(x =>
                    <li className="dashboard-fracshack-equipment-label">{ x }</li>
                )}
                </ul>
            </Fragment>
            }
        </div>

    )
}