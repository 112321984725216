import React, {useState, useEffect} from "react";
import {getState} from "litsy";
import Axios from "axios";
import {FormattedMessage, useIntl} from "react-intl";
import {Button, Col, Table, Tooltip} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {toast} from "react-toastify";
import moment from "moment";
import CustomerApiTokenRow from "./CustomerApiTokenRow";
import {SubscriptionTierEnum, TokenTierEnum, TokenTypeEnum} from "../interfaces/User";
import InfoIcon from "../images/icons8-info-16.png";

function CustomerApiTokenTable(props) {
    const [apiTokens, setApiTokens] = useState([]);
    const [modifiedApiTokens, setModifiedApiTokens] = useState([]);
    const [newApiTokens, setNewApiTokens] = useState([]);
    const [isModifying, setIsModifying] = useState(false);
    const [tooltipOpen, setTooltipOpen] = useState(false);

    const intl = useIntl();

    useEffect(() => {
        fetchApiTokens();
    }, [])

    const canGenerateTokens = async () => {
        if (props.type === TokenTypeEnum.Project) {
            return apiTokens.length + newApiTokens.length < props.userTierPolicy.policy.projectTokenLimit;
        } else if (props.type === TokenTypeEnum.Company) {
            return apiTokens.length + newApiTokens.length < props.userTierPolicy.policy.companyTokenLimit;
        }

        return false;
    }

    const toggle = () => setTooltipOpen(!tooltipOpen);

    // API call to retrieve api tokens based on table type
    const fetchApiTokens = async () => {
        if (props.type === TokenTypeEnum.Project) {
            const project = getState("fracShack_currentProject", "session");
            const url = `${getState("fracShack_apiEndpoint", "session")}apiToken/get-project-tokens?JobId=${project.jobId}`;
            await Axios.get(url, {
                headers: {
                    "Authorization": `Bearer ${getState("authToken", "session")}`
                }
            }).then(result => {
                if (result.status === 200) {
                    setApiTokens(result.data.tokens);
                } else {
                    // Throw toast error
                }
            });
        } else if (props.type === TokenTypeEnum.Company) {
            const project = getState("fracShack_currentProject", "session");
            const url = `${getState("fracShack_apiEndpoint", "session")}apiToken/get-company-tokens?JobId=${project.jobId}`;
            await Axios.get(url, {
                headers: {
                    "Authorization": `Bearer ${getState("authToken", "session")}`
                }
            }).then(result => {
                if (result.status === 200) {
                    setApiTokens(result.data.tokens);
                } else {
                    // Throw toast error
                }
            });
        }
    }

    // Update an existing api token
    const updateApiToken = async (apiToken) => {
        const url = `${getState("fracShack_apiEndpoint", "session")}apiToken/${apiToken.value}/update`;
        await Axios.put(url,
            apiToken, {
                headers: {
                    "Authorization": `Bearer ${getState("authToken", "session")}`,
                    "Content-Type": "application/json"
                }
            }).then(result => {
            if (result.status === 200) {
                // Replace existing object with new updated object
                let shallowCopy = [...apiTokens];
                let index = shallowCopy.findIndex(x => x.value === result.data.token.value);
                shallowCopy[index] = result.data.token;

                // Save state of shallow copy
                setApiTokens(shallowCopy);
                updateApiTokenPolicy(result.data.token);

                toast.success(intl.formatMessage({
                    id: "apiTokenUpdateSuccess.message",
                    defaultMessage: "Api token successfully updated"
                }), {
                    position: toast.POSITION.TOP_RIGHT
                });
            } else if (result.status === 204) {
                // Pass, no change
            } else {
                toast.error(intl.formatMessage({
                    id: "apiTokenUpdateFailure.message",
                    defaultMessage: "Api token update failure"
                }), {
                    position: toast.POSITION.TOP_RIGHT
                })
            }
        });
    }

    // Soft delete an api token
    const deleteApiToken = async (apiToken) => {
        const url = `${getState("fracShack_apiEndpoint", "session")}apiToken/${apiToken.value}`;
        await Axios.delete(url, {
            headers: {
                "Authorization": `Bearer ${getState("authToken", "session")}`
            }
        }).then(result => {
            // Successful delete, remove from existing list(s)
            if (result.status === 200) {
                // Remove from main list
                let shallowCopy = [...apiTokens];
                shallowCopy = shallowCopy.filter(x => x.value !== apiToken.value);
                setApiTokens(shallowCopy);

                // Remove from modified list
                let modifiedShallowCopy = [...modifiedApiTokens];
                modifiedShallowCopy = modifiedShallowCopy.filter(x => x.value !== apiToken.value);
                setModifiedApiTokens(modifiedShallowCopy);

                toast.success(intl.formatMessage({
                    id: "apiTokenDeleteSuccess.message",
                    defaultMessage: "Api token successfully deleted"
                }), {
                    position: toast.POSITION.TOP_RIGHT
                });
            } else {
                toast.error(intl.formatMessage({
                    id: "apiTokenDeleteFailure.message",
                    defaultMessage: "Api token deletion failure"
                }), {
                    position: toast.POSITION.TOP_RIGHT
                })
            }
        });
    };

    // Update api token rate limit policy
    const updateApiTokenPolicy = async (apiToken) => {
        const url = `${getState("fracShack_apiEndpoint", "session")}clientRateLimit/update-policy?apiToken=${apiToken.value}`;
        await Axios.post(url,
            {},
            {
                headers: {
                    "Authorization": `Bearer ${getState("authToken", "session")}`
                }
            }).then(result => {
            if (result.status === 200) {
                // Do nothing
            } else {
                // Throw toast error
            }
        });
    };

    // API call to generate api token
    const createApiToken = async (type, tier, expiryDate) => {
        const project = getState("fracShack_currentProject", "session");
        const url = `${getState("fracShack_apiEndpoint", "session")}apiToken/generate-api-token?jobId=${project.jobId}&type=${type}&tier=${tier}&expiryDate=${expiryDate}`;
        await Axios.post(url,
            {},
            {
                headers: {
                    "Authorization": `Bearer ${getState("authToken", "session")}`
                }
            }).then(result => {
            if (result.status === 200) {
                // Append new token to array of tokens
                setApiTokens([...apiTokens, result.data.token])
                updateApiTokenPolicy(result.data.token);

                toast.success(intl.formatMessage({
                    id: "apiTokenSuccess.message",
                    defaultMessage: "Api token successfully created"
                }), {
                    position: toast.POSITION.TOP_RIGHT
                });
            } else {
                toast.error(intl.formatMessage({
                    id: "apiTokenFailure.message",
                    defaultMessage: "Api token creation failure"
                }), {
                    position: toast.POSITION.TOP_RIGHT
                })
            }
        });
    }

    // Add token
    const appendApiToken = async () => {
        // Check if generation limit hit
        if (!await canGenerateTokens()) {
            toast.error(intl.formatMessage({
                id: "tokenGenerationLimitReached.message",
                defaultMessage: "Token Generation Limit reached."
            }), {
                position: toast.POSITION.TOP_RIGHT
            });

            return;
        }

        if (!isModifying) {
            setIsModifying(true);
            setModifiedApiTokens([...apiTokens])
        }

        let apiToken;
        switch (props.userTier) {
            case SubscriptionTierEnum.Silver: {
                apiToken = {
                    value: "On save, generated ApiToken Value",
                    tokenType: props.type,
                    tokenTier: TokenTierEnum.Silver,
                    isActive: true,
                    createdDate: moment(new Date(Date.now())).format("yyyy-MM-DDTHH:mm:ss"),
                    expiryDate: moment(new Date(new Date().setFullYear(new Date().getFullYear() + 1))).format("yyyy-MM-DDTHH:mm:ss")
                };

                break;
            }
            case SubscriptionTierEnum.Gold: {
                apiToken = {
                    value: "On save, generated ApiToken Value",
                    tokenType: props.type,
                    tokenTier: TokenTierEnum.Gold,
                    isActive: true,
                    createdDate: moment(new Date(Date.now())).format("yyyy-MM-DD HH:mm:ss"),
                    expiryDate:
                        props.type === "project" ?
                            moment(new Date(new Date().setFullYear(new Date().getFullYear() + 1))).format("yyyy-MM-DDTHH:mm:ss")
                            : moment(new Date(new Date().setFullYear(new Date().getFullYear() + 2))).format("yyyy-MM-DDTHH:mm:ss")
                };

                break;
            }
            case SubscriptionTierEnum.Free:
            default: {
                apiToken = {
                    value: "On save, generated ApiToken Value",
                    tokenType: props.type,
                    tokenTier: TokenTierEnum.Free,
                    isActive: true,
                    createdDate: moment(new Date(Date.now())).format("yyyy-MM-DDTHH:mm:ss"),
                    expiryDate: moment(new Date(new Date().setMonth(new Date().getMonth() + 6))).format("yyyy-MM-DDTHH:mm:ss")
                };

                break;
            }
        }

        setNewApiTokens([...newApiTokens, apiToken]);
    }
    
    // Remove new apiToken from temp list
    const removeNewApiToken = async (index) => {
        const shallowCopy = [...newApiTokens];
        shallowCopy.splice(index, 1);
        
        setNewApiTokens(shallowCopy);
    }

    // Enable modification of Api Tokens
    const modifyApiTokens = async () => {
        setIsModifying(true);
        setModifiedApiTokens([...apiTokens])
    }

    // Modify api token in current list
    const modifyApiToken = async (apiToken, newToken, index) => {
        if (newToken) {
            // Replace existing object with new updated object
            let shallowCopy = [...newApiTokens];
            shallowCopy[index] = apiToken;

            // Update list
            setNewApiTokens(shallowCopy);
        } else {
            // Replace existing object with new updated object
            let shallowCopy = [...modifiedApiTokens];
            shallowCopy[index] = apiToken;

            // Update list
            setModifiedApiTokens(shallowCopy);
        }
    }

    // On Cancel button click
    const onCancelClick = async () => {
        setIsModifying(false);
        setNewApiTokens([]);
        setModifiedApiTokens([]);
    }

    // On Save button click
    const onSaveClick = async () => {
        setIsModifying(false);

        // Update tokens
        for (const apiToken of modifiedApiTokens) {
            await updateApiToken(apiToken);
        }

        // Generate new tokens, if any
        for (const apiToken of newApiTokens) {
            await createApiToken(apiToken.tokenType, apiToken.tokenTier, apiToken.expiryDate)
        }

        // Clear new tokens array
        setNewApiTokens([]);
        setModifiedApiTokens([]);
    }

    return (
        <div className="d-flex flex-column" style={{marginLeft: "30px"}}>
            <div className="projectInfoTitle">
                {props.type === TokenTypeEnum.Project &&
                <FormattedMessage id="projectApiKeys.label" defaultMessage="Project API Keys"/>
                }
                {props.type === TokenTypeEnum.Company &&
                <FormattedMessage id="companyApiKeys.label" defaultMessage="Company API Keys"/>
                }
                <img src={InfoIcon} className="dashboard-info-icon"
                     id={`infoIcon-${props.type}`} alt="info"/>
                <Tooltip placement="right" isOpen={tooltipOpen} toggle={toggle}
                         target={`infoIcon-${props.type}`}>
                    {props.type === TokenTypeEnum.Project &&
                    <FormattedMessage id="projectInfoCon.message" defaultMessage="Data specific to one job"/>
                    }
                    {props.type === TokenTypeEnum.Company &&
                    <FormattedMessage id="companyInfoCon.message" defaultMessage="Grab data from all jobs"/>
                    }
                </Tooltip>
            </div>
            <div className="api-token-count">
                <span>{apiTokens.length + newApiTokens.length}</span>
                <span> / </span>
                <span>
                    {
                        props.type === TokenTypeEnum.Company
                            ? props.userTierPolicy.policy.companyTokenLimit
                            : props.userTierPolicy.policy.projectTokenLimit
                    }
                </span>
            </div>
            <Table dark responsive>
                <thead>
                <th><FormattedMessage id="apiKey.label" defaultMessage="API Key"/></th>
                <th><FormattedMessage id="created.label" defaultMessage="Created"/></th>
                <th><FormattedMessage id="expires.label" defaultMessage="Expires"/></th>
                <th><FormattedMessage id="status.label" defaultMessage="Status"/></th>
                <th><FormattedMessage id="tokenType.label" defaultMessage="Token Type"/></th>
                <th><FormattedMessage id="tokenTier.label" defaultMessage="Token Tier"/></th>
                <th>
                    <div className="d-flex flex-row justify-content-sm-around">
                        <FontAwesomeIcon className="icon-effects" icon="plus" style={{color: "green"}}
                                         onClick={appendApiToken}/>
                        {!isModifying &&
                        <FontAwesomeIcon className="icon-effects" icon="edit" style={{color: "white"}}
                                         onClick={modifyApiTokens}/>
                        }
                        {isModifying &&
                        <FontAwesomeIcon className="icon-effects" icon="times" style={{color: "red"}}
                                         onClick={onCancelClick}/>
                        }

                    </div>
                </th>
                </thead>
                <tbody>
                {!isModifying && apiTokens !== undefined &&
                apiTokens.map((apiToken, index) =>
                    <CustomerApiTokenRow apiToken={apiToken}
                                         userTier={props.userTier}
                                         index={index}
                                         newToken={false}
                                         isModifying={isModifying}
                                         modifyApiToken={modifyApiToken}
                                         deleteApiToken={deleteApiToken}/>
                )
                }
                {isModifying && modifiedApiTokens !== undefined &&
                modifiedApiTokens.map((apiToken, index) =>
                    <CustomerApiTokenRow apiToken={apiToken}
                                         userTier={props.userTier}
                                         index={index}
                                         newToken={false}
                                         isModifying={isModifying}
                                         modifyApiToken={modifyApiToken}
                                         deleteApiToken={deleteApiToken}/>
                )
                }
                {isModifying && newApiTokens !== undefined &&
                newApiTokens.map((apiToken, index) =>
                    <CustomerApiTokenRow apiToken={apiToken}
                                         userTier={props.userTier}
                                         index={index}
                                         newToken={true}
                                         isModifying={isModifying}
                                         modifyApiToken={modifyApiToken}
                                         deleteApiToken={deleteApiToken} 
                                         removeNewApiToken={removeNewApiToken}/>
                )
                }
                </tbody>
            </Table>
            {isModifying &&
            <Col sm={12}>
                <Button
                    color="warning"
                    className="float-right"
                    onClick={onSaveClick}
                    size="sm"
                >
                    <FormattedMessage id="save.label" defaultMessage="Save"/>
                </Button>
                <Button
                    color="danger"
                    className="float-right"
                    onClick={onCancelClick}
                    style={{marginRight: "15px"}}
                    size="sm"
                >
                    <FormattedMessage id="cancel.label" defaultMessage="Cancel"/>
                </Button>
            </Col>
            }
        </div>
    )
}

export default CustomerApiTokenTable;