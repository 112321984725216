import React, {Fragment, useContext} from "react";
import {ProjectContext} from "../Contexts/ProjectContext";
import ProjectConfig from "../../ProjectConfig";

export default function ProjectInfoPage(props) {
    const {project} = useContext(ProjectContext);

    return (
        <Fragment>
            {props.user !== null && project.token !== null && project.currentProject !== null && project.activePage === 'ProjectInfo' &&
            <ProjectConfig project={project.currentProject}
                           showUnitOfMeasureToggle={props.showUnitOfMeasureToggle} 
                           user={props.user}
                           token={project.token}/>
            }
        </Fragment>
    )
}