import React, {Fragment, useContext} from "react";
import {ProjectContext} from "../Contexts/ProjectContext";
import Dashboard from "../../Dashboard";

export default function DashboardPage(props) {
    const {project} = useContext(ProjectContext);
    
    return (
        <Fragment>
            {project.currentProject !== null && project.activePage === "Dashboard" &&
            <Dashboard bifuel={project.currentProject.bifuel} project={project.currentProject}
                       token={project.token}/>
            }
        </Fragment>
    )
}