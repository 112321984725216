import React, {Fragment, useEffect, useState} from 'react';
import {Col, Row} from 'reactstrap'
import * as Formatting from '../helpers/formatting';
import {ReactComponent as WhiteUp} from '../images/whiteUp.svg';
import {ReactComponent as WhiteDown} from '../images/whiteDown.svg';
import DailyTotalLine from '../components/DailyTotalLine';
import InfoIcon from '../images/icons8-info-16.png';
import {UncontrolledTooltip} from "reactstrap";
import _ from 'lodash';
import {FormattedMessage, useIntl} from "react-intl";
import {NaturalGasUnit} from "../enums";

export default function DailyTotalsSummary(props) {

    const intl = useIntl();

    const [activeSort, setActiveSort] = useState(null);
    const [sortAsc, setSortAsc] = useState(false);
    const [showInfo, setShowInfo] = useState(false);
    const [dailyTotalList, setDailyTotalList] = useState([]);
    const [totalizerTotalSum, setTotalizerTotalSum] = useState(0)
    const [varianceVolumeSum, setVarianceVolumeSum] = useState(0)


    useEffect(() => {
        if (props.dailyTotals !== undefined)
            setDailyTotalList(props.dailyTotals);
    }, []);

    useEffect(() => {
        setTotalizerTotalSum(_.sumBy(dailyTotalList, props.project.uomStringShort === 'L' ? 'dieselTotalizerLitres' : 'dieselTotalizerGallons'))
        setVarianceVolumeSum(_.sumBy(dailyTotalList, props.project.uomStringShort === 'L' ? 'dieselVarianceVolumeLitres' : 'dieselVarianceVolumeGallons'))
    }, [dailyTotalList])

    useEffect(() => sortColumns(), [sortAsc, activeSort])


    const toggleInfoIcon = () => {
        setShowInfo(!showInfo);
    }

    const sortData = (column) => {
        if (column === activeSort)
            setSortAsc(!sortAsc);
        else 
            setActiveSort(column)
    }

    function sortColumns() {
        if (activeSort === 'diesel')
            setDailyTotalList(dailyTotalList.sort(dieselCompare).slice());
        else if (activeSort === 'ng')
            setDailyTotalList(dailyTotalList.sort(ngCompare).slice());
        else if (activeSort === 'nonfrac')
            setDailyTotalList(dailyTotalList.sort(nonfracCompare).slice());
        else if (activeSort === 'date')
            setDailyTotalList(dailyTotalList.sort(dateCompare).slice());
        else if (activeSort === 'consumed')
            setDailyTotalList(dailyTotalList.sort(consumedCompare).slice());
        else if (activeSort === 'subRate')
            setDailyTotalList(dailyTotalList.sort(subRateCompare).slice());
        else if (activeSort === 'stages')
            setDailyTotalList(dailyTotalList.sort(stageCompare).slice());
        else if (activeSort === 'lineTotal')
            setDailyTotalList(dailyTotalList.sort(lineTotalCompare).slice());
        else if (activeSort === 'totalizerTotal')
            setDailyTotalList(dailyTotalList.sort(totalizerTotalCompare).slice());
        else if (activeSort === 'varianceVolume')
            setDailyTotalList(dailyTotalList.sort(varianceVolumeCompare).slice());
        else if (activeSort === 'variancePercentage')
            setDailyTotalList(dailyTotalList.sort(variancePercentageCompare).slice());
    }

    const dieselCompare = (a, b) => {
        if (props.project.uom === "Litres")
            if (sortAsc)
                return a.totalDieselLitres > b.totalDieselLitres ? 1 : -1
            else
                return a.totalDieselLitres <= b.totalDieselLitres ? 1 : -1
        else if (sortAsc)
            return a.totalDieselGallons > b.totalDieselGallons ? 1 : -1
        else
            return a.totalDieselGallons <= b.totalDieselGallons ? 1 : -1
    }

    const consumedCompare = (a, b) => {
        if (props.project.uom === "Litres")
            if (sortAsc)
                return a.totalConsumedLitres > b.totalConsumedLitres ? 1 : -1
            else
                return a.totalConsumedLitres <= b.totalConsumedLitres ? 1 : -1
        else if (sortAsc)
            return a.totalConsumedGallons > b.totalConsumedGallons ? 1 : -1
        else
            return a.totalConsumedGallons <= b.totalConsumedGallons ? 1 : -1
    }

    const stageCompare = (a, b) => {
        if (sortAsc)
            return a.stagesPerDay > b.stagesPerDay ? 1 : -1
        else
            return a.stagesPerDay <= b.stagesPerDay ? 1 : -1
    }

    const subRateCompare = (a, b) => {
        if (sortAsc)
            return a.subRate > b.subRate ? 1 : -1
        else
            return a.subRate <= b.subRate ? 1 : -1
    }

    const ngCompare = (a, b) => {
        if (props.project.uom === "Litres")
            if (sortAsc)
                return a.totalNGLitres > b.totalNGLitres ? 1 : -1
            else
                return a.totalNGLitres <= b.totalNGLitres ? 1 : -1
        else if (sortAsc)
            return a.totalNGGallons > b.totalNGGallons ? 1 : -1
        else
            return a.totalNGGallons <= b.totalNGGallons ? 1 : -1
    }

    const dateCompare = (a, b) => {
        if (sortAsc)
            return a.date > b.date ? 1 : -1
        else
            return a.date <= b.date ? 1 : -1
    }

    const nonfracCompare = (a, b) => {
        if (props.project.uom === "Litres")
            if (sortAsc)
                return a.totalNonFracDieselLitres > b.totalNonFracDieselLitres ? 1 : -1
            else
                return a.totalNonFracDieselLitres <= b.totalNonFracDieselLitres ? 1 : -1
        else if (sortAsc)
            return a.totalNonFracDieselGallons > b.totalNonFracDieselGallons ? 1 : -1
        else
            return a.totalNonFracDieselGallons <= b.totalNonFracDieselGallons ? 1 : -1
    }

    const lineTotalCompare = (a, b) => {
        if (props.project.uomStringShort === 'L')
            if (sortAsc)
                return a.lineTotalLitres > b.lineTotalLitres ? 1 : -1
            else
                return a.lineTotalLitres <= b.lineTotalLitres ? 1 : -1
        else if (sortAsc)
            return a.lineTotalGallons > b.lineTotalGallons ? 1 : -1
        else
            return a.lineTotalGallons <= b.lineTotalGallons ? 1 : -1
    }

    const totalizerTotalCompare = (a, b) => {
        if (props.project.uomStringShort === 'L')
            if (sortAsc)
                return a.dieselTotalizerLitres > b.dieselTotalizerLitres ? 1 : -1
            else
                return a.dieselTotalizerLitres <= b.dieselTotalizerLitres ? 1 : -1
        else if (sortAsc)
            return a.dieselTotalizerGallons > b.dieselTotalizerGallons ? 1 : -1
        else
            return a.dieselTotalizerGallons <= b.dieselTotalizerGallons ? 1 : -1
    }

    const varianceVolumeCompare = (a, b) => {
        if (props.project.uomStringShort === 'L')
            if (sortAsc)
                return a.dieselVarianceVolumeLitres > b.dieselVarianceVolumeLitres ? 1 : -1
            else
                return a.dieselVarianceVolumeLitres <= b.dieselVarianceVolumeLitres ? 1 : -1
        else if (sortAsc)
            return a.dieselVarianceVolumeGallons > b.dieselVarianceVolumeGallons ? 1 : -1
        else
            return a.dieselVarianceVolumeGallons <= b.dieselVarianceVolumeGallons ? 1 : -1
    }

    const variancePercentageCompare = (a, b) => {
        if (sortAsc)
            return a.dieselVariancePercentage > b.dieselVariancePercentage ? 1 : -1
        else
            return a.dieselVariancePercentage <= b.dieselVariancePercentage ? 1 : -1
    }


    return (
        <Row>
            <Col sm="12">
                <div className="pumperSummary">
                    <div className="pumperLinesHeader">
                        <div className="pumperLinesHeaderCell pumperLinesHeaderName pumperLinesHeaderCellSort" onClick={() => sortData('date')}>
                            <FormattedMessage id="date.label" defaultMessage="Date"/>
                            {activeSort === 'date' && (sortAsc ? <WhiteUp/> : <WhiteDown/>)}
                        </div>
                        <div className="pumperLinesHeaderCell pumperLinesHeaderValue pumperLinesHeaderCellSort" onClick={() => sortData('diesel')}>
                            <FormattedMessage id="fracDiesel.label" defaultMessage="Frac Diesel"/>
                            {activeSort === 'diesel' && (sortAsc ? <WhiteUp/> : <WhiteDown/>)}
                        </div>

                        {props.project !== null && props.project.bifuel &&
                            <Fragment>
                                <div className="pumperLinesHeaderCell pumperLinesHeaderValue pumperLinesHeaderCellSort" onClick={() => sortData('ng')}>
                                    <FormattedMessage id="naturalGas.label" defaultMessage="Natural Gas"/>
                                    {activeSort === 'ng' && (sortAsc ? <WhiteUp/> : <WhiteDown/>)}
                                </div>
                                <div className="pumperLinesHeaderCell pumperLinesHeaderValue pumperLinesHeaderCellSort" onClick={() => sortData('consumed')}>
                                    <FormattedMessage id="fracConsumedEnergy.label" defaultMessage="Frac Consumed Energy"/>
                                    {activeSort === 'consumed' && (sortAsc ? <WhiteUp/> : <WhiteDown/>)}
                                </div>
                            </Fragment>
                        }

                        <div className="pumperLinesHeaderCell pumperLinesHeaderValueNonFrac pumperLinesHeaderCellSort" onClick={() => sortData('nonfrac')}>
                            <FormattedMessage id="nonFracDiesel.label" defaultMessage="Non-Frac Diesel"/>
                            {activeSort === 'nonfrac' && (sortAsc ? <WhiteUp/> : <WhiteDown/>)}
                        </div>


                        <div className="pumperLinesHeaderCell pumperLinesHeaderValueLineTotal pumperLinesHeaderCellSort" onClick={() => sortData('lineTotal')}>
                            <FormattedMessage id="lineTotal.label" defaultMessage="Line Total"/>
                            {activeSort === 'lineTotal' && (sortAsc ? <WhiteUp/> : <WhiteDown/>)}
                        </div>

                        <div className="pumperLinesHeaderCell pumperLinesHeaderValueTotalizerTotal pumperLinesHeaderCellSort" onClick={() => sortData('totalizerTotal')}>
                            <FormattedMessage id="totalizerTotal.label" defaultMessage="Totalizer Total"/>
                            {activeSort === 'totalizerTotal' && (sortAsc ? <WhiteUp/> : <WhiteDown/>)}
                        </div>

                        <div className="pumperLinesHeaderCell pumperLinesHeaderValueVarianceVolume pumperLinesHeaderCellSort" onClick={() => sortData('varianceVolume')}>
                            <FormattedMessage id="varianceVolume.label" defaultMessage="Variance Volume"/>
                            {activeSort === 'varianceVolume' && (sortAsc ? <WhiteUp/> : <WhiteDown/>)}
                        </div>

                        <div className="pumperLinesHeaderCell pumperLinesHeaderValueVariancePercentage pumperLinesHeaderCellSort" onClick={() => sortData('variancePercentage')}>
                            <FormattedMessage id="variancePercentage.label" defaultMessage="Variance Percentage"/>
                            {activeSort === 'variancePercentage' && (sortAsc ? <WhiteUp/> : <WhiteDown/>)}
                        </div>


                        <div className="pumperLinesHeaderCell pumperLinesHeaderStages pumperLinesHeaderCellSort" onClick={() => sortData('stages')}>
                            <FormattedMessage id="numberOfStages.label" defaultMessage="# of Stages"/>
                            {activeSort === 'stages' && (sortAsc ? <WhiteUp/> : <WhiteDown/>)}
                        </div>

                        {props.project !== null && props.project.bifuel &&
                            <div id="infoIcon" className="pumperLinesHeaderCell pumperLinesHeaderSubRate pumperLinesHeaderCellSort" onClick={() => sortData('subRate')}>
                                <FormattedMessage id="subRate.label" defaultMessage="Sub Rate"/>
                                <img src={InfoIcon} className="infoIcon" onClick={() => toggleInfoIcon} alt="info"/>
                                <UncontrolledTooltip placement="right" target="infoIcon">
                                    <FormattedMessage id="substitutionRate.message" defaultMessage="Substitution rate is theoretical, calculated using the Net heating values."/>
                                </UncontrolledTooltip>
                                {activeSort === 'subRate' && (sortAsc ? <WhiteUp/> : <WhiteDown/>)}
                            </div>
                        }
                    </div>

                    <div className="clearfix"></div>

                    <div className="pumperLinesData">
                        <table className="fullWidth" id="table">
                            {props.project !== null && props.dailyTotals !== null && dailyTotalList !== null && dailyTotalList.map(dailyTotal =>
                                <DailyTotalLine
                                    naturalGasUnit={props.project.naturalGasUnit}
                                    bifuel={props.project.bifuel}
                                    dailyTotal={dailyTotal}
                                    unit={props.project.uomString}
                                    unitShort={props.project.uomStringShort}
                                />
                            )}
                            {props.project !== null && props.project.dailyTotals !== null && dailyTotalList !== null &&
                                <tr>
                                    <td>
                                        <div className='pumperLine'>
                                            <div className="pumperLineName">
                                                {(intl.formatMessage({id: "total.label", defaultMessage: "TOTAL"})).toUpperCase()}
                                            </div>
                                            <div className="pumperLineValue">
                                                <div className="pumperLineMobileTitle">
                                                    <FormattedMessage id="fracDiesel.label" defaultMessage="Frac Diesel"/>
                                                </div>
                                                <div className="pumperLineName1">
                                                    {Formatting.numberWithCommas(_.sumBy(dailyTotalList, props.project.uomStringShort === 'L' ? 'totalDieselLitres' : 'totalDieselGallons'))}
                                                    {props.project.uomString}
                                                </div>
                                            </div>
                                            {props.project.bifuel &&
                                                <Fragment>
                                                    <div className="pumperLineValue">
                                                        <div className="pumperLineMobileTitle">
                                                            <FormattedMessage id="naturalGas.label" defaultMessage="Natural Gas"/>
                                                        </div>
                                                        <div
                                                            className="pumperLineName1">{Formatting.numberWithCommas(_.sumBy(dailyTotalList, props.project.uomStringShort === 'L' ? 'totalNGLitres' : 'totalNGGallons'))} D{props.project.uomStringShort}E
                                                        </div>
                                                        {props.project.naturalGasUnit === NaturalGasUnit.SCF &&
                                                            < div className="pumperLineName2">
                                                                {Formatting.numberWithCommas(_.sumBy(dailyTotalList, 'totalSCF'))} SCF
                                                            </div>
                                                        }
                                                        {props.project.naturalGasUnit === NaturalGasUnit.MCF &&
                                                        < div className="pumperLineName2">
                                                            {Formatting.numberWithCommas(_.sumBy(dailyTotalList, 'totalMCF'))} MCF
                                                        </div>
                                                        }
                                                        {props.project.naturalGasUnit === NaturalGasUnit.E3M3 &&
                                                            < div className="pumperLineName2">
                                                                {Formatting.numberWithCommas(_.sumBy(dailyTotalList, 'totalDEC'), 4)} E3M3
                                                            </div>
                                                        }
                                                    </div>
                                                    <div className="pumperLineEnergy">
                                                        <div className="pumperLineMobileTitle">
                                                            <FormattedMessage id="fracConsumedEnergy.label" defaultMessage="Frac Consumed Energy"/>
                                                        </div>
                                                        <div className="pumperLineName1">
                                                            {Formatting.numberWithCommas(_.sumBy(dailyTotalList, props.project.uomStringShort === 'L' ? 'totalConsumedLitres' : 'totalConsumedGallons'))}
                                                            D{props.project.uomStringShort}E
                                                        </div>
                                                        <div className="pumperLineName2">
                                                            {Formatting.numberWithCommas(_.sumBy(dailyTotalList, 'totalBTU'))} BTU
                                                        </div>
                                                    </div>
                                                </Fragment>
                                            }

                                            <div className="pumperLineValueNonFrac">
                                                <div className="pumperLineMobileTitle">
                                                    <FormattedMessage id="nonFracDiesel.label" defaultMessage="Non-Frac Diesel"/>
                                                </div>
                                                <div className="pumperLineName1">
                                                    {Formatting.numberWithCommas(_.sumBy(dailyTotalList, props.project.uomStringShort === 'L' ? 'totalNonFracDieselLitres' : 'totalNonFracDieselGallons'))}
                                                    {props.project.uomString}
                                                </div>
                                            </div>

                                            <div className="pumperLineValueLineTotal">
                                                <div className="pumperLineMobileTitle">
                                                    <FormattedMessage id="lineTotal.label" defaultMessage="Line Total"/>
                                                </div>
                                                <div className="pumperLineName1">
                                                    {Formatting.numberWithCommas(_.sumBy(dailyTotalList, props.project.uomStringShort === 'L' ? 'lineTotalLitres' : 'lineTotalGallons'))}
                                                    {props.project.uomString}
                                                </div>
                                            </div>

                                            <div className="pumperLineValueTotalizerTotal">
                                                <div className="pumperLineMobileTitle">
                                                    <FormattedMessage id="totalizerTotal.label" defaultMessage="Totalizer Total"/>
                                                </div>
                                                <div className="pumperLineName1">
                                                    {totalizerTotalSum === 0 ? 'N/A' : `${Formatting.numberWithCommas(totalizerTotalSum)}${props.project.uomString}`}
                                                </div>
                                            </div>

                                            <div className="pumperLineValueVarianceVolume">
                                                <div className="pumperLineMobileTitle">
                                                    <FormattedMessage id="varianceVolume.label" defaultMessage="Variance Volume"/>
                                                </div>
                                                <div className="pumperLineName1">
                                                    {varianceVolumeSum === 0 ? 'N/A' : `${Formatting.numberWithCommas(varianceVolumeSum)}${props.project.uomString}`}
                                                </div>
                                            </div>

                                            <div className="pumperLineValueVariancePercentage">
                                                <div className="pumperLineMobileTitle">
                                                    <FormattedMessage id="variancePercentage.label" defaultMessage="Variance Percentage"/>
                                                </div>
                                                <div className="pumperLineName1">
                                                    {totalizerTotalSum === 0 ? 'N/A' : `${_.round((1 - (_.sumBy(dailyTotalList, props.project.uomStringShort === 'L' ? 'lineTotalLitres' : 'lineTotalGallons') / totalizerTotalSum)) * -100, 2)}%`}
                                                </div>
                                            </div>


                                            <div className="pumperLineStages">
                                                <div className="pumperLineMobileTitle">
                                                    <FormattedMessage id="numberOfStages.label" defaultMessage="# of Stages"/>
                                                </div>
                                                <div className="pumperLineName1 ">
                                                    {Formatting.numberWithCommas(_.sumBy(dailyTotalList, 'stagesPerDay'))}
                                                </div>
                                            </div>

                                            {props.project.bifuel &&
                                                <Fragment>
                                                    <div className="pumperLineSubRate">
                                                        <div className="pumperLineMobileTitle">
                                                            <FormattedMessage id="subRate.label" defaultMessage="Sub Rate"/>
                                                        </div>
                                                        <div className="pumperLineName1">
                                                            {Formatting.numberWithCommas(((_.sumBy(dailyTotalList, 'totalNGGallons') / (_.sumBy(dailyTotalList, 'totalDieselGallons') + _.sumBy(dailyTotalList, 'totalNGGallons'))) * 100).toString())}%
                                                        </div>
                                                    </div>
                                                </Fragment>
                                            }
                                            <div className="clearfix"></div>
                                        </div>
                                    </td>
                                </tr>
                            }
                        </table>
                    </div>
                </div>
            </Col>
        </Row>
    )
}