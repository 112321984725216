import React from "react";
import {FormattedMessage} from "react-intl";

export default function Authentication(props) {

    return (
        <div className="api-content-container" id="Authentication"  ref={props.refProp}>
            <div className="api-section-details half-section">
                <h1 className="api-section-header">
                    <FormattedMessage id="auth.header" defaultMessage="Authentication"/>
                </h1>
                <p>
                    <FormattedMessage id="auth.message1"
                                      defaultMessage="The Energera API uses API keys to authenticate requests. You can view and manage your API keys in the Project Info > Permissions."/>
                </p>
                <p>
                    <FormattedMessage id="auth.message2"
                                      defaultMessage="Be sure to keep your API keys secure! Do not share your API keys in publicly accessible areas such as GitHub or client-side code."/>
                </p>
                <p>
                    <FormattedMessage id="auth.message3"
                                      defaultMessage=""/>
                </p>

            </div>
            <div className="api-section-code half-section">
                <div className="api-section-code-topbar">
                    <FormattedMessage id="auth.Code.tokenAuthentication" defaultMessage="Token Authentication"/>
                </div>
                <div className="api-section-code-bottom">
                    <div>
                        <span className="curl-definition-path">ApiToken &#123;API key&#125;</span>
                    </div>
                </div>
            </div>
        </div>
    )
}