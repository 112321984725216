import React, { useState, useEffect } from "react";
import {FormattedMessage} from "react-intl";


export default function FreeTier(props) {
    const [show, setShow] = useState(false);
    let responseJson =
        "[\n" +
        "  {\n" +
        "    \"jobId\": 1234567890,\n" +
        "    \"date\": \"2021-01-01T00:00:00\",\n" +
        "    \"diesel\": 1000.00,\n" +
        "    \"naturalGas\": 1000.00,\n" +
        "    \"sideKick\": 1000.00,\n" +
        "    \"grossVolume\": 1000.00\n" +
        "  },\n" +
        "  {\n" +
        "    \"jobId\": 1234567890,\n" +
        "    \"date\": \"2021-01-02T00:00:00\",\n" +
        "    \"diesel\": 1000.00,\n" +
        "    \"naturalGas\": 1000.00,\n" +
        "    \"sideKick\": 1000.00,\n" +
        "    \"grossVolume\": 1000.00\n" +
        "  }\n" +
        "]";
    

    return (
        <div className="api-content-container" id="FreeTier" ref={props.refProp}>
            <div className="api-section-details half-section">
                <h1 className="api-section-header">
                    <FormattedMessage id="FreeTier.header" defaultMessage="Free Tier Fuel Consumption"/>
                </h1>
                <p>
                    <FormattedMessage id="FreeTier.message1"
                                      defaultMessage="For use by customers with a free subscription. Use this API to fetch the diesel, natural gas, and sidekick fuel consumption of a project on a per day basis. Limited to 3 a day."/>
                </p>
                {show && 
                    <div>
                        <h4>
                        <FormattedMessage id="FreeTier.showMore.header1" defaultMessage="Fuel Consumption Object"/>
                        </h4>
                        <h5 className={"api-section-return-object-header3"}>
                            <FormattedMessage id="FreeTier.showMore.header2" defaultMessage="Attributes"/>
                        </h5>
                        <hr className={"dividing-line"}/>
                        <div>
                            <span className="object-attribute-name">
                                <FormattedMessage id="FreeTier.showMore.attribute1" defaultMessage="jobId"/>
                            </span>
                            <span className="object-attribute-type">
                                <FormattedMessage id="FreeTier.showMore.attribute1.type" defaultMessage="long"/>
                            </span>
                            <p className="object-attribute-description">                                
                                <FormattedMessage id="FreeTier.showMore.attribute1.description" defaultMessage="Unique identifier for the job."/>
                            </p>
                        </div>
                        <div>
                            <span className="object-attribute-name">
                                <FormattedMessage id="FreeTier.showMore.attribute2" defaultMessage="date"/>
                            </span>
                            <span className="object-attribute-type">
                                <FormattedMessage id="FreeTier.showMore.attribute2.type" defaultMessage="dateTime"/>
                            </span>
                            <p className="object-attribute-description">
                                <FormattedMessage id="FreeTier.showMore.attribute2.description" defaultMessage="End date of the job. The end date of a job is determined by which day the endTime of the job lands on."/>
                            </p>
                        </div>
                        <div>                           
                            <span className="object-attribute-name">
                                <FormattedMessage id="FreeTier.showMore.attribute3" defaultMessage="diesel"/>
                            </span>
                            <span className="object-attribute-type">
                                <FormattedMessage id="FreeTier.showMore.attribute3.type" defaultMessage="double"/>
                            </span>
                            <p className="object-attribute-description">
                                <FormattedMessage id="FreeTier.showMore.attribute3.description" defaultMessage="Volume of diesel consumed in liters or gallons depending on project preference."/>
                            </p>
                        </div>
                        <div>
                            <span className="object-attribute-name">
                                <FormattedMessage id="FreeTier.showMore.attribute4" defaultMessage="naturalGas"/>
                            </span>
                            <span className="object-attribute-type">
                                <FormattedMessage id="FreeTier.showMore.attribute4.type" defaultMessage="double"/>
                            </span>
                            <p className="object-attribute-description">
                                <FormattedMessage id="FreeTier.showMore.attribute4.description" defaultMessage="Volume of diesel consumed in E3m3."/>
                            </p>
                        </div>
                        <div>
                            <span className="object-attribute-name">
                                <FormattedMessage id="FreeTier.showMore.attribute5" defaultMessage="sideKick"/>
                            </span>
                            <span className="object-attribute-type">
                                <FormattedMessage id="FreeTier.showMore.attribute5.type" defaultMessage="double"/>
                            </span>
                            <p className="object-attribute-description">
                                <FormattedMessage id="FreeTier.showMore.attribute5.description" defaultMessage="Volume of side kick fuel consumed in SCF."/>
                            </p>
                        </div>
                        <div>
                            <span className="object-attribute-name">
                                <FormattedMessage id="FreeTier.showMore.attribute6" defaultMessage="grossVolume"/>
                            </span>
                            <span className="object-attribute-type">
                                <FormattedMessage id="FreeTier.showMore.attribute6.type" defaultMessage="double"/>
                            </span>
                            <p className="object-attribute-description">
                                <FormattedMessage id="FreeTier.showMore.attribute6.description" defaultMessage="Gross volume for fuel tickets in liters or gallons depending on project preference."/>
                            </p>
                        </div>
                        <h5 className={"api-section-return-object-header3"}>
                            <FormattedMessage id="FreeTier.showMore.header3" defaultMessage="Parameters"/>
                        </h5>
                        <hr className={"dividing-line"}/>
                        <div>
                            <span className="object-attribute-name">
                                <FormattedMessage id="FreeTier.showMore.parameter1" defaultMessage="startDate"/>
                            </span>
                            <span className="object-attribute-type">
                                <FormattedMessage id="FreeTier.showMore.parameter1.type" defaultMessage="dateTime"/>
                            </span>
                            <p className="object-attribute-description">
                                <FormattedMessage id="FreeTier.showMore.parameter1.description" defaultMessage="The results will only include data after the specified date. If no date is specified then assumes startDate is 0001-01-01. Format YYYY-MM-DD."/>
                            </p>
                        </div>
                        <div>
                            <span className="object-attribute-name">
                                <FormattedMessage id="FreeTier.showMore.parameter2" defaultMessage="endDate"/>
                            </span>
                            <span className="object-attribute-type">
                                <FormattedMessage id="FreeTier.showMore.parameter2.type" defaultMessage="dateTime"/>
                            </span>
                            <p className="object-attribute-description">
                                <FormattedMessage id="FreeTier.showMore.parameter2.description" defaultMessage="The results will only include data before the specified date. If no date is specified then assumes endDate is current time. Format YYYY-MM-DD. "/>
                            </p>
                        </div>
                    </div>
                }
                <div >
                    <button className="show-button" onClick={() => setShow(!show)}>
                        {show && <FormattedMessage id="showButton.hide" defaultMessage="Hide"/> || <FormattedMessage id="showButton.show" defaultMessage="Show more"/>}
                    </button>
                </div>
            </div>
            <div className="api-section-code half-section">
                <div className="api-section-code-topbar">
                    <FormattedMessage id="FreeTier.Code.endpoints" defaultMessage="ENDPOINTS"/>
                </div>
                <div className="api-section-code-bottom">
                    <div>
                        <span className="curl-definition-method-get">GET</span>
                        <span className="curl-definition-path">/api/project/fuel-consumption</span>
                    </div>
                    <div>
                        <span className="curl-definition-method-get">GET</span>
                        <span className="curl-definition-path">/api/project/fuel-consumption?startDate={"{date}"}</span>
                    </div>
                    <div>
                        <span className="curl-definition-method-get">GET</span>
                        <span className="curl-definition-path">/api/project/fuel-consumption?endDate={"{date}"}</span>
                    </div>
                    <div>
                        <span className="curl-definition-method-get">GET</span>
                        <span className="curl-definition-path">/api/project/fuel-consumption?startDate={"{date}"}&endDate={"{date}"}</span>
                    </div>
                </div>
                {show &&
                    <div >
                        <div className="api-section-code-topbar">
                            <FormattedMessage id="FreeTier.Code.example" defaultMessage="EXAMPLE"/>
                        </div>
                        <div className="api-section-code-bottom">
                            <div>
                                <span className="curl-definition-method-get">GET</span>
                                <span className="curl-definition-path">/api/project/fuel-consumption?startDate=2021-01-01&endDate=2021-01-02</span>
                            </div>
                        </div>
                        <div className="api-section-code-topbar">
                            <FormattedMessage id="FreeTier.Code.response" defaultMessage="RESPONSE"/>
                        </div>
                        <div className="api-section-code-bottom">
                            <pre className={"curl-definition-path"}>{responseJson}</pre>
                        </div>
                    </div>
                }
            </div>
        </div>
        
    )
}