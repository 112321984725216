import React, {useState} from "react";
import {Badge, Button} from "reactstrap";
import {FormattedMessage, useIntl} from "react-intl";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Tooltip} from "reactstrap";
import Calendar from "react-calendar";
import {ApiToken} from "./index";
import moment from "moment";
import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle} from "reactstrap";
import {TokenTierEnum} from "../../../interfaces/User";

function ApiTokenRow(props: any) {
    const intl = useIntl();
    const [isExpiryCalendarOpen, setIsExpiryCalendarOpen] = useState(false);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [isTierDropdownOpen, setIsTierDropdownOpen] = useState(false);

    const toggleExpiryCalendar = () => setIsExpiryCalendarOpen(!isExpiryCalendarOpen);
    const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);
    const toggleTierDropdown = () => setIsTierDropdownOpen(!isTierDropdownOpen);

    const modifyExpiryDate = async (expiryDate: Date) => {
        let newApiToken: ApiToken = {
            ...props.apiToken,
            expiryDate: moment(expiryDate).format("yyyy-MM-DDTHH:mm:ss")
        }
        props.modifyApiToken(newApiToken, props.newToken, props.index);
        setIsExpiryCalendarOpen(false);
    }

    const modifyTokenType = async (tokenType: string) => {
        let newApiToken: ApiToken = {
            ...props.apiToken,
            tokenType: tokenType
        }
        props.modifyApiToken(newApiToken, props.newToken, props.index);
    }

    const modifyTokenTier = async (tokenTier: string) => {
        let newApiToken: ApiToken = {
            ...props.apiToken,
            tokenTier: tokenTier
        }
        props.modifyApiToken(newApiToken, props.newToken, props.index);
    }

    const modifyTokenStatus = async (status: boolean) => {
        let newApiToken: ApiToken = {
            ...props.apiToken,
            isActive: status
        }
        props.modifyApiToken(newApiToken, props.newToken, props.index);
    }

    return (
        <>
            {
                !props.isModifying &&
                <tr style={{textAlign: "center"}}>
                    <td>
                        <Button onClick={() => {
                            navigator.clipboard.writeText(props.apiToken.value)
                        }}
                                color="link">{props.apiToken.value}</Button>
                        <Badge color="light" pill><FormattedMessage id="clickToCopy.label"
                                                                    defaultMessage="Click to copy"/></Badge>
                    </td>
                    <td>{props.apiToken.createdDate}</td>
                    <td>{props.apiToken.expiryDate}</td>
                    <td>
                        {props.apiToken.isActive ?
                            new Date(props.apiToken.expiryDate) < new Date(Date.now()) ?
                                intl.formatMessage({id: "expired.label", defaultMessage: "Expired"}) :
                                intl.formatMessage({id: "active.label", defaultMessage: "Active"})
                            : intl.formatMessage({id: "disabled.label", defaultMessage: "Disabled"})
                        }
                    </td>
                    <td>{props.apiToken.tokenType}</td>
                    <td>{props.apiToken.tokenTier}</td>
                </tr>
            }

            {
                props.isModifying && props.newToken === false &&
                <tr style={{textAlign: "center"}}>
                    <td>{props.apiToken.value}</td>
                    <td>{props.apiToken.createdDate}</td>
                    <td>
                        <span>{props.apiToken.expiryDate}</span>
                        <span className="icon-effects" style={{marginLeft: "15px"}}>
                            <FontAwesomeIcon icon="calendar-alt" id={`infoIcon-existing-token-${props.index}`}/>
                            <Tooltip placement="right"
                                     isOpen={isExpiryCalendarOpen}
                                     toggle={toggleExpiryCalendar}
                                     trigger="click"
                                     target={`infoIcon-existing-token-${props.index}`}>
                                <Calendar value={new Date(props.apiToken.expiryDate)}
                                          onChange={modifyExpiryDate}/>
                            </Tooltip>
                        </span>
                    </td>
                    <td>
                        <span style={{marginRight: "15px"}}>
                            {props.apiToken.isActive ?
                                new Date(props.apiToken.expiryDate) < new Date(Date.now()) ?
                                    intl.formatMessage({id: "expired.label", defaultMessage: "Expired"}) :
                                    intl.formatMessage({id: "active.label", defaultMessage: "Active"})
                                : intl.formatMessage({id: "disabled.label", defaultMessage: "Disabled"})
                            }
                        </span>
                        {props.apiToken.isActive &&
                        <Button color="danger"
                                size="sm"
                                outline
                                onClick={() => modifyTokenStatus(!props.apiToken.isActive)}>
                            {intl.formatMessage({id: "disable.label", defaultMessage: "Disable"})}
                        </Button>
                        }
                        {!props.apiToken.isActive &&
                        <Button color="warning"
                                size="sm"
                                outline
                                onClick={() => modifyTokenStatus(!props.apiToken.isActive)}>
                            {intl.formatMessage({id: "enable.label", defaultMessage: "Enable"})}
                        </Button>
                        }
                    </td>
                    <td>{props.apiToken.tokenType}</td>
                    <td>{props.apiToken.tokenTier}</td>
                    <td>
                        <Button color="danger"
                                outline
                                onClick={() => props.deleteApiToken(props.apiToken)}>
                            <FormattedMessage id="delete.label" defaultMessage="Delete"/>
                        </Button>
                    </td>
                </tr>
            }

            {
                props.isModifying && props.newToken === true &&
                <tr style={{textAlign: "center"}}>
                    <td>{props.apiToken.value}</td>
                    <td>{props.apiToken.createdDate}</td>
                    <td>
                        <span>{props.apiToken.expiryDate}</span>
                        <span className="icon-effects" style={{marginLeft: "15px"}}>
                            <FontAwesomeIcon icon="calendar-alt" id={`infoIcon-new-token-${props.index}-end`}/>
                            <Tooltip placement="right"
                                     isOpen={isExpiryCalendarOpen}
                                     trigger="click"
                                     toggle={toggleExpiryCalendar}
                                     target={`infoIcon-new-token-${props.index}-end`}>
                                <Calendar value={new Date(props.apiToken.expiryDate)}
                                          onChange={modifyExpiryDate}/>
                            </Tooltip>
                        </span>
                    </td>
                    <td>
                        <span style={{marginRight: "15px"}}>
                            {props.apiToken.isActive ?
                                new Date(props.apiToken.expiryDate) < new Date(Date.now()) ?
                                    intl.formatMessage({id: "expired.label", defaultMessage: "Expired"}) :
                                    intl.formatMessage({id: "active.label", defaultMessage: "Active"})
                                : intl.formatMessage({id: "disabled.label", defaultMessage: "Disabled"})
                            }
                        </span>
                        {props.apiToken.isActive &&
                        <Button color="danger"
                                size="sm"
                                outline
                                onClick={() => modifyTokenStatus(!props.apiToken.isActive)}>
                            {intl.formatMessage({id: "disable.label", defaultMessage: "Disable"})}
                        </Button>
                        }
                        {!props.apiToken.isActive &&
                        <Button color="warning"
                                size="sm"
                                outline
                                onClick={() => modifyTokenStatus(!props.apiToken.isActive)}>
                            {intl.formatMessage({id: "enable.label", defaultMessage: "Enable"})}
                        </Button>
                        }
                    </td>
                    <td>
                        <Dropdown isOpen={isDropdownOpen} toggle={toggleDropdown}>
                            <DropdownToggle caret>
                                {props.apiToken.tokenType}
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu">
                                <DropdownItem className="dropdown-item"
                                              onClick={() => modifyTokenType("Project")}>
                                    <FormattedMessage id="project.label" defaultMessage="Project"/>
                                </DropdownItem>
                                <DropdownItem className="dropdown-item"
                                              onClick={() => modifyTokenType("Company")}>
                                    <FormattedMessage id="company.label" defaultMessage="Company"/>
                                </DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                    </td>
                    <td>
                        <Dropdown isOpen={isTierDropdownOpen} toggle={toggleTierDropdown}>
                            <DropdownToggle caret>
                                {props.apiToken.tokenTier}
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu">
                                <DropdownItem className="dropdown-item"
                                              onClick={() => modifyTokenTier(TokenTierEnum.Free)}>
                                    <FormattedMessage id="free.label" defaultMessage="Free"/>
                                </DropdownItem>
                                <DropdownItem className="dropdown-item"
                                              onClick={() => modifyTokenTier(TokenTierEnum.Silver)}>
                                    <FormattedMessage id="silver.label" defaultMessage="Silver"/>
                                </DropdownItem>
                                <DropdownItem className="dropdown-item"
                                              onClick={() => modifyTokenTier(TokenTierEnum.Gold)}>
                                    <FormattedMessage id="gold.label" defaultMessage="Gold"/>
                                </DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                    </td>
                    <td>
                        <Button color="danger"
                                outline
                                onClick={() => props.removeNewApiToken(props.index)}>
                            <FormattedMessage id="delete.label" defaultMessage="Delete"/>
                        </Button>
                    </td>
                </tr>
            }
        </>

    )
}

export default ApiTokenRow;