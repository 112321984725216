import React, { Component } from 'react'
import { Table, Input, Button } from 'reactstrap'
import { getState, setState, subscribeToState } from 'litsy'
import axios from 'axios';
import { NetworkRequestStatus } from '../enums';
import * as Formatting from '../helpers/formatting';
import {FormattedMessage} from "react-intl";

export default class FuelTicket extends Component {

  componentDidMount() {
    subscribeToState("fracShack_currentProject", "fracShack__screens__fuelTickets", () => {this.forceUpdate.bind(this)()}, "volatile");
  }

  render() {
    const project = getState("fracShack_currentProject", "volatile")
    return <>
      <div>
        <div className="fuel-ticket-title">
        <FormattedMessage id="fuelTickets.label" defaultMessage="Fuel Tickets" />
      </div>
        <Table dark>
          <thead>
            <th>
              <FormattedMessage id="meterNumberId.label" defaultMessage="Meter Number Id" />
            </th>
            <th>
              <FormattedMessage id="netVolume.label" defaultMessage="Net Volume" />
            </th>
            <th>
              <FormattedMessage id="grossVolume.label" defaultMessage="Gross Volume" />
            </th>
            <th>
              <FormattedMessage id="avgFlowRate.label" defaultMessage="Avg Flow Rate" />
            </th>
            <th>
              <FormattedMessage id="fuelType.label" defaultMessage="Fuel Type" />
            </th>
            <th>
              <FormattedMessage id="correctionTemperature.label" defaultMessage="Correction Temperature" />
            </th>
            <th>
              <FormattedMessage id="ambientTemperature.label" defaultMessage="Ambient Temperature" />
            </th>
            <th>
              <FormattedMessage id="truckOffloadStartTime.label" defaultMessage="Truck offload start time" />
            </th>
            <th>
              <FormattedMessage id="truckOffloadEndTime.label" defaultMessage="Truck offload end time" />
            </th>
          </thead>
          <tbody>
            {
              project && project.fuelTickets && project.fuelTickets.map(fuelTicket => 
              <tr>
                <td>{fuelTicket.auxMeterId}</td>
                <td>{Formatting.numberWithCommas(fuelTicket.eventVolumeCorrected)}</td>
                <td>{Formatting.numberWithCommas(fuelTicket.tripVolume)}</td>
                <td>{Formatting.numberWithCommas(fuelTicket.eventFlowRate)}</td>
                <td>{fuelTicket.commodityId}</td>
                <td>{this.props.uom === 1 ? "60 °F" : this.props.uom === 2 ? "15.5 °C" : "N/A"}</td>
                <td>{fuelTicket.ambientTemperature === null ? "N/A" : fuelTicket.ambientTemperature}</td>
                <td>{fuelTicket.eventStart}</td>
                <td>{fuelTicket.eventEnd}</td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
    </>
  }
}