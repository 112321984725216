import React, { useEffect } from 'react';
import { useCookies } from 'react-cookie';

export default function CookieUrl(props)
{
    const [cookies, setCookieURL, removeCookieURL] = useCookies(['url']);

    useEffect(() => {
        if (props.mode === 'set' && props.project !== null)
        {
            var url = window.location.pathname;
            removeCookieURL('url');
            setCookieURL('url', url, { path: '/', expires: new Date(new Date().setFullYear(new Date().getFullYear() + 1))} )
        }else if (props.mode == 'get')
        {
            if (cookies.url !== undefined)
            {
                var pathVars = cookies.url.split('/');
                var fetchURL = global.apiEndpoint + 'project/' + pathVars[2] + '?producerName=' + pathVars[1] + '&startTime=' + pathVars[3] + '&uom=2&test=true';
                const requestOptions = { method: 'GET',
                    headers: {
                    Authorization: 'Bearer ' + props.token
                    }
                };

                fetch(fetchURL, requestOptions).then(function(response){
                    if (response.status === 200)
                    {
                        // window.location.replace(cookies.url);
                    }else{
                        props.handleRedirect();
                    }
                });
            }else
            {
                props.handleRedirect();
            }
        }
    }, []);

    return null;
}