import React, {Fragment, useEffect, useState} from 'react';
import * as Formatting from '../helpers/formatting';
import {FormattedMessage} from "react-intl";
import NgSidekickGasCompositionElement from "./NgSidekickGasCompositionElement";

export default function FuelLevelWellLine(props)
{
    const requestWell = (well) => {
        props.requestWell(well);
    };

    return(
        <Fragment>
            <tr>
                <td>
                    <div className='pumperLine'>
                        {props.single === true &&
                        <Fragment>
                            <div className="pumperLineName"><FormattedMessage id="snapshot.label" defaultMessage="Snapshot" /> {props.index}</div>
                        </Fragment>
                        }
                        {props.single !== true &&
                        <Fragment>
                            <div className="pumperLineName">
                                <div className="pumperLineName1 pumperNameLink" onClick={() => requestWell(props.well)}>{props.well.wellId !== '' ?
                                    <span> <FormattedMessage id="well.label" defaultMessage="Well" /> {props.well.wellId}</span>
                                    : <i><FormattedMessage id="unknownWell.label" defaultMessage="Unknown Well" /></i>}</div>
                            </div>
                        </Fragment>
                        }
                        {props.single !== true &&
                        <fragment>
                            <div className="pumperLineValue">
                                <div className="pumperLineMobileTitle">
                                    <FormattedMessage id="totalVolumeConsumed.label" defaultMessage="Total Volume Consumed" />
                                </div>
                                <div className="pumperLineName1">{Formatting.numberWithCommas(props.well.totalVolumeConsumed)} {props.well.unitOfMeasurement}</div>
                            </div>
                            <div className="pumperLineValue">
                                <div className="pumperLineMobileTitle">
                                    <FormattedMessage id="totalVolumeFueled.label" defaultMessage="Total Volume Fueled" />
                                </div>
                                <div className="pumperLineName1">{Formatting.numberWithCommas(props.well.totalVolumeFueled)} {props.well.unitOfMeasurement}</div>
                            </div>
                            <div className="pumperLineValue">
                                <div className="pumperLineMobileTitle">
                                    <FormattedMessage id="numberOfSnapshots.label" defaultMessage="# of Snapshots" />
                                </div>
                                <div className="pumperLineName1">{props.well.totalSnapshots}</div>
                            </div>
                        </fragment>
                        }
                        {props.single === true &&
                        <Fragment>
                            <div className="pumperLineValue">
                                <div className="pumperLineMobileTitle">
                                    <FormattedMessage id="volumeConsumed.label" defaultMessage="Volume Consumed" />
                                </div>
                                <div className="pumperLineName1">{Formatting.numberWithCommas(props.well.volumeConsumed)} {props.well.unitOfMeasurement}</div>
                            </div>
                            <div className="pumperLineValue">
                                <div className="pumperLineMobileTitle">
                                    <FormattedMessage id="volumeFueled.label" defaultMessage="Volume Fueled" />
                                </div>
                                <div className="pumperLineName1">{Formatting.numberWithCommas(props.well.volumeFueled)} {props.well.unitOfMeasurement}</div>
                            </div>
                            <div className="pumperLineFinishTime">
                                <div className="pumperLineMobileTitle">
                                    <FormattedMessage id="finishTimeUTC.label" defaultMessage="Finish Time (UTC)" />
                                </div>
                                <div className="pumperLineName1 floatRightNoMargin">{props.well.endTimeString}</div>
                            </div>
                            <div className="pumperLineStartTime">
                                <div className="pumperLineMobileTitle">
                                    <FormattedMessage id="startTimeUTC.label" defaultMessage="Start Time (UTC)" />
                                </div>
                                <div className="pumperLineName1 floatRightNoMargin">{props.well.startTimeString}</div>
                            </div>
                        </Fragment>
                        }
                    </div>
                </td>
            </tr>
            <div className="clearfix"></div>
        </Fragment>
    )
}