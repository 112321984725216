import React, {Fragment, useContext, useEffect, useState} from 'react';
import {FormattedMessage, useIntl} from "react-intl";
import {ProjectMenuContext} from "../Contexts/ProjectMenuContext";
import {ReactComponent as WhiteUp} from "../../../images/whiteUp.svg";
import {ReactComponent as WhiteDown} from "../../../images/whiteDown.svg";
import NgSidekickWellList from "../../NgSidekickWellList";
import ReactGA from "react-ga";
import {ReactComponent as BlackRight} from "../../../images/arrowRightBlack.svg";
import {ProjectContext} from "../Contexts/ProjectContext";

export default function NgSideKick(props) {
    const {closeMobileMenu} = useContext(ProjectMenuContext);
    const {project, setActivePage, setValue, setMobileText} = useContext(ProjectContext);
    const [showNgSideKickDropdown, setShowNgSideKickDropdown] = useState(false);
    const intl = useIntl();

    useEffect(() => {
        if (project.activePage !== "NgSideKick") {
            setShowNgSideKickDropdown(false);
        }
    }, [project.activePage])

    const activePage = () => {
        return project.activePage === "NgSideKick" ? 'siteTitle siteTitleActive menuItem menuItemActive' : 'siteTitle menuItem'
    }

    const toggleNgSideKickMenu = () => {
        switch (project.activePage) {
            case "NgSideKick":
                setShowNgSideKickDropdown(!showNgSideKickDropdown);
                break;
            default:
                setShowNgSideKickDropdown(true);
                requestNgSideKickProjectStats();
        }
    }

    const requestNgSideKickProjectStats = () => {
        setValue('activeWell', 'all');
        setMobileText(intl.formatMessage({id: "allWells.label", defaultMessage: "All Wells"}));

        if (project.activePage !== "NgSideKick") {
            setActivePage("NgSideKick");
        } else {
            closeMobileMenu();
        }

        // Google Analytics
        ReactGA.event({
            category: "Project Menu Selection",
            action: `Clicked on "Ng Sidekick: All Wells" on Project "${window.location.pathname}"`
        })

        // Google Tag Manager
        window.dataLayer.push({
            'event': "Pageview",
            'pagePath': `${window.location.pathname}/ng-sidekick/wells/all-wells`,
            'pageTitle': `Ng Sidekick - All Wells`
        })
    }
    
    const requestNgSidekickWellStats = (well) => {
        setValue('activeWell', well);
        setMobileText(well.wellName !== '' ? 
            intl.formatMessage({id: "well.label", defaultMessage: "Well"}) + " " + well.wellName 
            : 'Unknown Well');
        closeMobileMenu();

        // Google Analytics
        ReactGA.event({
            category: "Project Menu Selection",
            action: `Clicked on "${'Ng Sidekick: Well ' + well.wellName}" on Project "${window.location.pathname}"`
        })

        // Google Tag Manager
        window.dataLayer.push({
            'event': "Pageview",
            'pagePath': `${window.location.pathname}/ng-sidekick/wells/${well.wellName}`,
            'pageTitle': `Ng Sidekick - Well ${well.wellName}`
        })
    }

    return (
        <Fragment>
            {project.currentProject.ngSideKick.ngSideKickWells.length > 0 &&
            <Fragment>
                {!props.mobile &&
                <Fragment>
                    <h1 className={activePage()} onClick={() => toggleNgSideKickMenu()}><FormattedMessage
                        id="ngSidekick.label" defaultMessage="NG Sidekick"/>
                        {showNgSideKickDropdown && <WhiteUp className="floatRight"/>}
                        {!showNgSideKickDropdown && <WhiteDown className="floatRight"/>}
                    </h1>
                    {showNgSideKickDropdown &&
                    <NgSidekickWellList wells={project.currentProject.ngSideKick.ngSideKickWells}
                                        onProjectClick={requestNgSideKickProjectStats}
                                        onClick={(well) => requestNgSidekickWellStats(well)}
                                        activeWell={project.activeWell} allProjects={props.projectStats}/>
                    }
                </Fragment>
                }
                {props.mobile &&
                <Fragment>
                    <div className="entireSiteMobile" onClick={() => toggleNgSideKickMenu()}>
                        <div className="mobileText">
                            <FormattedMessage id="ngSidekick.label" defaultMessage="NG Sidekick"/>
                        </div>
                        <div className="floatRight mobileArrow"><BlackRight/></div>
                    </div>
                    {showNgSideKickDropdown &&
                    <NgSidekickWellList wells={project.currentProject.ngSideKick.ngSideKickWells}
                                        onProjectClick={requestNgSideKickProjectStats}
                                        onClick={requestNgSidekickWellStats} activeWell={project.activeWell}
                                        allProjects={props.projectStats}/>
                    }
                </Fragment>
                }
            </Fragment>
            }
        </Fragment>
    )
}