import React, { Fragment } from 'react';
import ReactDOM from 'react-dom'
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { EnergyProducer } from '../../../interfaces/Project';
import ReactMenuAim from './ReactMenuAim'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronLeft, faChevronUp, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import slugify from '../../../helpers/slugify';
import { actionCreators } from './../../../store/Header'
import './NavMenu.css'
import SmallLoader from "../Loader/SmallLoader.js";
import { FormattedMessage } from "react-intl";
let createReactClass = require('create-react-class')

const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
enum navMenuStateTypes {
	producer = 'PRODUCER',
	project = 'PROJECT',
	date = 'DATE'
}
interface NavMenuProps {
	producers: EnergyProducer[]
	isLoading: boolean
	isError: boolean
}
const NavMenu: React.FunctionComponent<NavMenuProps> = createReactClass({
	mixins: [ReactMenuAim],

	getDefaultProps: function () {
		return {
			submenuDirection: 'right',
			subSubMenuDirection: 'right'
		};
	},

	getInitialState: function () {
		return {
			navMenuButtonText: '',
			isMobile: false,
			activeMenuIndex: -1,
			activeSubmenuIndex: -1,
			selectedProducer: '',
			selectedProject: '',
			isNavMenuOpen: false,
			navMenuState: navMenuStateTypes.producer
		};
	},

	componentDidMount: function () {
		this.handleWindowWidthChange(window.innerWidth)
		window.addEventListener("resize", () => { this.handleWindowWidthChange(window.innerWidth) })
		window.addEventListener("click", (e) => { this.handleMouseClick(e) })

		this.initMenuAim({
			submenuDirection: this.props.submenuDirection,
			menuSelector: '.menu',
			delay: 300,
			tolerance: 75
		});
	},

	handleSwitchMenuIndex: function (index: number) {
		this.setState({
			activeMenuIndex: index,
			activeSubmenuIndex: -1
		});
	},

	handleSwitchSubmenuIndex: function (index: number) {
		this.setState({
			activeSubmenuIndex: index
		});
	},

	handleWindowWidthChange: function (windowWidth: number) {
		const { project } = this.props
		const { selectedProducer, navMenuState, selectedProject, isMobile } = this.state

		this.setState({ isMobile: windowWidth <= 768 }, () => {
			if (project !== null)
			{
				if (!isMobile) {
					this.setState({ navMenuButtonText: project.energyProducerName + ' / ' + project.name })
				} else {
					if (navMenuState === navMenuStateTypes.producer) {
						this.setState({ navMenuButtonText: project.energyProducerName })
					} else if (navMenuState === navMenuStateTypes.project) {
						this.setState({ navMenuButtonText: selectedProducer })
					} else if (navMenuState === navMenuStateTypes.date) {
						this.setState({ navMenuButtonText: selectedProject })
					}
				}
			}else{
				this.setState({ navMenuButtonText: <FormattedMessage id="selectAProject.label" defaultMessage="Select a Project"/>})
			}
		})
	},

	// closes the menu and sets to default values when user clicks away (DESKTOP ONLY, doesn't work on mobile)
	handleMouseClick: function (e: any) {
		const { isNavMenuOpen, isMobile } = this.state
		const { project } = this.props
		const thisDomNode = ReactDOM.findDOMNode(this)
		const overlayDomNode = document.getElementById('header-site-dropdown-overlay')
		const clickedDomNode = e.target

		if ((!thisDomNode || !thisDomNode.contains(clickedDomNode) || clickedDomNode === overlayDomNode) && isNavMenuOpen && !isMobile) {
			document.body.classList.remove("loading-body")
			this.setState({ isNavMenuOpen: false })
			this.setState({ navMenuState: navMenuStateTypes.producer })
			this.setState({
				activeMenuIndex: -1,
				activeSubmenuIndex: -1
			})
			if (project !== null)
			{
				this.setState({ navMenuButtonText: project.energyProducerName + ' / ' + project.name });
			}else{
				this.setState({ navMenuButtonText: <FormattedMessage id="selectAProject.label" defaultMessage="Select a Project"/> });
			}
			
		}
	},

	handleNavMenuButtonOnClick: function () {
		const { selectedProducer, navMenuState, isNavMenuOpen, isMobile } = this.state
		const { project } = this.props

		if (isMobile) {
			//mobile
			if (navMenuState === navMenuStateTypes.producer) {
				if (isNavMenuOpen) {
					document.body.classList.remove("loading-body")
				} else {
					document.body.classList.add("loading-body")
				}
				this.setState({ isNavMenuOpen: !isNavMenuOpen })
				this.setState({
					activeMenuIndex: -1,
					activeSubmenuIndex: -1
				});
			} else if (navMenuState === navMenuStateTypes.project) {
				//user hits button, go back to producer menu
				this.setState({ navMenuButtonText: project.energyProducerName })
				this.setState({ navMenuState: navMenuStateTypes.producer })
			} else if (navMenuState === navMenuStateTypes.date) {
				//user is on date menu
				//user hits button, go back to project menu
				this.setState({ navMenuButtonText: selectedProducer })
				this.setState({ navMenuState: navMenuStateTypes.project })
			}
		} else {
			//desktop, simple on/off toggle
			if (isNavMenuOpen) {
				document.body.classList.remove("loading-body");
			} else {
				document.body.classList.add("loading-body");
			}
			this.setState({ isNavMenuOpen: !isNavMenuOpen })
			this.setState({ navMenuState: navMenuStateTypes.producer })
			this.setState({
				activeMenuIndex: -1,
				activeSubmenuIndex: -1
			});
		}
	},

	handleMenuItemOnClick: function (selectedItem: string, selectedItemType: navMenuStateTypes) {
		const { isMobile } = this.state
		//this function is called when the user hits any of the items in the menu
		//this function is only used to render the proper producer/project/date menu based on the nav menu state
		//this will propagate the user to the next menu

		if (selectedItemType === navMenuStateTypes.producer) {
			//user is currently on producer
			//user hits a producer in the menu
			//change state to project
			this.setState({ selectedProducer: selectedItem })
			this.setState({ navMenuState: navMenuStateTypes.project })
		} else if (selectedItemType === navMenuStateTypes.project) {
			//user is currently on project
			//user hits a project in the menu
			//change state to date
			this.setState({ selectedProject: selectedItem })
			this.setState({ navMenuState: navMenuStateTypes.date })
		}
		//user hits a date, don't handle. dates will open up the project page

		//user hits any item in any menu in mobile, set the nav menu text
		if (isMobile) this.setState({ navMenuButtonText: selectedItem })
	},

	generateButtonIcon: function () {
		const { isMobile, navMenuState, isNavMenuOpen } = this.state

		//used for the nav menu button
		if (!isMobile || navMenuState === navMenuStateTypes.producer) {
			//desktop OR mobile and menu state is producer
			if (isNavMenuOpen) return <FontAwesomeIcon icon={faChevronUp} />
			return <FontAwesomeIcon icon={faChevronDown} />
		} else if (isMobile && navMenuState !== navMenuStateTypes.producer) {
			//mobile and NOT producer menu
			return <FontAwesomeIcon icon={faChevronLeft} />
		}
		return null
	},

	generateMobileMenu: function () {
		let self = this;
		const { navMenuState, activeMenuIndex, activeSubmenuIndex } = this.state
		const { producers } = this.props

		if (navMenuState === navMenuStateTypes.producer) {
			return (
				<ul className="menu">
					<style dangerouslySetInnerHTML={{__html: `
						.whiteBackground { display: none; }
					`}}>
					</style>
					{producers.map((producer: EnergyProducer, index: number) => {
						return (
							<li className='menu-item' key={producer.name}
								onClick={() => {
									self.handleSwitchMenuIndex(index)
									self.handleMenuItemOnClick(producer.name, navMenuStateTypes.producer)
								}}>
								{producer.name} <FontAwesomeIcon icon={faChevronRight} />
							</li>
						);
					})}
				</ul>
			)
		} else if (navMenuState === navMenuStateTypes.project) {
			return (
				<ul className="sub-menu">
					{producers[activeMenuIndex].projects_menu.map((project: any, index: number) => {
						return (
							<li className='sub-menu-item' key={project.name}
								onClick={() => {
									self.handleSwitchSubmenuIndex(index)
									self.handleMenuItemOnClick(project.name, navMenuStateTypes.project)
								}}>
								{project.name} <FontAwesomeIcon icon={faChevronRight} />
							</li>
						);
					})}
				</ul>
			)
		} else if (navMenuState === navMenuStateTypes.date) {
			return (
				<ul className="sub-sub-menu">
					{producers[activeMenuIndex].projects_menu[activeSubmenuIndex].startTimes.map((startDateTime: { startDateURL: string, startTime: string }) => {
						return (
							<a key={producers[activeMenuIndex].projects_menu[activeSubmenuIndex].name + startDateTime.startTime} href={"/" +
								slugify(producers[this.state.activeMenuIndex].name) +
								"/" +
								slugify(producers[activeMenuIndex].projects_menu[activeSubmenuIndex].name) +
								"/" + startDateTime.startDateURL
							} className="sub-sub-menu-item">
								{monthNames[new Date(startDateTime.startTime).getMonth()] + ' ' + new Date(startDateTime.startTime).getDate() + ', ' + new Date(startDateTime.startTime).getFullYear()}
							</a>
						);
					})}
				</ul>
			)
		}
		return null
	},

	generateDesktopMenu: function () {
		let self = this;
		const { activeMenuIndex, activeSubmenuIndex } = this.state
		const { producers } = this.props

		return (
			<Fragment>
				<ul className="menu"
				// onMouseLeave={this.handleMouseLeaveMenu}
				>
					{producers.map((producer: EnergyProducer, index: number) => {
						let className = 'menu-item';
						if (index === self.state.activeMenuIndex) {
							className += ' active';
						}

						return (
							<li className={className} key={producer.name}
								// onMouseEnter={function () {
								// 	self.handleMouseEnterRow.call(self, index, self.handleSwitchMenuIndex);
								// }}
								onClick={() => {
									self.handleSwitchMenuIndex(index)
									self.handleMenuItemOnClick(producer.name, navMenuStateTypes.producer)
								}}>
								{producer.name} <FontAwesomeIcon icon={faChevronRight} />
							</li>
						);
					})}

					{producers.length === 0 && 
						<SmallLoader />
					}
				</ul>
				{activeMenuIndex > -1 && <ul className="sub-menu"
				// onMouseLeave={this.handleMouseLeaveMenu}
				>
					{producers[activeMenuIndex].projects_menu.map((project: any, index: number) => {
						let className = 'sub-menu-item';
						if (index === self.state.activeSubmenuIndex) {
							className += ' active';
						}

						return (
							<li className={className} key={project.name}
								// onMouseEnter={function () {
								// 	self.handleMouseEnterRow.call(self, index, self.handleSwitchSubmenuIndex);
								// }}>
								onClick={() => {
									self.handleSwitchSubmenuIndex(index)
									self.handleMenuItemOnClick(project.name, navMenuStateTypes.project)
								}}>
								{project.name} <FontAwesomeIcon icon={faChevronRight} />
							</li>
						);
					})}
				</ul>}
				{activeSubmenuIndex > -1 &&
					<ul className="sub-sub-menu">
						{producers[activeMenuIndex].projects_menu[activeSubmenuIndex].startTimes.map((startDateTime: { startTime: string, startDateURL: string }) => {
							return (
                                <a
                                    key={
                                        producers[activeMenuIndex]
                                            .projects_menu[activeSubmenuIndex]
                                            .name + startDateTime.startTime
                                    }
                                    href={
                                        "/" +
                                        slugify(
                                            producers[activeMenuIndex].name
                                        ) +
                                        "/" +
                                        slugify(
                                            producers[activeMenuIndex]
                                                .projects_menu[
                                                activeSubmenuIndex
                                            ].name
                                        ) +
                                        "/" +
                                        startDateTime.startDateURL
                                    }
                                    className="sub-sub-menu-item">
                                    {monthNames[
                                        new Date(
                                            startDateTime.startTime
                                        ).getMonth()
                                    ] +
                                        " " +
                                        new Date(
                                            startDateTime.startTime
                                        ).getDate() +
                                        ", " +
                                        new Date(
                                            startDateTime.startTime
                                        ).getFullYear()}
                                </a>
                            );
						})}
					</ul>}
			</Fragment>
		);
	},

	render: function () {
		let self = this;
		const { navMenuButtonText, isNavMenuOpen, isMobile } = this.state
		let containerClassName = 'menu-container ' + this.props.submenuDirection;

		return (
			<div className="header-site-dropdown-container">
				<div className="header-site-dropdown-button-container">
					<div className={`header-site-dropdown-button${isNavMenuOpen ? '--active' : ''}`} onClick={self.handleNavMenuButtonOnClick}>
						<span className="header-site-dropdown-button-text">{navMenuButtonText}</span>
						{self.generateButtonIcon()}
					</div>
				</div>
				{isNavMenuOpen && (
					<Fragment>
						<div className={containerClassName}>
							{isMobile ? self.generateMobileMenu() : self.generateDesktopMenu()}
						</div>
						<div className="header-site-dropdown-overlay" id="header-site-dropdown-overlay" />
					</Fragment>
				)}
			</div>
		)
	}
});

const mapStateToProps = (store: any) => {
	return {
		producers: store.producers.producers,
		project: store.header.project
	}
}
const mapDispatchToProps = (dispatch: any) => bindActionCreators(
	actionCreators,
	dispatch
)
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(NavMenu)