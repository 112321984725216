import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { routerReducer, routerMiddleware } from 'react-router-redux';
import * as Projects from './Projects';
import * as Companies from './Companies';
import * as Producers from './Producers';
import * as ManageUsers from './ManageUsers'
import * as Header from './Header'
import * as UserMetadata from './UserMetadata'
import * as LanguagePreference from './UserLanguagePreference'
import * as AdminSettings from './Admin'

export default function configureStore(history, initialState) {

	const reducers = {
		admin: AdminSettings.reducer,
        projects: Projects.reducer,
        companies: Companies.reducer,
        project: Projects.reducer,
        producers: Producers.reducer,
        users: ManageUsers.reducer,
		roles: ManageUsers.reducer,
        userMetadata: UserMetadata.reducer,
        header: Header.reducer,
		languagePreference : LanguagePreference.reducer
    }

	const middleware = [
		thunk,
		routerMiddleware(history)
	];

	// In development, use the browser's Redux dev tools extension if installed
	const enhancers = [];
	const isDevelopment = process.env.NODE_ENV === 'development';
	if (isDevelopment && typeof window !== 'undefined' && window.devToolsExtension) {
		enhancers.push(window.devToolsExtension());
	}

	const rootReducer = combineReducers({
		...reducers,
		routing: routerReducer
	});

	return createStore(
		rootReducer,
		initialState,
		compose(applyMiddleware(...middleware), ...enhancers)
	);
}
