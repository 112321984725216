import React, { Fragment, useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap'
import PumperLine from './PumperLine.js';
import InfoIcon from '../images/icons8-info-16.png';
import {UncontrolledTooltip} from "reactstrap";
import * as Formatting from '../helpers/formatting';
import {ReactComponent as WhiteUp} from '../images/whiteUp.svg';
import {ReactComponent as WhiteDown} from '../images/whiteDown.svg';
import _ from 'lodash';
import { FormattedMessage, useIntl } from 'react-intl';
import {NaturalGasUnit} from "../enums";

export default function PumperSummary(props)
{
    const intl = useIntl();
    const [activeLine, setActiveLine] = useState(null);
    const [activeSort, setActiveSort] = useState(null);
    const [sortAsc, setSortAsc] = useState(false);

    const requestPumper = (pumper) =>
    {
        setActiveLine(pumper);
        props.onPumperClick(pumper);
    }

    const requestPumperStageStats = (pumper) => {
        props.onPumperStageClick(pumper);
    }

    const requestPumperStage = (stage) =>
    {
        props.onStageClick(stage);
    }

    const [stages, setStages] = useState(null);
    const [pumperList, setPumperList] = useState(null);

    let _sortAsc = false;

    useEffect(() => {
        if (props.pumper !== undefined)
        {
            setStages(props.pumper.stages);
        }
        if (props.project.pumpers !== undefined)
        {
            setPumperList(props.project.pumpers);
        }
    }, []);

    useEffect(() => {

        if (props.pumper !== undefined)
        {
            setStages(props.pumper.stages);
        }
    }, [props.pumper]);

    const [showInfo, setShowInfo] = useState(false);
    
    const toggleInfoIcon = () =>
    {
        setShowInfo(!showInfo);
    }

    const sortData = (column) => {
        _sortAsc = sortAsc;

        if (column === activeSort)
        {
            setSortAsc(!sortAsc);
            _sortAsc = !_sortAsc;
        }

        setActiveSort(column);
        
        if (column === 'diesel')
        {
            setPumperList(pumperList.sort(dieselCompare).slice());
            if (stages !== null)
            {
                setStages(stages.sort(dieselCompare).slice());
            }
        }
        else if (column === 'Lines')
        {
            setPumperList(pumperList.sort(linesCompare).slice());
        }
        else if (column === 'Stages' || column === 'Wells')
        {
            setStages(stages.sort(stageCompare).slice());
        }
        else if (column === 'ng')
        {
            setPumperList(pumperList.sort(ngCompare).slice());
            if (stages !== null)
            {
                setStages(stages.sort(dieselCompare).slice());
            }
        }else if (column === 'energy')
        {
            setPumperList(pumperList.sort(energyCompare).slice());
            if (stages !== null)
            {
                setStages(stages.sort(dieselCompare).slice());
            }
        }else if (column === 'startTime')
        {
            setPumperList(pumperList.sort(timeCompare).slice());
            if (stages !== null)
            {
                setStages(stages.sort(dieselCompare).slice());
            }
        }else if (column === 'endTime')
        {
            setPumperList(pumperList.sort(timeCompare).slice());
            if (stages !== null)
            {
                setStages(stages.sort(dieselCompare).slice());
            }
        }else if (column === 'subRate')
        {
            setPumperList(pumperList.sort(subrateCompare).slice());
            if (stages !== null)
            {
                setStages(stages.sort(dieselCompare).slice());
            }
        }else if (column === 'stageCount')
        {
            setPumperList(pumperList.sort(stageCountCompare).slice());
        }else if (column === 'engineType') {
            setPumperList(pumperList.sort(engineCompare).slice());
        }
    }

    const linesCompare = (a,b) => {
        if (_sortAsc)
        {
            if (a.name > b.name)
            {
                return 1;
            }else{
                return -1;
            }
        }else{
            if (a.name < b.name)
            {
                return 1;
            }else{
                return -1;
            }
        }
    }
    
    const stageCountCompare = (a,b) => {
        if (_sortAsc)
        {
            if (a.stages.filter(x => x.totalDE > 0 || x.totalDiesel > 0).length > b.stages.filter(x => x.totalDE > 0 || x.totalDiesel > 0).length)
            {
                return 1;
            }else{
                return -1;
            }
        }else{
            if (a.stages.filter(x => x.totalDE > 0 || x.totalDiesel > 0).length < b.stages.filter(x => x.totalDE > 0 || x.totalDiesel > 0).length)
            {
                return 1;
            }else{
                return -1;
            }
        }
    }

    const stageCompare = (a,b) => {
        if (_sortAsc)
        {
            if (a.wellName > b.wellName)
            {
                return 1;
            }else{
                return -1;
            }
        }else{
            if (a.wellName < b.wellName)
            {
                return 1;
            }else{
                return -1;
            }
        }
    }

    const dieselCompare = (a,b) => {
        if (_sortAsc)
        {
            if (a.totalDiesel > b.totalDiesel)
            {
                return 1;
            }else{
                return -1;
            }
        }else{
            if (a.totalDiesel < b.totalDiesel)
            {
                return 1;
            }else{
                return -1;
            }
        }
    }

    const ngCompare = (a,b) => {
        if (_sortAsc)
        {
            if (a.totalDE > b.totalDE)
            {
                return 1;
            }else{
                return -1;
            }
        }else{
            if (a.totalDE < b.totalDE)
            {
                return 1;
            }else{
                return -1;
            }   
        }
    }
    const energyCompare = (a,b) => {
        if (_sortAsc)
        {
            if (a.totalPumped > b.totalPumped)
            {
                return 1;
            }else{
                return -1;
            }
        }else
        {
            if (a.totalPumped < b.totalPumped)
            {
                return 1;
            }else{
                return -1;
            }
        }
    }
    const timeCompare = (a,b) => {
        if (_sortAsc)
        {
            if (a.endTime > b.endTime)
            {
                return 1;
            }else{
                return -1;
            }
        }else{
            if (a.endTime < b.endTime)
            {
                return 1;
            }else{
                return -1;
            }
        }
    }
    const subrateCompare = (a,b) => {
        if (_sortAsc)
        {
            if (a.subRate > b.subRate)
            {
                return 1;
            }else{
                return -1;
            }
        }else{
            if (a.subRate < b.subRate)
            {
                return 1;
            }else{
                return -1;
            }
        }
    }
    
    const engineCompare = (a,b) => {
        if (_sortAsc)
        {
            if (a.engineType > b.engineType)
            {
                return 1;
            }else{
                return -1;
            }
        }else{
            if (a.engineType < b.engineType)
            {
                return 1;
            }else{
                return -1;
            }
        }
    }
    
    return (
        <Row>
            <Col sm="12">
            <div className="pumperSummary">
                <div className="pumperLinesHeader">
                    {props.pumperSelected !== "all" &&
                        <div className="pumperLinesHeaderCell pumperLinesHeaderName pumperLinesHeaderCellSort" onClick={() => sortData(props.title)}> <FormattedMessage id="stages.label" defaultMessage="Stages" /> {(activeSort === 'Lines' || activeSort === 'Stages' || activeSort === 'Wells') && sortAsc &&  <WhiteUp />}{(activeSort === 'Lines' || activeSort === 'Stages' || activeSort === 'Wells') && !sortAsc && <WhiteDown />}</div>
                    }
                    {props.pumperSelected === "all" &&
                        <div className="pumperLinesHeaderCell pumperLinesHeaderName pumperLinesHeaderCellSort" onClick={() => sortData(props.title)}><FormattedMessage id="pumpID.label" defaultMessage="Pump ID"/> {(activeSort === 'Lines' || activeSort === 'Stages' || activeSort === 'Wells') && sortAsc && <WhiteUp/>}{(activeSort === 'Lines' || activeSort === 'Stages' || activeSort === 'Wells') && !sortAsc && <WhiteDown/>}</div>
                    }
                    <div className="pumperLinesHeaderCell pumperLinesHeaderValue pumperLinesHeaderCellSort" onClick={() => sortData('diesel')}><FormattedMessage id="diesel.label" defaultMessage="Diesel" />{activeSort === 'diesel' && sortAsc && <WhiteUp />}{activeSort === 'diesel' && !sortAsc && <WhiteDown />}</div>                    
                    {props.project.bifuel && <div className="pumperLinesHeaderCell pumperLinesHeaderValueGas pumperLinesHeaderCellSort" onClick={() => sortData('ng')}><FormattedMessage id="naturalGas.label" defaultMessage="Natural Gas" />{activeSort === 'ng' && sortAsc &&  <WhiteUp />}{activeSort === 'ng' && !sortAsc && <WhiteDown />}</div>}
                    {props.project.bifuel && <div className="pumperLinesHeaderCell pumperLinesHeaderEnergy pumperLinesHeaderCellSort" onClick={() => sortData('energy')}><FormattedMessage id="consumedEnergy.label" defaultMessage="Consumed Energy" />{activeSort === 'energy' && sortAsc && <WhiteUp />}{activeSort === 'energy' && !sortAsc && <WhiteDown />}</div>}
                    {props.project !== null && props.project.pumpers !== null && props.pumper === undefined && pumperList !== null &&
                        <div className="pumperLinesHeaderCell pumperLinesHeaderStages pumperLinesHeaderCellSort" onClick={() => sortData('stageCount')}> <FormattedMessage id="activeNumberOfStages.label" defaultMessage="Active Number Of Stages" />{activeSort === 'stageCount' && sortAsc && <WhiteUp />}{activeSort === 'stageCount' && !sortAsc && <WhiteDown />}</div>
                    }
                    {props.project.bifuel && 
                        <div id="infoIcon" className="pumperLinesHeaderCell pumperLinesHeaderSubRate pumperLinesHeaderCellSort" onClick={() => sortData('subRate')}>
                            <FormattedMessage id="subRate.label" defaultMessage="Sub Rate" />
                            <img src={InfoIcon} className="infoIcon" onClick={() => toggleInfoIcon} alt="info" />
                            <UncontrolledTooltip placement="right" target="infoIcon">
                                <FormattedMessage id="substitutionRate.message" defaultMessage="Substitution rate is theoretical, calculated using the Net heating values." />
                            </UncontrolledTooltip>
                            {activeSort === 'subRate' && sortAsc && <WhiteUp />}
                            {activeSort === 'subRate' && !sortAsc && <WhiteDown />}
                        </div>
                    }
                    {props.project.bifuel && props.pumper === undefined &&
                        <div className="pumperLinesHeaderCell pumperLinesHeaderSubRate pumperLinesHeaderCellSort" onClick={() => sortData('engineType')}>
                            <FormattedMessage id="engineType.label" defaultMessage="Engine Type" />
                            {activeSort === 'engineType' && sortAsc && <WhiteUp />}
                            {activeSort === 'engineType' && !sortAsc && <WhiteDown />}
                        </div>
                    }
                    {props.pumper !== null && props.pumper !== undefined && 
                        <Fragment>
                            <div className="pumperLinesHeaderCell pumperLinesHeaderFinish pumperLinesHeaderCellSort" onClick={() => sortData('endTime')}>
                                <FormattedMessage id="finishTimeUTC.label" defaultMessage="Finish Time (UTC)" />{activeSort === 'endTime' && sortAsc &&  
                            <WhiteUp />}{activeSort === 'endTime' && !sortAsc && <WhiteDown />}
                            </div>
                            <div className="pumperLinesHeaderCell pumperLinesHeaderStart pumperLinesHeaderCellSort" onClick={() => sortData('startTime')}>
                                <FormattedMessage id="startTimeUTC.label" defaultMessage="Start Time (UTC)" /> {activeSort === 'startTime' && sortAsc &&  
                            <WhiteUp />}{activeSort === 'startTime' && !sortAsc && <WhiteDown />}
                            </div>
                        </Fragment>
                    }
                </div>
                <div className="clearfix"></div>
                <div className="pumperLinesData">
                    <table className="fullWidth" id="table">
                        {props.project !== null && props.project.pumpers !== null && props.pumper === undefined && pumperList !== null && pumperList.map(pumper => 
                            <PumperLine naturalGasUnit={props.project.naturalGasUnit} unit={props.project.uomString} unitShort={props.project.uomStringShort} key={pumper.name} pumper={pumper} bifuel={props.project.bifuel} onPumperStageClick={requestPumperStageStats} activeLine={activeLine} onPumperClick={requestPumper}></PumperLine>
                        )}
                        {props.project !== null && props.project.pumpers !== null && props.pumper === undefined && 
                            <tr><td>
                                <div className='pumperLine'>
                                    <div className="pumperLineName">
                                        {(intl.formatMessage({ id: "total.label", defaultMessage: "TOTAL"})).toUpperCase()}
                                        <br/>
                                        <FormattedMessage id="includesNonFracFuel.label" defaultMessage="Includes Non-Frac Fuel" />
                                    </div>
                                    <div className="pumperLineValue">
                                        <div className="pumperLineMobileTitle">
                                            <FormattedMessage id="diesel.label" defaultMessage="Diesel" />
                                        </div>
                                        <div className="pumperLineName1">{Formatting.numberWithCommas(_.sumBy(pumperList, 'totalDiesel'))}{props.project.uomString}</div>
                                    </div>
                                    {props.project.bifuel && 
                                        <div className="pumperLineValueGas">
                                        <div className="pumperLineMobileTitle">
                                            <FormattedMessage id="naturalGas.label" defaultMessage="Natural Gas" />
                                        </div>
                                            <div className="pumperLineName1">{Formatting.numberWithCommas(_.sumBy(pumperList, 'totalDE'))} D{props.project.uomStringShort}E</div>
                                            {props.project.naturalGasUnit === NaturalGasUnit.SCF &&
                                                <div className="pumperLineName2">{Formatting.numberWithCommas(_.sumBy(pumperList, 'totalSCF'))} SCF</div>
                                            }
                                            {props.project.naturalGasUnit === NaturalGasUnit.MCF &&
                                                <div className="pumperLineName2">{Formatting.numberWithCommas(_.sumBy(pumperList, 'totalMCF'))} MCF</div>
                                            }
                                            {props.project.naturalGasUnit === NaturalGasUnit.E3M3 &&
                                                <div className="pumperLineName2">{Formatting.numberWithCommas(_.sumBy(pumperList, 'totalDEC'), 4)} E3M3</div>
                                            }
                                        </div>}
                                    {props.project.bifuel && 
                                        <div className="pumperLineEnergy">
                                        <div className="pumperLineMobileTitle">
                                            <FormattedMessage id="consumedEnergy.label" defaultMessage="Consumed Energy" />
                                        </div>
                                            <div className="pumperLineName1">{Formatting.numberWithCommas(_.sumBy(pumperList, 'totalPumped'))} D{props.project.uomStringShort}E</div>
                                            <div className="pumperLineName2">{Formatting.numberWithCommas(_.sumBy(pumperList, 'totalBTU'))} BTU</div>
                                        </div>
                                    }

                                    <div className="pumperLineStages">
                                        <div className="pumperLineMobileTitle">
                                            <FormattedMessage id="activeNumberOfStages.label" defaultMessage="Active # of Stages" />
                                        </div>
                                        <div className="pumperLineName1 floatRightNoMargin">{Formatting.numberWithCommas(_.sumBy(pumperList, 'totalStages'))}</div>
                                    </div>

                                    {props.project.bifuel && pumperList !== null && <div className="pumperLineSubRate">
                                        <div className="pumperLineMobileTitle">
                                            <FormattedMessage id="substitutionRate.label" defaultMessage="Substitution Rate" />
                                        </div>
                                        <div className="pumperLineName1 floatRightNoMargin">{Formatting.numberWithCommas( ((_.sumBy(pumperList, 'totalDE') /
                                            (_.sumBy(pumperList, 'totalDiesel') + _.sumBy(pumperList, 'totalDE'))) * 100).toString())}%</div>
                                    </div>}
                                    
                                    <div className="clearfix"></div>
                                    <hr className="totalLine"/>
                                    <div className="pumperLineName">
                                        <FormattedMessage id="nonFracFuel.label" defaultMessage="NON-FRAC FUEL" />
                                    </div>
                                    <div className="pumperLineValue">
                                        <div className="pumperLineMobileTitle">
                                            <FormattedMessage id="nonFracFuel.label" defaultMessage="NON-FRAC FUEL" />
                                        </div>
                                        <div className="pumperLineName1">{Formatting.numberWithCommas(props.project.totalNonFracDiesel)}{props.project.uomString}</div>
                                    </div>

                                </div>
                            </td></tr>
                        }
                        {props.project !== null && props.pumper !== undefined && stages !== null && stages.map(stage => 
                            <PumperLine naturalGasUnit={props.project.naturalGasUnit} unit={props.project.uomString} unitShort={props.project.uomStringShort} key={props.title & stage.wellName + stage.stageNumber} pumper={stage} onStageClick={requestPumperStage} bifuel={props.project.bifuel} single={true}></PumperLine>
                        )}
                        {props.project !== null && props.pumper !== undefined && stages !== null &&
                            <Fragment>
                                <tr><td>
                                    <div className='pumperLine'>
                                        <div className="pumperLineName">
                                            {(intl.formatMessage({ id: "total.label", defaultMessage: "TOTAL"})).toUpperCase()}
                                        </div>
                                        <div className="pumperLineValue">
                                            <div className="pumperLineMobileTitle">
                                                <FormattedMessage id="diesel.label" defaultMessage="Diesel" />
                                            </div>
                                            <div className="pumperLineName1">{Formatting.numberWithCommas(props.pumper.totalDiesel)}{props.project.uomString}</div>
                                        </div>
                                        {props.project.bifuel && 
                                            <div className="pumperLineValueGas">
                                                <div className="pumperLineMobileTitle">
                                                    <FormattedMessage id="naturalGas.label" defaultMessage="Natural Gas" />
                                                </div>
                                                <div className="pumperLineName1">{Formatting.numberWithCommas(props.project.uomString === "L" ? props.pumper.totalDE : props.pumper.totalDEGallons)} D{props.project.uomStringShort}E</div>
                                                {props.project.naturalGasUnit === NaturalGasUnit.SCF &&
                                                    <div className="pumperLineName2">{Formatting.numberWithCommas(props.pumper.totalSCF)} SCF</div>
                                                }
                                                {props.project.naturalGasUnit === NaturalGasUnit.MCF &&
                                                    <div className="pumperLineName2">{Formatting.numberWithCommas(props.pumper.totalMCF)} MCF</div>
                                                }
                                                {props.project.naturalGasUnit === NaturalGasUnit.E3M3 &&
                                                    <div className="pumperLineName2">{Formatting.numberWithCommas(props.pumper.totalDEC, 4)} E3M3</div>
                                                }
                                            </div>}
                                        {props.project.bifuel && 
                                            <div className="pumperLineEnergy">
                                                <div className="pumperLineMobileTitle">
                                                    <FormattedMessage id="consumedEnergy.label" defaultMessage="Consumed Energy" />
                                                </div>
                                                <div className="pumperLineName1">{Formatting.numberWithCommas(props.pumper.totalPumped)} D{props.project.uomStringShort}E</div>
                                                <div className="pumperLineName2">{Formatting.numberWithCommas(props.pumper.totalBTU)} BTU</div>
                                            </div>
                                        }

                                        {props.project.bifuel && <div className="pumperLineSubRate">
                                            <div className="pumperLineMobileTitle">
                                                <FormattedMessage id="substitutionRate.label" defaultMessage="Substitution Rate" />
                                            </div>
                                            <div className="pumperLineName1 floatRightNoMargin">{Formatting.numberWithCommas(props.pumper.subRate)}%</div>
                                        </div>}
                                        
                                    </div>    
                                </td></tr>
                            </Fragment>
                        }
                    </table>
                </div>
            </div>
            </Col>
        </Row>
    )
}