import React, {Fragment} from "react";
import {FormattedMessage} from "react-intl";
import ProjectConfiguration from "./common/UnitsOfMeasureToggle/projectConfig";

export default function ApiConfig(props) {
    return (
        <Fragment>
            <div className="projectInfoContainer">
                <div className="projectInfoTitle">
                    <FormattedMessage
                        id="features.label"
                        defaultMessage="Features"
                    />
                    {" "}/{" "}
                    <FormattedMessage
                        id="configuration.label"
                        defaultMessage="Configuration"
                    />
                </div>
                {props.showUnitOfMeasureToggle && (
                    <ProjectConfiguration
                        dieselBTUValueDefault={global.dieselBTUValueDefault}
                        ngBTUValueDefault={global.ngBTUValueDefault}
                        brakeHorsePowerDefault={global.brakeHorsePowerDefault}
                        loadFactorIdleDefault={global.loadFactorIdleDefault}
                        loadFactorPumpingDefault={global.loadFactorPumpingDefault}
                        hoursPerDayIdleDefault={global.hoursPerDayIdleDefault}
                        hoursPerDayWorkingDefault={global.hoursPerDayWorkingDefault}
                        dieselCostPerGallonDefault={global.dieselCostPerGallonDefault}
                        ngCostPerMCFDefault={global.ngCostPerMCFDefault}
                        bifuelDailySetupCostDefault={global.bifuelDailySetupCostDefault}
                        substitutionRateDefault={global.substitutionRateDefault}
                    />
                )}
            </div>
        </Fragment>
    );
}