import React, {useEffect, useState} from 'react';
import ExportImage from '../images/export.svg';
import slugify from "../helpers/slugify";
import axios from "axios";
import {useIntl, FormattedMessage} from "react-intl";
import ReactGA from 'react-ga';
import {toast} from "react-toastify";
import {getUserLanguagePreference} from "../helpers/metadataHelper";
import {useAuth0} from "../auth/auth0-wrapper";

const FileDownload = require('js-file-download');


export default function ExportExcel(props) {
    const { user } = useAuth0();
    const [lock, setLock] = useState(false);
    const [languagePreference, setLanguagePreference] = useState("");
    
    const intl = useIntl();
    toast.configure();

    //Initial mount
    useEffect(() => {
        let metaDataValue = getUserLanguagePreference(user);

        if(metaDataValue !== null && metaDataValue !== undefined)
        {
            if(metaDataValue.languagePreference !== null)
            {
                setLanguagePreference(metaDataValue.languagePreference);
            }
        }else {
            setLanguagePreference("en-US");
        }
    },[])
    
    const fetchData = async () => {

         ReactGA.event({
             category: 'Export Excel',
             action: `Exported Excel Line Report}"`
         })

        let intUOM = 2;
        if (props.project.uomString === ' US gal') {
            intUOM = 1;
        }
        
        // Lock button from being clicked
        setLock(true);
        toast.info(intl.formatMessage({id: "excelDownloadCommencing.message", defaultMessage: "Download Commencing."}));
        
        await axios
            .get(global.apiEndpoint + 'project/export-excel?projectName=' + slugify(props.project.name) + '&companyName=' + slugify(props.project.companyName,) + '&producerName=' 
            + slugify(props.project.energyProducerName) + '&startTime=' + props.project.startDateURL + '&uom=' + intUOM + '&jobId=' + props.jobId + '&culture=' + languagePreference + '&allData=' + true, {
                    headers: {
                        Authorization: `Bearer ${props.token}`
                    },
                    responseType: 'blob',
                }
            ).then((response) => {
                if (response.status === 200) {
                    if (response.data) {
                        toast.success(intl.formatMessage({ id: "excelDownloadSuccess.message", defaultMessage: "Success: Download finished." }));
                        FileDownload(response.data, props.project.energyProducerName + '-' + props.project.name + ' ' + props.project.startDateURL + ' Line Report.xlsx');
                    } 
                    else {
                        toast.warn(intl.formatMessage({ id: "excelDownloadNoData.message", defaultMessage: "Warning: No data, download aborted." }));
                    }
                } 
                else {
                    toast.error(intl.formatMessage({ id: "excelDownloadFailure.message", defaultMessage: "Error: Download aborted."}));
                }
                setLock(false);
            });
    }

    return (
        <div className={"exportButtonContainer" + (lock === true ? " exportButtonBackground" : "")} onClick={fetchData}>
            <div className="exportImage">
                <img src={ExportImage} alt="Export Raw Data"/>
            </div>
            <span className="exportButtonText exportButtonNoFloat">
                Excel Data (.xlsx)
            </span>
            {lock &&
                <div className="spinner"/>
            }
        </div>
    );
}