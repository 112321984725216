import React, {useContext, useEffect, useState} from 'react';
import {ProjectContext} from "./common/Contexts/ProjectContext";
import {Col, Row} from "react-bootstrap";
import {FormattedMessage, useIntl} from "react-intl";
import {ReactComponent as WhiteUp} from "../images/whiteUp.svg";
import {ReactComponent as WhiteDown} from "../images/whiteDown.svg";
import DailyByWellTotalLine from "./DailyByWellTotalLine";
import * as Formatting from "../helpers/formatting";
import _ from "lodash";


export default function DailyByWellTotalSummary(props) {
    const {selectedDate} = props

    const {project} = useContext(ProjectContext)
    const intl = useIntl();

    const [activeSort, setActiveSort] = useState(null);
    const [sortAsc, setSortAsc] = useState(false);
    const [wellsList, setWellsList] = useState([]);
    const [displayList, setDisplayList] = useState([])
    const [wellTotals, setWellTotals] = useState(null)

    const unit = project.currentProject.uomString


    useEffect(() => {
        if (project.currentProject.wells !== undefined)
            setWellsList(project.currentProject.wells);
    }, []);

    useEffect(() => {
        // create well totals list

        let result = {
            fracDieselTotal: 0,
            nonFracDieselTotal: 0,
            lineTotal: 0,
            dieselTotalizerTotal: 0,
            dieselVarianceVolume: 0,
            dieselVariancePercentage: 0,
            stages: 0,
        }
        let varianceCount = 0

        for (let well of displayList) {
            if (well.fracDieselTotal === undefined)
                continue
            result.fracDieselTotal += well.fracDieselTotal
            result.nonFracDieselTotal += well.nonFracDieselTotal
            result.lineTotal += well.lineTotal
            result.dieselTotalizerTotal += well.dieselTotalizerTotal
            result.dieselVarianceVolume += well.dieselVarianceVolume
            result.stages += well.stages
            varianceCount += 1
        }

        result.dieselVariancePercentage = result.dieselTotalizerTotal !== 0 ? (1 - (result.lineTotal / result.dieselTotalizerTotal)) * -100 : 0;
        setWellTotals(result)
    }, [displayList])

    useEffect(() => {
        createDisplayList()
    }, [wellsList, selectedDate])


    useEffect(() => sortColumns(), [sortAsc, activeSort])


    function createDisplayList() {
        let result = []
        for (let well of wellsList) {
            if (selectedDate === null) {
                let wellDetailSummed = {
                    name: well.name,
                    fracDieselTotal: well.dailyTotals.reduce((sum, next) => sum + next.fracDieselTotal, 0),
                    nonFracDieselTotal: well.dailyTotals.reduce((sum, next) => sum + next.nonFracDieselTotal, 0),
                    lineTotal: well.dailyTotals.reduce((sum, next) => sum + next.lineTotal, 0),
                    dieselTotalizerTotal: well.dailyTotals.reduce((sum, next) => sum + next.dieselTotalizerTotal, 0),
                    dieselVarianceVolume: well.dailyTotals.reduce((sum, next) => sum + next.dieselVarianceVolume, 0),
                    dieselVariancePercentage: well.dailyTotals.reduce((sum, next) => sum + next.dieselTotalizerTotal, 0) !== 0 
                        ? (1 - (well.dailyTotals.reduce((sum, next) => sum + next.lineTotal, 0) 
                        / well.dailyTotals.reduce((sum, next) => sum + next.dieselTotalizerTotal, 0))) * -100 : 0,
                    stages: well.dailyTotals.reduce((sum, next) => sum + next.stages, 0),
                }
                // let varianceCount = well.dailyTotals.filter(item => item.dieselVariancePercentage > 0).length
                // wellDetailSummed.dieselVariancePercentage /= varianceCount === 0 ? 1 : varianceCount
                result.push(wellDetailSummed)
            } else {
                let wellDetailFound = well.dailyTotals.find(item => item.date.split('T')[0] === selectedDate.split('T')[0])
                if (wellDetailFound === undefined)
                    result.push({name: well.name})
                else {
                    wellDetailFound.name = well.name
                    result.push(wellDetailFound)
                }
            }
        }
        setDisplayList(result)
    }


    const sortData = (column) => {
        if (column === activeSort)
            setSortAsc(!sortAsc);
        else
            setActiveSort(column)
    }

    function sortColumns() {
        if (activeSort === 'diesel')
            setDisplayList(displayList.sort((a, b) => compareFunction(a.fracDieselTotal, b.fracDieselTotal)).slice());
        else if (activeSort === 'well')
            setDisplayList(displayList.sort((a, b) => compareFunction(a.name, b.name)).slice());
        else if (activeSort === 'nonfrac')
            setDisplayList(displayList.sort((a, b) => compareFunction(a.nonFracDieselTotal, b.nonFracDieselTotal)).slice());
        else if (activeSort === 'lineTotal')
            setDisplayList(displayList.sort((a, b) => compareFunction(a.lineTotal, b.lineTotal)).slice());
        else if (activeSort === 'totalizerTotal')
            setDisplayList(displayList.sort((a, b) => compareFunction(a.dieselTotalizerTotal, b.dieselTotalizerTotal)).slice());
        else if (activeSort === 'varianceVolume')
            setDisplayList(displayList.sort((a, b) => compareFunction(a.dieselVarianceVolume, b.dieselVarianceVolume)).slice());
        else if (activeSort === 'variancePercentage')
            setDisplayList(displayList.sort((a, b) => compareFunction(a.dieselVariancePercentage, b.dieselVariancePercentage)).slice());
        else if (activeSort === 'stages')
            setDisplayList(displayList.sort((a, b) => compareFunction(a.stages, b.stages)).slice());
    }

    const compareFunction = (a, b) => {
        if (a === undefined)
            return 1
        if (b === undefined)
            return -1
        
        if (sortAsc)
            return a > b ? 1 : -1
        else
            return a <= b ? 1 : -1
    }


    return (
        <Row>
            <Col sm="12">
                <div className="pumperSummary">
                    <div className="pumperLinesHeader">
                        <div className="pumperLinesHeaderCell pumperLinesHeaderName pumperLinesHeaderCellSort" onClick={() => sortData('well')}>
                            <FormattedMessage id="well.label" defaultMessage="Well"/>
                            {activeSort === 'well' && (sortAsc ? <WhiteUp/> : <WhiteDown/>)}
                        </div>

                        <div className="pumperLinesHeaderCell pumperLinesHeaderValue pumperLinesHeaderCellSort" onClick={() => sortData('diesel')}>
                            <FormattedMessage id="fracDiesel.label" defaultMessage="Frac Diesel"/>
                            {activeSort === 'diesel' && (sortAsc ? <WhiteUp/> : <WhiteDown/>)}
                        </div>

                        <div className="pumperLinesHeaderCell pumperLinesHeaderValueNonFrac pumperLinesHeaderCellSort" onClick={() => sortData('nonfrac')}>
                            <FormattedMessage id="nonFracDiesel.label" defaultMessage="Non-Frac Diesel"/>
                            {activeSort === 'nonfrac' && (sortAsc ? <WhiteUp/> : <WhiteDown/>)}
                        </div>

                        <div className="pumperLinesHeaderCell pumperLinesHeaderValueLineTotal pumperLinesHeaderCellSort" onClick={() => sortData('lineTotal')}>
                            <FormattedMessage id="lineTotal.label" defaultMessage="Line Total"/>
                            {activeSort === 'lineTotal' && (sortAsc ? <WhiteUp/> : <WhiteDown/>)}
                        </div>

                        <div className="pumperLinesHeaderCell pumperLinesHeaderValueTotalizerTotal pumperLinesHeaderCellSort" onClick={() => sortData('totalizerTotal')}>
                            <FormattedMessage id="totalizerTotal.label" defaultMessage="Totalizer Total"/>
                            {activeSort === 'totalizerTotal' && (sortAsc ? <WhiteUp/> : <WhiteDown/>)}
                        </div>

                        <div className="pumperLinesHeaderCell pumperLinesHeaderValueVarianceVolume pumperLinesHeaderCellSort" onClick={() => sortData('varianceVolume')}>
                            <FormattedMessage id="varianceVolume.label" defaultMessage="Variance Volume"/>
                            {activeSort === 'varianceVolume' && (sortAsc ? <WhiteUp/> : <WhiteDown/>)}
                        </div>

                        <div className="pumperLinesHeaderCell pumperLinesHeaderValueVariancePercentage pumperLinesHeaderCellSort" onClick={() => sortData('variancePercentage')}>
                            <FormattedMessage id="variancePercentage.label" defaultMessage="Variance Percentage"/>
                            {activeSort === 'variancePercentage' && (sortAsc ? <WhiteUp/> : <WhiteDown/>)}
                        </div>

                        <div className="pumperLinesHeaderCell pumperLinesHeaderStages pumperLinesHeaderCellSort" onClick={() => sortData('stages')}>
                            <FormattedMessage id="numberOfStages.label" defaultMessage="# of Stages"/>
                            {activeSort === 'stages' && (sortAsc ? <WhiteUp/> : <WhiteDown/>)}
                        </div>
                    </div>
                    <div className="clearfix"/>

                    {/*list of wells of selected date*/}
                    <div className="pumperLinesData">
                        <table className="fullWidth" id="table">
                            {project.currentProject.wells !== null &&
                            <>
                                {displayList.map((well, index) =>
                                    <DailyByWellTotalLine
                                        data={well}
                                        key={"daily total by well" + index.toString()}
                                    />
                                )}
                                <tr>
                                    <td>
                                        <div className='pumperLine'>
                                            <div className="pumperLineName" onClick={() => console.log(wellsList, displayList)}>
                                                Sum
                                            </div>

                                            <div className="pumperLineValue">
                                                <div className="pumperLineMobileTitle">
                                                    <FormattedMessage id="fracDiesel.label" defaultMessage="Frac Diesel"/>
                                                </div>
                                                <div className="pumperLineName1">
                                                    {wellTotals !== null && `${Formatting.numberWithCommas(wellTotals.fracDieselTotal)}${unit}`}
                                                </div>
                                            </div>

                                            <div className="pumperLineValueNonFrac">
                                                <div className="pumperLineMobileTitle">
                                                    <FormattedMessage id="nonFracDiesel.label" defaultMessage="Non-Frac Diesel"/>
                                                </div>
                                                <div className="pumperLineName1">
                                                    {wellTotals !== null && `${Formatting.numberWithCommas(wellTotals.nonFracDieselTotal)}${unit}`}
                                                </div>
                                            </div>

                                            <div className="pumperLineValueLineTotal">
                                                <div className="pumperLineMobileTitle">
                                                    <FormattedMessage id="lineTotal.label" defaultMessage="Line Total"/>
                                                </div>
                                                <div className="pumperLineName1">
                                                    {wellTotals !== null && `${Formatting.numberWithCommas(wellTotals.lineTotal)}${unit}`}
                                                </div>
                                            </div>

                                            <div className="pumperLineValueTotalizerTotal">
                                                <div className="pumperLineMobileTitle">
                                                    <FormattedMessage id="totalizerTotal.label" defaultMessage="Totalizer Total"/>
                                                </div>
                                                <div className="pumperLineName1">
                                                    {wellTotals !== null && (wellTotals.dieselTotalizerTotal === 0 ? "N/A"
                                                        : `${Formatting.numberWithCommas(wellTotals.dieselTotalizerTotal)}${unit}`)}
                                                </div>
                                            </div>

                                            <div className="pumperLineValueVarianceVolume">
                                                <div className="pumperLineMobileTitle">
                                                    <FormattedMessage id="varianceVolume.label" defaultMessage="Variance Volume"/>
                                                </div>
                                                <div className="pumperLineName1">
                                                    {wellTotals !== null && (wellTotals.dieselVarianceVolume === 0 ? "N/A"
                                                        : `${Formatting.numberWithCommas(wellTotals.dieselVarianceVolume)}${unit}`)}
                                                </div>
                                            </div>

                                            <div className="pumperLineValueVariancePercentage">
                                                <div className="pumperLineMobileTitle">
                                                    <FormattedMessage id="variancePercentage.label" defaultMessage="Variance Percentage"/>
                                                </div>
                                                <div className="pumperLineName1">
                                                    {wellTotals !== null && (wellTotals.dieselVariancePercentage === 0 ? "N/A"
                                                        : `${Formatting.numberWithCommas(wellTotals.dieselVariancePercentage)}%`)}
                                                </div>
                                            </div>

                                            <div className="pumperLineStages">
                                                <div className="pumperLineMobileTitle">
                                                    <FormattedMessage id="numberOfStages.label" defaultMessage="# of Stages"/>
                                                </div>
                                                <div className="pumperLineName1 ">
                                                    {wellTotals !== null && `${Formatting.numberWithCommas(wellTotals.stages)}`}
                                                </div>
                                            </div>

                                        </div>
                                    </td>
                                </tr>
                            </>
                            }
                        </table>
                    </div>
                </div>
            </Col>
        </Row>
    )
}
