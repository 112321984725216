import React, {Fragment, useState, useEffect} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import Error from "./common/Error/index";

export default function ProjectNoData(props) {
    const intl = useIntl();

    return (
        <Error
            title={intl.formatMessage({id: "projectNoData.label", defaultMessage: "Welcome, waiting for project data"})}
            pathText="" path="/">
            <FormattedMessage id="projectNoData.message"
                              defaultMessage="Currently there is no data available for this Project."/>
        </Error>

    )
}