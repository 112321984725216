import React, { Fragment, useState, useEffect } from 'react';
import { Row, Col, Container } from 'reactstrap';
import HeaderStat from './HeaderStat';
import * as Formatting from '../helpers/formatting';
import ChartSwitch from './ChartSwitch';
import NoData from './NoData.js';
import DailyTotalsChart from './DailyTotalsChart';
import { FormattedMessage, useIntl } from "react-intl";
import GreenhouseGasChart from "./GreenhouseGasChart";

export default function GreenhouseGasChartContainer(props){

    const [showChart, setShowChart] = useState(true);
    const intl = useIntl();

    useEffect(() => {
        if (props.gasTotals.length > 1)
        {
            setShowChart(true);
        }else{
            setShowChart(false);
        }
    });

    return (
        <Fragment>
            {(props.gasTotals.length === 0) &&
            <Fragment>
                <Container className="wellStatsContainer" fluid={true}>
                    <Row className="wellRow">
                        <Col xs="12" md="12" className="barChartContainer">
                            <NoData />
                        </Col>
                    </Row>
                </Container>
            </Fragment>
            }
            {props.gasTotals.length > 0 &&
            <Fragment>
                <Container fluid={true}>
                    <Row>
                        <ChartSwitch label={intl.formatMessage({ id: "reductionFromNaturalGasSubstitutionByDay.message" , defaultMessage: "Reduction from Natural Gas Substitution by Day"})} active={true} />
                        {/* <ChartSwitch label='Stage Rate' active={stageRateActive} onClick={setInactiveSwitch} /> */}
                    </Row>
                </Container>
                <Container className="wellStatsContainer" fluid={true}>
                    <Row className="wellRow">
                        {showChart &&
                        <Col xs="12" md="12" className="barChartContainer">
                            <GreenhouseGasChart gasTotals={props.gasTotals} project={props.project} />
                        </Col>
                        }
                    </Row>
                    <Row className="wellHeaderRow">
                    </Row>
                </Container>

            </Fragment>
            }
            <div className="blurbText">
                <FormattedMessage id="dailyTotalsCalculation.message" defaultMessage="Daily Totals are calculated based on the End Time of each transaction. Transactions ending after midnight in your selected timezone ({global.timezone}) are allocated to the next day’s total. You can change the cutoff time and your time zone from the Project Info > Configuration page." />
            </div>
        </Fragment>
    )

}

