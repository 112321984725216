import React from "react";
import Introduction from "./content/Introduction";
import Authentication from "./content/Authentication"
import Error from "./content/Error"
import FreeTierAPI from "./content/FreeTierAPI"
import GraphQLAPI from "./content/GraphQLAPI"

export default function ApiContent(props) {
    
    return (
        <div className="api-content">
            <Introduction  refProp={props.refProp[0]}/>
            <hr className={"dividing-line"}/>

            <Authentication refProp={props.refProp[1]}/>
            <hr className={"dividing-line"}/>

            <Error refProp={props.refProp[2]}/>
            <hr className={"dividing-line"}/>

            <FreeTierAPI  refProp={props.refProp[3]}/>
            <hr className={"dividing-line"}/>

            <GraphQLAPI  refProp={props.refProp[4]}/>







        </div>
    )
}