import React, {useState, useEffect} from "react";
import {IntlProvider} from "react-intl";
import {ConvertLanguageToJson} from '../../../enums';
import English from "../../../data/languages/en-US.json";
import Spanish from "../../../data/languages/es-AR.json";
import ApiEnglish from "../../../data/languages/docs/api/en-US.json";
import ApiSpanish from "../../../data/languages/docs/api/es-AR.json";

export const LocalizationContext = React.createContext();

const LanguageWrapper = props => {
        const [locale, setLocale] = useState("en-US");
        const [messages, setMessages] = useState(English);
        
        useEffect(() => {
            const initLocale = navigator.language;
            setLocale(initLocale);
        }, [])
    
        useEffect(() => {
            selectLang(locale)
        }, [locale])
        
        function selectLang(e, page) {
            const newLocale = ConvertLanguageToJson[e];
            setLocale(newLocale);
            switch (page) {
                case "main": {
                    if (newLocale === "es-AR") {
                        setMessages(Spanish);
                    } else {
                        setMessages(English);
                    }

                    break;
                }
                case "api": {
                    if (newLocale === "es-AR") {
                        setMessages(ApiSpanish);
                    } else {
                        setMessages(ApiEnglish);
                    }
                    break;
                }
                case null:
                case undefined: {
                    if (newLocale === "es-AR") {
                        setMessages(Spanish);
                    } else {
                        setMessages(English);
                    }

                    break;
                }
            }
        }

        return (
            <LocalizationContext.Provider value={{locale, selectLang}}>
                <IntlProvider messages={messages} locale={locale}>
                    {props.children}
                </IntlProvider>
            </LocalizationContext.Provider>
        );
    }
;

export default LanguageWrapper;
