import React, { useState, useEffect } from 'react';
import PumperSummary from './PumperSummary.js';
import PumperStageChart from './PumperStageChart.js';
import { Row, Container, Col } from "reactstrap";
import EnergyToggle from '../components/EnergyToggle.js';
import ExportUnit from '../components/ExportUnit.js';
import { FormattedMessage } from "react-intl";

export default function PumperStats(props)
{
    const requestPumperStage = (stage) => {
        props.onStageClick(stage);
    }

    const requestPumperStatsSummary = () => {
        props.onBackClick(null);
    }

    const changePumperChart = (title) => {
        setPumperChartView(title);
    }

    const [pumperChartView, setPumperChartView] = useState(null);
    const [showSubRate, setShowSubRate] = useState(false);

    useEffect(() => {
        if (props.project.bifuel)
        {
            setPumperChartView('Substitution Rate');
            setShowSubRate(true);
        }else{
            setPumperChartView('Diesel');
            setShowSubRate(false);
        }
    }, []);
    return(
        <div>
            <div className="backLink" onClick={() => requestPumperStatsSummary()}>
                <FormattedMessage id="backToPumpersList.label" defaultMessage="Back to pumpers list" />
            </div>
            <div className="pumperTitle">{props.pumper.name}</div>
            {
            props.project !== null && 
                <ExportUnit project={props.project} token={props.token} type="singlePumper" pumperName={props.pumper.name} />
            }
            <EnergyToggle onClick={changePumperChart} title={<FormattedMessage id="consumptionType.label" defaultMessage="Consumption Type"/>} bifuel={props.project.bifuel} subRate={showSubRate} activeTitle={props.project.bifuel ? 'Substitution Rate' : 'Diesel'} />
            <div className="clearfix"></div>
            <Container className="wellStatsContainer" fluid={true}>
                <Row className="margin-top-20">
                    <Col xs="12" md="12" className="pumperChartContainer">
                        <PumperStageChart project={props.project} pumper={props.pumper} showSubRate={props.showSubRate} view={pumperChartView} onClick={changePumperChart} />
                    </Col>
                </Row>
            </Container>
            <PumperSummary project={props.project} pumper={props.pumper} onStageClick={requestPumperStage} showSubRate={props.showSubRate} title={<FormattedMessage id="stage.label" defaultMessage="Stages" />} />
        </div>
        
    )
}