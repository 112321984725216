import fetchWithTimeout from '../helpers/fetchWithTimeout'
import { async } from 'q';
import { Action, ActionCreator, Dispatch, Reducer, Store, applyMiddleware, createStore, combineReducers } from 'redux';
import axios from 'axios';
import { User, SubmitUser, ISubmittedUserResult } from '../interfaces/User';
import { UserPermissionResponse, isProjectAdmin } from '../auth/accessControlService';
import { UnitOfMeasurement, SaveUserMetadataTypes, UnauthorizedSubmitError, NetworkRequestStatus, UnauthorizedError } from '../enums';
import { ISavingUserMetadataAction, ISavedUserMetadataAction, ISavingUserMetadataErrorAction, ISubmittedUserMetadataResult } from '../interfaces/UnitOfMeasure';
import { UserMetadata } from 'auth0';
declare var global:any;



const initialState = {
  posting: false,
  isError:false,
  error:''
};



export type UserMetadataActions =
  | ISavingUserMetadataAction
  | ISavedUserMetadataAction
  | ISavingUserMetadataErrorAction


export const actionCreators = {
    saveUserMetadata: (userMetadata:UserMetadata, token:string, reload:boolean) => async(dispatch:Dispatch<any>) => {
        const savingUserMetadataAction: ISavingUserMetadataAction = {
            type: SaveUserMetadataTypes.Submitting
        };

        dispatch(savingUserMetadataAction)
        await axios.post<ISubmittedUserMetadataResult>(global.apiEndpoint + 'users/update-user-metadata',
            {userMetadata:userMetadata},
            {headers:{
                    Authorization: `Bearer ${token}`
                }})
            .then(function (response) {
                const savedUserMetadataErrorAction: ISavingUserMetadataErrorAction = {type:SaveUserMetadataTypes.Error,error:""};
                if(response !== undefined && (response.status == 401 || response.status == 403)){
                    dispatch({type: UnauthorizedSubmitError})
                }
                if (response.status !== 200)
                {
                    savedUserMetadataErrorAction.error = "A error occured while trying to add or update the user's preferences";
                    dispatch(savedUserMetadataErrorAction);
                }
                else if(response.data && response.data.status != NetworkRequestStatus.Successful){
                    savedUserMetadataErrorAction.error = response.data.error;
                    if (reload)
                    {
                        window.location.reload();
                    }
                    dispatch(savedUserMetadataErrorAction) ;
                }
                try{
                    const savedUserMetadataAction:ISavedUserMetadataAction = { type: SaveUserMetadataTypes.Submitted, userMetadata: response.data.userMetadata }
                    if (reload)
                    {
                        window.location.reload();
                    }
                    dispatch(savedUserMetadataAction);
                }
                catch(err: any){
                    savedUserMetadataErrorAction.error = err;
                    dispatch(savedUserMetadataErrorAction);
                }
            })
            .catch(function (error) {
                if(error.response !== undefined && (error.response.status == 401 || error.response.status == 403)){
                    dispatch({type: UnauthorizedSubmitError})
                }else{
                    const savedUserMetadataErrorAction: ISavingUserMetadataErrorAction = {type:SaveUserMetadataTypes.Error,error:""};
                    dispatch(savedUserMetadataErrorAction);
                }
            });
    }
}

export const saveUserMetadataActionCreator  = (userMetadata:UserMetadata, token:string, reload:boolean) => {
    return async (dispatch:Dispatch<any>) => {
        const savingUserMetadataAction: ISavingUserMetadataAction = {
            type: SaveUserMetadataTypes.Submitting
        };

        dispatch(savingUserMetadataAction)
        await axios.post<ISubmittedUserMetadataResult>(global.apiEndpoint + 'users/update-user-metadata', 
            {userMetadata:userMetadata},
            {headers:{
                Authorization: `Bearer ${token}`
            }})
            .then(function (response) {
                const savedUserMetadataErrorAction: ISavingUserMetadataErrorAction = {type:SaveUserMetadataTypes.Error,error:""};
                if(response !== undefined && (response.status == 401 || response.status == 403)){
                    dispatch({type: UnauthorizedSubmitError})
                }
                if (response.status !== 200)
                {
                    savedUserMetadataErrorAction.error = "A error occured while trying to add or update the user's preferences";
                    return dispatch(savedUserMetadataErrorAction);
                }
                else if(response.data && response.data.status != NetworkRequestStatus.Successful){
                    savedUserMetadataErrorAction.error = response.data.error;
                    if (reload)
                    {
                        window.location.reload();
                    }
                    return dispatch(savedUserMetadataErrorAction) ;
                }
                try{
                    const savedUserMetadataAction:ISavedUserMetadataAction = { type: SaveUserMetadataTypes.Submitted, userMetadata: response.data.userMetadata }
                    if (reload)
                    {
                        window.location.reload();
                    }
                    return dispatch(savedUserMetadataAction);
                }
                catch(err: any){
                    savedUserMetadataErrorAction.error = err;
                    return dispatch(savedUserMetadataErrorAction);
                }
            })
            .catch(function (error) {
                if(error.response !== undefined && (error.response.status == 401 || error.response.status == 403)){
                    dispatch({type: UnauthorizedSubmitError})
                }else{
                    const savedUserMetadataErrorAction: ISavingUserMetadataErrorAction = {type:SaveUserMetadataTypes.Error,error:""};
                    return dispatch(savedUserMetadataErrorAction);
                }
            });
    };
};


export const reducer = (
  state = initialState,
  action:any,
) => {
    switch(action.type){
    case SaveUserMetadataTypes.Error: {
      return {
        ...state,
        loading: false,
        isError:true,
        error:action.error
        
      };
    }
    case  SaveUserMetadataTypes.Submitting: {
      return {
        ...state,
        posting: true,
        posted:false
      };
    }
    case SaveUserMetadataTypes.Submitted: {
      return {
        ...state,
        posting: false,
        userMetadata:action.userMetadata,
        posted:true
      };
    }
    case UnauthorizedError:
    {
      return {
        ...state,
        isError: true,
        loading: false,
        isUnauthorized:true
      }
    }
    case UnauthorizedSubmitError:
      {
        return {
          ...state,
          isError: true,
          loading: false,
          posting:false,
          posted:true,
          error:"You are not authorized to perform this action!",
          isSubmissionUnauthorized:true
        }
      }
    default:
      neverReached(action); 
      break;
  }
  return state;
};

// tslint:disable-next-line:no-empty
const neverReached = (never:any) => {};