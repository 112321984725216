import React, { Fragment, useState, useEffect } from 'react';
import {ReactComponent as WhiteUp} from '../images/whiteUp.svg';
import {ReactComponent as WhiteDown} from '../images/whiteDown.svg';
import { FormattedMessage, useIntl } from "react-intl";
import ConvertStringToLabel from "../helpers/ConvertStringToLabel";

export default function EnergyToggle(props)
{
    const intl = useIntl();

    const toggleDropdown = () => {
        setShowDropdown(!showDropdown);
    }

    const itemClick = (title) => {
        setActiveTitle(intl.formatMessage({ id: ConvertStringToLabel(title) + ".label" , defaultMessage: title}));
        setShowDropdown(false);
        props.onClick(title);
    }

    const [activeTitle, setActiveTitle] = useState(intl.formatMessage({id: ConvertStringToLabel(props.activeTitle) + ".label", defaultMessage: props.activeTitle}));
    const [showDropdown, setShowDropdown] = useState(false);

    return (
        <Fragment>
            <div className="energyToggle">
                <div className="energyToggleTitle">{props.title}</div>
                <div className="energyToggleSelected" onClick={toggleDropdown}>
                    {activeTitle} {showDropdown && <WhiteUp className="energyToggleArrow" />} {!showDropdown && <WhiteDown className="energyToggleArrow" />}
                </div>
                {showDropdown && 
                    <div className="energyToggleDropdown">
                        {props.bifuel && props.subRate && <div className="energyToggleItem" onClick={() => itemClick('Substitution Rate')}>
                            <FormattedMessage id="substitutionRate.label" defaultMessage="Substitution Rate" />
                        </div>}
                        <div className="energyToggleItem" onClick={() => itemClick('Diesel')}>
                            <FormattedMessage id="diesel.label" defaultMessage="Diesel" />
                        </div>
                        {props.bifuel && <div className="energyToggleItem" onClick={() => itemClick('Natural Gas')}>
                            <FormattedMessage id="naturalGas.label" defaultMessage="Natural Gas" />
                        </div>}
                        {props.bifuel && <div className="energyToggleItem" onClick={() => itemClick('Consumed Energy')}>
                            <FormattedMessage id="consumedEnergy.label" defaultMessage="Consumed Energy" />
                        </div>}
                    </div>
                }
            </div>
            <div className="clearfix"></div>
        </Fragment>
    )
}