import React, { Fragment } from 'react';
import {FormattedMessage} from "react-intl";
import ConvertStringToLabel from "../helpers/ConvertStringToLabel";

export default function ConnectedEquipment(props)
{
    return (
        <div className="connectedEquipment">
            {props.pumperCounts.length > 0 && 
                <Fragment>
                    <div className="dashboardMultiTitle connectedEquipmentTitle">
                        <FormattedMessage id="connectedEquipment.label" defaultMessage="Connected Equipment" />
                    </div>
                    <div className="clearfix"></div>
                    {props.pumperCounts.map(x =>
                        <Fragment>
                            <div className="connectedEquipmentItem">
                                <div className="connectedEquipmentLabel">
                                    <FormattedMessage id= { x.pumper.toLowerCase() + '.label'} defaultMessage={ x.pumper } /> 
                                </div>
                                <div className="connectedEquipmentValue">{x.count}</div>  
                            </div>
                            <div className="clearfix"></div>
                        </Fragment>
                    )}
                </Fragment>
            }
        </div>
        
    )
}