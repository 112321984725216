import React, {Fragment, useContext} from "react";
import {ProjectContext} from "../Contexts/ProjectContext";
import FuelTicket from "../../FuelTickets";

export default function FuelTicketPage(props) {
    const {project} = useContext(ProjectContext);

    return (
        <Fragment>
            {project.currentProject !== null && project.activePage === 'FuelTicket' &&
            <FuelTicket uom={project.currentProject.uom}/>
            }
        </Fragment>
    )
}