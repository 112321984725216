import React, {Fragment, useEffect, useState} from 'react';
import {Col, Row} from 'reactstrap'
import InfoIcon from '../images/icons8-info-16.png';
import {UncontrolledTooltip} from "reactstrap";
import * as Formatting from '../helpers/formatting';
import {ReactComponent as WhiteUp} from '../images/whiteUp.svg';
import {ReactComponent as WhiteDown} from '../images/whiteDown.svg';
import WellLine from '../components/WellLine.js';
import {FormattedMessage, useIntl} from "react-intl";
import ConvertStringToLabel from "../helpers/ConvertStringToLabel";
import NgSidekickWellLine from "./NgSidekickWellLine";
import NgSidekickGasCompositionElement from "./NgSidekickGasCompositionElement";
import {NaturalGasUnit} from "../enums";

export default function NgSideKickSummary(props) {
    const intl = useIntl();
    const [activeSort, setActiveSort] = useState(null);
    const [sortAsc, setSortAsc] = useState(false);

    const [stages, setStages] = useState(null);
    const [wellList, setWellList] = useState(null);
    const [gasComposition, setGasComposition] = useState({});

    let _sortAsc = false;

    useEffect(() => {
        if (props.well !== undefined) {
            var gasDict = {
                "Nitrogen": props.well.nitrogen,
                "Carbon Dioxide": props.well.carbonDiOxide,
                "Hydrogen Sulfide": props.well.hydrogenSulfide,
                "Water": props.well.water,
                "Helium": props.well.helium,
                "Methane": props.well.methane,
                "Ethane": props.well.ethane,
                "Propane": props.well.propane,
                "N-Butane": props.well.nButane,
                "I-Butane": props.well.iButane,
                "N-Pentane": props.well.nPentane,
                "N-Hexane": props.well.nHexane,
                "N-Heptane": props.well.nHeptane,
                "N-Octane": props.well.nOctane,
                "N-Nonane": props.well.nNonane,
                "N-Decane": props.well.nDecane,
                "Oxygen": props.well.oxygen,
                "Carbon Monoxide": props.well.carbonMonoxide,
                "Hydrogen": props.well.hydrogen
            }
        } else {
            var gasDict = {
                "Nitrogen": props.project.ngSideKick.nitrogen,
                "Carbon Dioxide": props.project.ngSideKick.carbonDiOxide,
                "Hydrogen Sulfide": props.project.ngSideKick.hydrogenSulfide,
                "Water": props.project.ngSideKick.water,
                "Helium": props.project.ngSideKick.helium,
                "Methane": props.project.ngSideKick.methane,
                "Ethane": props.project.ngSideKick.ethane,
                "Propane": props.project.ngSideKick.propane,
                "N-Butane": props.project.ngSideKick.nButane,
                "I-Butane": props.project.ngSideKick.iButane,
                "N-Pentane": props.project.ngSideKick.nPentane,
                "N-Hexane": props.project.ngSideKick.nHexane,
                "N-Heptane": props.project.ngSideKick.nHeptane,
                "N-Octane": props.project.ngSideKick.nOctane,
                "N-Nonane": props.project.ngSideKick.nNonane,
                "N-Decane": props.project.ngSideKick.nDecane,
                "Oxygen": props.project.ngSideKick.oxygen,
                "Carbon Monoxide": props.project.ngSideKick.carbonMonoxide,
                "Hydrogen": props.project.ngSideKick.hydrogen
            }
        }
        setGasComposition(gasDict);

    }, [])


    useEffect(() => {
        if (props.well !== undefined) {
            setStages(props.well.stages);
        }
        if (props.project.ngSideKick.ngSideKickWells !== undefined) {
            setWellList(props.project.ngSideKick.ngSideKickWells);
        }
    }, []);

    const requestWell = (well) => {
        props.requestWell(well);
    };

    useEffect(() => {
        if (props.well !== undefined) {
            setStages(props.well.stages);
        }
    }, [props.well]);

    const [showInfo, setShowInfo] = useState(false);

    const toggleInfoIcon = () => {
        setShowInfo(!showInfo);
    }

    const sortData = (column) => {
        _sortAsc = sortAsc;

        if (column === activeSort) {
            setSortAsc(!sortAsc);
            _sortAsc = !_sortAsc;
        }

        setActiveSort(column);

        if (column === 'Lines') {
            setWellList(wellList.sort(linesCompare).slice());
        } else if (column === 'Stages') {
            setStages(stages.sort(stageCompare).slice());
        } else if (column === 'volume') {
            setStages(stages.sort(volumeCompare).slice());
        } else if (column === 'avgBTU') {
            setStages(stages.sort(avgBTUCompare).slice());
        } else if (column === 'avgFlowRate') {
            setStages(stages.sort(avgFlowRateCompare).slice());
        } else if (column === 'endTime') {
            setWellList(wellList.sort(timeCompare).slice());
            if (stages !== null) {
                setStages(stages.sort(volumeCompare).slice());
            }
        } else if (column === 'stageCount') {
            setWellList(wellList.sort(stageCountCompare).slice());
        }
    }

    const linesCompare = (a, b) => {
        if (_sortAsc) {
            if (a.wellName > b.wellName) {
                return 1;
            } else {
                return -1;
            }
        } else {
            if (a.wellName < b.wellName) {
                return 1;
            } else {
                return -1;
            }
        }
    }

    const stageCountCompare = (a, b) => {
        if (_sortAsc) {
            if (a.totalStages > b.totalStages) {
                return 1;
            } else {
                return -1;
            }
        } else {
            if (a.totalStages < b.totalStages) {
                return 1;
            } else {
                return -1;
            }
        }
    }

    const stageCompare = (a, b) => {
        if (_sortAsc) {
            if (a.wellName > b.wellName) {
                return 1;
            } else {
                return -1;
            }
        } else {
            if (a.wellName < b.wellName) {
                return 1;
            } else {
                return -1;
            }
        }
    }

    const volumeCompare = (a, b) => {
        if (props.well !== undefined || true) {
            if (_sortAsc) {
                if (a.totalVolume > b.totalVolume) {
                    return 1;
                } else {
                    return -1;
                }
            } else {
                if (a.totalVolume < b.totalVolume) {
                    return 1
                } else {
                    return -1;
                }
            }
        } else {
            if (_sortAsc) {
                if (a.volume > b.volume) {
                    return 1;
                } else {
                    return -1;
                }
            } else {
                if (a.volume < b.volume) {
                    return 1
                } else {
                    return -1;
                }
            }
        }

    }

    const avgBTUCompare = (a, b) => {
        if (props.well !== undefined || true) {
            if (_sortAsc) {
                if (a.totalAvgBTU > b.totalAvgBTU) {
                    return 1;
                } else {
                    return -1;
                }
            } else {
                if (a.totalAvgBTU < b.totalAvgBTU) {
                    return 1
                } else {
                    return -1;
                }
            }
        } else {
            if (_sortAsc) {
                if (a.avgBTU > b.avgBTU) {
                    return 1;
                } else {
                    return -1;
                }
            } else {
                if (a.avgBTU < b.avgBTU) {
                    return 1
                } else {
                    return -1;
                }
            }
        }
    }

    const avgFlowRateCompare = (a, b) => {
        if (props.well !== undefined || true) {
            if (_sortAsc) {
                if (a.totalAvgFlowRate > b.totalAvgFlowRate) {
                    return 1;
                } else {
                    return -1;
                }
            } else {
                if (a.totalAvgFlowRate < b.totalAvgFlowRate) {
                    return 1
                } else {
                    return -1;
                }
            }
        } else {
            if (_sortAsc) {
                if (a.avgFlowRate > b.avgFlowRate) {
                    return 1;
                } else {
                    return -1;
                }
            } else {
                if (a.avgFlowRate < b.avgFlowRate) {
                    return 1
                } else {
                    return -1;
                }
            }
        }
    }

    const timeCompare = (a, b) => {
        if (_sortAsc) {
            if (a.endTime > b.endTime) {
                return 1;
            } else {
                return -1;
            }
        } else {
            if (a.endTime < b.endTime) {
                return 1;
            } else {
                return -1;
            }
        }
    }

    // Todo: Fix sorting for the titles
    return (
        <div className="ng-sidekick-container">
            {props.title === 'Wells' &&
                <div className="ng-sidekick-title">
                    <FormattedMessage id="allWells.label" defaultMessage="All Wells"/>
                </div>
            }
            {props.title === 'Stages' &&
                <div className="ng-sidekick-title">
                    {props.well.name !== '' ?
                        <span><FormattedMessage id="well.label" defaultMessage="Well"/> {props.well.wellName}</span>
                        : <i><FormattedMessage id="unknownWell.label" defaultMessage="Unknown Well"/></i>}
                </div>
            }
            <div className="pumperSummary">
                <div className="pumperLinesHeader">
                    <div className="pumperLinesHeaderCell pumperLinesHeaderName pumperLinesHeaderCellSort">
                        <FormattedMessage id={ConvertStringToLabel(props.title) + '.label'}
                                          defaultMessage={props.title}/></div>
                    <div className="pumperLinesHeaderCell pumperLinesHeaderValue pumperLinesHeaderCellSort">
                        <FormattedMessage id="volume.label" defaultMessage="Volume"/></div>
                    <div className="pumperLinesHeaderCell pumperLinesHeaderValue pumperLinesHeaderCellSort">
                        <FormattedMessage id="avgBTU.label" defaultMessage="Avg BTU"/></div>
                    <div className="pumperLinesHeaderCell pumperLinesHeaderValue pumperLinesHeaderCellSort">
                        <FormattedMessage id="avgFlowRate.label" defaultMessage="Avg Flow Rate"/></div>
                    <div className="pumperLinesHeaderCell pumperLinesHeaderValue pumperLinesHeaderCellSort">
                        <FormattedMessage id="gasComposition.label" defaultMessage="Gas Composition"/></div>

                    {props.type === 'all' && props.project !== null && props.project.wells !== null && props.well === undefined && wellList !== null &&
                        <div className="pumperLinesHeaderCell pumperLinesHeaderStages pumperLinesHeaderCellSort"
                             onClick={() => sortData('stageCount')}><FormattedMessage id="numberOfStages.label"
                                                                                      defaultMessage="# of Stages"/> {activeSort === 'stageCount' && sortAsc &&
                            <WhiteUp/>}{activeSort === 'stageCount' && !sortAsc && <WhiteDown/>}</div>
                    }

                    {props.well !== null && props.well !== undefined &&
                        <Fragment>
                            <div className="pumperLinesHeaderCell pumperLinesHeaderFinish pumperLinesHeaderCellSort"
                                 onClick={() => sortData('endTime')}><FormattedMessage id="finishTimeUTC.label"
                                                                                       defaultMessage="Finish Time (UTC)"/>{activeSort === 'endTime' && sortAsc &&
                                <WhiteUp/>}{activeSort === 'endTime' && !sortAsc && <WhiteDown/>}</div>
                            <div className="pumperLinesHeaderCell pumperLinesHeaderStart pumperLinesHeaderCellSort"
                                 onClick={() => sortData('startTime')}><FormattedMessage id="startTimeUTC.label"
                                                                                         defaultMessage="Start Time (UTC)"/> {activeSort === 'startTime' && sortAsc &&
                                <WhiteUp/>}{activeSort === 'startTime' && !sortAsc && <WhiteDown/>}</div>
                        </Fragment>
                    }
                </div>
                <div className="clearfix"></div>
                <div className="pumperLinesData">
                    <table className="fullWidth" id="table">
                        {props.project !== null && props.project.ngSideKick.ngSideKickWells !== null && props.well === undefined && wellList !== null && wellList.map(well =>
                            <NgSidekickWellLine naturalGasUnit={props.project.naturalGasUnit}
                                                unit={props.project.uomString} unitShort={props.project.uomStringShort}
                                                key={well.wellName} requestWell={requestWell} well={well}
                                                bifuel={props.project.bifuel}></NgSidekickWellLine>
                        )}
                        {props.project !== null && props.project.ngSideKick.ngSideKickWells !== null && props.well === undefined &&
                            <tr>
                                <td>
                                    <div className='pumperLine'>
                                        <div className="pumperLineName">
                                            {(intl.formatMessage({
                                                id: "total.label",
                                                defaultMessage: "TOTAL"
                                            })).toUpperCase()}
                                        </div>
                                        <div className="pumperLineValue">
                                            <div className="pumperLineMobileTitle">
                                                <FormattedMessage id="volume.label" defaultMessage="Volume"/>
                                            </div>
                                            {props.project.naturalGasUnit === NaturalGasUnit.SCF &&
                                                <div
                                                    className="pumperLineName1">{Formatting.numberWithCommas(props.project.ngSideKick.totalVolume)} SCF</div>
                                            }
                                            {props.project.naturalGasUnit === NaturalGasUnit.MCF &&
                                                <div
                                                    className="pumperLineName1">{Formatting.numberWithCommas(props.project.ngSideKick.totalVolumeMCF)} MCF</div>
                                            }
                                            {props.project.naturalGasUnit === NaturalGasUnit.E3M3 &&
                                                <div
                                                    className="pumperLineName1">{Formatting.numberWithCommas(props.project.ngSideKick.totalVolumeDEC, 4)} E3M3</div>
                                            }
                                        </div>
                                        <div className="pumperLineValue">
                                            <div className="pumperLineMobileTitle">
                                                <FormattedMessage id="avgBTU.label" defaultMessage="avg BTU"/>
                                            </div>
                                            <div
                                                className="pumperLineName1">{Formatting.numberWithCommas(props.project.ngSideKick.totalAvgBTU)} BTU
                                            </div>
                                        </div>
                                        <div className="pumperLineValue">
                                            <div className="pumperLineMobileTitle">
                                                <FormattedMessage id="avgFlowRate.label"
                                                                  defaultMessage="Avg Flow Rate"/>
                                            </div>
                                            {/*<div className="pumperLineName1">{Formatting.numberWithCommas(props.project.ngSideKick.totalAvgFlowRate)} SCF</div>*/}
                                            {props.project.naturalGasUnit === NaturalGasUnit.SCF &&
                                                <div className="pumperLineName1">{Formatting.numberWithCommas(props.project.ngSideKick.totalAvgFlowRate)} SCF</div>
                                            }
                                            {props.project.naturalGasUnit === NaturalGasUnit.MCF &&
                                                <div className="pumperLineName1">{Formatting.numberWithCommas(props.project.ngSideKick.totalAvgFlowRateMCF)} MCF</div>
                                            }
                                            {props.project.naturalGasUnit === NaturalGasUnit.E3M3 &&
                                                <div
                                                    className="pumperLineName1">{Formatting.numberWithCommas(props.project.ngSideKick.totalAvgFlowRateDEC)} E3M3</div>
                                            }
                                        </div>
                                        <div className="pumperLineValue">
                                            <div className="pumperLineMobileTitle">
                                                <FormattedMessage id="gasComposition.label"
                                                                  defaultMessage="Gas Composition"/>
                                            </div>
                                            <NgSidekickGasCompositionElement
                                                totalGasComposition={props.project.ngSideKick.totalGasComposition}
                                                gasComposition={gasComposition}/>
                                        </div>
                                        <div className="pumperLineValue">
                                            <div className="pumperLineMobileTitle">
                                                <FormattedMessage id="numberOfStages.label"
                                                                  defaultMessage="# of Stages"/>
                                            </div>
                                            <div
                                                className="pumperLineName1">{Formatting.numberWithCommas(props.project.ngSideKick.totalStages)}</div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        }
                        {props.project !== null && props.well !== undefined && stages !== null && stages.map(stage =>
                            <NgSidekickWellLine naturalGasUnit={props.project.naturalGasUnit}
                                                unit={props.project.uomString} unitShort={props.project.uomStringShort}
                                                key={props.title & stage.wellName + stage.stageNumber} well={stage}
                                                bifuel={props.project.bifuel} single={true}></NgSidekickWellLine>
                        )}
                        {props.project !== null && props.well !== undefined && stages !== null &&
                            <Fragment>
                                <tr>
                                    <td>
                                        <div className='pumperLine'>
                                            <div className="pumperLineName">
                                                {(intl.formatMessage({
                                                    id: "total.label",
                                                    defaultMessage: "TOTAL"
                                                })).toUpperCase()}
                                            </div>

                                            <div className="pumperLineValue">
                                                <div className="pumperLineMobileTitle">
                                                    <FormattedMessage id="volume.label" defaultMessage="Volume"/>
                                                </div>
                                                {props.project.naturalGasUnit === NaturalGasUnit.SCF &&
                                                    <div className="pumperLineName1">
                                                        {Formatting.numberWithCommas(props.well.totalVolume)} SCF
                                                    </div>
                                                }
                                                {props.project.naturalGasUnit === NaturalGasUnit.MCF &&
                                                    <div className="pumperLineName1">
                                                        {Formatting.numberWithCommas(props.well.totalVolumeMCF)} MCF
                                                    </div>
                                                }
                                                {props.project.naturalGasUnit === NaturalGasUnit.E3M3 &&
                                                    <div className="pumperLineName1">
                                                        {Formatting.numberWithCommas(props.well.totalVolume, 4)} E3M3
                                                    </div>
                                                }
                                            </div>
                                            <div className="pumperLineValue">
                                                <div className="pumperLineMobileTitle">
                                                    <FormattedMessage id="avgBTU.label" defaultMessage="Avg BTU"/>
                                                </div>
                                                <div className="pumperLineName1">
                                                    {Formatting.numberWithCommas(props.well.totalAvgBTU)} BTU
                                                </div>
                                            </div>
                                            <div className="pumperLineValue">
                                                <div className="pumperLineMobileTitle">
                                                    <FormattedMessage id="avgFlowRate.label"
                                                                      defaultMessage="Avg Flow Rate"/>
                                                </div>
                                                {/*<div className="pumperLineName1">*/}
                                                {/*    {Formatting.numberWithCommas(props.well.totalAvgFlowRate)} SCF*/}
                                                {/*</div>*/}
                                                {props.project.naturalGasUnit === NaturalGasUnit.SCF &&
                                                    <div className="pumperLineName1">
                                                        {Formatting.numberWithCommas(props.well.totalAvgFlowRate)} SCF
                                                    </div>
                                                }
                                                {props.project.naturalGasUnit === NaturalGasUnit.MCF &&
                                                    <div className="pumperLineName1">
                                                        {Formatting.numberWithCommas(props.well.totalAvgFlowRateMCF)} MCF
                                                    </div>
                                                }
                                                {props.project.naturalGasUnit === NaturalGasUnit.E3M3 &&
                                                    <div className="pumperLineName1">
                                                        {Formatting.numberWithCommas(props.well.totalAvgFlowRateDEC)} E3M3
                                                    </div>
                                                }
                                            </div>
                                            <div className="pumperLineValue">
                                                <div className="pumperLineMobileTitle">
                                                    <FormattedMessage id="gasComposition.label"
                                                                      defaultMessage="Gas Composition"/>
                                                </div>
                                                <NgSidekickGasCompositionElement
                                                    totalGasComposition={props.well.totalGasComposition}
                                                    gasComposition={gasComposition}/>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </Fragment>
                        }
                    </table>
                </div>
            </div>
        </div>
    )
}