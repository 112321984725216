import React, { useEffect, useState } from 'react';
import { Bar, Chart } from 'react-chartjs-2';
import * as Formatting from '../helpers/formatting';
import {useIntl} from "react-intl";
import {NaturalGasUnit} from "../enums";

// flow: component is mounted/props is changed -> data is set -> render component
export default function PumperStageChart(props) {
	const [data, setData] = useState(null) // data is set to null initially
	const intl = useIntl();
	// data is set when this component is mounted (first time visit to well info) or when props is changed (going from well info to well info, component if not unmounted, have to check change in props)
	useEffect(() => {
		if (props.view === 'Substitution Rate'){
			let dataSets = [
				{
					label: props.project.bifuel ? intl.formatMessage({ id: "totalDiesel.label" , defaultMessage: "Total Diesel"}) : intl.formatMessage({ id: "dieselConsumption.label" , defaultMessage: "Diesel Consumption"}),
					data: props.project.bifuel ? Object.keys(props.pumper.stages).map(i => props.pumper.stages[i].totalPumped) : Object.keys(props.pumper.stages).map(i => props.pumper.stages[i].totalDiesel),
					fill: true,
					yAxisID: 'y-axis-1',
					lineTension: 0.1,
					backgroundColor: '#5F8BFF',
					borderColor: 'rgba(75,192,192,1)',
					borderCapStyle: 'butt',
					borderDash: [],
					borderDashOffset: 0.0,
					borderJoinStyle: 'miter',
					pointBorderColor: 'rgba(75,192,192,1)',
					pointBackgroundColor: '#fff',
					pointBorderWidth: 1,
					pointHoverRadius: 5,
					pointHoverBackgroundColor: 'rgba(75,192,192,1)',
					pointHoverBorderColor: 'rgba(220,220,220,1)',
					pointHoverBorderWidth: 2,
					pointRadius: 5,
					pointHitRadius: 10,
					type: 'bar'
				}];

			if (props.project.bifuel)
			{
				dataSets.unshift(			{
					label: intl.formatMessage({ id: "substitutionRate.label" , defaultMessage: "Substitution Rate"}) + ' %',
					data: (Object.keys(props.pumper.stages).map(i => props.pumper.stages[i].subRate)),
					fill: false,
					yAxisID: 'y-axis-0',
					lineTension: 0.1,
					backgroundColor: '#FFC72F',
					borderColor: '#EDBC23',
					borderCapStyle: 'butt',
					borderDash: [],
					borderDashOffset: 0.0,
					borderJoinStyle: 'miter',
					pointBorderColor: '#EDBC23',
					pointBackgroundColor: '#EDBC23',
					pointBorderWidth: 1,
					pointHoverRadius: 1,
					pointHoverBackgroundColor: 'rgba(75,192,192,1)',
					pointHoverBorderColor: 'rgba(220,220,220,1)',
					pointHoverBorderWidth: 1,
					pointRadius: 1,
					pointHitRadius: 5,
					type: 'line'
				},
				{
					label:intl.formatMessage({ id: "naturalGas.label" , defaultMessage: "Natural Gas"}),
					data: (Object.keys(props.pumper.stages).map(i => props.project.uomString === "L" ? props.pumper.stages[i].totalDE : props.pumper.stages[i].totalDEGallons)),
					fill: true,
					yAxisID: 'y-axis-1',
					lineTension: 0.1,
					backgroundColor: '#66B47D',
					borderColor: 'rgba(75,192,192,1)',
					borderCapStyle: 'butt',
					borderDash: [],
					borderDashOffset: 0.0,
					borderJoinStyle: 'miter',
					pointBorderColor: 'rgba(75,192,192,1)',
					pointBackgroundColor: '#fff',
					pointBorderWidth: 1,
					pointHoverRadius: 5,
					pointHoverBackgroundColor: 'rgba(75,192,192,1)',
					pointHoverBorderColor: 'rgba(220,220,220,1)',
					pointHoverBorderWidth: 2,
					pointRadius: 5,
					pointHitRadius: 10,
					type: 'bar'
				},
				{
					label: 'hide',
					data: (Object.keys(props.pumper.stages).map(i => props.pumper.stages[i].totalDiesel)),
					fill: true,
					yAxisID: 'y-axis-1',
					lineTension: 0.1,
					backgroundColor: '#5F8BFF',
					borderColor: 'rgba(75,192,192,1)',
					borderCapStyle: 'butt',
					borderDash: [],
					borderDashOffset: 0.0,
					borderJoinStyle: 'miter',
					pointBorderColor: 'rgba(75,192,192,1)',
					pointBackgroundColor: '#fff',
					pointBorderWidth: 1,
					pointHoverRadius: 5,
					pointHoverBackgroundColor: 'rgba(75,192,192,1)',
					pointHoverBorderColor: 'rgba(220,220,220,1)',
					pointHoverBorderWidth: 2,
					pointRadius: 5,
					pointHitRadius: 10,
					type: 'bar'
				});
			}

			setData({
				labels: Object.keys(props.pumper.stages).map(i => intl.formatMessage({ id: "well.label", defaultMessage: "Well"}) + ' ' + props.pumper.stages[i].wellName + ' ' + intl.formatMessage({ id: "stage.label", defaultMessage: "Stage"}) + ' '  + props.pumper.stages[i].stageNumber),
				datasets: dataSets
			})
		}else if (props.view === 'Diesel'){
			let dataSets = [
				{
					label: props.project.bifuel ? intl.formatMessage({ id: "totalDiesel.label" , defaultMessage: "Total Diesel"}) : intl.formatMessage({ id: "dieselConsumption.label" , defaultMessage: "Diesel Consumption"}),
					data: props.project.bifuel ? Object.keys(props.pumper.stages).map(i => props.pumper.stages[i].totalDiesel) : Object.keys(props.pumper.stages).map(i => props.pumper.stages[i].totalDiesel),
					fill: true,
					yAxisID: 'y-axis-1',
					lineTension: 0.1,
					backgroundColor: '#5F8BFF',
					borderColor: 'rgba(75,192,192,1)',
					borderCapStyle: 'butt',
					borderDash: [],
					borderDashOffset: 0.0,
					borderJoinStyle: 'miter',
					pointBorderColor: 'rgba(75,192,192,1)',
					pointBackgroundColor: '#fff',
					pointBorderWidth: 1,
					pointHoverRadius: 5,
					pointHoverBackgroundColor: 'rgba(75,192,192,1)',
					pointHoverBorderColor: 'rgba(220,220,220,1)',
					pointHoverBorderWidth: 2,
					pointRadius: 5,
					pointHitRadius: 10,
					type: 'bar'
				}];

			if (props.project.bifuel)
			{
				dataSets.unshift(
				{
					label: 'hide',
					data: (Object.keys(props.pumper.stages).map(i => props.pumper.stages[i].totalDiesel)),
					fill: true,
					yAxisID: 'y-axis-1',
					lineTension: 0.1,
					backgroundColor: '#5F8BFF',
					borderColor: 'rgba(75,192,192,1)',
					borderCapStyle: 'butt',
					borderDash: [],
					borderDashOffset: 0.0,
					borderJoinStyle: 'miter',
					pointBorderColor: 'rgba(75,192,192,1)',
					pointBackgroundColor: '#fff',
					pointBorderWidth: 1,
					pointHoverRadius: 5,
					pointHoverBackgroundColor: 'rgba(75,192,192,1)',
					pointHoverBorderColor: 'rgba(220,220,220,1)',
					pointHoverBorderWidth: 2,
					pointRadius: 5,
					pointHitRadius: 10,
					type: 'bar'
				});
			}

			setData({
				labels: Object.keys(props.pumper.stages).map(i => intl.formatMessage({ id: "well.label", defaultMessage: "Well"}) + ' ' + props.pumper.stages[i].wellName + ' ' + intl.formatMessage({ id: "stage.label", defaultMessage: "Stage"}) + ' '  + props.pumper.stages[i].stageNumber),
				datasets: dataSets
			})
		}else if (props.view === 'Natural Gas'){
			let dataSets = [
				{
					label: props.project.bifuel ?intl.formatMessage({ id: "totalDePumped.label" , defaultMessage: "Total DE Pumped"}) : intl.formatMessage({ id: "dieselConsumption.label" , defaultMessage: "Diesel Consumption"}),
					data: props.project.bifuel ? Object.keys(props.pumper.stages).map(i => props.pumper.stages[i].totalDE) : Object.keys(props.pumper.stages).map(i => props.pumper.stages[i].totalDE),
					fill: true,
					yAxisID: 'y-axis-1',
					lineTension: 0.1,
					backgroundColor: '#5F8BFF',
					borderColor: 'rgba(75,192,192,1)',
					borderCapStyle: 'butt',
					borderDash: [],
					borderDashOffset: 0.0,
					borderJoinStyle: 'miter',
					pointBorderColor: 'rgba(75,192,192,1)',
					pointBackgroundColor: '#fff',
					pointBorderWidth: 1,
					pointHoverRadius: 5,
					pointHoverBackgroundColor: 'rgba(75,192,192,1)',
					pointHoverBorderColor: 'rgba(220,220,220,1)',
					pointHoverBorderWidth: 2,
					pointRadius: 5,
					pointHitRadius: 10,
					type: 'bar'
				}];

			if (props.project.bifuel)
			{
				dataSets.unshift(
				{
					label: 'hide',
					data: (Object.keys(props.pumper.stages).map(i => props.pumper.stages[i].totalDE)),
					fill: true,
					yAxisID: 'y-axis-1',
					lineTension: 0.1,
					backgroundColor: '#5F8BFF',
					borderColor: 'rgba(75,192,192,1)',
					borderCapStyle: 'butt',
					borderDash: [],
					borderDashOffset: 0.0,
					borderJoinStyle: 'miter',
					pointBorderColor: 'rgba(75,192,192,1)',
					pointBackgroundColor: '#fff',
					pointBorderWidth: 1,
					pointHoverRadius: 5,
					pointHoverBackgroundColor: 'rgba(75,192,192,1)',
					pointHoverBorderColor: 'rgba(220,220,220,1)',
					pointHoverBorderWidth: 2,
					pointRadius: 5,
					pointHitRadius: 10,
					type: 'bar'
				});
			}

			setData({
				labels: Object.keys(props.pumper.stages).map(i => intl.formatMessage({ id: "well.label", defaultMessage: "Well"}) + ' ' + props.pumper.stages[i].wellName + ' ' + intl.formatMessage({ id: "stage.label", defaultMessage: "Stage"}) + ' ' + props.pumper.stages[i].stageNumber),
				datasets: dataSets
			})
		}else if (props.view === 'Consumed Energy'){
			let dataSets = [
				{
					label: props.project.bifuel ? intl.formatMessage({ id: "dieselPumped.label" , defaultMessage: "Diesel Pumped"}) : intl.formatMessage({ id: "dieselConsumption.label" , defaultMessage: "Diesel Consumption"}),
					data: props.project.bifuel ? Object.keys(props.pumper.stages).map(i => props.pumper.stages[i].totalBTU) : Object.keys(props.pumper.stages).map(i => props.pumper.stages[i].totalBTU),
					fill: true,
					yAxisID: 'y-axis-1',
					lineTension: 0.1,
					backgroundColor: '#5F8BFF',
					borderColor: 'rgba(75,192,192,1)',
					borderCapStyle: 'butt',
					borderDash: [],
					borderDashOffset: 0.0,
					borderJoinStyle: 'miter',
					pointBorderColor: 'rgba(75,192,192,1)',
					pointBackgroundColor: '#fff',
					pointBorderWidth: 1,
					pointHoverRadius: 5,
					pointHoverBackgroundColor: 'rgba(75,192,192,1)',
					pointHoverBorderColor: 'rgba(220,220,220,1)',
					pointHoverBorderWidth: 2,
					pointRadius: 5,
					pointHitRadius: 10,
					type: 'bar'
				}];

			if (props.project.bifuel)
			{
				dataSets.unshift(
				{
					label: 'hide',
					data: (Object.keys(props.pumper.stages).map(i => props.pumper.stages[i].totalBTU)),
					fill: true,
					yAxisID: 'y-axis-1',
					lineTension: 0.1,
					backgroundColor: '#5F8BFF',
					borderColor: 'rgba(75,192,192,1)',
					borderCapStyle: 'butt',
					borderDash: [],
					borderDashOffset: 0.0,
					borderJoinStyle: 'miter',
					pointBorderColor: 'rgba(75,192,192,1)',
					pointBackgroundColor: '#fff',
					pointBorderWidth: 1,
					pointHoverRadius: 5,
					pointHoverBackgroundColor: 'rgba(75,192,192,1)',
					pointHoverBorderColor: 'rgba(220,220,220,1)',
					pointHoverBorderWidth: 2,
					pointRadius: 5,
					pointHitRadius: 10,
					type: 'bar'
				});
			}

			setData({
				labels: Object.keys(props.pumper.stages).map(i => intl.formatMessage({ id: "well.label", defaultMessage: "Well"}) + ' ' + props.pumper.stages[i].wellName + ' ' + intl.formatMessage({ id: "stage.label", defaultMessage: "Stage"}) + ' ' + props.pumper.stages[i].stageNumber),
				datasets: dataSets
			})
		}

	}, [props.view])

	// called when after data is updated
	useEffect(() => {
		Chart.helpers.each(Chart.instances, function (instance) {
			if (instance.canvas.id === 'pumperStageChart')
			{
				const chartOptions = {
					scales: {
						xAxes: [{
							stacked: true,
							scaleLabel: {
								display: true,
								labelString: intl.formatMessage({ id: "well.label", defaultMessage: "Well"}) + ' / ' + intl.formatMessage({ id: "stage.label", defaultMessage: "Stage"})
							}
						}],
						yAxes: [
							{
								type: 'linear',
								display: false,
								position: 'right',
								id: 'y-axis-0',
								gridLines: {
									display: false
								},
								labels: {
									show: true
								},
								ticks: {
									beginAtZero: true
								}							},
							{
								type: 'linear',
								display: true,
								position: 'left',
								id: 'y-axis-1',
								gridLines: {
									display: false
								},
								labels: {
									show: false
								},
								ticks: {
									beginAtZero: true
								},
								scaleLabel: {
									display: true,
									labelString: intl.formatMessage({ id: "consumption.label", defaultMessage: "Consumption"}) + '  ' + '(' + props.project.uomString + ')'
								}
							}
						]
					},
					legend:
					{
						display: true,
						position: 'bottom',
						onClick: (e) => e.stopPropagation(),
						labels: {
							filter: function(item, chart) {
								return !item.text.includes('hide');
							}
						}
					},
					tooltips: {
						mode: 'index',
						intersect: false,
						position: 'nearest',
						backgroundColor: '#ffffff',
						titleFontColor: '#000000',
						bodyFontColor: '#000000',
						titleFontFamily: 'Calibri, Arial',
						titleFontSize: 12,
						bodyFontFamily: 'Calibri, Arial',
						bodyFontSize: 12,
						itemSort: function (a, b) {
							if (a.datasetIndex === 0 && b.datasetIndex === 1) {
								return 2;
							} else if (a.datasetIndex === 0 && b.datasetIndex === 2) {
								return 1;
							} else if (a.datasetIndex === 1 && b.datasetIndex === 2) {
								return 0;
							}
						},
						callbacks: {
							label: function (tooltipItem, data) {
								let label;
								if (tooltipItem.datasetIndex === 2) {
									label = Math.round(tooltipItem.yLabel * 100) / 100;
									return Formatting.numberWithCommas(label) + (props.project.bifuel ? props.project.uomString + ' ' + intl.formatMessage({ id: "diesel.label", defaultMessage: "Diesel"}) : props.project.uomString + ' ' + intl.formatMessage({ id: "dieselConsumption.label", defaultMessage: "Diesel Consumption"}));
								} else if (tooltipItem.datasetIndex === 0) {
									label = Math.round(tooltipItem.yLabel * 100) / 100;
									if (props.view === 'Subsitution Rate')
									{
										return label + ((props.project.bifuel === true ? '% ' + intl.formatMessage({ id: "substitution.label", defaultMessage: "Substitution"}) : ' ' + props.project.uomString + '  ' + intl.formatMessage({ id: "diesel.label", defaultMessage: "Diesel"})));
									}else if (props.view === 'Diesel' && props.project.bifuel === false)
									{
										return label + (props.project.uomString + ' ' + intl.formatMessage({ id: "diesel.label", defaultMessage: "Diesel"}));
									}
								} else if (tooltipItem.datasetIndex === 1) {
									label = Math.round(tooltipItem.yLabel * 100) / 100;
									if (props.view === 'Substitution Rate')
									{
										switch (props.project.naturalGasUnit) {
											case NaturalGasUnit.E3M3:
												return Formatting.numberWithCommas(props.pumper.stages[tooltipItem.index].totalDEC, 4) + ' E3M3 ' + intl.formatMessage({ id: "naturalGas.label", defaultMessage: "Natural Gas"});
											case NaturalGasUnit.MCF:
												return Formatting.numberWithCommas(props.pumper.stages[tooltipItem.index].totalMCF) + ' MCF ' + intl.formatMessage({ id: "naturalGas.label", defaultMessage: "Natural Gas"});
											case NaturalGasUnit.SCF:
											default:
												return Formatting.numberWithCommas(props.pumper.stages[tooltipItem.index].totalSCF) + ' SCF ' + intl.formatMessage({ id: "naturalGas.label", defaultMessage: "Natural Gas"});
										}
									}else if (props.view === 'Diesel')
									{
										return label + (props.project.uomString + ' ' + intl.formatMessage({ id: "diesel.label", defaultMessage: "Diesel"}));
									}else if (props.view === 'Natural Gas')
									{
										switch (props.project.naturalGasUnit) {
											case NaturalGasUnit.E3M3:
												return Formatting.numberWithCommas(props.pumper.stages[tooltipItem.index].totalDEC, 4) + ' E3M3 ' + intl.formatMessage({ id: "naturalGas.label", defaultMessage: "Natural Gas"});
											case NaturalGasUnit.MCF:
												return Formatting.numberWithCommas(props.pumper.stages[tooltipItem.index].totalMCF) + ' MCF ' + intl.formatMessage({ id: "naturalGas.label", defaultMessage: "Natural Gas"});
											case NaturalGasUnit.SCF:
											default:
												return Formatting.numberWithCommas(props.pumper.stages[tooltipItem.index].totalSCF) + ' SCF ' + intl.formatMessage({ id: "naturalGas.label", defaultMessage: "Natural Gas"});
										}
									}
									else if (props.view === 'Consumed Energy')
									{
										return Formatting.numberWithCommas(props.pumper.stages[tooltipItem.index].totalBTU) + ' BTU';
									}
								}
							},
							beforeLabel: function(tooltipItem, data)
							{
							},
							afterLabel: function (tooltipItem, data)
							{
								var label;
								if (tooltipItem.datasetIndex === 1) {
									label = props.project.uomString === "L" ? props.pumper.stages[tooltipItem.index].totalDE : props.pumper.stages[tooltipItem.index].totalDEGallons;
									if (props.view === 'Substitution Rate' || props.view === 'Natural Gas')
									{
										return '(' + Formatting.numberWithCommas(label) + ' D' + props.project.uomStringShort + 'E)';
									}
								}else if (tooltipItem.datasetIndex === 0){
									return intl.formatMessage({ id: "start.label", defaultMessage: "Start"}) + "/" + intl.formatMessage({ id: "end.label", defaultMessage: "End"}) + ' ' + props.pumper.stages[tooltipItem.index].startTimeString + ' / ' + props.pumper.stages[tooltipItem.index].endTimeString;
								}
							},
							title: function (tooltipItems, data) {
								var label = tooltipItems[0].label;
								return intl.formatMessage({ id: "stage.label", defaultMessage: "Stage"}) + label;
							}
						}
					},
					responsive: false,
					maintainAspectRatio: false
				}
				instance.chart.data = data;
				instance.chart.options = chartOptions;
				instance.chart.canvas.parentNode.style.height = '300px';
				instance.chart.update();

			}
		});
	}, [data])

	// render bar chart when there is data
	return data && (<Bar className="barChart" id='pumperStageChart' />)
}