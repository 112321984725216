import React, {Fragment, useContext, useEffect, useState} from 'react';
import {FormattedMessage, useIntl} from "react-intl";
import {ProjectMenuContext} from "../Contexts/ProjectMenuContext";
import ReactGA from "react-ga";
import {ReactComponent as BlackRight} from "../../../images/arrowRightBlack.svg";
import {ProjectContext} from "../Contexts/ProjectContext";
import {ReactComponent as WhiteUp} from "../../../images/whiteUp.svg";
import {ReactComponent as WhiteDown} from "../../../images/whiteDown.svg";
import FuelLevelWellList from "../../FuelLevelWellList";

export default function FuelLevel(props) {
    const {closeMobileMenu} = useContext(ProjectMenuContext);
    const {project, setActivePage, setValue, setMobileText} = useContext(ProjectContext);
    const [showFuelLevelDropdown, setShowFuelLevelDropdown] = useState(false);
    const intl = useIntl();

    useEffect(() => {
        if (project.activePage !== "FuelLevel") {
            setShowFuelLevelDropdown(false);
        }
    }, [project.activePage])
    
    const activePage = () => {
        return project.activePage === "FuelLevel" ? 'siteTitle siteTitleActive menuItem menuItemActive' : 'siteTitle menuItem'
    }

    const toggleFuelLevelDropdown = () => {
        switch (project.activePage) {
            case "FuelLevel":
                setShowFuelLevelDropdown(!showFuelLevelDropdown);
                break;
            default:
                requestFuelLevelProjectStats();
                setShowFuelLevelDropdown(true);
        }
    }
    
    const requestFuelLevelProjectStats = () => {
        setValue('activeWell', 'all');
        setMobileText(intl.formatMessage({id: "fuelLevel.label", defaultMessage: "Fuel Level"}));

        if (project.activePage !== "FuelLevel") {
            setActivePage("FuelLevel");
        } else {
            closeMobileMenu();
        }

        // Google Analytics
        ReactGA.event({
            category: "Project Menu Selection",
            action: `Clicked on "Fuel Level: All Wells" on Project "${window.location.pathname}"`
        })

        // Google Tag Manager
        window.dataLayer.push({
            'event': "Pageview",
            'pagePath': `${window.location.pathname}/fuel-levels/wells/all-wells`,
            'pageTitle': `Fuel Levels - All Wells`
        })
    }
    
    const requestFuelLevelWellStats = (well) => {
        setValue('activeWell', well);
        setMobileText(well.wellName !== '' ?
            intl.formatMessage({id: "well.label", defaultMessage: "Well"}) + " " + well.wellId
            : 'Unknown Well');
        closeMobileMenu();

        // Google Analytics
        ReactGA.event({
            category: "Project Menu Selection",
            action: `Clicked on "Fuel Level by Well" on Project "${window.location.pathname}"`
        })

        // Google Tag Manager
        window.dataLayer.push({
            'event': "Pageview",
            'pagePath': `${window.location.pathname}/fuel-levels/wells/${well.wellId}`,
            'pageTitle': `Fuel Levels - Well ${well.wellId}`
        })
    }

    return (
        <Fragment>
            {project.currentProject.fuelLevel.fuelLevelWells.length > 0 &&
            <Fragment>
                {!props.mobile &&
                    <h1 className={activePage()} onClick={() => toggleFuelLevelDropdown()}><FormattedMessage
                        id="fuelLevel.label" defaultMessage="Fuel Level"/>
                        {showFuelLevelDropdown && <WhiteUp className="floatRight"/>}
                        {!showFuelLevelDropdown && <WhiteDown className="floatRight"/>}
                    </h1>
                }
                {props.mobile &&
                    <div className="entireSiteMobile" onClick={() => toggleFuelLevelDropdown()}>
                        <div className="mobileText">
                            <FormattedMessage id="fuelLevel.label" defaultMessage="Fuel Level"/>
                        </div>
                        <div className="floatRight mobileArrow"><BlackRight/></div>
                    </div>
                }
                {showFuelLevelDropdown &&
                <FuelLevelWellList wells={project.currentProject.fuelLevel.fuelLevelWells}
                                   onProjectClick={requestFuelLevelProjectStats}
                                   onClick={requestFuelLevelWellStats} activeWell={project.activeWell}
                                   allProjects={props.projectStats}/>
                }
            </Fragment>
            }
        </Fragment>
    )
}