import React, {useState} from "react";
import {FormattedMessage} from "react-intl";
import GraphQLShowMoreParameters from "./component/GraphQLShowMoreParameters"
import {SidebarData} from "./component/SidebarData";



export default function GraphQl(props) {
    const [show, setShow] = useState(false);
    let postJson = "query {\n" +
        "  snapshots (where : {\n" +
        "    startTime_lt: \"2020-12-31\"\n" +
        "    jobId: 1234567890\n" +  
        "  })\n" +
        "  {\n" +
        "    jobId\n" +
        "    diesel\n" +
        "    wellName\n" +
        "    startTime\n" +
        "    endTime\n" +
        "    fuelProviderName\n" +
        "  }\n" +
        "}";
    
    let responseJson = "{\n" +
        "  \"data\": {\n" +
        "    \"snapshots\": [\n" +
        "      {\n" +
        "        \"jobId\": 1234567890,\n" +
        "        \"diesel\": 0,\n" +
        "        \"wellName\": \"1\",\n" +
        "        \"startTime\": \"2021-10-01T22:00:00.000-06:00\",\n" +
        "        \"endTime\": \"2021-10-01T22:30:00.000-06:00\",\n" +
        "        \"fuelProviderName\": \"Frac Shack\"\n" +
        "      },\n" +
        "      {\n" +
        "        \"jobId\": 1234567890,\n" +
        "        \"diesel\": 0,\n" +
        "        \"wellName\": \"1\",\n" +
        "        \"startTime\": \"2021-10-01T22:10:00.000-06:00\",\n" +
        "        \"endTime\": \"2021-10-01T22:10:00.000-06:00\",\n" +
        "        \"fuelProviderName\": \"Frac Shack\"\n" +
        "      }\n" + 
        "    ]\n" + 
        "  }\n" +
        "}";
    
    return (
        <div className="api-content-container" id="GraphQl" ref={props.refProp}>
            <div className="api-section-details half-section">
                <h1 className="api-section-header">
                    <FormattedMessage id="graphql.header" defaultMessage="GraphQL API"/>
                </h1>
                <p>
                    <FormattedMessage id="graphql.message1" defaultMessage="The GraphQL API is for premium subscription clients which can fetch detailed data about a project. The client can specify attributes and parameters to filter desired results."/>
                </p>
                <p>
                    <FormattedMessage id="graphql.message2" defaultMessage="Limited to 24 queries a day (1/hour) for silver tier and 1 query every 5 minutes (288/day) for gold tier."/>
                </p>
                <p>
                    <FormattedMessage id="graphql.message3" defaultMessage="Use the Project > Features > CreateGraphQL API to help create custom queries."/>
                </p>
                {show &&
                <div>
                    <h4>
                        <FormattedMessage id="graphql.showMore.header1" defaultMessage="GraphQL Object"/>
                    </h4>
                    <h5 className={"api-section-return-object-header3"}>
                        <FormattedMessage id="graphql.showMore.header2" defaultMessage="Attributes"/>
                    </h5>
                    <hr className={"dividing-line"}/>
                    <div>
                        <FormattedMessage id="graphql.showMore.attribute.description1" defaultMessage=""/>
                        <code style={{color: "inherit"}}>_not, _gt, _gte, _lt, _lte</code>
                        <FormattedMessage id="graphql.showMore.attribute.description2" defaultMessage=""/>
                    </div>

                    <h5 className={"api-section-return-object-header3"}>
                        <FormattedMessage id="graphql.showMore.header3" defaultMessage="Parameters"/>
                    </h5>
                    <hr className={"dividing-line"}/>

                    {[...Array(66).keys()].slice(1).map((item) => {
                        return (
                            <GraphQLShowMoreParameters i={item}/>
                        )
                    })}
                    
                </div>
                }
                <button className="show-button" onClick={() => setShow(!show)}>
                    {show && <FormattedMessage id="showButton.hide" defaultMessage="Hide"/> || <FormattedMessage id="showButton.show" defaultMessage="Show more"/>}
                </button>
            </div>
            <div className="api-section-code half-section">
                <div className="api-section-code-topbar">
                    <FormattedMessage id="graphql.Code.endpoints" defaultMessage="ENDPOINTS"/>
                </div>
                <div className="api-section-code-bottom">
                    <span className="curl-definition-method-post">POST</span>
                    <span className="curl-definition-path">/api/graphql</span>
                </div>
                {show &&
                <div >
                    <div className="api-section-code-topbar">
                        <FormattedMessage id="graphql.Code.QueryBody" defaultMessage="QUERY BODY"/>
                    </div>
                    <div className="api-section-code-bottom">
                        <pre className={"curl-definition-path"}>{postJson}</pre>
                    </div>
                    <div className="api-section-code-topbar">
                        <FormattedMessage id="graphql.Code.response" defaultMessage="RESPONSE"/>
                    </div>
                    <div className="api-section-code-bottom">
                        <pre className={"curl-definition-path"}>{responseJson}</pre>
                    </div>
                </div>
                }
            </div>
        </div>
    )
}