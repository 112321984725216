import React, {Fragment, useContext, useEffect, useState} from 'react';
import {FormattedMessage} from "react-intl";
import * as Formatting from "../helpers/formatting";
import _ from "lodash";
import {ProjectContext} from "./common/Contexts/ProjectContext";

export default function DailyByWellTotalLine(props) {
    const {data} = props
    const {project} = useContext(ProjectContext)

    const unit = project.currentProject.uomString

    return <Fragment>
        <tr>
            <td>
                <div className='pumperLine'>
                    <div className="pumperLineName">
                        {data.name}
                    </div>

                    <div className="pumperLineValue">
                        <div className="pumperLineMobileTitle">
                            <FormattedMessage id="fracDiesel.label" defaultMessage="Frac Diesel"/>
                        </div>
                        <div className="pumperLineName1">
                            {data.fracDieselTotal === undefined ? "N/A" : `${Formatting.numberWithCommas(data.fracDieselTotal, 2)}${unit}`}
                        </div>
                    </div>

                    <div className="pumperLineValueNonFrac">
                        <div className="pumperLineMobileTitle">
                            <FormattedMessage id="nonFracDiesel.label" defaultMessage="Non Frac Diesel"/>
                        </div>
                        <div className="pumperLineName1">
                            {data.nonFracDieselTotal === undefined ? "N/A" : `${Formatting.numberWithCommas(data.nonFracDieselTotal, 2)}${unit}`}
                        </div>
                    </div>

                    <div className="pumperLineValueLineTotal">
                        <div className="pumperLineMobileTitle">
                            <FormattedMessage id="lineTotal.label" defaultMessage="Line Total"/>
                        </div>
                        <div className="pumperLineName1">
                            {data.lineTotal === undefined || data.lineTotal === 0 ? "N/A" : `${Formatting.numberWithCommas(data.lineTotal, 2)}${unit}`}
                        </div>
                    </div>

                    <div className="pumperLineValueTotalizerTotal">
                        <div className="pumperLineMobileTitle">
                            <FormattedMessage id="totalizerTotal.label" defaultMessage="Totalizer Total"/>
                        </div>
                        <div className="pumperLineName1">
                            {data.dieselTotalizerTotal === undefined || data.dieselTotalizerTotal === 0 ? "N/A" : `${Formatting.numberWithCommas(data.dieselTotalizerTotal, 2)}${unit}`}
                        </div>
                    </div>

                    <div className="pumperLineValueVarianceVolume">
                        <div className="pumperLineMobileTitle">
                            <FormattedMessage id="varianceVolume.label" defaultMessage="Variance Volume"/>
                        </div>
                        <div className="pumperLineName1">
                            {data.dieselVarianceVolume === undefined || data.dieselVarianceVolume === 0 ? "N/A" : `${Formatting.numberWithCommas(data.dieselVarianceVolume, 2)}${unit}`}
                        </div>
                    </div>

                    <div className="pumperLineValueVariancePercentage">
                        <div className="pumperLineMobileTitle">
                            <FormattedMessage id="variancePercentage.label" defaultMessage="Variance Percentage"/>
                        </div>
                        <div className="pumperLineName1">
                            {data.dieselVariancePercentage === undefined || data.dieselVariancePercentage === 0 ? "N/A" : `${Formatting.numberWithCommas(data.dieselVariancePercentage, 2)}%`}
                        </div>
                    </div>

                    <div className="pumperLineStages">
                        <div className="pumperLineMobileTitle">
                            <FormattedMessage id="numberOfStages.label" defaultMessage="# of Stages"/>
                        </div>
                        <div className="pumperLineName1">
                            {data.stages === undefined ? "N/A" : data.stages}
                        </div>
                    </div>

                </div>
            </td>
        </tr>
        <div className="clearfix"/>
    </Fragment>
}