import React from "react";
import {FormattedMessage} from "react-intl";



export default function Introduction(props) {

    return (
        <div>
            <span className="object-attribute-name">
                <FormattedMessage id={"graphql.showMore.parameter" + props.i} defaultMessage=""/>
            </span>
            <span className="object-attribute-type">
                <FormattedMessage id={"graphql.showMore.parameter" + props.i + ".type"} defaultMessage=""/>
            </span>
            <p className="object-attribute-description">
                <FormattedMessage id={"graphql.showMore.parameter" + props.i + ".description"} defaultMessage=""/>
            </p>
        </div>
    )
    
    
    
    
    

}