import React, {useEffect, useState} from "react";
import {FormattedMessage} from "react-intl";
import CustomerApiTokenTable from "./CustomerApiTokenTable";
import {useAuth0} from "../auth/auth0-wrapper";
import {SubscriptionTierEnum, TokenTypeEnum} from "../interfaces/User";
import {getAppMetadata} from "../helpers/metadataHelper";
import {getState} from "litsy";
import Axios from "axios";

function Api(props) {
    const {user, loading} = useAuth0();
    const [userTier, setUserTier] = useState(SubscriptionTierEnum.Free);
    const [expiryDate, setExpiryDate] = useState(null);
    const [userTierPolicy, setUserTierPolicy] = useState(null);

    const energyProducerName = window.location.pathname.split("/")[1];

    // Load info once auth0 done loading
    useEffect(() => {
        if (!loading) {
            fetchUserInfo().then(tier => {
                    if (tier !== undefined)
                        fetchUserTierPolicy(tier);
                    else {
                        fetchUserTierPolicy(SubscriptionTierEnum.Free);
                    }
                }
            );
        }
    }, [loading])

    // Retrieve user tier and expiry date
    const fetchUserInfo = async () => {
        var appMetadata = await getAppMetadata(user);
        if (appMetadata.access !== undefined && appMetadata.access.energyProducers !== undefined) {
            var energyProducerList = appMetadata.access.energyProducers;

            // Find energy producer inside app_metadata
            var energyProducer = energyProducerList.find((energyProducer) => {
                return energyProducer.name === energyProducerName
            })

            // Checks to see if energy producer permissions exist
            if (energyProducer !== undefined) {
                setUserTier(energyProducer.tier);
                setExpiryDate(energyProducer.expiryDate);

                return energyProducer.tier
            }
        }
    }

    // Retrieve user tier policy
    const fetchUserTierPolicy = async (tier) => {
        const url = `${getState("fracShack_apiEndpoint", "session")}policy/user-tiers/${tier}`;
        await Axios.get(url, {
            headers: {
                "Authorization": `Bearer ${getState("authToken", "session")}`
            }
        }).then(result => {
            if (result.status === 200) {
                setUserTierPolicy(result.data.policy);
            } else {
                // Throw toast error
            }
        });
    }

    return (
        <div className="d-flex flex-column">
            <div className="projectInfoContainer">
                <div className="projectInfoTitle">
                    <FormattedMessage
                        id="features.label"
                        defaultMessage="Features"
                    />
                    {" "}/{" "}
                    <FormattedMessage
                        id="api.label"
                        defaultMessage="Api"
                    />
                </div>
            </div>

            {userTier !== null && userTierPolicy !== null && !loading &&
                <>
                    <CustomerApiTokenTable type={TokenTypeEnum.Project}
                                           userTier={userTier}
                                           userTierPolicy={userTierPolicy}/>
                    <CustomerApiTokenTable type={TokenTypeEnum.Company}
                                           userTier={userTier}
                                           userTierPolicy={userTierPolicy}/>
                </>
            }
        </div>
    )
}

export default Api;