import React, {Fragment, useContext} from "react";
import {ProjectContext} from "../Contexts/ProjectContext";
import ApiConfig from "../../ApiConfig";
import Api from "../../Api";
import CreateGraphQLAPI from "../../CreateGraphQLAPI"

export default function FeaturesPage(props) {
    const {project} = useContext(ProjectContext);

    return (
        <Fragment>
            {props.user !== null && project.token !== null && project.currentProject !== null && project.activePage === 'Features' &&
                <Fragment>
                    {project.activeSubPage === "api" && 
                        <Api/>
                    }
                    {project.activeSubPage === "create graphql api" &&
                        <CreateGraphQLAPI/>
                    }
    
                    {project.activeSubPage === "configuration" &&
                        <ApiConfig project={project.currentProject}
                                   showUnitOfMeasureToggle={props.showUnitOfMeasureToggle}
                                   token={project.token}/>
                    }
                </Fragment>
            }
        </Fragment>
    )
}