import React, {Fragment, useState, useEffect, useContext} from 'react';
import {Row, Col, Container} from 'reactstrap';
import HeaderStat from './HeaderStat';
import * as Formatting from '../helpers/formatting';
import {useIntl} from "react-intl";
import FuelLevelChart from "./FuelLevelChart";
import {ProjectContext} from "./common/Contexts/ProjectContext";

export default function FuelLevelChartContainer(props) {
    const {project} = useContext(ProjectContext);
    const intl = useIntl();

    return (
        <Fragment>
            {(props.well !== null && props.well !== undefined) &&
                <Container className="wellStatsContainer" fluid={true}>
                    <Row className="wellRow">
                        <Col xs="12" md="12" className="barChartContainer">
                            <FuelLevelChart type="fuelLevel" well={props.well}/>
                        </Col>
                    </Row>
                    <Row className="wellHeaderRow">
                        <HeaderStat
                            title={(intl.formatMessage({id: "totals.label", defaultMessage: "TOTALS"})).toUpperCase()}
                            stat={`${Formatting.numberWithCommas(props.well.totalVolumeConsumed)} ${props.well.unitOfMeasurement}`}
                            smallStat={intl.formatMessage({
                                id: "totalVolumeConsumed.label",
                                defaultMessage: "Total Volume Consumed"
                            })}/>
                        <HeaderStat 
                            title="&nbsp;" 
                            stat={`${Formatting.numberWithCommas(props.well.totalVolumeFueled)} ${props.well.unitOfMeasurement}`}
                            smallStat={intl.formatMessage({
                                id: "totalVolumeFueled.label",
                                defaultMessage: "Total Volume Fueled"
                            })}/>
                    </Row>
                </Container>
            }
            {(props.well === null || props.well === undefined) &&
                <Container className="wellStatsContainer" fluid={true}>
                    <Row className="wellRow">
                        <Col xs="12" md="12" className="barChartContainer">
                            <FuelLevelChart type="fuelLevel" />
                        </Col>
                    </Row>
                    <Row className="wellHeaderRow">
                        <HeaderStat
                            title={(intl.formatMessage({id: "totals.label", defaultMessage: "TOTALS"})).toUpperCase()}
                            stat={Formatting.numberWithCommas(project.currentProject.fuelLevel.totalVolume) + ' ' + 
                                                                 project.currentProject.fuelLevel.unitOfMeasurement}
                            smallStat={intl.formatMessage({
                                id: "totalVolumeToDate.label",
                                defaultMessage: "Total Volume to Date"
                            })}/>
                        <HeaderStat title="&nbsp;" stat={Formatting.numberWithCommas(project.currentProject.fuelLevel.totalVolumeConsumed) + ' ' +
                                                                                     project.currentProject.fuelLevel.unitOfMeasurement}
                                    smallStat={intl.formatMessage({
                                        id: "totalVolumeConsumed.label",
                                        defaultMessage: "Total Volume Consumed"
                                    })}/>
                        <HeaderStat title="&nbsp;" stat={Formatting.numberWithCommas(project.currentProject.fuelLevel.totalVolumeFueled) + ' ' +
                                                                                     project.currentProject.fuelLevel.unitOfMeasurement}
                                    smallStat={intl.formatMessage({
                                        id: "totalVolumeFueled.label",
                                        defaultMessage: "Total Volume Fueled"
                                    })}/>
                        <HeaderStat title="&nbsp;" stat={Formatting.numberWithCommas(project.currentProject.fuelLevel.currentFuelLevel) + ' ' + 
                                                                                     project.currentProject.fuelLevel.unitOfMeasurement}
                                    smallStat={intl.formatMessage({
                                        id: "currentVolume.label",
                                        defaultMessage: "Current Volume"
                                    })}
                                    smallSubStat={intl.formatMessage({ 
                                        id: "asOf.label",
                                        defaultMessage: "as of"
                                    })}
                                    smallSubStatValue={project.currentProject.fuelLevel.currentFuelLevelDateString}/>
                    </Row>
                </Container>
            }
        </Fragment>
    )

}

