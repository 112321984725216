import {useCallback, useEffect, useState} from 'react';
import {UserConfigurationFormData} from './UserConfigurationFormData';
import {getMetadataValues, newMetadataValues} from "../../../helpers/metadataHelper";
import {NaturalGasUnit, UnitOfMeasurement} from "../../../enums";

export default function useUserConfigurationFormData (
    user: any,
    energyProducer: string,
    projectName: string,
    token: string,
    saveUserMetadata: Function,
    handleDefaultValues: (userData: UserConfigurationFormData) => void
) {
    const [formData, setFormData] = useState<UserConfigurationFormData>();
    const [isLoading, setIsLoading] = useState(true);
    
    const fetchUserConfigurationFormData = useCallback(async (user : any) => {
        let metadataValues = await getMetadataValues(user, energyProducer, projectName);
        if (metadataValues !== null && metadataValues !== undefined) {
            const userData : UserConfigurationFormData = {
                unitOfMeasurement: parseInt(metadataValues.uom) === UnitOfMeasurement.Metric,
                naturalGasUnit: parseInt(metadataValues.naturalGasUnit) || NaturalGasUnit.SCF,
                btuHeatingValue: metadataValues.btuHeatingValue,
                ngHeatingValue: metadataValues.ngHeatingValue,
                dailyCutOffTime: metadataValues.dailyCutoffTime,
                selectedTimezone: metadataValues.timezone,
                dailyCutOffHour: metadataValues.dailyCutoffHour,
                brakeHorsePower: metadataValues.brakeHorsePower,
                loadFactorPumping: metadataValues.loadFactorPumping,
                loadFactorIdle: metadataValues.loadFactorIdle,
                hoursPerDayIdle: metadataValues.hoursPerDayIdle,
                hoursPerDayWorking: metadataValues.hoursPerDayWorking,
                dieselCostPerGallon: metadataValues.dieselCostPerGallon,
                ngCostPerMCF: metadataValues.ngCostPerMCF,
                bifuelDailySetupCost: metadataValues.bifuelDailySetupCost,
                substitutionRate: metadataValues.substitutionRate,
            }
            setFormData(userData);
            return userData;
        }
    }, [setFormData]);
    
    const updateUserConfigurationFormData = useCallback( (formData : UserConfigurationFormData) => {
        let isMetric = formData.unitOfMeasurement ? UnitOfMeasurement.Metric : UnitOfMeasurement.Imperial;
        
        let newMeta = newMetadataValues(user, energyProducer, projectName, isMetric, formData.naturalGasUnit,
           formData.btuHeatingValue, formData.ngHeatingValue, formData.dailyCutOffTime, formData.selectedTimezone, 
            formData.dailyCutOffHour, formData.brakeHorsePower, formData.loadFactorPumping, formData.loadFactorIdle, 
            formData.hoursPerDayIdle, formData.hoursPerDayWorking, formData.dieselCostPerGallon, formData.ngCostPerMCF, 
            formData.bifuelDailySetupCost, formData.substitutionRate);
        
        saveUserMetadata(newMeta, token, true);
    },[formData])

    useEffect(() => {
        setIsLoading(true);
        fetchUserConfigurationFormData(user)
            .then((userData: any) => {
                setIsLoading(false);
                handleDefaultValues(userData);
            })
    }, [fetchUserConfigurationFormData, user, setIsLoading, handleDefaultValues])

    return {
        formData,
        fetchUserConfigurationFormData,
        updateUserConfigurationFormData,
        isLoading
    }
}