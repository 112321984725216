import React, {useContext, useEffect, useRef, useState} from "react";
import ApiSidebar from "./ApiSidebar";
import "./api.css";
import ApiContent from "./ApiContent";
import {getUserLanguagePreference} from "../../../helpers/metadataHelper";
import {useAuth0} from "../../../auth/auth0-wrapper";
import {LocalizationContext} from "../../common/Localization/LanguageWrapper";

function Api (props) {
    const { user } = useAuth0();
    const localizationContext = useContext(LocalizationContext);

    useEffect(() => {
        let metaDataValue = getUserLanguagePreference(user);
        let languagePreference = "en-US";
        
        if(metaDataValue !== null && metaDataValue !== undefined)
        {
            if(metaDataValue.languagePreference !== null)
            {
                languagePreference = metaDataValue.languagePreference;

                localizationContext.selectLang(languagePreference, "api");
            } 
        }
    }, []);

    
    const IntroRef = useRef(null);
    const AuthRef = useRef(null);
    const ErrorRef = useRef(null);
    const FreeRef = useRef(null);
    const GraphRef = useRef(null);

    function executeScroll(index) {
        if (index === 0){
            IntroRef.current.scrollIntoView({behavior: 'smooth', block: 'center'});
        }
        else if (index === 1){
            AuthRef.current.scrollIntoView({behavior: 'smooth', block: 'center'});
        }
        else if (index === 2){
            ErrorRef.current.scrollIntoView({behavior: 'smooth', block: 'center'});
        }
        else if (index === 3){
            FreeRef.current.scrollIntoView({behavior: 'smooth', block: 'center'});
        }
        else if (index === 4){
            GraphRef.current.scrollIntoView({behavior: 'smooth', block: 'start'});
        }
    }



    return (
        <div className="docs-api">
            <ApiSidebar scrollProp={executeScroll}/>
            <ApiContent refProp={[IntroRef, AuthRef, ErrorRef, FreeRef, GraphRef]}/>
        </div>
    )
}

export default Api;