import React, {Fragment, useContext} from 'react';
import {ProjectContext} from "../Contexts/ProjectContext";
import DailyTotalStats from "../../DailyTotalStats";
import DailyNgTotalStats from "../../DailyNgTotalStats";
import DailyFuelLevelTotalStats from "../../DailyFuelLevelTotalStats";
import DailyTotalsByWellStats from "../../DailyTotalsByWellStats"

export default function DailyTotalsPage() {
    const {project} = useContext(ProjectContext)

    return (
        <Fragment>
            {project.currentProject !== null && project.activePage === "Daily Totals" &&
                <Fragment>
                    {project.activeSubPage === "consumed" &&
                        <DailyTotalStats bifuel={project.currentProject.bifuel}
                                         dailyTotals={project.currentProject.dailyTotals} token={project.token}
                                         project={project.currentProject}
                        />
                    }

                    {project.activeSubPage === "processed" &&
                        <DailyNgTotalStats dailyTotals={project.currentProject.ngSideKick.ngSideKickDailyTotals}
                                           token={project.token} project={project.currentProject}
                        />
                    }

                    {project.activeSubPage === "byWells" &&
                        <DailyTotalsByWellStats dailyTotals={project.currentProject.dailyTotals}/>
                        // todo needs data
                    }

                    {project.activeSubPage === "fuelLevel" &&
                        <DailyFuelLevelTotalStats dailyTotals={project.currentProject.fuelLevel.fuelLevelDailyTotals}
                                                  token={project.token} project={project.currentProject}
                        />
                    }
                </Fragment>
            }
        </Fragment>
    )
}