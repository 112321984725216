import {useCallback, useEffect, useState} from 'react';
import {UserConfigurationFormData} from './UserConfigurationFormData';
import {NetworkRequestStatus, UnitOfMeasurement} from "../../../enums";
import {getState} from "litsy";
import Axios from "axios";
import {ConfigurationSubmitData} from "./ConfigurationSubmitData";
import {toast} from "react-toastify";
import {useIntl} from "react-intl";
import project from "../../Project";
import {Form} from "react-bootstrap";

interface IProjectPreferenceResponse {
    status: NetworkRequestStatus,
    preferences: ConfigurationSubmitData
}

export default function useProjectConfigurationFormData(
    handleDefaultValues: (userData: UserConfigurationFormData) => void
) {
    const [formData, setFormData] = useState<UserConfigurationFormData>();
    const [isLoading, setIsLoading] = useState(true);

    const intl = useIntl();

    const fetchProjectConfigurationFormData = useCallback(async () => {
        return await getProjectPreferences().then((projectPreferences) => {
                if (projectPreferences !== null && projectPreferences !== undefined) {
                    const userData: UserConfigurationFormData = {
                        unitOfMeasurement: projectPreferences.unitOfMeasurement === UnitOfMeasurement.Metric,
                        naturalGasUnit: projectPreferences.naturalGasUnit,
                        btuHeatingValue: projectPreferences.btuHeatingValue,
                        ngHeatingValue: projectPreferences.ngHeatingValue,
                        dailyCutOffTime: projectPreferences.dailyCutoffTime,
                        selectedTimezone: projectPreferences.timeZone,
                        dailyCutOffHour: projectPreferences.dailyCutoffHour,
                        brakeHorsePower: projectPreferences.brakeHorsePower,
                        loadFactorPumping: projectPreferences.loadFactorPumping,
                        loadFactorIdle: projectPreferences.loadFactorIdle,
                        hoursPerDayIdle: projectPreferences.hoursPerDayIdle,
                        hoursPerDayWorking: projectPreferences.hoursPerDayWorking,
                        dieselCostPerGallon: projectPreferences.dieselCostPerGallon,
                        ngCostPerMCF: projectPreferences.naturalGasCostPerMCF,
                        bifuelDailySetupCost: projectPreferences.dailyBifuelSetupCost,
                        substitutionRate: projectPreferences.substitutionRate,
                    }
                    setFormData(userData);

                    return userData;
                }
            }
        );

    }, [setFormData]);

    const updateProjectConfigurationFormData = useCallback((formData: UserConfigurationFormData) => {
        let isMetric = formData.unitOfMeasurement ? UnitOfMeasurement.Metric : UnitOfMeasurement.Imperial;
        let newPrefs = {
            jobId: getState("jobId", "session"),
            unitOfMeasurement: isMetric,
            naturalGasUnit: formData.naturalGasUnit,
            btuHeatingValue: formData.btuHeatingValue,
            ngHeatingValue: formData.ngHeatingValue,
            dailyCutoffTime: formData.dailyCutOffTime,
            timeZone: formData.selectedTimezone,
            dailyCutoffHour: formData.dailyCutOffHour,
            brakeHorsePower: formData.brakeHorsePower,
            loadFactorPumping: formData.loadFactorPumping,
            loadFactorIdle: formData.loadFactorIdle,
            hoursPerDayIdle: formData.hoursPerDayIdle,
            hoursPerDayWorking: formData.hoursPerDayWorking,
            dieselCostPerGallon: formData.dieselCostPerGallon,
            naturalGasCostPerMCF: formData.ngCostPerMCF,
            dailyBifuelSetupCost: formData.bifuelDailySetupCost,
            substitutionRate: formData.substitutionRate,
        }

        updateProjectPreferences(newPrefs);
    }, [formData])

    useEffect(() => {
        setIsLoading(true);
        fetchProjectConfigurationFormData()
            .then((projData: any) => {
                setIsLoading(false);
                handleDefaultValues(projData);
            })
    }, [fetchProjectConfigurationFormData, setIsLoading, handleDefaultValues])

    const getProjectPreferences = async () => {
        const url = `${getState("fracShack_apiEndpoint", "session")}project/project-preferences/${getState("jobId", "session")}`;
        return await Axios.get<IProjectPreferenceResponse>(url, {
            headers: {
                "Authorization": `Bearer ${getState("authToken", "session")}`
            }
        }).then(result => {
            if (result.status === 200) {
                return result.data.preferences;
            } else {
                // Throw toast error
            }
        });
    }

    const updateProjectPreferences = async (newPrefs: object) => {
        const url = `${getState("fracShack_apiEndpoint", "session")}project/project-preferences`;
        await Axios.post(url,
            newPrefs,
            {
                headers: {
                    "Authorization": `Bearer ${getState("authToken", "session")}`
                }
            }).then(result => {
            if (result.status === 200) {
                toast.success(intl.formatMessage({
                    id: "projectPreferenceSuccessfullyUpdated.label",
                    defaultMessage: "Project Preference Successfully Updated"
                }), {
                    position: toast.POSITION.TOP_RIGHT
                })
            } else {
                toast.error(intl.formatMessage({
                    id: "projectPreferenceUpdateFailure.label",
                    defaultMessage: "Project Preference Updated Failure"
                }), {
                    position: toast.POSITION.TOP_RIGHT
                })
            }
        });
    }

    return {
        formData,
        fetchProjectConfigurationFormData,
        updateProjectConfigurationFormData,
        isLoading
    }
}