import React, { Fragment } from 'react';
import SmallLoading from './../../../images/loading.gif';

export default function SmallLoader()
{
	return (
		<Fragment>
			<img src={SmallLoading} className="smallLoader" alt="Loading" />
		</Fragment>
	);	
}