import React, {useContext, useEffect, useState} from 'react';
import {Bar, Chart} from 'react-chartjs-2';
import * as Formatting from '../helpers/formatting';
import {useIntl} from 'react-intl';
import {ProjectContext} from "./common/Contexts/ProjectContext";

export default function FuelLevelChart(props) {
    const {project} = useContext(ProjectContext);
    const [data, setData] = useState(null) // data is set to null initially
    const [chartOptions, setChartOptions] = useState(null);
    const intl = useIntl();
    
    useEffect(() => {
        const dataSets = [
            {
                label: (props.well !== null && props.well !== undefined)
                    ? intl.formatMessage({id: "volumeConsumed.label", defaultMessage: "Volume Consumed"})
                    : intl.formatMessage({id: "totalVolumeConsumed.label", defaultMessage: "Total Volume Consumed"}),
                data: (props.well !== null && props.well !== undefined)
                    ? Object.keys(props.well.fuelLevelSnapshot).map(i => props.well.fuelLevelSnapshot[i].volumeConsumed)
                    : Object.keys(project.currentProject.fuelLevel.fuelLevelWells).map(i => project.currentProject.fuelLevel.fuelLevelWells[i].totalVolumeConsumed),
                fill: true,
                lineTension: 0.1,
                backgroundColor: '#5F8BFF',
                borderColor: 'rgba(75,192,192,1)',
                borderCapStyle: 'butt',
                borderDash: [],
                borderDashOffset: 0.0,
                borderJoinStyle: 'miter',
                pointBorderColor: 'rgba(75,192,192,1)',
                pointBackgroundColor: '#fff',
                pointBorderWidth: 1,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: 'rgba(75,192,192,1)',
                pointHoverBorderColor: 'rgba(220,220,220,1)',
                pointHoverBorderWidth: 2,
                pointRadius: 5,
                pointHitRadius: 10,
                type: 'bar'
            },
            {
                label: (props.well !== null && props.well !== undefined)
                    ? intl.formatMessage({id: "volumeFueled.label", defaultMessage: "Volume Fueled"})
                    : intl.formatMessage({id: "totalVolumeFueled.label", defaultMessage: "Total Volume Fueled"}),
                data: (props.well !== null && props.well !== undefined)
                    ? Object.keys(props.well.fuelLevelSnapshot).map(i => props.well.fuelLevelSnapshot[i].volumeFueled)
                    : Object.keys(project.currentProject.fuelLevel.fuelLevelWells).map(i => project.currentProject.fuelLevel.fuelLevelWells[i].totalVolumeFueled),
                fill: true,
                lineTension: 0.1,
                backgroundColor: '#CCCCCC',
                borderColor: 'rgba(75,192,192,1)',
                borderCapStyle: 'butt',
                borderDash: [],
                borderDashOffset: 0.0,
                borderJoinStyle: 'miter',
                pointBorderColor: 'rgba(75,192,192,1)',
                pointBackgroundColor: '#fff',
                pointBorderWidth: 1,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: 'rgba(75,192,192,1)',
                pointHoverBorderColor: 'rgba(220,220,220,1)',
                pointHoverBorderWidth: 2,
                pointRadius: 5,
                pointHitRadius: 10,
                type: 'bar'
            }
        ];

        const chartOptions = {
            scales: {
                xAxes: [{
                    stacked: false,
                    scaleLabel: {
                        display: true,
                        labelString: (props.well !== null && props.well !== undefined)
                            ? intl.formatMessage({id: "snapshot.label", defaultMessage: "Snapshot"})
                            : intl.formatMessage({id: "well.label", defaultMessage: "Well"})
                    }
                }],
                yAxes: [
                    {
                        display: true,
                        position: 'left',
                        gridLines: {
                            display: false
                        },
                        labels: {
                            show: true
                        },
                        ticks: {
                            beginAtZero: true
                        }
                        ,
                        scaleLabel: {
                            display: true,
                            labelString: 
                                `${intl.formatMessage({
                                    id: "volume.label",
                                    defaultMessage: "Volume"
                                })} (${project.currentProject.fuelLevel.unitOfMeasurement})`
                        }
                    },
                ]
            }
            ,
            legend:
                {
                    display: true,
                    position: 'bottom',
                    onClick: (e) => e.stopPropagation(),
                    labels: {
                        filter: function (item, chart) {
                            return !item.text.includes('hide');
                        }
                    }
                },
            tooltips: {
                mode: 'index',
                intersect: false,
                position: 'nearest',
                backgroundColor: '#ffffff',
                titleFontColor: '#000000',
                bodyFontColor: '#000000',
                titleFontFamily: 'Calibri, Arial',
                titleFontSize: 12,
                bodyFontFamily: 'Calibri, Arial',
                bodyFontSize: 12,
                itemSort: function (a, b) {
                    return a.datasetIndex - b.datasetIndex;
                },
                callbacks: {
                    label: function (tooltipItem, data) {
                        var label = Math.round(tooltipItem.yLabel * 100) / 100;
                        if (isNaN(label)) label = 0;
                        if (tooltipItem.datasetIndex === 0) {
                            return (props.well !== null && props.well !== undefined)
                                ? `${intl.formatMessage({
                                    id: "volumeConsumed.label",
                                    defaultMessage: "Volume Consumed"
                                })}: ${Formatting.numberWithCommas(label)} ${project.currentProject.fuelLevel.unitOfMeasurement}`
                                : `${intl.formatMessage({
                                    id: "totalVolumeConsumed.label",
                                    defaultMessage: "Total Volume Consumed"
                                })}: ${Formatting.numberWithCommas(label)} ${project.currentProject.fuelLevel.unitOfMeasurement}`
                        } else if (tooltipItem.datasetIndex == 1) {
                            return (props.well !== null && props.well !== undefined)
                                ? `${intl.formatMessage({
                                    id: "volumeFueled.label",
                                    defaultMessage: "Volume Fueled"
                                })}: ${Formatting.numberWithCommas(label)} ${project.currentProject.fuelLevel.unitOfMeasurement}`
                                : `${intl.formatMessage({
                                    id: "totalVolumeFueled.label",
                                    defaultMessage: "Total Volume Fueled"
                                })}: ${Formatting.numberWithCommas(label)} ${project.currentProject.fuelLevel.unitOfMeasurement}`
                        } else {
                            return label;
                        }
                    },
                    beforeLabel: function (tooltipItem, data) {
                    },
                    afterLabel: function (tooltipItem, data) {
                    },
                    title: function (tooltipItems, data) {
                        var label = tooltipItems[0].label;
                        return label;
                    }
                }
            },
            responsive: true,
            maintainAspectRatio: false
        }
        
        setData({
            labels: (props.well !== null && props.well !== undefined)
                ? Object.keys(props.well.fuelLevelSnapshot).map((index) => `${intl.formatMessage({
                    id: "snapshot.label",
                    defaultMessage: "Snapshot"
                })} ${index}`)
                : Object.keys(project.currentProject.fuelLevel.fuelLevelWells).map(i => project.currentProject.fuelLevel.fuelLevelWells[i].wellId),
            datasets: dataSets
        })
        setChartOptions(chartOptions);
    }, [props])

    // render bar chart when there is data
    return data && chartOptions && (<Bar className="barChart" data={data} options={chartOptions}/>)
}