import React, { Fragment, useEffect, useState } from 'react';
import _ from 'lodash';
import {FormattedMessage} from "react-intl";
import DailyTotalsChartContainer from "./DailyTotalsChartContainer";
import DailyNgTotalsSummary from "./DailyNgTotalsSummary";

export default function DailyNgTotalStats(props) {

    const [dailyTotals, setDailyTotals] = useState(null);

    useEffect(() => {
        let dailyTotals = _.orderBy(props.dailyTotals, ['date'], ['asc']);
        setDailyTotals(dailyTotals);
    }, []);

    return (
        <Fragment>
            {
                props.dailyTotals !== null && dailyTotals !== null &&
                <Fragment>
                    <div className="pumperTitle">
                        <FormattedMessage id="dailyTotalsProcessed.label" defaultMessage="Daily Totals Processed" />
                    </div>
                    <DailyTotalsChartContainer dailyTotals={dailyTotals} project={props.project} type="processed"/>
                    <DailyNgTotalsSummary project={props.project} dailyTotals={dailyTotals} />
                </Fragment>
            }
        </Fragment>
    )

}