import React, {Fragment, useState} from 'react';
import * as Formatting from '../helpers/formatting';
import _ from 'lodash';
import { FormattedMessage } from "react-intl";
import * as Conversion from '../helpers/Conversion';
import {NaturalGasUnit} from "../enums";


export default function DailyNgTotalLine(props)
{
    return(
        <Fragment>
            <tr>
                <td>
                    <div className='pumperLine'>
                        <Fragment>
                            <div className="pumperLineName">{props.dailyTotal.date.substring(0,10)}</div>
                        </Fragment>
                        <Fragment>
                            <div className="pumperLineValue">
                                <div className="pumperLineMobileTitle">
                                    <FormattedMessage id="naturalGas.label" defaultMessage="Natural Gas" />
                                </div>
                                <div className="pumperLineName1">{props.unit === "L" ? Formatting.numberWithCommas(Conversion.toDLE(props.dailyTotal.volume)) :
                                    Formatting.numberWithCommas(Conversion.toDGE(props.dailyTotal.volume))} D{props.unitShort}E</div>
                                {props.naturalGasUnit === NaturalGasUnit.SCF &&
                                    <div className="pumperLineName2">{Formatting.numberWithCommas(props.dailyTotal.volume)} SCF</div>
                                }
                                {props.naturalGasUnit === NaturalGasUnit.MCF &&
                                    <div className="pumperLineName2">{Formatting.numberWithCommas(props.dailyTotal.volumeMCF)} MCF</div>
                                }
                                {props.naturalGasUnit === NaturalGasUnit.E3M3 &&
                                    <div className="pumperLineName2">{Formatting.numberWithCommas(props.dailyTotal.volumeDEC, 4)} E3M3</div>
                                }
                            </div>
                        </Fragment>
                        <div className="pumperLineStages">
                            <div className="pumperLineMobileTitle">
                                <FormattedMessage id="numberOfStages.label" defaultMessage="# of Stages" />
                            </div>
                            <div className="pumperLineName1 floatRightNoMargin">{props.dailyTotal.stagesCompleted}</div>
                        </div>
                    </div>
                </td>
            </tr>
            <div className="clearfix"></div>
        </Fragment>
    )
}