import React, { Fragment, useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap'
import InfoIcon from '../images/icons8-info-16.png';
import {UncontrolledTooltip} from "reactstrap";
import * as Formatting from '../helpers/formatting';
import {ReactComponent as WhiteUp} from '../images/whiteUp.svg';
import {ReactComponent as WhiteDown} from '../images/whiteDown.svg';
import WellLine from '../components/WellLine.js';
import { FormattedMessage, useIntl } from "react-intl";
import ConvertStringToLabel from "../helpers/ConvertStringToLabel";
import NgSidekickWellLine from "./NgSidekickWellLine";
import NgSidekickGasCompositionElement from "./NgSidekickGasCompositionElement";
import FuelLevelWellLine from "./FuelLevelWellLine";

export default function FuelLevelSummary(props)
{
    const intl = useIntl();
    const [activeSort, setActiveSort] = useState(null);
    const [sortAsc, setSortAsc] = useState(false);

    const [stages, setStages] = useState(null);
    const [wellList, setWellList] = useState(null);

    let _sortAsc = false;

    useEffect(() => {
        if (props.well !== undefined)
        {
            setStages(props.well.fuelLevelSnapshot);
        }
        if (props.project.fuelLevel.fuelLevelWells !== undefined)
        {
            setWellList(props.project.fuelLevel.fuelLevelWells);
        }
    }, []);

    const requestWell = (well) => {
        props.requestWell(well);
    };

    useEffect(() => {
        if (props.well !== undefined)
        {
            setStages(props.well.fuelLevelSnapshot);
        }
    }, [props.well]);

    const [showInfo, setShowInfo] = useState(false);

    const toggleInfoIcon = () =>
    {
        setShowInfo(!showInfo);
    }

    const sortData = (column) => {
        _sortAsc = sortAsc;

        if (column === activeSort)
        {
            setSortAsc(!sortAsc);
            _sortAsc = !_sortAsc;
        }

        setActiveSort(column);

        if (column === 'totalVolumeConsumed')
        {
            setWellList(wellList.sort(volumeCompare).slice());
        }
        else if (column === 'startTime')
        {
            setWellList(wellList.sort(startTimeCompare).slice());
            if (stages !== null)
            {
                setStages(stages.sort(volumeCompare).slice());
            }
        }
        else if (column === 'endTime')
        {
            setWellList(wellList.sort(endTimeCompare).slice());
            if (stages !== null)
            {
                setStages(stages.sort(volumeCompare).slice());
            }
        }
        else if (column === 'snapshotCount') {
            setWellList(wellList.sort(stageCountCompare).slice());
        }
    }
    
    const volumeCompare = (a,b) => {
        if (props.well !== undefined || true) {
            if (_sortAsc)
            {
                if (a.totalVolumeConsumed > b.totalVolumeConsumed)
                {
                    return 1;
                } else {
                    return -1;
                }
            } else {
                if (a.totalVolumeConsumed < b.totalVolumeConsumed)
                {
                    return 1
                } else {
                    return -1;
                }
            }
        } else {
            if (_sortAsc)
            {
                if (a.volumeConsumed > b.volumeConsumed)
                {
                    return 1;
                } else {
                    return -1;
                }
            } else {
                if (a.volumeConsumed < b.volumeConsumed)
                {
                    return 1
                } else {
                    return -1;
                }
            }
        }

    }

    const stageCountCompare = (a,b) => {
        if (_sortAsc)
        {
            if (a.totalStages > b.totalStages)
            {
                return 1;
            }else{
                return -1;
            }
        }else{
            if(a.totalStages < b.totalStages)
            {
                return 1;
            }else{
                return -1;
            }
        }
    }
    
    const endTimeCompare = (a,b) => {
        if (_sortAsc)
        {
            if (a.endTime > b.endTime)
            {
                return 1;
            }else{
                return -1;
            }
        }else{
            if (a.endTime < b.endTime)
            {
                return 1;
            }else{
                return -1;
            }
        }
    }

    const startTimeCompare = (a,b) => {
        if (_sortAsc)
        {
            if (a.startTime > b.startTime)
            {
                return 1;
            }else{
                return -1;
            }
        }else{
            if (a.startTime < b.startTime)
            {
                return 1;
            }else{
                return -1;
            }
        }
    }

    // Todo: Fix sorting for the titles
    return (
        <div className="ng-sidekick-container">
            <div className="pumperSummary">
                <div className="pumperLinesHeader">
                    <div className="pumperLinesHeaderCell pumperLinesHeaderName pumperLinesHeaderCellSort"> <FormattedMessage id= { ConvertStringToLabel(props.title) + '.label'} defaultMessage={ props.title } /></div>
                    <div className="pumperLinesHeaderCell pumperLinesHeaderValue pumperLinesHeaderCellSort"> <FormattedMessage id="totalVolumeConsumed.label" defaultMessage="Total Volume Consumed" /></div>
                    <div className="pumperLinesHeaderCell pumperLinesHeaderValue pumperLinesHeaderCellSort"> <FormattedMessage id="totalVolumeFueled.label" defaultMessage="Total Volume Fueled" /></div>

                    {props.type === 'all' && props.project !== null && props.project.wells !== null && props.well === undefined && wellList !== null &&
                    <div className="pumperLinesHeaderCell pumperLinesHeaderStages pumperLinesHeaderCellSort" onClick={() => sortData('snapshotCount')}><FormattedMessage id="numberOfSnapshots.label" defaultMessage="# of Snapshots" /> {activeSort === 'snapshotCount' && sortAsc && <WhiteUp />}{activeSort === 'snapshotCount' && !sortAsc && <WhiteDown />}</div>
                    }

                    {props.well !== null && props.well !== undefined &&
                    <Fragment>
                        <div className="pumperLinesHeaderCell pumperLinesHeaderFinish pumperLinesHeaderCellSort" onClick={() => sortData('endTime')}> <FormattedMessage id="finishTimeUTC.label" defaultMessage="Finish Time (UTC)" />{activeSort === 'endTime' && sortAsc &&  <WhiteUp />}{activeSort === 'endTime' && !sortAsc && <WhiteDown />}</div>
                        <div className="pumperLinesHeaderCell pumperLinesHeaderStart pumperLinesHeaderCellSort" onClick={() => sortData('startTime')}> <FormattedMessage id="startTimeUTC.label" defaultMessage="Start Time (UTC)" /> {activeSort === 'startTime' && sortAsc &&  <WhiteUp />}{activeSort === 'startTime' && !sortAsc && <WhiteDown />}</div>
                    </Fragment>
                    }
                </div>
                <div className="clearfix"></div>
                <div className="pumperLinesData">
                    <table className="fullWidth" id="table">
                        {props.project !== null && props.project.fuelLevel.fuelLevelWells !== null && props.well === undefined && wellList !== null && wellList.map((well, index) =>
                            <FuelLevelWellLine key={well.wellName} 
                                               index={index}
                                               requestWell={requestWell} 
                                               well={well}/>
                        )}
                        {props.project !== null && props.project.fuelLevel.fuelLevelWells !== null && props.well === undefined &&
                        <tr><td>
                            <div className='pumperLine'>
                                <div className="pumperLineName">
                                    {(intl.formatMessage({ id: "total.label", defaultMessage: "TOTAL"})).toUpperCase()}
                                </div>
                                <div className="pumperLineValue">
                                    <div className="pumperLineMobileTitle">
                                        <FormattedMessage id="totalVolumeConsumed.label" defaultMessage="Total Volume Consumed" />
                                    </div>
                                    <div className="pumperLineName1">{Formatting.numberWithCommas(props.project.fuelLevel.totalVolumeConsumed)} {props.project.fuelLevel.unitOfMeasurement}</div>
                                </div>
                                <div className="pumperLineValue">
                                    <div className="pumperLineMobileTitle">
                                        <FormattedMessage id="totalVolumeFueled.label" defaultMessage="Total Volume Fueled" />
                                    </div>
                                    <div className="pumperLineName1">{Formatting.numberWithCommas(props.project.fuelLevel.totalVolumeFueled)} {props.project.fuelLevel.unitOfMeasurement}</div>
                                </div>
                                <div className="pumperLineStages">
                                    <div className="pumperLineMobileTitle">
                                        <FormattedMessage id="numberofSnapshots.label" defaultMessage="# of Snapshots" />
                                    </div>
                                    <div className="pumperLineName1">{Formatting.numberWithCommas(props.project.fuelLevel.totalSnapshots)}</div>
                                </div>
                            </div>
                        </td></tr>
                        }
                        {props.project !== null && props.well !== undefined && stages !== null && stages.map((stage, index) =>
                            <FuelLevelWellLine well={stage}
                                               index={index}
                                               single={true}/>
                        )}
                        {props.project !== null && props.well !== undefined && stages !== null &&
                        <Fragment>
                            <tr><td>
                                <div className='pumperLine'>
                                    <div className="pumperLineName">
                                        {(intl.formatMessage({ id: "total.label", defaultMessage: "TOTAL"})).toUpperCase()}
                                    </div>
                                    <div className="pumperLineValue">
                                        <div className="pumperLineMobileTitle">
                                            <FormattedMessage id="totalVolumeConsumed.label" defaultMessage="Total Volume Consumed" />
                                        </div>
                                        <div className="pumperLineName1">
                                            {Formatting.numberWithCommas(props.well.totalVolumeConsumed)} {props.well.unitOfMeasurement}
                                        </div>
                                    </div>
                                    <div className="pumperLineValue">
                                        <div className="pumperLineMobileTitle">
                                            <FormattedMessage id="totalVolumeFueled.label" defaultMessage="Total Volume Fueled" />
                                        </div>
                                        <div className="pumperLineName1">
                                            {Formatting.numberWithCommas(props.well.totalVolumeFueled)} {props.well.unitOfMeasurement}
                                        </div>
                                    </div>
                                </div>
                            </td></tr>
                        </Fragment>
                        }
                    </table>
                </div>
            </div>
        </div>
    )
}