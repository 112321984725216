import React, {Fragment, useContext, useEffect, useState} from 'react';
import {Button} from "reactstrap";
import {useAuth0} from "../../../auth/auth0-wrapper";
import './AccountSettings.css';
import '../../../Site.css';
import {saveUserLanguagePreferenceActionCreator} from "../../../store/UserLanguagePreference";
import {actionCreators as actionCreatorsHeader} from "../../../store/Header";
import {getUserLanguagePreference, newUserLanguagePreference} from "../../../helpers/metadataHelper";
import {connect} from "react-redux";
import {FormattedMessage, useIntl} from "react-intl";
import {LocalizationContext} from "../Localization/LanguageWrapper";
import Loader from "../Loader/Loader";

function AccountSettings(props){
    
     const { getTokenSilently, loading, user, } = useAuth0();
     const [token, setToken] = useState(null);
     const [isLoading, setIsLoading] = useState(false);
     const [languagePreference, setLanguagePreference] = useState("");
     const intl = useIntl();
     const localizationContext = useContext(LocalizationContext);

    //Initial mount
    useEffect(() => {
        const fetchToken = async () => {
            const auth0Token = await getTokenSilently();
            setToken(auth0Token);
        };
        fetchToken();

        let metaDataValue = getUserLanguagePreference(user);
        let language = "en-US"
        
        if(metaDataValue !== null && metaDataValue !== undefined)
        {
            if(metaDataValue.languagePreference !== null)
            {
                setLanguagePreference(metaDataValue.languagePreference);
                language = metaDataValue.languagePreference;
            }
        }else {
            setLanguagePreference("en-US");
        }

        localizationContext.selectLang(language, null);

    },[])
    
     const goBack = () => {
         window.location.assign(window.localStorage.getItem('prevPath'));
     }
     
     const goHome = () => {
         window.location.assign("\\");
     }
     
     const handleSelect = (event) => {
         setLanguagePreference(event.target.value);
     }
     
     const save = () => {
         // do stuff to save then refresh
         let newMeta = newUserLanguagePreference(user, languagePreference);
         props.saveUserMetadata(newMeta, token, false);
         
         props.showHeaderNavMenuButton(false);
         document.body.style.pointerEvents = "none";
         
         setIsLoading(true);

         setTimeout(function() { goHome() }, 3000);
     }

    return(
        <Fragment>
            {isLoading &&
                <div className="center">
                    <Loader/>
                </div>
            }
            {!isLoading &&
                        <div className="padding-top-lg">
                            <div className="account-settings-container offset-md-1">
                                <h2>
                                    <FormattedMessage id="accountSettings.label" defaultMessage="Account Settings"/>
                                </h2>
                                <div className="user-preference-container">
                                    <div className="language-preference-heading">
                                        <FormattedMessage id="languagePreference.label"
                                                          defaultMessage="Language Preference"/>
                                    </div>
                                    <div className="language-preference-dropdown">
                                        <select value={languagePreference} onChange={handleSelect}>
                                            <option value="en-US">{intl.formatMessage({
                                                id: "english.label",
                                                defaultMessage: "English"
                                            })} - (USA)
                                            </option>
                                            <option value="es-AR">{intl.formatMessage({
                                                id: "spanish.label",
                                                defaultMessage: "Spanish"
                                            })} - (Argentina)
                                            </option>
                                            <option value="en-CA">{intl.formatMessage({
                                                id: "english.label",
                                                defaultMessage: "English"
                                            })} - (Canada)
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div className="button-container">
                                    <Button
                                        color="warning"
                                        className="float-right"
                                        disabled={user.loading === true}
                                        outline
                                        onClick={save}
                                        size="md"
                                    >
                                        <FormattedMessage id="save.label" defaultMessage="Save"/>
                                    </Button>
                                    <Button
                                        color="warning"
                                        className="float-right"
                                        disabled={user.loading === true}
                                        outline
                                        onClick={goBack}
                                        size="md"
                                    >
                                        <FormattedMessage id="back.label" defaultMessage="Back"/>
                                    </Button>
                                </div>
                            </div>
                        </div>
                }
        </Fragment>
    )
}
const mapStateToProps = (store) => {
    return {
        userMetadata:store.languagePreference
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        saveUserMetadata: (userMetadata, token, reload) => dispatch(saveUserLanguagePreferenceActionCreator(userMetadata, token, reload)),
        showHeaderNavMenuButton: (show) => dispatch(actionCreatorsHeader.setShowNavMenuButton(show)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AccountSettings);