import React, { Fragment } from 'react';
import WellChartContainer from './WellChartContainer';
import ExportUnit from './ExportUnit';
import {FormattedMessage} from "react-intl";

export default function WellStats(props) {
    return (
        <Fragment>
            {
                props.well != null && 
                    <Fragment>
                        <div className="pumperTitle">{props.well.name !== '' ? 
                            <span><FormattedMessage id="well.label" defaultMessage="Well" /> {props.well.name}</span> 
                            : <i><FormattedMessage id="unknownWell.label" defaultMessage="Unknown Well" /></i>}</div>
                        {
                        props.project !== null && 
                            <ExportUnit project={props.project} token={props.token} type="singleWell" wellName={props.well.name} />
                        }
                        <WellChartContainer well={props.well} naturalGasUnit={props.project.naturalGasUnit} type="productivity" />
                        {(props.showSubRate === true || props.showSubRate === 'true') && 
                        <WellChartContainer well={props.well} naturalGasUnit={props.project.naturalGasUnit} type="consumption" bifuel={props.bifuel} />
                        }
                    </Fragment>
            }
        </Fragment>
    )
 
}