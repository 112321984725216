import React, {Fragment} from 'react';
import {Link} from 'react-router-dom';
import './Error.css'
import {Container} from 'reactstrap';

interface ErrorProps {
    title?: string
    children?: React.ReactChild
    path?: string
    pathText?: string
    useStaticLink?: boolean
}

const Error: React.FunctionComponent<ErrorProps> = ({
                                                        title = 'Oops',
                                                        children = 'Something went wrong and our developers are on the case. Feel free to let us know what went wrong by reporting the issue.',
                                                        path = '/',
                                                        pathText = 'Retry',
                                                        useStaticLink
                                                    }) => {

    const reloadPage = () => {
        if (pathText.toLowerCase() === 'retry') {
            window.location.reload();
            return false;
        }
    }

    return (
        <div className="error-container">
            <Container className="error-content">
                <h1 className="error-title">{title}</h1>
                <h3 className="error-description">{children}</h3>
                {pathText.length > 0 &&
                    <Fragment>
                        {pathText.toLowerCase() === 'retry' &&
                            <div className="error-button" onClick={reloadPage}>
                                <div className="error-button-text">
                                    {pathText}
                                </div>
                            </div>
                        }
                        {pathText.toLowerCase() !== 'retry' &&
                            <Fragment>
                                <a href={path} className="error-button">
                                    <div className="error-button-text">
                                        {pathText}
                                    </div>
                                </a>
                            </Fragment>
                        }
                    </Fragment>
                }
            </Container>
        </div>
    )
}

export default Error