import React, {createContext, Fragment, useContext, useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle, faSignOutAlt} from '@fortawesome/free-solid-svg-icons'
import {ButtonDropdown, DropdownToggle, DropdownItem, DropdownMenu } from 'reactstrap';
import { FormattedMessage } from "react-intl";
import {AccessType} from "../../../auth/accessControlService";
import AccessControl from "../AccessControl/AccessControl";

export default function ProfileDropdown(props){
    // Checks if the current url was from account settings itself,
    // If it is, pass
    // else, set prevPath as current url
    const setPrevPath = () => {
        if (window.location.pathname !== "/user/accountsettings" && 
            window.location.pathname !== "/user/admin") {
            window.localStorage.setItem("prevPath", window.location.pathname)
        }}

    return(
        <Fragment>
            <ButtonDropdown className="custom-link " tag={props.tag} to={props.to} isOpen={props.isOpen} toggle={props.toggle}>
                <DropdownToggle tag={props.tag} to={props.to} size="lg" color="secondary">
                    <FontAwesomeIcon icon={faUserCircle} size="2x"/>
                </DropdownToggle>
                <DropdownMenu right>
                    <DropdownItem header><FormattedMessage id="hi.label" defaultMessage="Hi,"/> {props.username}!</DropdownItem>
                    <Link to={'/user/accountsettings'} onClick={setPrevPath()}>
                        <DropdownItem>
                            <FormattedMessage id="accountSettings.label" defaultMessage="Account Settings"/> 
                        </DropdownItem>
                    </Link>
                    <AccessControl
                        extraAccessData={{serviceEndpoint:global.apiEndpoint, token:props.token }}
                        accessType={AccessType.IsITSiteAdmin}
                        renderNoAccess={() => ''}>
                        <Link to={'/user/admin'}>
                            <DropdownItem>
                                <FormattedMessage id="adminSettings.label" defaultMessage="Admin Settings" />
                            </DropdownItem>
                        </Link>
                    </AccessControl>
                    <DropdownItem onClick={()=>props.logout({returnTo:global.auth0RedirectUri})}>
                        <span>
                            <FormattedMessage id="logout.label" defaultMessage="Logout"/> 
                            &nbsp;<FontAwesomeIcon icon={faSignOutAlt} /></span>
                    </DropdownItem>
                </DropdownMenu>
            </ButtonDropdown>
        </Fragment>
    )
}