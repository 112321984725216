import React, {Fragment, useEffect, useState} from 'react';
import * as Formatting from '../helpers/formatting';
import _ from 'lodash';
import {FormattedMessage} from "react-intl";
import {NaturalGasUnit} from "../enums";


export default function DailyTotalLine(props) {
    const {naturalGasUnit, bifuel, dailyTotal, unit, unitShort} = props
    
    let lineTotal = `${Formatting.numberWithCommas(_.round(unit === "L" ? dailyTotal.lineTotalLitres : dailyTotal.lineTotalGallons, 2))}${unit}`
    let totalizerTotal = dailyTotal.dieselTotalizerLitres === 0 ? "N/A"
        : `${Formatting.numberWithCommas(_.round(unit === "L" ? dailyTotal.dieselTotalizerLitres : dailyTotal.dieselTotalizerGallons, 2))}${unit}`
    let varianceVolume = dailyTotal.dieselVarianceVolumeLitres === 0 || dailyTotal.dieselVarianceVolumeLitres == null ? "N/A"
        : `${Formatting.numberWithCommas(_.round(unit === "L" ? dailyTotal.dieselVarianceVolumeLitres : dailyTotal.dieselVarianceVolumeGallons, 2))}${unit}`
    let variancePercentage = dailyTotal.dieselVariancePercentage === 0 ? "N/A"
        : `${_.round(dailyTotal.dieselVariancePercentage, 2)}%`

    
    return (
        <Fragment>
            <tr>
                <td>
                    <div className='pumperLine'>

                        <div className="pumperLineName">
                            {dailyTotal.date.substring(0, 10)}
                        </div>

                        <div className="pumperLineValue">
                            <div className="pumperLineMobileTitle">
                                <FormattedMessage id="fracDiesel.label" defaultMessage="Frac Diesel"/>
                            </div>
                            <div className="pumperLineName1">
                                {Formatting.numberWithCommas(unit === "L" ? _.round(dailyTotal.totalDieselLitres, 2) : _.round(dailyTotal.totalDieselGallons, 2))}
                                {unit}
                            </div>
                        </div>

                        {bifuel &&
                            <Fragment>
                                <div className="pumperLineValue">
                                    <div className="pumperLineMobileTitle">
                                        <FormattedMessage id="naturalGas.label" defaultMessage="Natural Gas"/>
                                    </div>
                                    <div className="pumperLineName1">
                                        {Formatting.numberWithCommas(unit === "L" ? _.round(dailyTotal.totalNGLitres, 2) : _.round(dailyTotal.totalNGGallons, 2))} D{unitShort}E
                                    </div>
                                    {naturalGasUnit === NaturalGasUnit.SCF &&
                                        <div className="pumperLineName2">
                                            {Formatting.numberWithCommas(_.round(dailyTotal.totalSCF, 2))} SCF
                                        </div>
                                    }
                                    {naturalGasUnit === NaturalGasUnit.MCF &&
                                    <div className="pumperLineName2">
                                        {Formatting.numberWithCommas(_.round(dailyTotal.totalMCF, 2))} MCF
                                    </div>
                                    }
                                    {naturalGasUnit === NaturalGasUnit.E3M3 &&
                                        <div className="pumperLineName2">
                                            {Formatting.numberWithCommas(_.round(dailyTotal.totalDEC, 4), 4)} E3M3
                                        </div>
                                    }
                                </div>
                                <div className="pumperLineEnergy">
                                    <div className="pumperLineMobileTitle">
                                        <FormattedMessage id="fracConsumedEnergy.label" defaultMessage="Frac Consumed Energy"/>
                                    </div>
                                    <div className="pumperLineName1">
                                        {Formatting.numberWithCommas(unit === "L" ? _.round(dailyTotal.totalConsumedLitres, 2) : _.round(dailyTotal.totalConsumedGallons, 2))} D{unitShort}E
                                    </div>
                                    <div className="pumperLineName2">
                                        {Formatting.numberWithCommas(_.round(dailyTotal.totalBTU, 2))} BTU
                                    </div>
                                </div>
                            </Fragment>
                        }

                        <div className="pumperLineValueNonFrac">
                            <div className="pumperLineMobileTitle">
                                <FormattedMessage id="nonFracDiesel.label" defaultMessage="Non Frac Diesel"/>
                            </div>
                            <div className="pumperLineName1">
                                {Formatting.numberWithCommas(unit === "L" ? _.round(dailyTotal.totalNonFracDieselLitres, 2) : _.round(dailyTotal.totalNonFracDieselGallons, 2))}
                                {unit}
                            </div>
                        </div>

                        <div className="pumperLineValueLineTotal">
                            <div className="pumperLineMobileTitle">
                                <FormattedMessage id="lineTotal.label" defaultMessage="Line Total"/>
                            </div>
                            <div className="pumperLineName1">
                                {lineTotal}
                            </div>
                        </div>

                        <div className="pumperLineValueTotalizerTotal">
                            <div className="pumperLineMobileTitle">
                                <FormattedMessage id="totalizerTotal.label" defaultMessage="Totalizer Total"/>
                            </div>
                            <div className="pumperLineName1">
                                {totalizerTotal}
                            </div>
                        </div>

                        <div className="pumperLineValueVarianceVolume">
                            <div className="pumperLineMobileTitle">
                                <FormattedMessage id="varianceVolume.label" defaultMessage="Variance Volume"/>
                            </div>
                            <div className="pumperLineName1">
                                {varianceVolume}
                            </div>
                        </div>

                        <div className="pumperLineValueVariancePercentage">
                            <div className="pumperLineMobileTitle">
                                <FormattedMessage id="variancePercentage.label" defaultMessage="Variance Percentage"/>
                            </div>
                            <div className="pumperLineName1">
                                {variancePercentage}
                            </div>
                        </div>


                        <div className="pumperLineStages">
                            <div className="pumperLineMobileTitle">
                                <FormattedMessage id="numberOfStages.label" defaultMessage="# of Stages"/>
                            </div>
                            <div className="pumperLineName1">
                                {dailyTotal.stagesPerDay}
                            </div>
                        </div>

                        {bifuel &&
                            <div className="pumperLineSubRate">
                                <div className="pumperLineMobileTitle">
                                    <FormattedMessage id="substitutionRate.label" defaultMessage="Substitution Rate"/>
                                </div>
                                <div className="pumperLineName1">
                                    {dailyTotal.subRate}%
                                </div>
                            </div>
                        }

                    </div>
                </td>
            </tr>
            <div className="clearfix"/>
        </Fragment>
    )
}