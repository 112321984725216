import React from "react";
import {FormattedMessage} from "react-intl";

export default function Introduction(props) {
 
    return (
        
        <div className="api-content-container" id="Introduction" ref={props.refProp}>
            <div className="api-section-details half-section">
                <h1 className="api-section-header">
                    <FormattedMessage id="intro.header" defaultMessage="Introduction"/>
                </h1>
                <p className="api-section-content">
                    <FormattedMessage id="intro.message1"
                                      defaultMessage="Welcome to the Energera API! You can use our API to access Energera endpoints, which can get information on various snapshot data in our database."/>
                </p>
                <p>
                    <FormattedMessage id="intro.message2"
                                      defaultMessage="The Energera API is organized around REST and GraphQL. Our API has predictable resource-oriented URLs, accepts form-encoded request bodies, returns JSON-encoded responses, and uses standard HTTP response codes, authentication, and verbs."/>
                </p>
                <p>
                    <FormattedMessage id="intro.message3"
                                      defaultMessage="The free fuel consumption API uses REST to provide summarized data on daily fuel consumption. While the premium API uses GraphQL to allow the user to fetch more detailed data."/>
                </p>
            </div>
            <div className="api-section-code half-section">
                <div className="api-section-code-topbar">
                    <FormattedMessage id="intro.Code.baseURL" defaultMessage="Base URL"/>
                </div>
                <div className="api-section-code-bottom">
                    <div>
                        <span className="curl-definition-path">https://api.enertrail.com/</span>
                    </div>
                </div>
            </div>
        </div>
    )
}