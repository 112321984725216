import { User } from "../interfaces/User";

export enum UnitOfMeasurement{
	Default = 0,
	Imperial = 1,
	Metric = 2
}

export enum NaturalGasUnit {
    SCF,
    E3M3,
    MCF
}

export enum SubscriptionType {
    Daily = 0,
    Stage = 1,
    Excel = 2,
    Scheduled = 3
}

export enum NetworkRequestStatus
{
    Successful,
    Failed
}

export enum CheckSiteAdminAuthTypes{
    CheckingSiteAdmin = "CHECKING_USER_SITE_ADMIN",
    AuhtorizedSiteAdmin = "USER_AUTHORIZED_SITE_ADMIN",
    UnauthorizedSiteAdmin = "USER_UNAUTHORIZED_SITE_ADMIN"
}

export enum CheckProjectAdminAuthTypes{
    CheckingProjectAdmin = "CHECKING_USER_PROJECT_ADMIN",
    AuhtorizedProjectAdmin = "USER_AUTHORIZED_PROJECT_ADMIN",
    UnauthorizedProjectAdmin = "USER_UNAUTHORIZED_PROJECT_ADMIN"
}
  
export enum SubmitUserTypes{
    Uninitialized = "UNINIT_SUBMIT_USER",
    Submitting = "SUBMITTING_USER",
    Submitted = "SUBMITTED_USER",
    Error = "SUBMIT_USER_ERROR"
}
export enum GetUsersTypes{
    Uninitialized = "UNINIT_GET_USERS",
    Getting = "GETTING_USERS",
    Got = "GOT_USERS",
    Error = "GET_USERS_ERROR"
}

export enum GetUserRoles {
    Uninitialized = "UNINIT_GET_USER_ROLES",
    Getting = "GETTING_USER_ROLES",
    Got = "GOT_USER_ROLES",
    Error = "GET_USER_ROLES_ERROR"
}

export const UnauthorizedError = "UNAUTHORIZED";
export const UnauthorizedSubmitError = "UNAUTHORIZED_SUBMIT";

export enum SaveUserMetadataTypes{
    Uninitialized = "UNINIT_SUBMIT_USER_METADATA",
    Submitting = "SUBMITTING_USER_METADATA",
    Submitted = "SUBMITTED_USER_METADATA",
    Error = "SUBMIT_USER_ERROR_METADATA"
}

export enum ConvertLanguageToJson {
    "en-US" = "en-US",
    "en-CA" = "en-US",
    "es-AR" = "es-AR",
}