import React, {Fragment, useContext} from "react";
import {ProjectContext} from "../Contexts/ProjectContext";
import GreenhouseGases from "../../GreenhouseGases";

export default function GreenhouseGasPage(props) {
    const {project} = useContext(ProjectContext);

    return (
        <Fragment>
            {project.currentProject !== null && project.activePage === "Greenhouse Gas" &&
            <GreenhouseGases bifuel={project.currentProject.bifuel} project={project.currentProject}
                             gasTotals={project.currentProject.dailyTotals} token={project.token}/>
            }
        </Fragment>
    )
}