import React, {Fragment, useContext, useEffect, useState} from 'react';
import {FormattedMessage, useIntl} from "react-intl";
import {ProjectMenuContext} from "../Contexts/ProjectMenuContext";
import {ReactComponent as WhiteUp} from "../../../images/whiteUp.svg";
import {ReactComponent as WhiteDown} from "../../../images/whiteDown.svg";
import ReactGA from "react-ga";
import WellList from "../../WellList";
import {ReactComponent as BlackRight} from "../../../images/arrowRightBlack.svg";
import {ProjectContext} from "../Contexts/ProjectContext";

export default function Wells(props) {
    const {closeMobileMenu} = useContext(ProjectMenuContext)
    const {project, setActivePage, setValue, setMobileText} = useContext(ProjectContext);
    const [showWellsDropdown, setShowWellsDropdown] = useState(false);
    const intl = useIntl();

    useEffect(() => {
        if (project.activePage !== "Wells") {
            setShowWellsDropdown(false);
        }
    }, [project.activePage])

    const activePage = () => {
        return project.activePage === "Wells" ? 'siteTitle siteTitleActive menuItem menuItemActive' : 'siteTitle menuItem'
    }

    const toggleWellsMenu = () => {
        switch (project.activePage) {
            case "Wells":
                setShowWellsDropdown(!showWellsDropdown);
                break;
            default:
                setShowWellsDropdown(true);
                requestProjectStats();
        }
    }

    const requestProjectStats = () => {
        setValue('activeWell', 'all');
        setMobileText(intl.formatMessage({id: "allWells.label", defaultMessage: "All Wells"}));

        if (project.activePage !== "Wells") {
            setActivePage("Wells");
        } else {
            closeMobileMenu();
        }

        // Google Analytics
        ReactGA.event({
            category: "Project Menu Selection",
            action: `Clicked on "All Wells" on Project "${window.location.pathname}"`
        })

        // Google Tag Manager
        window.dataLayer.push({
            'event': "Pageview",
            'pagePath': `${window.location.pathname}/wells/all-wells`,
            'pageTitle': `All Wells`
        })
    }

    const requestWellStats = (well) => {
        setValue('activeWell', well);
        setMobileText(well.name !== '' ?
            intl.formatMessage({id: "well.label", defaultMessage: "Well"}) + " " + well.name
            : 'Unknown Well');
        closeMobileMenu();

        // Google Analytics
        ReactGA.event({
            category: "Project Menu Selection",
            action: `Clicked on "${'Well ' + well.name}" on Project "${window.location.pathname}"`
        })

        // Google Tag Manager
        window.dataLayer.push({
            'event': "Pageview",
            'pagePath': `${window.location.pathname}/wells/${well.name}`,
            'pageTitle': `Well ${well.name}`
        })
    }

    return (
        <Fragment>
            {!props.mobile &&
            <Fragment>
                <h1 className={activePage()} onClick={() => toggleWellsMenu()}>
                    <FormattedMessage id="wells.label" defaultMessage="Wells"/>
                    {showWellsDropdown && <WhiteUp className="floatRight"/>}
                    {!showWellsDropdown && <WhiteDown className="floatRight"/>}</h1>
                {showWellsDropdown &&
                <WellList wells={project.currentProject.wells} onProjectClick={requestProjectStats}
                          onClick={requestWellStats} activeWell={project.activeWell} allProjects={props.projectStats}/>
                }
            </Fragment>
            }
            {props.mobile &&
            <Fragment>
                <div className="entireSiteMobile" onClick={() => toggleWellsMenu()}>
                    <div className="mobileText">
                        <FormattedMessage id="wells.label" defaultMessage="Wells"/>
                    </div>
                    <div className="floatRight mobileArrow"><BlackRight/></div>
                </div>
                {showWellsDropdown &&
                <WellList wells={project.currentProject.wells} onProjectClick={requestProjectStats}
                          onClick={requestWellStats} activeWell={project.activeWell} allProjects={props.projectStats}/>
                }
            </Fragment>
            }
        </Fragment>
    )
}