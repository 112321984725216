import React, {useState} from "react";
import {Badge, Button} from "reactstrap";
import {FormattedMessage, useIntl} from "react-intl";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Tooltip} from "reactstrap";
import Calendar from "react-calendar";
import moment from "moment";
import {SubscriptionTierEnum, TokenTierEnum, TokenTypeEnum} from "../interfaces/User";

function CustomerApiTokenRow(props) {
    const intl = useIntl();
    const [isExpiryCalendarOpen, setIsExpiryCalendarOpen] = useState(false);

    const toggleExpiryCalendar = () => setIsExpiryCalendarOpen(!isExpiryCalendarOpen);

    const modifyExpiryDate = async (expiryDate) => {
        let newApiToken = {
            ...props.apiToken,
            expiryDate: moment(expiryDate).format("yyyy-MM-DDTHH:mm:ss")
        }
        props.modifyApiToken(newApiToken, props.newToken, props.index);
        setIsExpiryCalendarOpen(false);
    }

    const modifyTokenStatus = async (status) => {
        let newApiToken = {
            ...props.apiToken,
            isActive: status
        }
        props.modifyApiToken(newApiToken, props.newToken, props.index);
    }

    return (
        <>
            {
                !props.isModifying &&
                <tr style={{textAlign: "center"}}>
                    <td>
                        <Button onClick={() => {
                            navigator.clipboard.writeText(props.apiToken.value)
                        }}
                                color="link">{props.apiToken.value}</Button>
                        <Badge color="light" pill><FormattedMessage id="clickToCopy.label"
                                                                    defaultMessage="Click to copy"/></Badge>
                    </td>
                    <td>{props.apiToken.createdDate}</td>
                    <td>{props.apiToken.expiryDate}</td>
                    <td>
                        {props.apiToken.isActive ?
                            new Date(props.apiToken.expiryDate) < new Date(Date.now()) ?
                                intl.formatMessage({id: "expired.label", defaultMessage: "Expired"}) :
                                intl.formatMessage({id: "active.label", defaultMessage: "Active"})
                            : intl.formatMessage({id: "disabled.label", defaultMessage: "Disabled"})
                        }
                    </td>
                    <td>
                        {props.apiToken.tokenType === TokenTypeEnum.Project
                            ? intl.formatMessage({id: "project.label", defaultMessage: "Project"})
                            : props.apiToken.tokenType === TokenTypeEnum.Company
                                ? intl.formatMessage({id: "company.label", defaultMessage: "Company"})
                                : null
                        }
                    </td>
                    <td>
                        {props.apiToken.tokenTier === TokenTierEnum.Free
                            ? intl.formatMessage({id: "free.label", defaultMessage: "Free"})
                            : props.apiToken.tokenTier === TokenTierEnum.Silver
                                ? intl.formatMessage({id: "silver.label", defaultMessage: "Silver"})
                                : props.apiToken.tokenTier === TokenTierEnum.Gold
                                    ? intl.formatMessage({id: "gold.label", defaultMessage: "Gold"})
                                    : null
                        }
                    </td>
                </tr>
            }

            {
                props.isModifying && props.newToken === false &&
                <tr style={{textAlign: "center"}}>
                    <td>{props.apiToken.value}</td>
                    <td>{props.apiToken.createdDate}</td>
                    <td>
                        <span>{props.apiToken.expiryDate}</span>
                        {props.userTier !== SubscriptionTierEnum.Free &&
                            <span className="icon-effects" style={{marginLeft: "15px"}}>
                            <FontAwesomeIcon icon="calendar-alt" id={`infoIcon-existing-token-${props.index}`}/>
                            <Tooltip placement="right"
                                     isOpen={isExpiryCalendarOpen}
                                     toggle={toggleExpiryCalendar}
                                     trigger="click"
                                     target={`infoIcon-existing-token-${props.index}`}>
                                <Calendar value={new Date(props.apiToken.expiryDate)}
                                          onChange={modifyExpiryDate}/>
                            </Tooltip>
                        </span>
                        }
                    </td>
                    <td>
                        <span style={{marginRight: "15px"}}>
                            {props.apiToken.isActive ?
                                new Date(props.apiToken.expiryDate) < new Date(Date.now()) ?
                                    intl.formatMessage({id: "expired.label", defaultMessage: "Expired"}) :
                                    intl.formatMessage({id: "active.label", defaultMessage: "Active"})
                                : intl.formatMessage({id: "disabled.label", defaultMessage: "Disabled"})
                            }
                        </span>
                        {props.apiToken.isActive &&
                            <Button color="danger"
                                    size="sm"
                                    outline
                                    onClick={() => modifyTokenStatus(!props.apiToken.isActive)}>
                                {intl.formatMessage({id: "disable.label", defaultMessage: "Disable"})}
                            </Button>
                        }
                        {!props.apiToken.isActive &&
                            <Button color="warning"
                                    size="sm"
                                    outline
                                    onClick={() => modifyTokenStatus(!props.apiToken.isActive)}>
                                {intl.formatMessage({id: "enable.label", defaultMessage: "Enable"})}
                            </Button>
                        }
                    </td>
                    <td>
                        {props.apiToken.tokenType === TokenTypeEnum.Project
                            ? intl.formatMessage({id: "project.label", defaultMessage: "Project"})
                            : props.apiToken.tokenType === TokenTypeEnum.Company
                                ? intl.formatMessage({id: "company.label", defaultMessage: "Company"})
                                : null
                        }
                    </td>
                    <td>
                        {props.apiToken.tokenTier === TokenTierEnum.Free
                            ? intl.formatMessage({id: "free.label", defaultMessage: "Free"})
                            : props.apiToken.tokenTier === TokenTierEnum.Silver
                                ? intl.formatMessage({id: "silver.label", defaultMessage: "Silver"})
                                : props.apiToken.tokenTier === TokenTierEnum.Gold
                                    ? intl.formatMessage({id: "gold.label", defaultMessage: "Gold"})
                                    : null
                        }
                    </td>
                    <td>
                        {props.userTier !== SubscriptionTierEnum.Free &&
                            <Button color="danger"
                                    outline
                                    onClick={() => props.deleteApiToken(props.apiToken)}>
                                <FormattedMessage id="delete.label" defaultMessage="Delete"/>
                            </Button>
                        }
                    </td>
                </tr>
            }

            {
                props.isModifying && props.newToken === true &&
                <tr style={{textAlign: "center"}}>
                    <td>{props.apiToken.value}</td>
                    <td>{props.apiToken.createdDate}</td>
                    <td>
                        <span>{props.apiToken.expiryDate}</span>
                        <span className="icon-effects" style={{marginLeft: "15px"}}>
                            <FontAwesomeIcon icon="calendar-alt" id={`infoIcon-new-token-${props.index}-end`}/>
                            <Tooltip placement="right"
                                     isOpen={isExpiryCalendarOpen}
                                     trigger="click"
                                     toggle={toggleExpiryCalendar}
                                     target={`infoIcon-new-token-${props.index}-end`}>
                                <Calendar value={new Date(props.apiToken.expiryDate)}
                                          onChange={modifyExpiryDate}/>
                            </Tooltip>
                        </span>
                    </td>
                    <td>
                        <span style={{marginRight: "15px"}}>
                            {props.apiToken.isActive ?
                                new Date(props.apiToken.expiryDate) < new Date(Date.now()) ?
                                    intl.formatMessage({id: "expired.label", defaultMessage: "Expired"}) :
                                    intl.formatMessage({id: "active.label", defaultMessage: "Active"})
                                : intl.formatMessage({id: "disabled.label", defaultMessage: "Disabled"})
                            }
                        </span>
                        {props.apiToken.isActive &&
                            <Button color="danger"
                                    size="sm"
                                    outline
                                    onClick={() => modifyTokenStatus(!props.apiToken.isActive)}>
                                {intl.formatMessage({id: "disable.label", defaultMessage: "Disable"})}
                            </Button>
                        }
                        {!props.apiToken.isActive &&
                            <Button color="warning"
                                    size="sm"
                                    outline
                                    onClick={() => modifyTokenStatus(!props.apiToken.isActive)}>
                                {intl.formatMessage({id: "enable.label", defaultMessage: "Enable"})}
                            </Button>
                        }
                    </td>
                    <td>
                        {props.apiToken.tokenType === TokenTypeEnum.Project
                            ? intl.formatMessage({id: "project.label", defaultMessage: "Project"})
                            : props.apiToken.tokenType === TokenTypeEnum.Company
                                ? intl.formatMessage({id: "company.label", defaultMessage: "Company"})
                                : null
                        }
                    </td>
                    <td>
                        {props.apiToken.tokenTier === TokenTierEnum.Free
                            ? intl.formatMessage({id: "free.label", defaultMessage: "Free"})
                            : props.apiToken.tokenTier === TokenTierEnum.Silver
                                ? intl.formatMessage({id: "silver.label", defaultMessage: "Silver"})
                                : props.apiToken.tokenTier === TokenTierEnum.Gold
                                    ? intl.formatMessage({id: "gold.label", defaultMessage: "Gold"})
                                    : null
                        }
                    </td>
                    <td>
                        <Button color="danger"
                                outline
                                onClick={() => props.removeNewApiToken(props.index)}>
                            <FormattedMessage id="delete.label" defaultMessage="Delete"/>
                        </Button>
                    </td>
                </tr>
            }
        </>

    )
}

export default CustomerApiTokenRow;