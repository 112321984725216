import React, {Fragment, useContext} from 'react';
import {FormattedMessage, useIntl} from "react-intl";
import {ProjectMenuContext} from "../Contexts/ProjectMenuContext";
import ReactGA from "react-ga";
import {ReactComponent as BlackRight} from "../../../images/arrowRightBlack.svg";
import {ProjectContext} from "../Contexts/ProjectContext";

export default function GreenhouseGas(props) {
    const {closeMobileMenu} = useContext(ProjectMenuContext)
    const {project, setActivePage, setMobileText} = useContext(ProjectContext);
    const intl = useIntl();

    const activePage = () => {
        return project.activePage === "Greenhouse Gas" ? 'siteTitle siteTitleActive menuItem menuItemActive' : 'siteTitle menuItem'
    }

    const requestGreenhouseGas = () => {
        setActivePage("Greenhouse Gas")
        setMobileText(intl.formatMessage({id: "greenhouseGases.label", defaultMessage: "Greenhouse Gases"}));
        closeMobileMenu();

        // Google Analytics
        ReactGA.event({
            category: "Project Menu Selection",
            action: `Clicked on "Greenhouse Gas" on Project "${window.location.pathname}"`
        })

        // Google Tag Manager
        window.dataLayer.push({
            'event': "Pageview",
            'pagePath': `${window.location.pathname}/greenhouse-gases`,
            'pageTitle': `Greenhouse Gases`
        })
    }

    return (
        <Fragment>
            {project.currentProject.bifuel &&
            <Fragment>
                {!props.mobile &&
                    <div>
                        <h1 onClick={() => requestGreenhouseGas()} className={activePage()}>
                            <FormattedMessage id="greenhouseGases.label" defaultMessage="Greenhouse Gases"/>
                        </h1>
                    </div>
                }
                {props.mobile &&
                    <div className="entireSiteMobile" onClick={() => requestGreenhouseGas()}>
                        <div className="mobileText">
                            <FormattedMessage id="greenhouseGases.label" defaultMessage="Greenhouse Gases"/>
                        </div>
                        <div className="floatRight mobileArrow"><BlackRight/></div>
                    </div>
                }
            </Fragment>
            }
        </Fragment>
    )
}