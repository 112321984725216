import React, { Fragment, useState, useEffect } from 'react';
import { Row, Col, Container } from 'reactstrap';
import HeaderStat from './HeaderStat';
import * as Formatting from '../helpers/formatting';
import ChartSwitch from './ChartSwitch';
import NoData from './NoData.js';
import DailyTotalsChart from './DailyTotalsChart';
import { FormattedMessage, useIntl } from "react-intl";
import DailyNgTotalsChart from "./DailyNgTotalsChart";
import DailyFuelLevelTotalsChart from "./DailyFuelLevelTotalsChart";
import DailyByWellTotalsChart from "./DailyByWellTotalsChart";

export default function DailyTotalsChartContainer(props){

    const [showChart, setShowChart] = useState(true);
    const intl = useIntl();
    
    useEffect(() => {
        setShowChart(props.dailyTotals.length > 1);
    });

    return (
        <Fragment>
            {(props.dailyTotals.length === 0) && 
                <Fragment>
                    <Container className="wellStatsContainer" fluid={true}>
                        <Row className="wellRow">
                            <Col xs="12" md="12" className="barChartContainer">
                                <NoData />            
                            </Col>
                        </Row>
                    </Container>
                </Fragment>
            }
            {props.dailyTotals.length > 0 && 
                <Fragment>
                    <Container fluid={true}>
                        <Row>
                            <ChartSwitch label={intl.formatMessage({ id: "volumeByDay.label" , defaultMessage: "Volume by Day"})} active={true} />
                            {/* <ChartSwitch label='Stage Rate' active={stageRateActive} onClick={setInactiveSwitch} /> */}
                        </Row>
                    </Container>
                    <Container className="wellStatsContainer" fluid={true}>
                        <Row className="wellRow">
                            {showChart && 
                            <Col xs="12" md="12" className="barChartContainer">
                                {props.type === "consumed" &&
                                    <DailyTotalsChart dailyTotals={props.dailyTotals} bifuel={props.bifuel} project={props.project} />
                                }
                                {props.type === "processed" &&
                                    <DailyNgTotalsChart dailyTotals={props.dailyTotals} project={props.project}/>
                                }
                                {props.type === "fuelLevel" &&
                                    <DailyFuelLevelTotalsChart dailyTotals={props.dailyTotals} project={props.project}/>
                                }
                                {props.type === "byWell" &&
                                    <DailyByWellTotalsChart selectedDate={props.selectedDate}/>
                                }
                            </Col>
                            }
                        </Row>
                        <Row className="wellHeaderRow">
                        </Row>
                    </Container>

                </Fragment>
            }
            <div className="blurbText">
                <FormattedMessage id="dailyTotalsCalculation.message" defaultMessage="Daily Totals are calculated based on the End Time of each transaction. Transactions ending after midnight in your selected timezone ({global.timezone}) are allocated to the next day’s total. You can change the cutoff time and your time zone from the Project Info &gt; Configuration page." />
            </div>
        </Fragment>            
    )

}

