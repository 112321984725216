import React, {Component, useState} from "react";
import {ProjectContext} from "../Contexts/ProjectContext";
import {setState} from "litsy";

export class ProjectProvider extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentProject: null,
            token: null,
            activePage: "Dashboard",
            activeSubPage: null,
            activeSubCategoryPage: null,
            activePumper: null,
            activeWell: null,
            activeUnit: null,
            mobileText: "Dashboard"
        };
    }
    
    // Update single values in state
    setValue = (key, value) => {
        this.setState({[key]: value})
    }

    // Update Active subpage
    setProject = (value) => {
        setState("jobId", value.jobId, "session");
        this.setState({currentProject: value});
    }
    
    // Update Active page
    setActivePage = (value) => {
        this.setState({activePage: value});
    }
    
    // Update Active subpage
    setActiveSubPage = (value) => {
        this.setState({activeSubPage: value});
    }
    
    // Update Active sub-category page
    setActiveSubCategoryPage = (value) => {
        this.setState({activeSubCategoryPage: value});
    }
    
    // Update Mobile text
    setMobileText = (value) => {
        this.setState({mobileText: value});
    }
    
    // Update auth0 token
    setToken = (value) => {
        this.setState({token: value});
    }

    render() {
        return (
            <ProjectContext.Provider
                value={{
                    project: this.state,
                    setProject: this.setProject,
                    setToken: this.setToken,
                    setMobileText: this.setMobileText,
                    setActivePage: this.setActivePage,
                    setActiveSubPage: this.setActiveSubPage,
                    setActiveSubCategoryPage: this.setActiveSubCategoryPage,
                    setValue: this.setValue
                }}
            >
                {this.props.children}
            </ProjectContext.Provider>
        );
    }
}