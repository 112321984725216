import React, {useEffect, useState} from 'react';
import { useIdleTimer } from "react-idle-timer";
import App from "./../App"
import "./../css/Timeout.css";
import TimeoutPopup from "./PopupComponents/TimeoutPopup";
import {useAuth0} from "../auth/auth0-wrapper";

export default function (props) {
    const { logout } = useAuth0();
    const [isOpen, setIsOpen] = useState(false);
    let [popupTimeout, setPopupTimeout] = useState(null)
    
    // 30 minutes of additional timeout after popup shows up until it force logout
    const popupTimeoutTime = 1000 * 60 * 30;
    
    // 4 hours of idle time until popup shows up.
    const idleTimeoutTime = 1000 * 60 * 60 * 4
    
    const clearTimeoutTimer = () => {
        clearTimeout(popupTimeout)
    }
    
    // Logout on idle timeout
    const handleOnIdle = event => {
        if (!isOpen) {
            setIsOpen(true);
            setPopupTimeout(setTimeout(function(){logout({returnTo: global.auth0RedirectUri})}, popupTimeoutTime));
        }
    }

    const { reset } = useIdleTimer({
        timeout: idleTimeoutTime,
        onIdle: handleOnIdle,
        debounce: 500
    })
    
    return (
        <div>
            <TimeoutPopup isOpen={isOpen} setIsOpen={setIsOpen} popupTimeoutReset={clearTimeoutTimer} reset={reset}/>
            <App/>
        </div>
    )
}