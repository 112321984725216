import React, {useEffect, useState} from "react";
import {FormattedMessage} from "react-intl";
import {ComparisonParams, Params} from "./CreateGraphQLData";
import Select, {StylesConfig} from 'react-select';


export default function CreateGraphQLAPI(props) {
    let [attributeDivList, setAttributeDivList] = useState([]);
    let [attributeIndex, setAttributeIndex] = useState(-1);
    let [attributeList, setAttributeList] = useState([]);
    let [returnParams, setReturnParams] = useState([])
    let [resultQuery, setResultQuery] = useState("");
    let [adding, setAdding] = useState(true);

    useEffect(() => {
        // calls addAttributeDiv every time an attribute is added to attributeList
        if (attributeIndex !== -1 && adding === true)
            addAttributeDiv();
    }, [attributeList])

    function subAttribute() {
        // removing an attribute, removes the last element from attribute list and div list 
        setAdding(false);
        if (attributeIndex > -1) {
            setAttributeDivList(attributeDivList.slice(0, -1));
            setAttributeIndex(attributeIndex - 1);
            setAttributeList(attributeList.slice(0, -1));
        }
    }

    function addAttribute() {
        // adds a basic attribute to attribute list, sets adding to true and increment the index 
        setAdding(true);
        setAttributeList([...attributeList, {attribute: "jobId", comparison: "==", value: ""}]);
        setAttributeIndex(attributeIndex + 1);
    }

    function addAttributeDiv() {
        // when called adds a new row for the attribute section
        setAttributeDivList(attributeDivList.concat(
            <div style={{color: "#00ab49", display: "flex"}}>
                <Select
                    defaultValue={Params[0]}
                    options={Params}
                    className="attribute-select"
                    title={"attribute"}
                    onChange={(values) => handleAttributeSelect(values.value, attributeIndex)}
                />
                <Select
                    defaultValue={ComparisonParams[0]}
                    options={ComparisonParams}
                    className="comparison-select"
                    title={"comparison"}
                    onChange={(values) => handleComparisonSelect(values.value, attributeIndex)}
                />
                <input style={{padding: "5px"}} type={"text"} placeholder={"value"}
                       onChange={(e) => handleValueInput(e.target.value, attributeIndex)}/>
            </div>
        ))
    }


    function handleAttributeSelect(value, index) {
        // onclick for the attribute select, changes the respective attribute in attributeList
        attributeList[index].attribute = value;
    }

    function handleComparisonSelect(value, index) {
        // onclick for the comparison select, changes the respective comparison sign in attributeList
        attributeList[index].comparison = value;
    }

    function handleValueInput(value, index) {
        // onchange for the value field, changes the respective value text in attributeList
        attributeList[index].value = value;
    }

    function handleParamInput(values) {
        // onchange for the return param multiselect, updates the returnParam list
        setReturnParams(values)
    }


    function generate() {
        // generate button onclick, builds the string which is the resulting query.

        if (returnParams.length === 0) {
            setResultQuery("Query must have at least one return parameter.");
            return;
        }


        let query = "query {\n\tsnapshots"

        if (attributeList.length > 0) {
            query += "(where : {\n";
            for (let i = 0; i < attributeList.length; i++) {
                query += "\t\t" + attributeList[i].attribute
                switch (attributeList[i].comparison) {
                    case "==":
                        break;
                    case "!=":
                        query += "_not";
                        break;
                    case ">":
                        query += "_gt";
                        break;
                    case ">=":
                        query += "_gte";
                        break;
                    case "<":
                        query += "_lt";
                        break;
                    case "<=":
                        query += "_lte";
                        break;
                }

                if (attributeList[i].value.trim(" ") === "") {
                    // error check, if any value field for an attribute is empty then print error message in result text box
                    setResultQuery("Value field of attribute " + attributeList[i].attribute + " cannot be empty.");
                    return;
                }

                query += ": "

                for (let j = 0; j < Params.length; j++) {
                    if (Params[j].value === attributeList[i].attribute) {
                        if ((Params[j].type === "double" || Params[j].type === "long") && isNaN(attributeList[i].value)) {
                            // error check, if the the attribute selected is a number and the value field contains non numeric characters then print error message in text box
                            setResultQuery("Attribute " + attributeList[i].attribute + " only allows numbers for its value.");
                            return;
                        } else if (Params[j].type === "int" && !(/^-?\d+$/.test(attributeList[i].value))) {
                            // error check, same as previous but type int does not allow decimals
                            setResultQuery("Attribute " + attributeList[i].attribute + " only allows integer for its value.");
                            return;
                        } else if ((Params[j].type === "string" || Params[j].type === "dateTime") &&
                            (attributeList[i].value.slice(attributeList[i].value.length - 1) !== "\"" || attributeList[i].value[0] !== "\"")) {
                            // error check, if value is a string and it is not enclosed by "" then tell them to do so in the text box                
                            setResultQuery("Attribute " + attributeList[i].attribute + " is a string/dateTime attribute and must be enclosed by \"\".");
                            return;
                        }

                        query += attributeList[i].value + "\n"
                        break;
                    }
                }
            }
            query += "\t})"
        }

        if (returnParams.length > 0) {
            query += "\n\t{\n";
            for (let i = 0; i < returnParams.length; i++) {
                query += "\t\t" + returnParams[i].value + "\n";
            }
            query += "\t}\n";
        }
        query += "}\n";

        setResultQuery(query);
    }


    return (
        <div className="d-flex flex-column">
            <div className="projectInfoContainer">
                <div className="projectInfoTitle">
                    <FormattedMessage
                        id="features.label"
                        defaultMessage="Features"
                    />
                    {" "}/{" "}
                    <FormattedMessage id="createGraphQLAPI.label" defaultMessage="Create GraphQL API"/>
                </div>
                <p style={{color: "white"}}>
                    <FormattedMessage id="createGraphQLAPI.description"
                                      defaultMessage="This page is to help create the query for the premium GraphQL API."/>
                </p>
                <div>
                    <h4 style={{color: "white"}}>
                        <FormattedMessage id="attributes.label" defaultMessage="Attributes"/>
                    </h4>

                    {attributeDivList}
                    <button className={"add-remove-generate-button"} onClick={() => addAttribute()}>
                        <FormattedMessage id="add.label" defaultMessage="Add"/>
                    </button>
                    <button className={"add-remove-generate-button"} onClick={() => subAttribute()}>
                        <FormattedMessage id="remove.label" defaultMessage="Remove"/>
                    </button>

                    <h4 style={{display: "flex", color: "white", marginTop: "10px"}}>
                        <FormattedMessage id="returnParam.label" defaultMessage="Return Parameters"/>
                    </h4>


                    {/* could do separated sections in the multiselect and have preset bundles to select and then a separator and then individual params */}
                    <Select
                        isMulti
                        closeMenuOnSelect={false}
                        options={Params}
                        className={"parameter-select"}
                        onChange={handleParamInput}

                        // theme={(theme) => ({
                        //     ...theme,
                        //     borderRadius: 0,
                        //     colors: {
                        //         ...theme.colors,
                        //         // primary25: "#c6ffdd",
                        //         // neutral10: "#6FCF97FF",
                        //         neutral80: "black"
                        //     },
                        // })}
                    />


                    <h4 style={{display: "flex", color: "white", marginTop: "10px"}}>
                        <FormattedMessage id="resultQuery.label" defaultMessage="Result Query"/>
                    </h4>
                    <button className={"add-remove-generate-button"} onClick={generate}>
                        <FormattedMessage id="generate.label" defaultMessage="Generate"/>
                    </button>
                    {resultQuery !== "" &&
                        <button className={"add-remove-generate-button"} onClick={() => {
                            navigator.clipboard.writeText(resultQuery)
                        }}>
                            <FormattedMessage id="copy.label" defaultMessage="Copy"/>
                        </button>
                    }
                    <div>
                <pre className={"result-query"}>
                    {resultQuery}
                </pre>
                    </div>
                </div>
            </div>

            
        </div>
    )
}