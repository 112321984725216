import React, {Fragment, useEffect, useState, useContext} from 'react';
import {FormattedMessage} from "react-intl";
import DailyTotalsChartContainer from "./DailyTotalsChartContainer";
import {ProjectContext} from "./common/Contexts/ProjectContext";
import DailyByWellTotalSummary from "./DailyByWellTotalSummary";
import {Dropdown} from "react-bootstrap";


export default function DailyTotalsByWellStats(props) {
    const {project} = useContext(ProjectContext)

    const {dailyTotals} = props
    const [selectedDate, setSelectedDate] = useState(null)

    function onDropdownSelect(selected) {
        setSelectedDate(selected)
    }


    return (
        <Fragment>
            {props.dailyTotals !== null && dailyTotals !== null &&
            <Fragment>
                <div className="pumperTitle">
                    <FormattedMessage id="dailyTotalByWell.label" defaultMessage="Daily Totals By Wells"/>
                </div>

                <div className={"selectDateText"} style={{marginLeft: "2em"}}>
                    <FormattedMessage id="selectDate.label" defaultMessage="Select Date"/>
                    :
                </div>
                
                <Dropdown>
                    <Dropdown.Toggle variant="secondary" size={"lg"} style={{marginLeft: "1em"}}>
                        {selectedDate === null ? <FormattedMessage id="all.label" defaultMessage="All"/>
                            : selectedDate.split('T')[0]}
                    </Dropdown.Toggle>
                    <Dropdown.Menu style={{maxHeight: "50vh", overflowY: "scroll"}} variant="dark">
                        <Dropdown.Item onClick={() => onDropdownSelect(null)}>All</Dropdown.Item>
                        {dailyTotals.sort((a, b) => Date.parse(a.date) > Date.parse(b.date) ? -1 : 1)
                            .map(dt =>
                                <Dropdown.Item onClick={() => onDropdownSelect(dt.date)}>
                                    {dt.date.split('T')[0]}
                                </Dropdown.Item>
                            )}
                    </Dropdown.Menu>
                </Dropdown>


                <DailyTotalsChartContainer dailyTotals={dailyTotals} project={project} type="byWell" selectedDate={selectedDate}/>

                <DailyByWellTotalSummary selectedDate={selectedDate}/>
            </Fragment>
            }
        </Fragment>
    )
}