import React, {useEffect, useState} from "react";
import {getState} from "litsy";
import Axios from "axios";
import {ApiToken} from "./index";
import {FormattedMessage, useIntl} from "react-intl";
import {Button, Col, Table} from "reactstrap";
import ApiTokenRow from "./ApiTokenRow";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import moment from "moment";
import {toast} from "react-toastify";

function ApiTokenTable(props: any) {
    const [apiTokens, setApiTokens] = useState<ApiToken[]>([]);
    const [modifiedApiTokens, setModifiedApiTokens] = useState<ApiToken[]>([]);
    const [newApiTokens, setNewApiTokens] = useState<ApiToken[]>([]);
    const [isModifying, setIsModifying] = useState(false);

    const intl = useIntl();
    
    useEffect(() => {
        getApiTokens();
    }, [])

    // Get all api tokens
    const getApiTokens = async () => {
        const url = `${getState("fracShack_apiEndpoint", "session")}apiToken/get-api-tokens?JobId=${getState("jobId", "session")}`;
        await Axios.get(url, {
            headers: {
                "Authorization": `Bearer ${getState("authToken", "session")}`
            }
        }).then(result => {
            if (result.status === 200) {
                setApiTokens(result.data.tokens);
            } else {
                // Throw toast error
            }
        });
    }

    // Generate a new api token
    const createApiToken = async (type: string, tier: string, expiryDate: string) => {
        const url = `${getState("fracShack_apiEndpoint", "session")}apiToken/generate-api-token?jobId=${getState("jobId", "session")}&type=${type}&tier=${tier}&expiryDate=${expiryDate}`;
        await Axios.post(url, 
            {},
            {
            headers: {
                "Authorization": `Bearer ${getState("authToken", "session")}`
            }
        }).then(result => {
            if (result.status === 200) {
                // Append new token to array of tokens
                setApiTokens([...apiTokens, result.data.token])
                updateApiTokenPolicy(result.data.token);
                
                toast.success(intl.formatMessage({
                    id: "apiTokenSuccess.message",
                    defaultMessage: "Api token successfully created"
                }), {
                    position: toast.POSITION.TOP_RIGHT
                });
            } else {
                toast.error(intl.formatMessage({
                    id: "apiTokenFailure.message",
                    defaultMessage: "Api token creation failure"
                }), {
                    position: toast.POSITION.TOP_RIGHT
                })
            }
        });
    }

    // Update an existing api token
    const updateApiToken = async (apiToken: ApiToken) => {
        const url = `${getState("fracShack_apiEndpoint", "session")}apiToken/${apiToken.value}/update`;
        await Axios.put(url, 
            apiToken, {
            headers: {
                "Authorization": `Bearer ${getState("authToken", "session")}`,
                "Content-Type": "application/json"
            }
        }).then(result => {
            if (result.status === 200) {
                // Replace existing object with new updated object
                let shallowCopy: ApiToken[] = [...apiTokens];
                let index: number = shallowCopy.findIndex(x => x.value === result.data.token.value);
                shallowCopy[index] = result.data.token;

                // Save state of shallow copy
                setApiTokens(shallowCopy);
                updateApiTokenPolicy(result.data.token);

                toast.success(intl.formatMessage({
                    id: "apiTokenUpdateSuccess.message",
                    defaultMessage: "Api token successfully updated"
                }), {
                    position: toast.POSITION.TOP_RIGHT
                });
            } else if (result.status === 204) {
                // Pass, no change
            } else {
                toast.error(intl.formatMessage({
                    id: "apiTokenUpdateFailure.message",
                    defaultMessage: "Api token update failure"
                }), {
                    position: toast.POSITION.TOP_RIGHT
                })
            }
        });
    }

    // Soft delete an api token
    const deleteApiToken = async (apiToken: ApiToken) => {
        const url = `${getState("fracShack_apiEndpoint", "session")}apiToken/${apiToken.value}`;
        await Axios.delete(url, {
            headers: {
                "Authorization": `Bearer ${getState("authToken", "session")}`
            }
        }).then(result => {
            // Successful delete, remove from existing list(s)
            if (result.status === 200) {
                // Remove from main list
                let shallowCopy: ApiToken[] = [...apiTokens];
                shallowCopy = shallowCopy.filter(x => x.value != apiToken.value);
                setApiTokens(shallowCopy);

                // Remove from modified list
                let modifiedShallowCopy: ApiToken[] = [...modifiedApiTokens];
                modifiedShallowCopy = modifiedShallowCopy.filter(x => x.value !== apiToken.value);
                setModifiedApiTokens(modifiedShallowCopy);

                toast.success(intl.formatMessage({
                    id: "apiTokenDeleteSuccess.message",
                    defaultMessage: "Api token successfully deleted"
                }), {
                    position: toast.POSITION.TOP_RIGHT
                });
            } else {
                toast.error(intl.formatMessage({
                    id: "apiTokenDeleteFailure.message",
                    defaultMessage: "Api token deletion failure"
                }), {
                    position: toast.POSITION.TOP_RIGHT
                })
            }
        });
    };
    
    // Update api token rate limit policy
    const updateApiTokenPolicy = async (apiToken: ApiToken) => {
        const url = `${getState("fracShack_apiEndpoint", "session")}clientRateLimit/update-policy?apiToken=${apiToken.value}`;
        await Axios.post(url,
            {},
            {
                headers: {
                    "Authorization": `Bearer ${getState("authToken", "session")}`
                }
            }).then(result => {
            if (result.status === 200) {
                // Do nothing
            } else {
                // Throw toast error
            }
        });
    };

    // Add token
    const appendApiToken = async () => {
        if (!isModifying) {
            setIsModifying(true);
            setModifiedApiTokens([...apiTokens])
        }

        let apiToken: ApiToken = {
            value: "On save, generated ApiToken Value",
            tokenType: "Project",
            tokenTier: "Free",
            isActive: true,
            createdDate: moment(new Date(Date.now())).format("yyyy-MM-DDTHH:mm:ss"),
            expiryDate: moment(new Date(new Date().setMonth(new Date().getMonth() + 6))).format("yyyy-MM-DDTHH:mm:ss")
        };

        setNewApiTokens([...newApiTokens, apiToken])
    }

    // Remove new apiToken from temp list
    const removeNewApiToken = async (index: number) => {
        const shallowCopy = [...newApiTokens];
        shallowCopy.splice(index, 1);

        setNewApiTokens(shallowCopy);
    }

    // Enable modification of Api Tokens
    const modifyApiTokens = async () => {
        setIsModifying(true);
        setModifiedApiTokens([...apiTokens])
    }

    // Modify api token in current list
    const modifyApiToken = async (apiToken: ApiToken, newToken: boolean, index: number) => {
        if (newToken) {
            // Replace existing object with new updated object
            let shallowCopy: ApiToken[] = [...newApiTokens];
            shallowCopy[index] = apiToken;

            // Update list
            setNewApiTokens(shallowCopy);
        } else {
            // Replace existing object with new updated object
            let shallowCopy: ApiToken[] = [...modifiedApiTokens];
            shallowCopy[index] = apiToken;

            // Update list
            setModifiedApiTokens(shallowCopy);
        }
    }

    // On Cancel button click
    const onCancelClick = async () => {
        setIsModifying(false);
        setNewApiTokens([]);
        setModifiedApiTokens([]);
    }

    // On Save button click
    const onSaveClick = async () => {
        setIsModifying(false);

        // Update tokens
        for (const apiToken of modifiedApiTokens) {
            await updateApiToken(apiToken);
        }

        // Generate new tokens, if any
        for (const apiToken of newApiTokens) {
            await createApiToken(apiToken.tokenType, apiToken.tokenTier, apiToken.expiryDate)
        }

        // Clear new tokens array
        setNewApiTokens([]);
        setModifiedApiTokens([]);
    }

    return (
        <div>
            <h2><FormattedMessage id="manageApiKeys.label" defaultMessage="Manage API Keys"/></h2>
            <Table dark responsive>
                <thead>
                <th><FormattedMessage id="apiKey.label" defaultMessage="API Key"/></th>
                <th><FormattedMessage id="created.label" defaultMessage="Created"/></th>
                <th><FormattedMessage id="expires.label" defaultMessage="Expires"/></th>
                <th><FormattedMessage id="status.label" defaultMessage="Status"/></th>
                <th><FormattedMessage id="tokenType.label" defaultMessage="Token Type"/></th>
                <th><FormattedMessage id="tokenTier.label" defaultMessage="Token Tier"/></th>
                <th>
                    <div className="d-flex flex-row justify-content-sm-around">
                        <FontAwesomeIcon className="icon-effects" icon="plus" style={{color: "green"}}
                                         onClick={appendApiToken}/>
                        {!isModifying &&
                        <FontAwesomeIcon className="icon-effects" icon="edit" style={{color: "white"}}
                                         onClick={modifyApiTokens}/>
                        }
                        {isModifying &&
                        <FontAwesomeIcon className="icon-effects" icon="times" style={{color: "red"}}
                                         onClick={onCancelClick}/>
                        }

                    </div>
                </th>
                </thead>
                <tbody>
                {!isModifying && apiTokens !== undefined &&
                apiTokens.map((apiToken, index) =>
                    <ApiTokenRow apiToken={apiToken}
                                 index={index}
                                 newToken={false}
                                 isModifying={isModifying}
                                 modifyApiToken={modifyApiToken}
                                 deleteApiToken={deleteApiToken}/>
                )
                }
                {isModifying && modifiedApiTokens !== undefined &&
                modifiedApiTokens.map((apiToken, index) =>
                    <ApiTokenRow apiToken={apiToken}
                                 index={index}
                                 newToken={false}
                                 isModifying={isModifying}
                                 modifyApiToken={modifyApiToken}
                                 deleteApiToken={deleteApiToken}/>
                )
                }
                {isModifying && newApiTokens !== undefined &&
                newApiTokens.map((apiToken, index) =>
                    <ApiTokenRow apiToken={apiToken}
                                 index={index}
                                 newToken={true}
                                 isModifying={isModifying}
                                 modifyApiToken={modifyApiToken}
                                 deleteApiToken={deleteApiToken} 
                                 removeNewApiToken={removeNewApiToken}/>
                )
                }
                </tbody>
            </Table>
            {isModifying &&
            <Col sm={12}>
                <Button
                    color="warning"
                    className="float-right"
                    onClick={onSaveClick}
                    size="sm"
                >
                    <FormattedMessage id="save.label" defaultMessage="Save"/>
                </Button>
                <Button
                    color="danger"
                    className="float-right"
                    onClick={onCancelClick}
                    style={{marginRight: "15px"}}
                    size="sm"
                >
                    <FormattedMessage id="cancel.label" defaultMessage="Cancel"/>
                </Button>
            </Col>
            }
        </div>
    )

}

export default ApiTokenTable;