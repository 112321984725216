import React, {Fragment, useState} from 'react';
import * as Formatting from '../helpers/formatting';
import {FormattedMessage} from "react-intl";
import {NaturalGasUnit} from "../enums";

export default function WellLine(props) {

    const requestWell = (well) => {
        props.requestWell(well);
    };

    return (
        <Fragment>
            <tr>
                <td>
                    <div className='pumperLine'>
                        {props.single === true &&
                            <Fragment>
                                <div className="pumperLineName"><FormattedMessage id="stage.label"
                                                                                  defaultMessage="Stage"/> {props.well.stageNumber}
                                </div>
                            </Fragment>
                        }
                        {props.single !== true &&
                            <Fragment>
                                <div className="pumperLineName">
                                    <div className="pumperLineName1 pumperNameLink"
                                         onClick={() => requestWell(props.well)}>{props.well.name !== '' ?
                                        <span> <FormattedMessage id="well.label"
                                                                 defaultMessage="Well"/> {props.well.name}</span>
                                        : <i><FormattedMessage id="unknownWell.label"
                                                               defaultMessage="Unknown Well"/></i>}</div>
                                </div>
                            </Fragment>
                        }
                        <div className="pumperLineValue">
                            <div className="pumperLineMobileTitle">
                                <FormattedMessage id="diesel.label" defaultMessage="Diesel"/>
                            </div>
                            <div
                                className="pumperLineName1">{Formatting.numberWithCommas(props.well.totalPumpedRounded)}{props.unit}</div>
                        </div>
                        {props.bifuel &&
                            <div className="pumperLineValueGas">
                                <div className="pumperLineMobileTitle">
                                    <FormattedMessage id="naturalGas.label" defaultMessage="Natural Gas"/>
                                </div>
                                <div
                                    className="pumperLineName1">{Formatting.numberWithCommas(props.unit === "L" ? props.well.totalDE : props.well.totalDEGallons)} D{props.unitShort}E
                                </div>
                                {props.naturalGasUnit === NaturalGasUnit.SCF &&
                                    <div
                                        className="pumperLineName2">{Formatting.numberWithCommas(props.well.totalSCF)} SCF</div>
                                }
                                {props.naturalGasUnit === NaturalGasUnit.MCF &&
                                    <div
                                        className="pumperLineName2">{Formatting.numberWithCommas(props.well.totalMCF)} MCF</div>
                                }
                                {props.naturalGasUnit === NaturalGasUnit.E3M3 &&
                                    <div
                                        className="pumperLineName2">{Formatting.numberWithCommas(props.well.totalDEC, 4)} E3M3</div>
                                }
                            </div>
                        }
                        {props.bifuel && props.single &&
                            <div className="pumperLineEnergy">
                                <div className="pumperLineMobileTitle"><FormattedMessage id="consumedEnergy.label"
                                                                                         defaultMessage="Consumed Energy"/>
                                </div>
                                <div
                                    className="pumperLineName1">{Formatting.numberWithCommas(props.unit === "L" ? props.well.totalPumpedWithGasLitres : props.well.totalPumpedWithGasGallons)} D{props.unitShort}E
                                </div>
                                <div
                                    className="pumperLineName2">{Formatting.numberWithCommas(props.well.totalBTU)} BTU
                                </div>
                            </div>
                        }
                        {props.bifuel && !props.single &&
                            <div className="pumperLineEnergy">
                                <div className="pumperLineMobileTitle"><FormattedMessage id="consumedEnergy.label"
                                                                                         defaultMessage="Consumed Energy"/>
                                </div>
                                <div
                                    className="pumperLineName1">{Formatting.numberWithCommas(props.unit === "L" ? props.well.totalPumpedWithGas : props.well.totalPumpedWithGas)} D{props.unitShort}E
                                </div>
                                <div
                                    className="pumperLineName2">{Formatting.numberWithCommas(props.well.totalBTU)} BTU
                                </div>
                            </div>
                        }
                        {props.single !== true &&
                            <div className="pumperLineStages">
                                <div className="pumperLineMobileTitle"><FormattedMessage id="numberOfStages.label"
                                                                                         defaultMessage="# of Stages"/>
                                </div>
                                <div
                                    className="pumperLineName1 floatRightNoMargin">{props.well.stages.filter(x => x.totalDE > 0 || x.totalPumped > 0).length}</div>
                            </div>
                        }
                        {props.bifuel &&
                            <div className="pumperLineSubRate">
                                <div className="pumperLineMobileTitle">
                                    <FormattedMessage id="substitutionRate.label" defaultMessage="Substitution Rate"/>
                                </div>
                                <div className="pumperLineName1 floatRightNoMargin">{props.well.substitutionRate}%</div>
                            </div>
                        }
                        {props.bifuel &&
                            <div className="pumperLineSubRate">
                                <div className="pumperLineMobileTitle">
                                    <FormattedMessage id="substitutionRateGen2.label"
                                                      defaultMessage="Substitution Rate (Gen 2)"/>
                                </div>
                                <div className="pumperLineName1 floatRightNoMargin">{props.well.substitutionRateGen2}%
                                </div>
                            </div>
                        }
                        {props.bifuel &&
                            <div className="pumperLineSubRate">
                                <div className="pumperLineMobileTitle">
                                    <FormattedMessage id="substitutionRateGen4.label"
                                                      defaultMessage="Substitution Rate (Gen 4)"/>
                                </div>
                                <div className="pumperLineName1 floatRightNoMargin">{props.well.substitutionRateGen4}%
                                </div>
                            </div>
                        }
                        {props.single === true &&
                            <Fragment>
                                <div className="pumperLineFinishTime">
                                    <div className="pumperLineMobileTitle">
                                        <FormattedMessage id="finishTimeUTC.label" defaultMessage="Finish Time (UTC)"/>
                                    </div>
                                    <div className="pumperLineName1 floatRightNoMargin">{props.well.endTimeString}</div>
                                </div>
                                <div className="pumperLineStartTime">
                                    <div className="pumperLineMobileTitle">
                                        <FormattedMessage id="startTimeUTC.label" defaultMessage="Start Time (UTC)"/>
                                    </div>
                                    <div
                                        className="pumperLineName1 floatRightNoMargin">{props.well.startTimeString}</div>
                                </div>
                            </Fragment>
                        }
                    </div>
                </td>
            </tr>
            <div className="clearfix"></div>
        </Fragment>
    )
}