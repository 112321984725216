import React, {Fragment, useEffect, useState} from 'react';
import * as Formatting from '../helpers/formatting';
import {FormattedMessage} from "react-intl";
import NgSidekickGasCompositionElement from "./NgSidekickGasCompositionElement";
import {NaturalGasUnit} from "../enums";

export default function NgSidekickWellLine(props)
{
    const [gasComposition, setGasComposition] = useState({});
    
    const requestWell = (well) => {
        props.requestWell(well);
    };
    
    useEffect(() => {
        var gasDict = {
                "Nitrogen": props.well.nitrogen,
                "Carbon Dioxide": props.well.carbonDiOxide,
                "Hydrogen Sulfide": props.well.hydrogenSulfide,
                "Water": props.well.water,
                "Helium": props.well.helium,
                "Methane": props.well.methane,
                "Ethane": props.well.ethane,
                "Propane": props.well.propane,
                "N-Butane": props.well.nButane,
                "I-Butane": props.well.iButane,
                "N-Pentane": props.well.nPentane,
                "N-Hexane": props.well.nHexane,
                "N-Heptane": props.well.nHeptane,
                "N-Octane": props.well.nOctane,
                "N-Nonane": props.well.nNonane,
                "N-Decane": props.well.nDecane,
                "Oxygen": props.well.oxygen,
                "Carbon Monoxide": props.well.carbonMonoxide,
                "Hydrogen": props.well.hydrogen
        }
        
        setGasComposition(gasDict);
        
    }, [props.single])
    
    return(
        <Fragment>
            <tr>
                <td>
                    <div className='pumperLine'>
                        {props.single === true &&
                        <Fragment>
                            <div className="pumperLineName"><FormattedMessage id="stage.label" defaultMessage="Stage" /> {props.well.stageNumber}</div>
                        </Fragment>
                        }
                        {props.single !== true &&
                        <Fragment>
                            <div className="pumperLineName">
                                <div className="pumperLineName1 pumperNameLink" onClick={() => requestWell(props.well)}>{props.well.wellName !== '' ?
                                    <span> <FormattedMessage id="well.label" defaultMessage="Well" /> {props.well.wellName}</span>
                                    : <i><FormattedMessage id="unknownWell.label" defaultMessage="Unknown Well" /></i>}</div>
                            </div>
                        </Fragment>
                        }
                        {props.single !== true &&
                            <fragment>
                                <div className="pumperLineValue">
                                    <div className="pumperLineMobileTitle">
                                        <FormattedMessage id="volume.label" defaultMessage="Volume" />
                                    </div>
                                    {props.naturalGasUnit === NaturalGasUnit.SCF &&
                                        <div className="pumperLineName1">{Formatting.numberWithCommas(props.well.totalVolume)} SCF</div>
                                    }
                                    {props.naturalGasUnit === NaturalGasUnit.MCF &&
                                        <div className="pumperLineName1">{Formatting.numberWithCommas(props.well.totalVolumeMCF)} MCF</div>
                                    }
                                    {props.naturalGasUnit === NaturalGasUnit.E3M3 &&
                                        <div className="pumperLineName1">{Formatting.numberWithCommas(props.well.totalVolumeDEC, 4)} E3M3</div>
                                    }
                                </div>
                                <div className="pumperLineValue">
                                    <div className="pumperLineMobileTitle">
                                        <FormattedMessage id="avgBTU.label" defaultMessage="Avg BTU" />
                                    </div>
                                    <div className="pumperLineName1">{Formatting.numberWithCommas(props.well.totalAvgBTU)} BTU</div>
                                </div>
                                <div className="pumperLineValue">
                                    <div className="pumperLineMobileTitle">
                                        <FormattedMessage id="avgFlowRate.label" defaultMessage="Avg Flow Rate" />
                                    </div>
                                    {/*<div className="pumperLineName1">{Formatting.numberWithCommas(props.well.totalAvgFlowRate)} SCF</div>*/}
                                    {props.naturalGasUnit === NaturalGasUnit.SCF &&
                                        <div className="pumperLineName1">{Formatting.numberWithCommas(props.well.totalAvgFlowRate)} SCF</div>
                                    }
                                    {props.naturalGasUnit === NaturalGasUnit.MCF &&
                                        <div className="pumperLineName1">{Formatting.numberWithCommas(props.well.totalAvgFlowRateMCF)} MCF</div>
                                    }
                                    {props.naturalGasUnit === NaturalGasUnit.E3M3 &&
                                        <div className="pumperLineName1">{Formatting.numberWithCommas(props.well.totalAvgFlowRateDEC)} E3M3</div>
                                    }
                                </div>
                                <div className="pumperLineValue">
                                    <div className="pumperLineMobileTitle">
                                        <FormattedMessage id="gasComposition.label" defaultMessage="Gas Composition" />
                                    </div>
                                    <NgSidekickGasCompositionElement totalGasComposition={props.well.totalGasComposition} gasComposition={gasComposition}/>
                                </div>
                                <div className="pumperLineValue">
                                    <div className="pumperLineMobileTitle">
                                        <FormattedMessage id="numberOfStages.label" defaultMessage="# of Stages" />
                                    </div>
                                    <div className="pumperLineName1">{props.well.totalStages}</div>
                                </div>
                            </fragment>
                        }
                        {props.single === true &&
                        <Fragment>
                            <div className="pumperLineValue">
                                <div className="pumperLineMobileTitle">
                                    <FormattedMessage id="volume.label" defaultMessage="Volume" />
                                </div>
                                {props.naturalGasUnit === NaturalGasUnit.SCF &&
                                    <div className="pumperLineName1">{Formatting.numberWithCommas(props.well.volume)} SCF</div>
                                }
                                {props.naturalGasUnit === NaturalGasUnit.MCF &&
                                    <div className="pumperLineName1">{Formatting.numberWithCommas(props.well.volumeMCF)} MCF</div>
                                }
                                {props.naturalGasUnit === NaturalGasUnit.E3M3 &&
                                    <div className="pumperLineName1">{Formatting.numberWithCommas(props.well.volumeDEC, 4)} E3M3</div>
                                }
                            </div>
                            <div className="pumperLineValue">
                                <div className="pumperLineMobileTitle">
                                    <FormattedMessage id="avgBTU.label" defaultMessage="Avg BTU" />
                                </div>
                                <div className="pumperLineName1">{Formatting.numberWithCommas(props.well.avgBTU)} BTU</div>
                            </div>
                            <div className="pumperLineValue">
                                <div className="pumperLineMobileTitle">
                                    <FormattedMessage id="avgFlowRate.label" defaultMessage="Avg Flow Rate" />
                                </div>
                                {/*<div className="pumperLineName1">{Formatting.numberWithCommas(props.well.avgFlowRate)} SCF</div>*/}
                                {props.naturalGasUnit === NaturalGasUnit.SCF &&
                                    <div className="pumperLineName1">{Formatting.numberWithCommas(props.well.avgFlowRate)} SCF</div>
                                }
                                {props.naturalGasUnit === NaturalGasUnit.MCF &&
                                    <div className="pumperLineName1">{Formatting.numberWithCommas(props.well.avgFlowRateMCF)} MCF</div>
                                }
                                {props.naturalGasUnit === NaturalGasUnit.E3M3 &&
                                    <div className="pumperLineName1">{Formatting.numberWithCommas(props.well.avgFlowRateDEC)} E3M3</div>
                                }
                            </div>
                            
                            <div className="pumperLineValue">
                                <div className="pumperLineMobileTitle">
                                    <FormattedMessage id="gasComposition.label" defaultMessage="Gas Composition" />
                                </div>
                                <NgSidekickGasCompositionElement totalGasComposition={props.well.totalGasComposition} gasComposition={gasComposition}/>
                            </div>
                            
                            <div className="pumperLineFinishTime">
                                <div className="pumperLineMobileTitle">
                                    <FormattedMessage id="finishTimeUTC.label" defaultMessage="Finish Time (UTC)" />
                                </div>
                                <div className="pumperLineName1 floatRightNoMargin">{props.well.endTimeString}</div>
                            </div>
                            <div className="pumperLineStartTime">
                                <div className="pumperLineMobileTitle">
                                    <FormattedMessage id="startTimeUTC.label" defaultMessage="Start Time (UTC)" />
                                </div>
                                <div className="pumperLineName1 floatRightNoMargin">{props.well.startTimeString}</div>
                            </div>
                        </Fragment>
                        }
                    </div>
                </td>
            </tr>
            <div className="clearfix"></div>
        </Fragment>
    )
}