import React, {Fragment, useContext} from 'react';
import {ProjectContext} from "../Contexts/ProjectContext";
import SubscriptionConfig from "../../SubscriptionConfig";
import ExcelReportConfig from "../../ExcelReportConfig";

export default function ReportingPage(props) {
    const {project} = useContext(ProjectContext)

    return (
        <Fragment>
            {project.currentProject !== null && project.activePage === "Reporting" &&
            <Fragment>
                {project.activeSubPage === "subscriptions" &&
                <SubscriptionConfig project={project.currentProject} user={props.user} token={project.token}/>
                }

                {project.activeSubPage === "reports" &&
                <ExcelReportConfig project={project.currentProject} token={project.token}/>
                }
            </Fragment>
            }
        </Fragment>
    )
}