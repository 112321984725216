import React, {Fragment, useContext} from 'react';
import {ProjectContext} from "../Contexts/ProjectContext";
import UnitStats from "../../UnitStats";

export default function OperatingUnitPage(props) {
    const {project} = useContext(ProjectContext)

    return (
        <Fragment>
            {project.currentProject !== null && project.activePage === "OperatingUnit" &&
                <Fragment>
                    {props.showUnitStats && project.activeUnit !== null && 
                        <UnitStats
                            units={project.activeUnit}
                            project={project.currentProject}
                            export={props.showExportData}
                            token={project.token}
                            showSubRate={props.showSubstitutionRate}
                        />
                    }
                </Fragment>
            }
        </Fragment>
    )
}