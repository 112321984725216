import React, { useEffect } from 'react';

export default function HeaderStat(props) {

    useEffect(() => {

    }, []);

    return (
        <div className="headerStat">
            {props.title !== '' && 
                <div className="title">{props.title}</div>
            }
            {props.stat !== '' && 
                <div className="stat">{props.stat}</div>
            }
            {props.smallStat !== '' && !props.smallStat.startsWith('NaN') && 
                <div className="smallStat">{props.smallStat}</div>
            }
            {props.smallSubStat !== '' && props.smallSubStatValue !== undefined &&
                <span className="smallSubStat">{props.smallSubStat} {props.smallSubStatValue}</span>
            }
        </div>
    );
}