import React, {Fragment, useState} from 'react';
import * as Formatting from '../helpers/formatting';
import _ from 'lodash';
import { FormattedMessage } from "react-intl";
import {NaturalGasUnit} from "../enums";


export default function GreenhouseGasTotalLine(props)
{
    return(
        <Fragment>
            <tr>
                <td>
                    <div className='pumperLine'>
                        <Fragment>
                            <div className="pumperLineName">{props.gasTotal.date.substring(0,10)}</div>
                        </Fragment>
                        <div className="pumperLineValue">
                            <div className="pumperLineMobileTitle">
                                <FormattedMessage id="fracDiesel.label" defaultMessage="Frac Diesel" />
                            </div>
                            <div className="pumperLineName1">{Formatting.numberWithCommas(props.unit === "L" ? _.round(props.gasTotal.totalDieselLitres, 2) : _.round(props.gasTotal.totalDieselGallons, 2))}{props.unit}</div>
                        </div>
                        {/*{props.bifuel &&*/}
                        <Fragment>
                            <div className="pumperLineValue">
                                <div className="pumperLineMobileTitle">
                                    <FormattedMessage id="naturalGas.label" defaultMessage="Natural Gas" />
                                </div>
                                <div className="pumperLineName1">{Formatting.numberWithCommas(props.unit === "L" ? _.round(props.gasTotal.totalNGLitres, 2) : _.round(props.gasTotal.totalNGGallons, 2))} D{props.unitShort}E</div>
                                {props.naturalGasUnit === NaturalGasUnit.SCF &&
                                    <div className="pumperLineName2">{Formatting.numberWithCommas(_.round(props.gasTotal.totalSCF, 2))} SCF</div>
                                }
                                {props.naturalGasUnit === NaturalGasUnit.MCF &&
                                    <div className="pumperLineName2">{Formatting.numberWithCommas(_.round(props.gasTotal.totalMCF, 2))} MCF</div>
                                }
                                {props.naturalGasUnit === NaturalGasUnit.E3M3 &&
                                    <div className="pumperLineName2">{Formatting.numberWithCommas(_.round(props.gasTotal.totalDEC, 4), 4)} E3M3</div>
                                }
                            </div>
                            <div className="pumperLineEnergy">
                                <div className="pumperLineMobileTitle">
                                    <FormattedMessage id="fracConsumedEnergy.label" defaultMessage="Frac Consumed Energy" />
                                </div>
                                <div className="pumperLineName1">{Formatting.numberWithCommas(props.unit === "L" ? _.round(props.gasTotal.totalConsumedLitres, 2) : _.round(props.gasTotal.totalConsumedGallons, 2))} D{props.unitShort}E</div>
                                <div className="pumperLineName2">{Formatting.numberWithCommas(_.round(props.gasTotal.totalBTU,2 ))} BTU</div>
                            </div>
                            <div className="pumperLineValue">
                                <div className="pumperLineMobileTitle">
                                    <FormattedMessage id="co2Reduction.label" defaultMessage="CO2 Reduction" />
                                </div>
                                <div className="pumperLineName1">{Formatting.numberWithCommas(_.round(props.gasTotal.co2eReductionPerDay, 2))}
                                    &nbsp;
                                    <FormattedMessage id="tonnes.label" defaultMessage="Tonnes" />
                                </div>
                            </div>
                        </Fragment>
                        {/*}*/}
                        <div className="pumperLineValueNonFrac">
                            <div className="pumperLineMobileTitle">
                                <FormattedMessage id="nonFracDiesel.label" defaultMessage="Non Frac Diesel" />
                            </div>
                            <div className="pumperLineName1">{Formatting.numberWithCommas(props.unit === "L" ? _.round(props.gasTotal.totalNonFracDieselLitres, 2) : _.round(props.gasTotal.totalNonFracDieselGallons, 2))}{props.unit}</div>
                        </div>

                    </div>
                </td>
            </tr>
            <div className="clearfix"></div>
        </Fragment>
    )
}