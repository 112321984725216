import React, {Fragment, useContext} from "react";
import {ProjectContext} from "../Contexts/ProjectContext";
import HseSiteVisits from "../../HseSiteVisits";
import {categoryEnum, subCategoryEnum} from "../../../enums/SiteDocumentsEnums";

export default function SiteDocumentsPage(props) {
    const {project} = useContext(ProjectContext);

    return (
        <Fragment>
            {project.token !== null && project.currentProject !== null && project.activePage === 'SiteDocuments' &&
                <Fragment>
                    {project.activeSubPage === categoryEnum.HSE &&
                        <Fragment>
                            {project.activeSubCategoryPage === subCategoryEnum.HSE.SiteVisits &&
                                <HseSiteVisits project={project.currentProject}
                                               token={project.token}/>
                            }
                        </Fragment>
                    }
                </Fragment>
            }
        </Fragment>
    )
}