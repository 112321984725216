import fetchWithTimeout from '../helpers/fetchWithTimeout'
import {async} from 'q';
import {Action, ActionCreator, Dispatch, Reducer, Store, applyMiddleware, createStore, combineReducers} from 'redux';
import axios from 'axios';
import {
    User,
    SubmitUser,
    IGetUsersInitialAction,
    IGettingUsersAction,
    IGettingUsersErrorAction,
    IGotUsersAction,
    ISubmittingUserAction,
    ISubmittedUserAction,
    ISubmittedUserErrorAction,
    IAddUserParams,
    IGetUsersResult,
    ISubmittedUserResult,
    IGettingUserRolesAction,
    IGetUserRolesResult,
    IGettingUserRolesErrorAction,
    IGotUserRolesAction, EnergyProducerPermission, SubscriptionTierEnum
} from '../interfaces/User';
import {UserPermissionResponse, isProjectAdmin} from '../auth/accessControlService';
import {
    CheckProjectAdminAuthTypes,
    NetworkRequestStatus,
    GetUsersTypes,
    UnauthorizedError,
    SubmitUserTypes,
    UnauthorizedSubmitError,
    CheckSiteAdminAuthTypes,
    GetUserRoles
} from '../enums';
import {toast} from 'react-toastify';
import {getState} from "litsy";
import Axios from "axios";

declare var global: any;


const initialUsersState = {
    users: [],
    roles: [],
    loading: true,
    posting: false,
    isError: false,
    error: ''
};

export type UsersActions =
    IGetUsersInitialAction
    | IGettingUsersAction
    | IGettingUsersErrorAction
    | IGotUsersAction
    | ISubmittingUserAction
    | ISubmittedUserAction
    | ISubmittedUserErrorAction
    | IGettingUserRolesAction
    | IGettingUserRolesErrorAction
    | IGotUserRolesAction


export const checkAccessActionCreator = (energyProducerName: string, completeProjectName: string, token: string) => {
    return async (dispatch: Dispatch<any>) => {
        dispatch({type: CheckProjectAdminAuthTypes.CheckingProjectAdmin})
        await axios.get<UserPermissionResponse>(global.apiEndpoint + 'auth/is-project-admin', {
            params: {
                project: completeProjectName,
                energyProducer: energyProducerName
            },
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then(function (response: any) {
                if (response.status == 401 || response.status == 403 || response.status !== 200 || (response.data && response.data.status != NetworkRequestStatus.Successful)) {
                    dispatch({type: CheckProjectAdminAuthTypes.UnauthorizedProjectAdmin})
                } else if (response.status == 200 && response.data && response.data.status == NetworkRequestStatus.Successful) {
                    if (response.data.hasAccess) {
                        dispatch({type: CheckProjectAdminAuthTypes.AuhtorizedProjectAdmin})
                    } else {
                        dispatch({type: CheckProjectAdminAuthTypes.UnauthorizedProjectAdmin})
                    }
                }
            })
            .catch(function (e) {
                dispatch({type: CheckProjectAdminAuthTypes.UnauthorizedProjectAdmin})
            })
    };
};

export const checkSiteAdminAccessActionCreator = (token: string) => {
    return async (dispatch: Dispatch<any>) => {
        dispatch({type: CheckSiteAdminAuthTypes.CheckingSiteAdmin})
        await axios.get<UserPermissionResponse>(global.apiEndpoint + 'auth/is-site-admin', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then(function (response: any) {
                if (response.status == 401 || response.status == 403 || response.status !== 200 || (response.data && response.data.status != NetworkRequestStatus.Successful)) {
                    dispatch({type: CheckSiteAdminAuthTypes.UnauthorizedSiteAdmin})
                } else if (response.status == 200 && response.data && response.data.status == NetworkRequestStatus.Successful) {
                    if (response.data.hasAccess) {
                        dispatch({type: CheckSiteAdminAuthTypes.AuhtorizedSiteAdmin})
                    } else {
                        dispatch({type: CheckSiteAdminAuthTypes.UnauthorizedSiteAdmin})
                    }
                }
            })
            .catch(function (e) {
                dispatch({type: CheckSiteAdminAuthTypes.UnauthorizedSiteAdmin})
            })
    };
};

export const getUsersActionCreator = (params: IAddUserParams) => {
    return async (dispatch: Dispatch<any>) => {
        const gettingUsersAction: IGettingUsersAction = {type: GetUsersTypes.Getting}
        dispatch(gettingUsersAction);
        await axios.get<IGetUsersResult>(global.apiEndpoint + 'users', {
            params: {
                project: params.ProjectName,
                energyProducer: params.EnergyProducerName
            },
            headers: {
                Authorization: `Bearer ${params.token}`
            }
        })
            .then(function (response) {
                if (response.status == 401 || response.status == 403) {
                    dispatch({type: UnauthorizedError})
                }
                if (response.status !== 200) {
                    const gettingUsersErrorAction: IGettingUsersErrorAction = {
                        type: GetUsersTypes.Error,
                        error: "A error occured while trying to get users"
                    }
                    return dispatch(gettingUsersErrorAction);
                } else if (response.data && response.data.status != NetworkRequestStatus.Successful) {
                    const gettingUsersErrorAction: IGettingUsersErrorAction = {
                        type: GetUsersTypes.Error,
                        error: response.data.error
                    }
                    return dispatch(gettingUsersErrorAction);
                }
                try {
                    const gotUsers: IGotUsersAction = {
                        type: GetUsersTypes.Got,
                        users: response.data.users ? response.data.users : []
                    }
                    return dispatch(gotUsers);
                } catch (err: any) {
                    if (err.response !== undefined && (err.response.status == 401 || err.response.status == 403)) {
                        dispatch({type: UnauthorizedError})
                    } else {
                        const gettingUsersErrorAction: IGettingUsersErrorAction = {
                            type: GetUsersTypes.Error,
                            error: err
                        }
                        return dispatch(gettingUsersErrorAction);
                    }

                }
            })
            .catch(function (error) {
                if (error.response !== undefined && (error.response.status == 401 || error.response.status == 403)) {
                    dispatch({type: UnauthorizedError})
                } else {
                    const gettingUsersErrorAction: IGettingUsersErrorAction = {
                        type: GetUsersTypes.Error,
                        error: error.message
                    }
                    return dispatch(gettingUsersErrorAction);
                }

            });
    };
};

export const submitProjectUserActionCreator = (energyProducer: string, project: string, user: SubmitUser) => {
    return async (dispatch: Dispatch<any>) => {
        const submittingUserAction: ISubmittingUserAction = {
            type: SubmitUserTypes.Submitting
        };
        dispatch(submittingUserAction)
        await axios.post<ISubmittedUserResult>(global.apiEndpoint + 'users/project-admin',
            {
                energyProducer: energyProducer,
                project: project,
                user: {email: user.email, app_metadata: user.app_metadata},
                frontendClientId: global.auth0ClientId
            },
            {
                headers: {
                    Authorization: `Bearer ${user.token}`
                }
            })
            .then(async function (response) {
                const submittedUserErrorAction: ISubmittedUserErrorAction = {type: SubmitUserTypes.Error, error: ""};
                if (response !== undefined && (response.status == 401 || response.status == 403)) {
                    dispatch({type: UnauthorizedSubmitError})
                    if (response.data.error) toast.error(response.data.error)
                }
                if (response.status !== 200) {
                    submittedUserErrorAction.error = "A error occured while trying to add or update the user";
                    if (response.data.error) toast.error(response.data.error)
                    return dispatch(submittedUserErrorAction);
                } else if (response.data && response.data.status != NetworkRequestStatus.Successful) {
                    submittedUserErrorAction.error = response.data.error;
                    if (response.data.error) toast.error(response.data.error)
                    return dispatch(submittedUserErrorAction);
                }
                try {
                    const submittedUser: ISubmittedUserAction = {
                        type: SubmitUserTypes.Submitted,
                        user: response.data.user
                    }

                    // await updateSubscription(user.token, user, energyProducer);
                    
                    toast.success("User updated");
                    return dispatch(submittedUser);
                } catch (err: any) {
                    submittedUserErrorAction.error = err;
                    if (err) toast.error(err)
                    return dispatch(submittedUserErrorAction);
                }
            })
            .catch(function (error) {
                if (error.response !== undefined && (error.response.status == 401 || error.response.status == 403)) {
                    if (error.response.data.error) toast.error(error.response.data.error)
                    dispatch({type: UnauthorizedSubmitError})
                } else {
                    const submittedUserErrorAction: ISubmittedUserErrorAction = {
                        type: SubmitUserTypes.Error,
                        error: ""
                    };
                    toast.error("Sorry, something went wrong")
                    return dispatch(submittedUserErrorAction);
                }
            });
    };
};

export const submitEnergyProducerUserActionCreator = (energyProducer: string, user: SubmitUser) => {
    return async (dispatch: Dispatch<any>) => {
        const submittingUserAction: ISubmittingUserAction = {
            type: SubmitUserTypes.Submitting
        };
        dispatch(submittingUserAction)
        await axios.post<ISubmittedUserResult>(global.apiEndpoint + 'users/energy-producer-admin',
            {
                energyProducer: energyProducer,
                user: {email: user.email, app_metadata: user.app_metadata},
                frontendClientId: global.auth0ClientId
            },
            {
                headers: {
                    Authorization: `Bearer ${user.token}`
                }
            })
            .then(async function (response) {
                const submittedUserErrorAction: ISubmittedUserErrorAction = {type: SubmitUserTypes.Error, error: ""};
                if (response !== undefined && (response.status == 401 || response.status == 403)) {
                    dispatch({type: UnauthorizedSubmitError})
                    if (response.data.error) toast.error(response.data.error)
                }
                if (response.status !== 200) {
                    submittedUserErrorAction.error = "A error occured while trying to add or update the user";
                    toast.error("A error occured while trying to add or update the user")
                    return dispatch(submittedUserErrorAction);
                } else if (response.data && response.data.status != NetworkRequestStatus.Successful) {
                    submittedUserErrorAction.error = response.data.error;
                    if (response.data.error) toast.error(response.data.error)
                    return dispatch(submittedUserErrorAction);
                }
                try {
                    const submittedUser: ISubmittedUserAction = {
                        type: SubmitUserTypes.Submitted,
                        user: response.data.user
                    }
                    
                    // Update role and db subscription
                    // Todo: commented out for now as its broken
                    await updateRole(user.token, user, energyProducer)
                        .then(async () => {
                            // await updateSubscription(user.token, user, energyProducer);

                            return;
                        });

                    toast.success("User updated");
                    return dispatch(submittedUser);
                } catch (err: any) {
                    submittedUserErrorAction.error = err;
                    if (err) toast.error(err)
                    return dispatch(submittedUserErrorAction);
                }
            })
            .catch(function (error) {
                if (error.response !== undefined && (error.response.status == 401 || error.response.status == 403)) {
                    dispatch({type: UnauthorizedSubmitError})
                    if (error.response.data.error) toast.error(error.response.data.error)
                } else {
                    const submittedUserErrorAction: ISubmittedUserErrorAction = {
                        type: SubmitUserTypes.Error,
                        error: ""
                    };
                    toast.error("Sorry, something went wrong")
                    return dispatch(submittedUserErrorAction);
                }
            });
    };
};

export const getUserRolesActionCreator = (token: string, role: string) => {
    return async (dispatch: Dispatch<any>) => {
        const gettingUserRolesAction: IGettingUserRolesAction = {type: GetUserRoles.Getting}
        dispatch(gettingUserRolesAction);
        await axios.get<IGetUserRolesResult>(global.apiEndpoint + `policy/user-roles/${role}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then(function (response) {
                if (response.status == 401 || response.status == 403) {
                    dispatch({type: UnauthorizedError})
                }
                if (response.status !== 200 && response.status !== 204) {
                    const gettingUserRolesErrorAction: IGettingUserRolesErrorAction = {
                        type: GetUserRoles.Error,
                        error: "A error occured while trying to get users"
                    }
                    return dispatch(gettingUserRolesErrorAction);
                }
                try {
                    const gotRoles: IGotUserRolesAction = {
                        type: GetUserRoles.Got,
                        roles: response.data.roles ? response.data.roles : []
                    }
                    return dispatch(gotRoles);
                } catch (err: any) {
                    if (err.response !== undefined && (err.response.status == 401 || err.response.status == 403)) {
                        dispatch({type: UnauthorizedError})
                    } else {
                        const gettingUserRolesErrorAction: IGettingUserRolesErrorAction = {
                            type: GetUserRoles.Error,
                            error: err
                        }
                        return dispatch(gettingUserRolesErrorAction);
                    }

                }
            })
            .catch(function (error) {
                if (error.response !== undefined && (error.response.status == 401 || error.response.status == 403)) {
                    dispatch({type: UnauthorizedError})
                } else {
                    const gettingUserRolesErrorAction: IGettingUserRolesErrorAction = {
                        type: GetUserRoles.Error,
                        error: error.message
                    }
                    return dispatch(gettingUserRolesErrorAction);
                }

            });
    };
}

const getInfo = (user: any, energyProducerName: string) => {
    var energyProducerList: EnergyProducerPermission[] = user.app_metadata.access.energyProducers;
    var energyProducer: EnergyProducerPermission | undefined = energyProducerList.find(energyProducer => {
        return energyProducer.name === energyProducerName
    })

    // Check if info exists
    if (energyProducer !== null && energyProducer !== undefined) {
        return {
            role: energyProducer.role,
            tier: energyProducer.tier,
            expiryDate: energyProducer.expiryDate
        }
    }

    return null;
}

const updateUserRole = async (token: string, userId: string, role: string | null) => {
    const url = `${getState("fracShack_apiEndpoint", "session")}users/${userId}/roles?role=${role}`;
    await Axios.post(url,
        {},
        {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        }).then(result => {
        if (result.status === 200) {
            // Pass
        } else {
            // Throw toast error
        }
    });
}

const updateUserSubscription = async (creatorId: string, userId: string, token: string, tier: string | null, expiryDate: Date | null, jobId: number, energyProducer: string) => {
    const url = `${getState("fracShack_apiEndpoint", "session")}subscription/user-subscriptions`;
    await Axios.put(url,
        {
            userId: userId,
            creatorId: creatorId,
            subscriptionTier: tier,
            expiryDate: expiryDate,
            jobId: jobId,
            energyProducer: energyProducer
        },
        {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        }).then(result => {
        if (result.status === 200) {
            // Pass
        } else {
            // Throw toast error
        }
    });
}

const updateRole = async (token: string, user: any, energyProducerName: string) => {
    // @ts-ignore
    let userId = user["user_id"];
    let role: string | null = null;
    
    // Determine user role
    var userInfo = getInfo(user, energyProducerName);
    switch (userInfo) {
        case null:
        case undefined: {
            role = null;
            
            break;
        }
        default: {
            if (userInfo.role !== undefined)
                role = userInfo.role;
            
            break;
        }
    }
    
    // Update auth user role
    await updateUserRole(token, userId, role);
}

// Updates user db subscription
const updateSubscription = async (token: string, user: any, energyProducerName: string) => {
    // @ts-ignore
    let userId = user["user_id"];
    let creatorId = getState("userId", "session");
    let project = getState("fracShack_currentProject", "session");
    let expiryDate: Date | null = null;
    let tier: string = SubscriptionTierEnum.Free;

    // Determine user tier and expiry date
    var userInfo = getInfo(user, energyProducerName);
    switch (userInfo) {
        case null:
        case undefined: {
            tier = SubscriptionTierEnum.Free;
            expiryDate = null;

            break;
        }
        default: {
            if (userInfo.tier !== undefined)
                tier = userInfo.tier;
            if (userInfo.expiryDate !== undefined)
                expiryDate = userInfo.expiryDate;

            break;
        }
    }

    // Update user db subscription
    await updateUserSubscription(creatorId, userId, token, tier, expiryDate, project.jobId, energyProducerName)
}

export const reducer = (
    state = initialUsersState,
    action: any,
) => {
    switch (action.type) {
        case GetUsersTypes.Getting: {
            return {
                ...state,
                loading: true,
            };
        }
        case GetUsersTypes.Got: {
            return {
                ...state,
                users: action.users,
                loading: false,
            };
        }
        case GetUsersTypes.Error: {
            return {
                ...state,
                loading: false,
                isError: true,
                error: action.error
            };
        }
        case GetUserRoles.Getting: {
            return {
                ...state,
                loading: true
            };
        }
        case GetUserRoles.Got: {
            return {
                ...state,
                roles: action.roles,
                loading: false,
            };
        }
        case GetUserRoles.Error: {
            return {
                ...state,
                loading: false,
                isError: true,
                error: action.error
            };
        }
        case SubmitUserTypes.Error: {
            return {
                ...state,
                loading: false,
                isError: true,
                error: action.error
            };
        }
        case  SubmitUserTypes.Submitting: {
            return {
                ...state,
                posting: true,
                posted: false
            };
        }
        case SubmitUserTypes.Submitted: {
            return {
                ...state,
                posting: false,
                users: state.users.find((u: any) => u.email == action.user.email) ? state.users.map((user: any) => user.email === action.user.email ? {
                    ...user,
                    app_metadata: action.user.app_metadata
                } : user) : state.users.concat(action.user),
                posted: true
            };
        }
        case CheckProjectAdminAuthTypes.CheckingProjectAdmin: {
            return {
                ...state,
                checkingAuth: true,
                isUserProjectAdmin: false
            }
        }
        case CheckProjectAdminAuthTypes.AuhtorizedProjectAdmin: {
            return {
                ...state,
                checkingAuth: false,
                isUserProjectAdmin: true
            }
        }
        case CheckProjectAdminAuthTypes.UnauthorizedProjectAdmin: {
            return {
                ...state,
                checkingAuth: false,
                isUserProjectAdmin: false
            }
        }
        case CheckSiteAdminAuthTypes.CheckingSiteAdmin: {
            return {
                ...state,
                checkingAuth: true,
                isUserSiteAdmin: false
            }
        }
        case CheckSiteAdminAuthTypes.AuhtorizedSiteAdmin: {
            return {
                ...state,
                checkingAuth: false,
                isUserSiteAdmin: true
            }
        }
        case CheckSiteAdminAuthTypes.UnauthorizedSiteAdmin: {
            return {
                ...state,
                checkingAuth: false,
                isUserSiteAdmin: false
            }
        }


        case UnauthorizedError: {
            return {
                ...state,
                isError: true,
                loading: false,
                isUnauthorized: true
            }
        }
        case UnauthorizedSubmitError: {
            return {
                ...state,
                isError: true,
                loading: false,
                posting: false,
                posted: true,
                error: "You are not authorized to perform this action!",
                isSubmissionUnauthorized: true
            }
        }
        default:
            neverReached(action);
            break;
    }

    return state;
};

// tslint:disable-next-line:no-empty
const neverReached = (never: any) => {
};