﻿﻿import React, { useState, useEffect, Fragment } from "react";
import Logo from "../images/FracShackLogoStackedNoTm.svg";
import SalesBlob from "./SalesBlob";

// This component holds the main context of the landing page, this includes the logo and SalesBlob.
export default function LandingPageBody(props) {
    return (
        <div className="landing-page-body">
            <img className="logo" src={Logo} alt="Enertrail Logo"/>
            
            {/*<SalesBlob/>*/}
        </div>
    )
}
