import React, {useEffect, useState} from 'react'
import { Bar, Chart } from 'react-chartjs-2';
import * as Formatting from '../helpers/formatting';
import { faLessThanEqual } from '@fortawesome/free-solid-svg-icons';
import { useIntl } from "react-intl";
import * as Conversion from '../helpers/Conversion';

export default function DailyNgTotalsChart(props)
{
    const [data, setData] = useState(null) // data is set to null initially
    const intl = useIntl();

    useEffect(() => {
        let dailyTotals = props.dailyTotals;
        
    let dataSets = [{
        label: intl.formatMessage({ id: "naturalGas.label", defaultMessage: "Natural Gas"}),
        data: Object.keys(dailyTotals).map(i => props.project.uomString === "L" ? Conversion.toDLE(dailyTotals[i].volume) : Conversion.toDGE(dailyTotals[i].volume)),
        fill: true,
        yAxisID: 'y-axis-1',
        lineTension: 0.1,
        backgroundColor: '#66B47D',
        borderColor: 'rgba(75,192,192,1)',
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: 'rgba(75,192,192,1)',
        pointBackgroundColor: '#fff',
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: 'rgba(75,192,192,1)',
        pointHoverBorderColor: 'rgba(220,220,220,1)',
        pointHoverBorderWidth: 2,
        pointRadius: 5,
        pointHitRadius: 10,
        type: 'bar', stacked: 'stack'
        }];
    
        setData({
            labels: Object.keys(dailyTotals).map(i => dailyTotals[i].date.substring(0,10)),
            datasets: dataSets
        })

    }, []);

    useEffect(() => {
        Chart.helpers.each(Chart.instances, function (instance) {
            if (instance.canvas.id === 'dailyTotals')
            {
                const chartOptions = {
                    scales: {
                        xAxes: [{
                            stacked: true,
                            scaleLabel: {
                                display: true,
                                labelString: intl.formatMessage({ id: "date.label", defaultMessage: "Date"})
                            }
                        }],
                        yAxes: [
                            {
                                stacked: true,
                                type: 'linear',
                                display: false,
                                position: 'right',
                                id: 'y-axis-0',
                                gridLines: {
                                    display: false
                                },
                                labels: {
                                    show: true
                                },
                                ticks: {
                                    beginAtZero: true
                                }							},
                            {
                                stacked: true,
                                type: 'linear',
                                display: true,
                                position: 'left',
                                id: 'y-axis-1',
                                gridLines: {
                                    display: false
                                },
                                labels: {
                                    show: false
                                },
                                ticks: {
                                    beginAtZero: true
                                },
                                scaleLabel: {
                                    display: true,
                                    labelString: intl.formatMessage({ id: "processed.label", defaultMessage: "Processed"}) + '  (D' + props.project.uomStringShort + 'E)'
                                }
                            }
                        ]
                    },
                    legend:
                        {
                            display: true,
                            position: 'bottom',
                            onClick: (e) => e.stopPropagation(),
                            labels: {
                                filter: function(item, chart) {
                                    return !item.text.includes('hide');
                                }
                            }
                        },
                    tooltips: {
                        mode: 'index',
                        intersect: false,
                        position: 'nearest',
                        backgroundColor: '#ffffff',
                        titleFontColor: '#000000',
                        bodyFontColor: '#000000',
                        titleFontFamily: 'Calibri, Arial',
                        titleFontSize: 12,
                        bodyFontFamily: 'Calibri, Arial',
                        bodyFontSize: 12,
                        itemSort: function (a, b) {
                            return a.datasetIndex - b.datasetIndex;
                        },
                        callbacks: {
                            label: function (tooltipItem, data) {
                                var label = Math.round(tooltipItem.yLabel * 100) / 100;
                                if (isNaN(label)) label = 0;
                                if (tooltipItem.datasetIndex === 0) {
                                    return intl.formatMessage({ id: "naturalGas.label", defaultMessage: "Natural Gas"}) + ': ' + Formatting.numberWithCommas(label) + ' D' + props.project.uomStringShort + 'E';
                                } else {
                                    return label;
                                }
                            },
                            beforeLabel: function(tooltipItem, data)
                            {
                            },
                            afterLabel: function (tooltipItem, data)
                            {
                            },
                            title: function (tooltipItems, data) {
                                var label = tooltipItems[0].label;
                                return label;
                            }
                        }
                    },
                    responsive: false,
                    maintainAspectRatio: false
                }
                instance.chart.data = data;
                instance.chart.options = chartOptions;
                instance.chart.canvas.parentNode.style.height = '300px';
                instance.chart.update();

            }
        });
    }, [data])

    return data && (<Bar className="barChart" id='dailyTotals' />)
}