import React, {Fragment, useState} from 'react';
import * as Formatting from '../helpers/formatting';
import _ from 'lodash';
import { FormattedMessage } from "react-intl";

export default function DailyFuelLevelLine(props)
{
    return(
        <Fragment>
            <tr>
                <td>
                    <div className='pumperLine'>
                        <Fragment>
                            <div className="pumperLineName">{props.dailyTotal.date.substring(0,10)}</div>
                        </Fragment>
                        <div className="pumperLineName">
                            <div className="pumperLineMobileTitle">
                                <FormattedMessage id="totalVolumeConsumed.label" defaultMessage="Total Volume Consumed" />
                            </div>
                            <div className="pumperLineName1">{Formatting.numberWithCommas(_.round(props.dailyTotal.totalVolumeConsumed, 2))}{props.unit}</div>
                        </div>
                        <div className="pumperLineName">
                            <div className="pumperLineMobileTitle">
                                <FormattedMessage id="totalVolumeFueled.label" defaultMessage="Total Volume Fueled" />
                            </div>
                            <div className="pumperLineName1">{Formatting.numberWithCommas(_.round(props.dailyTotal.totalVolumeFueled, 2))}{props.unit}</div>
                        </div>
                        <div className="pumperLineStages">
                            <div className="pumperLineMobileTitle">
                                <FormattedMessage id="numberOfSnapshots.label" defaultMessage="# of Snapshots" />
                            </div>
                            <div className="pumperLineName1 floatRightNoMargin">{props.dailyTotal.totalSnapshots}</div>
                        </div>
                    </div>
                </td>
            </tr>
            <div className="clearfix"></div>
        </Fragment>
    )
}