import React, {Fragment, useEffect, useState} from "react";
import AccessControl from "../../common/AccessControl/AccessControl";
import {AccessType} from "../../../auth/accessControlService";
import {FormattedMessage, useIntl} from "react-intl";
import {Button, Col, Table} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import UserManagementRow from "../../common/UserManagementRow";
import {faCopy} from "@fortawesome/free-solid-svg-icons";
import {UserManagementType} from "./index";
import _ from "lodash";
import {EnergyProducerPermission, PermissionsEnum, SubmitUser, SubscriptionTierEnum} from "../../../interfaces/User";
import {toast} from "react-toastify";
import {
    submitEnergyProducerUserActionCreator,
    submitProjectUserActionCreator
} from "../../../store/ManageUsers";
import {connect} from "react-redux";

declare var global: any;

function UserManagementTable(props: any) {
    const [emailsToCopy, setEmailsToCopy] = useState('');
    const [isModifying, setIsModifying] = useState<boolean>(false);

    const [modifiedManageUsers, setModifiedManageUsers] = useState<SubmitUser[]>([]);
    const [modifiedViewUsers, setModifiedViewUsers] = useState<SubmitUser[]>([]);

    const intl = useIntl();

    useEffect(() => {
        setModifiedManageUsers(JSON.parse(JSON.stringify(props.manageUsers)));
        setModifiedViewUsers(JSON.parse(JSON.stringify(props.viewUsers)));
    }, [])

    // Checks for changes in view users from parent, if yes, override copy of view users
    useEffect(() => {
        setModifiedViewUsers(JSON.parse(JSON.stringify(props.viewUsers)));
    }, [props.viewUsers])

    // Checks for changes in manage users from parent, if yes, override copy of manage users
    useEffect(() => {
        setModifiedManageUsers(JSON.parse(JSON.stringify(props.manageUsers)));
    }, [props.manageUsers])
    
    const onModifyClick = () => {
        setIsModifying(true);
    }
    
    // Todo: Only save the entries that had changes; Currently it is saving all the users in the respective table.
    // Save user app_metadata changes to auth0
    const onSaveClick = async (tableType: UserManagementType) => {
        // Update view users
        for (const viewUser of modifiedViewUsers) {
            switch (tableType) {
                case (UserManagementType.EnergyProducerUser): {
                    // Update user app_metadata
                    props.submitEnergyProducerUser(
                        props.energyProducerName,
                        {
                            ...viewUser,
                            token: props.token
                        }
                    );
        
                    break;
                }
                case (UserManagementType.ProjectUser): {
                    props.submitProjectUser(
                        props.energyProducerName,
                        props.projectName,
                        {
                            ...viewUser,
                            token: props.token
                        }
                    );
                    
                    break;
                }
            }
        }

        // Update manage users
        for (const manageUser of modifiedManageUsers) {
            switch (tableType) {
                case (UserManagementType.EnergyProducerUser): {
                    props.submitEnergyProducerUser(
                        props.energyProducerName,
                        {
                            ...manageUser,
                            token: props.token
                        }
                    );
  
                    break;
                }
                case (UserManagementType.ProjectUser): {
                    props.submitProjectUser(
                        props.energyProducerName,
                        props.projectName,
                        {
                            ...manageUser,
                            token: props.token
                        }
                    );

                    break;
                }
            }
        }

        // Replace original array
        props.setManageUsers(JSON.parse(JSON.stringify(modifiedManageUsers)));
        props.setViewUsers(JSON.parse(JSON.stringify(modifiedViewUsers)));

        setIsModifying(false);
    }

    const onCancelClick = () => {
        // Reset data to original state
        setModifiedManageUsers(JSON.parse(JSON.stringify(props.manageUsers)));
        setModifiedViewUsers(JSON.parse(JSON.stringify(props.viewUsers)));
        setIsModifying(false);
    }

    // Modify api token in current list
    const modifyUser = async (tokenTier: SubscriptionTierEnum, expiryDate: Date, role: string, isManage: boolean, index: number) => {
        if (isManage) {
            // Replace existing object with new updated object
            let shallowCopy: SubmitUser[] = [...modifiedManageUsers];
            let userCopy: SubmitUser = shallowCopy[index];
            
            var energyProducerList = userCopy.app_metadata.access.energyProducers;

            // Find index of energy producer inside app_metadata
            var energyProducerIndex: number = 0;
            var energyProducer = energyProducerList.find((energyProducer, index) => {
                energyProducerIndex = index;
                return energyProducer.name === props.energyProducerName
            })

            // Checks to see if energy producer permissions exist
            if (energyProducer !== undefined) {
                energyProducer = {
                    ...energyProducer,
                    tier: tokenTier,
                    expiryDate: expiryDate,
                    role: role
                }

                // Replace existing energy producer app_metadata
                shallowCopy[index].app_metadata.access.energyProducers[energyProducerIndex] = energyProducer;

                // Update list
                setModifiedManageUsers(shallowCopy);
            }
        } else {
            // Replace existing object with new updated object
            let shallowCopy: SubmitUser[] = [...modifiedViewUsers];
            let userCopy: SubmitUser = shallowCopy[index];

            var energyProducerList = userCopy.app_metadata.access.energyProducers;

            // Find index of energy producer inside app_metadata
            var energyProducerIndex: number = 0;
            var energyProducer = energyProducerList.find((energyProducer, index) => {
                energyProducerIndex = index;
                return energyProducer.name === props.energyProducerName
            })

            // Checks to see if energy producer permissions exist
            if (energyProducer !== undefined) {
                energyProducer = {
                    ...energyProducer,
                    tier: tokenTier,
                    expiryDate: expiryDate,
                    role: role
                }

                // Replace existing energy producer app_metadata
                shallowCopy[index].app_metadata.access.energyProducers[energyProducerIndex] = energyProducer;

                // Update list
                setModifiedViewUsers(shallowCopy);
            }
        }
    }

    // Copy user emails to clipboard
    const copyEmailAddresses = (type: UserManagementType) => {
        let viewers = [];
        switch (type) {
            case UserManagementType.EnergyProducerUser: {
                viewers = _.filter(props.users.users, {
                    "app_metadata": {
                        "access": {
                            "energyProducers": [{
                                "name": props.energyProducerName,
                                "permission": PermissionsEnum.View
                            }]
                        }
                    }
                });
                break;
            }
            case UserManagementType.ProjectUser: {
                viewers = _.filter(props.users.users, {
                    "app_metadata": {
                        "access": {
                            "energyProducers": [{
                                "projects": [{
                                    "permission": PermissionsEnum.View,
                                    "name": props.projectName
                                }],
                                "permission": PermissionsEnum.None
                            }]
                        }
                    }
                });
                break;
            }
        }

        let viewerUsers = [];
        for (var i = 0; i < viewers.length; i++) {
            viewerUsers.push(viewers[i].email);
        }
        var joinedString = viewerUsers.join(',')
        setEmailsToCopy(joinedString);
        const el = document.createElement('textarea');  // Create a <textarea> element
        el.value = joinedString;                                 // Set its value to the string that you want copied
        el.setAttribute('readonly', '');                // Make it readonly to be tamper-proof
        el.style.position = 'absolute';
        el.style.left = '-9999px';                      // Move outside the screen to make it invisible
        document.body.appendChild(el);                  // Append the <textarea> element to the HTML document
        const selection = document.getSelection() as Selection;
        const selected =
            selection != null && selection.rangeCount > 0        // Check if there is any content selected previously
                ? selection.getRangeAt(0)     // Store selection if found
                : false;                                    // Mark as false to know no selection existed before
        el.select();                                    // Select the <textarea> content
        document.execCommand('copy');                   // Copy - only works as a result of a user action (e.g. click events)
        document.body.removeChild(el);
        toast.info(intl.formatMessage({
            id: "emailCopied.message",
            defaultMessage: "Email(s) copied to the clipboard!"
        }), {
            position: toast.POSITION.TOP_RIGHT
        });                 // Remove the <textarea> element
        if (selected) {                                 // If a selection existed before copying
            selection.removeAllRanges();    // Unselect everything on the HTML document
            selection.addRange(selected);
            // Restore the original selection
        }
    }

    return (
        <div className="padding-top-md">
            {!props.users && <Fragment></Fragment>}
            {props.users.users && props.type === UserManagementType.EnergyProducerUser && (
                <Fragment>
                    <AccessControl
                        extraAccessData={{
                            token: props.token,
                            completeProjectName: props.projectName,
                            energyProducerName: props.energyProducerName,
                            serviceEndpoint: global.apiEndpoint
                        }}
                        accessType={AccessType.IsEnergyProducerAdmin}
                        renderNoAccess={() => ''}
                    >
                        <div className="user-table-heading">
                            <h4>
                                <FormattedMessage id="energyProducer.label"
                                                  defaultMessage="Energy Producer"/>
                            </h4>
                            <Col md={12} className="row noRightPadding noLeftPadding">
                                <Col className="noLeftPadding">{props.energyProducerName}</Col>
                                <Button
                                    disabled={props.users.loading === true}
                                    className="float-right"
                                    color="warning"
                                    size="sm"
                                    onClick={() => copyEmailAddresses(UserManagementType.EnergyProducerUser)}
                                >
                                    <FontAwesomeIcon icon={faCopy}/>
                                    &nbsp;Copy Email Addresses
                                </Button>
                            </Col>
                        </div>
                        <Table dark responsive>
                            <thead>
                            <tr>
                                <th><FormattedMessage id="name.label" defaultMessage="Name"/></th>
                                <th><FormattedMessage id="email.label" defaultMessage="Email"/></th>
                                <th><FormattedMessage id="tier.label" defaultMessage="Tier"/></th>
                                <th><FormattedMessage id="subscriptionEndDate.label" defaultMessage="Subscription End Date"/></th>
                                {props.type  === UserManagementType.EnergyProducerUser &&
                                    <th><FormattedMessage id="role.label" defaultMessage="Role"/></th>
                                }
                                <th><FormattedMessage id="permissions.label"
                                                      defaultMessage="Permissions"/></th>
                                <th><FormattedMessage id="verification.label"
                                                      defaultMessage="Verification"/></th>
                                <th>
                                    {!isModifying &&
                                    <FontAwesomeIcon className="icon-effects" icon="edit" style={{color: "white"}}
                                                     onClick={onModifyClick}/>
                                    }
                                    {isModifying &&
                                    <FontAwesomeIcon className="icon-effects" icon="times" style={{color: "red"}}
                                                     onClick={onCancelClick}/>
                                    }
                                </th>
                            </tr>
                            </thead>
                            {!isModifying &&
                            <tbody>
                            {props.manageUsers.map((u: any) => (
                                <Fragment>
                                    <UserManagementRow
                                        key={u.email}
                                        type={UserManagementType.EnergyProducerUser}
                                        user={u}
                                        isAdmin={true}
                                        emailInvite={props.resendEmailInvite}
                                        energyProducerName={props.energyProducerName}
                                        projectName={props.projectName}
                                        enableElevationToAdmin={false}
                                        demoteToViewer={props.removeEnergyProducerAdmin}
                                        enableDemotionToProject={true}
                                        demoteToProject={props.demoteToProject}
                                        removeAccess={props.removeEnergyProducerAccess}
                                        enableElevationToEnergyProducer={false}
                                        optionalClassName={'energy-producer-admin'}
                                        isModifying={false}
                                    />
                                </Fragment>
                            ))}
                            {props.viewUsers.map((u: any) => (
                                <Fragment key={u.email}>
                                    <UserManagementRow
                                        key={u.email}
                                        type={UserManagementType.EnergyProducerUser}
                                        user={u}
                                        isAdmin={false}
                                        emailInvite={props.resendEmailInvite}
                                        energyProducerName={props.energyProducerName}
                                        projectName={props.projectName}
                                        enableElevationToAdmin={true}
                                        elevateToAdmin={props.makeEnergyProducerAdmin}
                                        enableDemotionToProject={true}
                                        removeAccess={props.removeEnergyProducerAccess}
                                        demoteToProject={props.demoteToProject}
                                        enableElevationToEnergyProducer={false}
                                        optionalClassName={'energy-producer-viewer'}
                                        isModifying={false}
                                    />
                                </Fragment>
                            ))}
                            </tbody>
                            }
                            {isModifying &&
                            <tbody>
                            {modifiedManageUsers.map((u: any, index: number) => (
                                <Fragment>
                                    <UserManagementRow
                                        key={u.email}
                                        type={UserManagementType.EnergyProducerUser}
                                        user={u}
                                        isAdmin={true}
                                        emailInvite={props.resendEmailInvite}
                                        energyProducerName={props.energyProducerName}
                                        projectName={props.projectName}
                                        enableElevationToAdmin={false}
                                        demoteToViewer={props.removeEnergyProducerAdmin}
                                        enableDemotionToProject={true}
                                        demoteToProject={props.demoteToProject}
                                        removeAccess={props.removeEnergyProducerAccess}
                                        enableElevationToEnergyProducer={false}
                                        optionalClassName={'energy-producer-admin'}
                                        isModifying={true}
                                        index={index}
                                        modifyUser={modifyUser}
                                    />
                                </Fragment>
                            ))}
                            {modifiedViewUsers.map((u: any, index: number) => (
                                <Fragment key={u.email}>
                                    <UserManagementRow
                                        key={u.email}
                                        type={UserManagementType.EnergyProducerUser}
                                        user={u}
                                        isAdmin={false}
                                        emailInvite={props.resendEmailInvite}
                                        energyProducerName={props.energyProducerName}
                                        projectName={props.projectName}
                                        enableElevationToAdmin={true}
                                        elevateToAdmin={props.makeEnergyProducerAdmin}
                                        enableDemotionToProject={true}
                                        removeAccess={props.removeEnergyProducerAccess}
                                        demoteToProject={props.demoteToProject}
                                        enableElevationToEnergyProducer={false}
                                        optionalClassName={'energy-producer-viewer'}
                                        isModifying={true}
                                        index={index}
                                        modifyUser={modifyUser}
                                    />
                                </Fragment>
                            ))}
                            </tbody>
                            }
                        </Table>
                        {isModifying &&
                        <Col sm={12}>
                            <Button
                                color="warning"
                                className="float-right"
                                onClick={() => onSaveClick(UserManagementType.EnergyProducerUser)}
                                size="sm"
                            >
                                <FormattedMessage id="save.label" defaultMessage="Save"/>
                            </Button>
                            <Button
                                color="danger"
                                className="float-right"
                                onClick={onCancelClick}
                                style={{marginRight: "15px"}}
                                size="sm"
                            >
                                <FormattedMessage id="cancel.label" defaultMessage="Cancel"/>
                            </Button>
                        </Col>
                        }
                    </AccessControl>
                </Fragment>
            )}
            {props.users.users && props.type === UserManagementType.ProjectUser && (
                <Fragment>
                    <div className="user-table-heading">
                        <h4><FormattedMessage id="project.label" defaultMessage="Project"/></h4>
                        <Col md={12} className="row noRightPadding noLeftPadding">
                            <Col className="noLeftPadding">{props.projectName}</Col>
                            <Button
                                disabled={props.users.loading === true}
                                className="float-right"
                                color="warning"
                                size="sm"
                                onClick={() => copyEmailAddresses(UserManagementType.ProjectUser)}
                            >
                                <FontAwesomeIcon icon={faCopy}/>
                                &nbsp;Copy Email Addresses
                            </Button>
                        </Col>
                    </div>
                    <Table dark responsive>
                        <thead>
                        <tr>
                            <th><FormattedMessage id="name.label" defaultMessage="Name"/></th>
                            <th><FormattedMessage id="email.label" defaultMessage="Email"/></th>
                            <th><FormattedMessage id="tier.label" defaultMessage="Tier"/></th>
                            <th><FormattedMessage id="subscriptionEndDate.label" defaultMessage="Subscription End Date"/></th>
                            <th><FormattedMessage id="permissions.label"
                                                  defaultMessage="Permissions"/></th>
                            <th><FormattedMessage id="verification.label"
                                                  defaultMessage="Verification"/></th>
                            <th>
                                {!isModifying &&
                                <FontAwesomeIcon className="icon-effects" icon="edit" style={{color: "white"}}
                                                 onClick={onModifyClick}/>
                                }
                                {isModifying &&
                                <FontAwesomeIcon className="icon-effects" icon="times" style={{color: "red"}}
                                                 onClick={onCancelClick}/>
                                }
                            </th>
                        </tr>
                        </thead>
                        {!isModifying &&
                        <tbody>
                        {props.manageUsers.map((u: any) => (
                            <Fragment>
                                <UserManagementRow
                                    key={u.email}
                                    type={UserManagementType.ProjectUser}
                                    user={u}
                                    isAdmin={true}
                                    emailInvite={props.resendEmailInvite}
                                    energyProducerName={props.energyProducerName}
                                    projectName={props.completeProjectName}
                                    enableElevationToAdmin={false}
                                    demoteToViewer={props.removeProjectAdmin}
                                    removeAccess={props.removeProjectAccess}
                                    enableElevationToEnergyProducer={true}
                                    elevateToEnergyProducer={props.elevateToEnergyProducer}
                                    token={props.token}
                                    serviceEndpoint={global.apiEndpoint}
                                    optionalClassName={'project-admin'}
                                    isModifying={false}
                                />
                            </Fragment>
                        ))}
                        {props.viewUsers.map((u: any) => (
                            <Fragment>
                                <UserManagementRow
                                    key={u.email}
                                    type={UserManagementType.ProjectUser}
                                    user={u}
                                    isAdmin={false}
                                    emailInvite={props.resendEmailInvite}
                                    energyProducerName={props.energyProducerName}
                                    projectName={props.projectName}
                                    enableElevationToAdmin={true}
                                    elevateToAdmin={props.makeProjectAdmin}
                                    removeAccess={props.removeProjectAccess}
                                    enableElevationToEnergyProducer={true}
                                    elevateToEnergyProducer={props.elevateToEnergyProducer}
                                    optionalClassName={'project-viewer'}
                                    isModifying={false}
                                />
                            </Fragment>
                        ))}
                        </tbody>
                        }
                        {isModifying &&
                        <tbody>
                        {modifiedManageUsers.map((u: any, index: number) => (
                            <Fragment>
                                <UserManagementRow
                                    key={u.email}
                                    type={UserManagementType.ProjectUser}
                                    user={u}
                                    isAdmin={true}
                                    emailInvite={props.resendEmailInvite}
                                    energyProducerName={props.energyProducerName}
                                    projectName={props.completeProjectName}
                                    enableElevationToAdmin={false}
                                    demoteToViewer={props.removeProjectAdmin}
                                    removeAccess={props.removeProjectAccess}
                                    enableElevationToEnergyProducer={true}
                                    elevateToEnergyProducer={props.elevateToEnergyProducer}
                                    token={props.token}
                                    serviceEndpoint={global.apiEndpoint}
                                    optionalClassName={'project-admin'}
                                    isModifying={true}
                                    index={index}
                                    modifyUser={modifyUser}
                                />
                            </Fragment>
                        ))}
                        {modifiedViewUsers.map((u: any, index: number) => (
                            <Fragment>
                                <UserManagementRow
                                    key={u.email}
                                    type={UserManagementType.ProjectUser}
                                    user={u}
                                    isAdmin={false}
                                    emailInvite={props.resendEmailInvite}
                                    energyProducerName={props.energyProducerName}
                                    projectName={props.projectName}
                                    enableElevationToAdmin={true}
                                    elevateToAdmin={props.makeProjectAdmin}
                                    removeAccess={props.removeProjectAccess}
                                    enableElevationToEnergyProducer={true}
                                    elevateToEnergyProducer={props.elevateToEnergyProducer}
                                    optionalClassName={'project-viewer'}
                                    isModifying={true}
                                    index={index}
                                    modifyUser={modifyUser}
                                />
                            </Fragment>
                        ))}
                        </tbody>
                        }
                    </Table>
                    {isModifying &&
                    <Col sm={12}>
                        <Button
                            color="warning"
                            className="float-right"
                            onClick={() => onSaveClick(UserManagementType.ProjectUser)}
                            size="sm"
                        >
                            <FormattedMessage id="save.label" defaultMessage="Save"/>
                        </Button>
                        <Button
                            color="danger"
                            className="float-right"
                            onClick={onCancelClick}
                            style={{marginRight: "15px"}}
                            size="sm"
                        >
                            <FormattedMessage id="cancel.label" defaultMessage="Cancel"/>
                        </Button>
                    </Col>
                    }
                </Fragment>
            )}
        </div>
    )
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        submitProjectUser: (energyProducer: string, project: string, user: SubmitUser) => dispatch(submitProjectUserActionCreator(energyProducer, project, user)),
        submitEnergyProducerUser: (energyProducer: string, user: SubmitUser) => dispatch(submitEnergyProducerUserActionCreator(energyProducer, user))
    };
};

export default connect(
    null,
    mapDispatchToProps
)(UserManagementTable);