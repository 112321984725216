import React, { useState, useEffect, Fragment } from 'react';
import { ReactComponent as CircleImage } from '../images/circle.svg';
import * as Formatting from '../helpers/formatting';
import ExportUnit from './ExportUnit.js';

export default function UnitStats(props)
{
    const [currentUnit, setCurrentUnit] = useState(null);
    const [showCurrentUnit, setShowCurrentUnit] = useState(false);

    useEffect(() => {
        setCurrentUnit(props.units);
        setShowCurrentUnit(true);

        if (currentUnit === null)
            setShowCurrentUnit(false);
    });

    return (
        <Fragment>
            {showCurrentUnit && 
                <Fragment>
                    {(currentUnit.snapshotType_ID === 1 || currentUnit.snapshotType_ID === 3 || currentUnit.snapshotType_ID === 5) && 
                        <div className="unitStatInfoContainer">
                            <div className="unitStatInfoTitle">Total Diesel</div>
                            <div className="unitStatInfo">
                                <div className="unitStatInfoValue">
                                    {currentUnit.snapshotType_ID !== 5 && <span>{Formatting.numberWithCommas(currentUnit.totalDiesel)}{currentUnit.uom}</span>}
                                    {currentUnit.snapshotType_ID === 5 && <span>{Formatting.numberWithCommas(currentUnit.totalVolume)}{currentUnit.uom}</span>}
                                </div>
                                <div className="unitStatInfoSmallValue">
                                    <CircleImage /> N/A {currentUnit.uom}/hr
                                </div>
                            </div>
                        </div>
                    }
                    {(currentUnit.snapshotType_ID === 1 || currentUnit.snapshotType_ID === 3) && 
                        <div className="unitStatInfoContainer">
                            <div className="unitStatInfoTitle">Total Non Frac Diesel</div>
                                <div className="unitStatInfo">
                                    <div className="unitStatInfoValue">
                                        {Formatting.numberWithCommas(currentUnit.totalNonFracDiesel)}{currentUnit.uom}
                                    </div>
                                    <div className="unitStatInfoSmallValue">
                                        <CircleImage /> N/A {currentUnit.uom}/hr
                                    </div>
                                </div>
                        </div>
                    }        
                    {(currentUnit.snapshotType_ID === 2 || currentUnit.snapshotType_ID === 5) && 
                        <div className="unitStatInfoContainer">
                            <div className="unitStatInfoTitle">Total Natural Gas</div>
                                <div className="unitStatInfo">
                                    <div className="unitStatInfoValue">
                                        {currentUnit.snapshotType_ID !== 5 && <span>{Formatting.numberWithCommas(currentUnit.totalVolume)} SCF ({Formatting.numberWithCommas(currentUnit.totalDE)} DE)</span>}
                                        {currentUnit.snapshotType_ID === 5 && <span>{Formatting.numberWithCommas(currentUnit.totalNGManual)} SCF ({Formatting.numberWithCommas(currentUnit.totalDE)} DE)</span>}
                                    </div>
                                    <div className="unitStatInfoSmallValue">
                                        <CircleImage /> N/A {currentUnit.uom}/hr
                                    </div>
                                </div>
                        </div>
                    }  

                    {(currentUnit.snapshotType_ID === 5) && !isNaN(currentUnit.subRate) && (props.showSubRate === 'true' || props.showSubRate === true) && 
                        <div className="unitStatInfoContainer">
                        <div className="unitStatInfoTitle">Substitution Rate</div>
                            <div className="unitStatInfo">
                                <div className="unitStatInfoSubRate">
                                    {currentUnit.subRate}%
                                </div>
                            </div>
                        </div>
                    }


                    {
                        (props.export === true || props.export === "true") && 
                            <ExportUnit project={props.project} unit={currentUnit} token={props.token} />
                    }
                    <div className="clearfix"></div>
                </Fragment>
            }
            <div className="unitTableContainer">
            {showCurrentUnit && (currentUnit.snapshotType_ID !== 4 && currentUnit.totalVolume === 0)  &&
                        <div className="center margin-bottom-20">
                            No Data
                        </div>
                    }
            {showCurrentUnit && (currentUnit.snapshotType_ID === 1 || currentUnit.snapshotType_ID === 2 || currentUnit.snapshotType_ID == 5) && currentUnit.lines.map(config => 
                <Fragment key={currentUnit}>
                    {currentUnit.totalVolume > 0 && 
                        <table className="unitTable center">
                            <tr className="wellRow">
                                <td></td>
                                <td className="wellCellHeader totalCell">Totals {currentUnit.snapshotType_ID === 5 && (props.showSubRate === 'true' || props.showSubRate === true) && <Fragment>(Sub rate %)</Fragment>}</td>
                                {currentUnit.snapshotType_ID !== 2 && <td className="wellCellHeader totalCell">Total Non Frac Diesel</td>}
                                {currentUnit.snapshotType_ID !== 2 && <td className="wellCellHeader totalCell">Total Diesel</td>}
                                {currentUnit.snapshotType_ID === 5 && <td className="wellCellHeader totalCell">Total Natural Gas</td>}
                                {config[0].equip1 !== '' && <td className="wellCellHeader">{config[0].equip1} {config[0].serial1}</td>}
                                {config[0].equip2 !== '' && <td className="wellCellHeader">{config[0].equip2} {config[0].serial2}</td>}
                                {config[0].equip3 !== '' && <td className="wellCellHeader">{config[0].equip3} {config[0].serial3}</td>}
                                {config[0].equip4 !== '' && <td className="wellCellHeader">{config[0].equip4} {config[0].serial4}</td>}
                                {config[0].equip5 !== '' && <td className="wellCellHeader">{config[0].equip5} {config[0].serial5}</td>}
                                {config[0].equip6 !== '' && <td className="wellCellHeader">{config[0].equip6} {config[0].serial6}</td>}
                                {config[0].equip7 !== '' && <td className="wellCellHeader">{config[0].equip7} {config[0].serial7}</td>}
                                {config[0].equip8 !== '' && <td className="wellCellHeader">{config[0].equip8} {config[0].serial8}</td>}
                                {config[0].equip9 !== '' && <td className="wellCellHeader">{config[0].equip9} {config[0].serial9}</td>}
                                {config[0].equip10 !== '' && <td className="wellCellHeader">{config[0].equip10} {config[0].serial10}</td>}
                                {config[0].equip11 !== '' && <td className="wellCellHeader">{config[0].equip11} {config[0].serial11}</td>}
                                {config[0].equip12 !== '' && <td className="wellCellHeader">{config[0].equip12} {config[0].serial12}</td>}
                                {config[0].equip13 !== '' && <td className="wellCellHeader">{config[0].equip13} {config[0].serial13}</td>}
                                {config[0].equip14 !== '' && <td className="wellCellHeader">{config[0].equip14} {config[0].serial14}</td>}
                            </tr>
                            {config.map(line =>
                                <Fragment key={line}>
                                    {line.meterTotal !== 0 &&
                                        <tr className="wellRow">
                                            <td className="wellCell">
                                                <div className="lineName">Well {line.wellName}, Stage {line.stageNumber}</div>
                                                <div className="lineDuration">{line.startTimeString.replace('-', '/')} - {line.endTimeString.replace('-','/')}</div>
                                            </td>
                                            <td className="wellCellValue totalCell">
                                                {line.meterTotal}{currentUnit.snapshotType_ID === 2 && <span> SCF</span>}{currentUnit.snapshotType_ID !== 2 && <span>{line.uomString}</span>} {currentUnit.snapshotType_ID === 5 && (props.showSubRate === 'true' || props.showSubRate === true) && <Fragment>({line.subRate}%)</Fragment>} 
                                            </td>
                                            {currentUnit.snapshotType_ID !== 2 && 
                                                <td className="wellCellValue softTotal">
                                                    {line.totalNonFracDiesel}{line.uomString}
                                                </td>
                                            }
                                            {currentUnit.snapshotType_ID !== 2 && 
                                                <td className="wellCellValue softTotal">
                                                    {line.totalDiesel}{line.uomString}
                                                </td>
                                            }
                                            {currentUnit.snapshotType_ID === 5 && 
                                                <td className="wellCellValue softTotal">
                                                    {line.totalSCF} SCF
                                                </td>
                                            }
                                           {config[0].equip1 !== '' && 
                                                <td className="wellCellValue">
                                                    {line.meter1}{currentUnit.snapshotType_ID === 2 && <span> SCF</span>}{currentUnit.snapshotType_ID !== 2 && <span>{line.uomString}</span>}
                                                </td>
                                            }
                                            {config[0].equip2 !== '' && 
                                                <td className="wellCellValue">
                                                    {line.meter2}{currentUnit.snapshotType_ID === 2 && <span> SCF</span>}{currentUnit.snapshotType_ID !== 2 && <span>{line.uomString}</span>}
                                                </td>
                                            }
                                            {config[0].equip3 !== '' && 
                                                <td className="wellCellValue">
                                                    {line.meter3}{currentUnit.snapshotType_ID === 2 && <span> SCF</span>}{currentUnit.snapshotType_ID !== 2 && <span>{line.uomString}</span>}
                                                </td>
                                            }
                                            {config[0].equip4 !== '' && 
                                                <td className="wellCellValue">
                                                    {line.meter4}{currentUnit.snapshotType_ID === 2 && <span> SCF</span>}{currentUnit.snapshotType_ID !== 2 && <span>{line.uomString}</span>}
                                                </td>
                                            }
                                            {config[0].equip5 !== '' && 
                                                <td className="wellCellValue">
                                                    {line.meter5}{currentUnit.snapshotType_ID === 2 && <span> SCF</span>}{currentUnit.snapshotType_ID !== 2 && <span>{line.uomString}</span>}
                                                </td>
                                            }
                                            {config[0].equip6 !== '' && 
                                                <td className="wellCellValue">
                                                    {line.meter6}{currentUnit.snapshotType_ID === 2 && <span> SCF</span>}{currentUnit.snapshotType_ID !== 2 && <span>{line.uomString}</span>}
                                                </td>
                                            }
                                            {config[0].equip7 !== '' && 
                                                <td className="wellCellValue">
                                                    {line.meter7}{currentUnit.snapshotType_ID === 2 && <span> SCF</span>}{currentUnit.snapshotType_ID !== 2 && <span>{line.uomString}</span>}
                                                </td>
                                            }
                                            {config[0].equip8 !== '' && 
                                                <td className="wellCellValue">
                                                  {line.meter8}{currentUnit.snapshotType_ID === 2 && <span> SCF</span>}{currentUnit.snapshotType_ID !== 2 && <span>{line.uomString}</span>}
                                                </td>
                                            }
                                            {config[0].equip9 !== '' && 
                                                <td className="wellCellValue">
                                                  {line.meter9}{currentUnit.snapshotType_ID === 2 && <span> SCF</span>}{currentUnit.snapshotType_ID !== 2 && <span>{line.uomString}</span>}
                                                </td>
                                            }
                                            {config[0].equip10 !== '' && 
                                                <td className="wellCellValue">
                                                    {line.meter10}{currentUnit.snapshotType_ID === 2 && <span> SCF</span>}{currentUnit.snapshotType_ID !== 2 && <span>{line.uomString}</span>}
                                                </td>
                                            }
                                            {config[0].equip11 !== '' && 
                                                <td className="wellCellValue">
                                                    {line.meter11}{currentUnit.snapshotType_ID === 2 && <span> SCF</span>}{currentUnit.snapshotType_ID !== 2 && <span>{line.uomString}</span>}
                                                </td>
                                            }
                                            {config[0].equip12 !== '' && 
                                                <td className="wellCellValue">
                                                    {line.meter12}{currentUnit.snapshotType_ID === 2 && <span> SCF</span>}{currentUnit.snapshotType_ID !== 2 && <span>{line.uomString}</span>}
                                                </td>
                                            }
                                            {config[0].equip13 !== '' && 
                                                <td className="wellCellValue">
                                                    {line.meter13}{currentUnit.snapshotType_ID === 2 && <span> SCF</span>}{currentUnit.snapshotType_ID !== 2 && <span>{line.uomString}</span>}
                                                </td>
                                            }
                                            {config[0].equip14 !== '' && 
                                                <td className="wellCellValue">
                                                    {line.meter14}{currentUnit.snapshotType_ID === 2 && <span> SCF</span>}{currentUnit.snapshotType_ID !== 2 && <span>{line.uomString}</span>}
                                                </td>
                                            }
                                        </tr>
                                    }
                                </Fragment>
                            )}
                        </table>
                    }
                </Fragment>
            )}

            {showCurrentUnit && (currentUnit.snapshotType_ID === 3) && currentUnit.lines.map(config => 
                <Fragment key={currentUnit}>
                    {currentUnit.totalVolume > 0  &&
                        <table className="unitTable center">
                            <tr className="wellRow">
                                <td></td>
                                <td className="wellCellHeader totalCell">Totals</td>
                                {config[0].equip1 !== '' && <td className="wellCellHeader">{config[0].equip1} {config[0].serial1}</td>}
                                {config[0].equip2 !== '' && <td className="wellCellHeader">{config[0].equip2} {config[0].serial2}</td>}
                                {config[0].equip3 !== '' && <td className="wellCellHeader">{config[0].equip3} {config[0].serial3}</td>}
                                {config[0].equip4 !== '' && <td className="wellCellHeader">{config[0].equip4} {config[0].serial4}</td>}
                                {config[0].equip5 !== '' && <td className="wellCellHeader">{config[0].equip5} {config[0].serial5}</td>}
                                {config[0].equip6 !== '' && <td className="wellCellHeader">{config[0].equip6} {config[0].serial6}</td>}
                                {config[0].equip7 !== '' && <td className="wellCellHeader">{config[0].equip7} {config[0].serial7}</td>}
                                {config[0].equip8 !== '' && <td className="wellCellHeader">{config[0].equip8} {config[0].serial8}</td>}
                                {config[0].equip9 !== '' && <td className="wellCellHeader">{config[0].equip9} {config[0].serial9}</td>}
                                {config[0].equip10 !== '' && <td className="wellCellHeader">{config[0].equip10} {config[0].serial10}</td>}
                                {config[0].equip11 !== '' && <td className="wellCellHeader">{config[0].equip11} {config[0].serial11}</td>}
                                {config[0].equip12 !== '' && <td className="wellCellHeader">{config[0].equip12} {config[0].serial12}</td>}
                                {config[0].equip13 !== '' && <td className="wellCellHeader">{config[0].equip13} {config[0].serial13}</td>}
                                {config[0].equip14 !== '' && <td className="wellCellHeader">{config[0].equip14} {config[0].serial14}</td>}
                                {config[0].equip15 !== '' && <td className="wellCellHeader">{config[0].equip15} {config[0].serial15}</td>}
                                {config[0].equip16 !== '' && <td className="wellCellHeader">{config[0].equip16} {config[0].serial16}</td>}
                                {config[0].equip17 !== '' && <td className="wellCellHeader">{config[0].equip17} {config[0].serial17}</td>}
                                {config[0].equip18 !== '' && <td className="wellCellHeader">{config[0].equip18} {config[0].serial18}</td>}
                                {config[0].equip19 !== '' && <td className="wellCellHeader">{config[0].equip19} {config[0].serial19}</td>}
                                {config[0].equip20 !== '' && <td className="wellCellHeader">{config[0].equip20} {config[0].serial20}</td>}
                                {config[0].equip21 !== '' && <td className="wellCellHeader">{config[0].equip21} {config[0].serial21}</td>}
                                {config[0].equip22 !== '' && <td className="wellCellHeader">{config[0].equip22} {config[0].serial22}</td>}
                                {config[0].equip23 !== '' && <td className="wellCellHeader">{config[0].equip23} {config[0].serial23}</td>}
                                {config[0].equip24 !== '' && <td className="wellCellHeader">{config[0].equip24} {config[0].serial24}</td>}
                                {config[0].equip25 !== '' && <td className="wellCellHeader">{config[0].equip25} {config[0].serial25}</td>}
                                {config[0].equip26 !== '' && <td className="wellCellHeader">{config[0].equip26} {config[0].serial26}</td>}
                                {config[0].equip27 !== '' && <td className="wellCellHeader">{config[0].equip27} {config[0].serial27}</td>}
                                {config[0].equip28 !== '' && <td className="wellCellHeader">{config[0].equip28} {config[0].serial28}</td>}

                            </tr>
                            {config.map(line =>
                                <Fragment key={line}>
                                    {line.meterTotal !== 0 &&
                                        <tr className="wellRow">
                                            <td className="wellCell">
                                                <div className="lineName">Well {line.wellName}, Stage {line.stageNumber}</div>
                                                <div className="lineDuration">{line.startTimeString.replace('-', '/')} - {line.endTimeString.replace('-','/')}</div>
                                            </td>
                                            <td className="wellCellValue totalCell">
                                                {line.meterTotal}{line.uomString}
                                            </td>
                                            {config[0].equip1 !== '' && 
                                                <td className="wellCellValue">
                                                    {line.meter1}{line.uomString}
                                                </td>
                                            }
                                            {config[0].equip2 !== '' && 
                                                <td className="wellCellValue">
                                                    {line.meter2}{line.uomString}
                                                </td>
                                            }
                                            {config[0].equip3 !== '' && 
                                                <td className="wellCellValue">
                                                    {line.meter3}{line.uomString}
                                                </td>
                                            }
                                            {config[0].equip4 !== '' && 
                                                <td className="wellCellValue">
                                                    {line.meter4}{line.uomString}
                                                </td>
                                            }
                                            {config[0].equip5 !== '' && 
                                                <td className="wellCellValue">
                                                    {line.meter5}{line.uomString}
                                                </td>
                                            }
                                            {config[0].equip6 !== '' && 
                                                <td className="wellCellValue">
                                                    {line.meter6}{line.uomString}
                                                </td>
                                            }
                                            {config[0].equip7 !== '' && 
                                                <td className="wellCellValue">
                                                    {line.meter7}{line.uomString}
                                                </td>
                                            }
                                            {config[0].equip8 !== '' && 
                                                <td className="wellCellValue">
                                                    {line.meter8}{line.uomString}
                                                </td>
                                            }
                                            {config[0].equip9 !== '' && 
                                                <td className="wellCellValue">
                                                    {line.meter9}{line.uomString}
                                                </td>
                                            }
                                            {config[0].equip10 !== '' && 
                                                <td className="wellCellValue">
                                                    {line.meter10}{line.uomString}
                                                </td>
                                            }
                                            {config[0].equip11 !== '' && 
                                                <td className="wellCellValue">
                                                    {line.meter11}{line.uomString}
                                                </td>
                                            }
                                            {config[0].equip12 !== '' && 
                                                <td className="wellCellValue">
                                                    {line.meter12}{line.uomString}
                                                </td>
                                            }
                                            {config[0].equip13 !== '' && 
                                                <td className="wellCellValue">
                                                    {line.meter13}{line.uomString}
                                                </td>
                                            }
                                            {config[0].equip14 !== '' && 
                                                <td className="wellCellValue">
                                                    {line.meter14}{line.uomString}
                                                </td>
                                            }
                                            {config[0].equip15 !== '' && 
                                                <td className="wellCellValue">
                                                    AAA {line.meter15}{line.uomString}
                                                </td>
                                            }
                                            {config[0].equip16 !== '' && 
                                                <td className="wellCellValue">
                                                    {line.meter16}{line.uomString}
                                                </td>
                                            }
                                            {config[0].equip17 !== '' &&  
                                                <td className="wellCellValue">
                                                    {line.meter17}{line.uomString}
                                                </td>
                                            }
                                            {config[0].equip18 !== '' &&
                                                <td className="wellCellValue">
                                                    {line.meter18}{line.uomString}
                                                </td>
                                            }
                                            {config[0].equip19 !== '' &&
                                                <td className="wellCellValue">
                                                    {line.meter19}{line.uomString}
                                                </td>
                                            }
                                            {config[0].equip20 !== '' &&
                                                <td className="wellCellValue">
                                                    {line.meter20}{line.uomString}
                                                </td>
                                            }
                                            {config[0].equip21 !== '' &&
                                                <td className="wellCellValue">
                                                    {line.meter21}{line.uomString}
                                                </td>
                                            }
                                            {config[0].equip22 !== '' &&
                                                <td className="wellCellValue">
                                                    {line.meter22}{line.uomString}
                                                </td>
                                            }
                                            {config[0].equip23 !== '' &&
                                                <td className="wellCellValue">
                                                    {line.meter23}{line.uomString}
                                                </td>
                                            }
                                            {config[0].equip24 !== '' &&
                                                <td className="wellCellValue">
                                                    {line.meter24}{line.uomString}
                                                </td>
                                            }
                                            {config[0].equip25 !== '' &&
                                                <td className="wellCellValue">
                                                    {line.meter25}{line.uomString}
                                                </td>
                                            }
                                            {config[0].equip26 !== '' &&
                                                <td className="wellCellValue">
                                                    {line.meter26}{line.uomString}
                                                </td>
                                            }
                                            {config[0].equip27 !== '' &&
                                                <td className="wellCellValue">
                                                    {line.meter27}{line.uomString}
                                                </td>
                                            }
                                            {config[0].equip28 !== '' &&
                                                <td className="wellCellValue">
                                                    {line.meter28}{line.uomString}
                                                </td>
                                            }
                                        </tr>
                                    }
                                </Fragment>
                            )}
                        </table>
                    }
                </Fragment>
            )}

            {showCurrentUnit && (currentUnit.snapshotType_ID === 4 && currentUnit.lineCount > 0) && currentUnit.lines.map(config => 
                <table className="unitTable" key={config}>
                    <tr className="wellRow">
                        <td></td>
                        <td className="wellCellHeader totalCell">Volume</td>
                        <td className="wellCellHeader">Avg BTU</td>
                        <td className="wellCellHeader">Avg Flow Rate</td>
                    </tr>
                    {config.map(line =>
                        <Fragment key={line.wellName}>
                            <tr className="wellRow">
                                <td className="wellCell">
                                    Well {line.wellName}, Stage {line.stageNumber}
                                </td>
                                <td className="wellCellValue totalCell">
                                    {line.volume}
                                </td>
                                <td className="wellCellValue">
                                    {line.avgBTU}
                                </td>
                                <td className="wellCellValue">
                                    {line.avgFlowRate}
                                </td>
                            </tr>
                        </Fragment>
                    )}
                </table>
            )}    
        </div>
        </Fragment>
    )
}