import slugify from '../helpers/slugify';

class MetaDataValues{
    constructor(uom, naturalGasUnit, btuHeatingValue, ngHeatingValue, dailyCutoffTime, timezone, dailyCutoffHour, 
                brakeHorsePower, loadFactorPumping, loadFactorIdle, hoursPerDayIdle, hoursPerDayWorking,
                dieselCostPerGallon, ngCostPerMCF, bifuelDailySetupCost, substitutionRate)
    {
        this.uom = uom;
        this.naturalGasUnit = naturalGasUnit;
        this.btuHeatingValue = btuHeatingValue;
        this.ngHeatingValue = ngHeatingValue;
        this.dailyCutoffTime = dailyCutoffTime;
        this.timezone = timezone;
        this.dailyCutoffHour = dailyCutoffHour;
        this.brakeHorsePower = brakeHorsePower;
        this.loadFactorPumping = loadFactorPumping;
        this.loadFactorIdle = loadFactorIdle;
        this.hoursPerDayIdle = hoursPerDayIdle;
        this.hoursPerDayWorking= hoursPerDayWorking;
        this.dieselCostPerGallon = dieselCostPerGallon;
        this.ngCostPerMCF = ngCostPerMCF;
        this.bifuelDailySetupCost = bifuelDailySetupCost;
        this.substitutionRate = substitutionRate;
    }
}

export const getUserMetadata = (user) => {
    return user && user["https://portal.fracshack.com_user_metadata"];
}

export const getAppMetadata = (user) => {
    return user && user["https://portal.fracshack.com_app_metadata"];
}

export const getMetadataValues = (user, producer, project) => {
    let metadata = getUserMetadata(user);
    let proj = slugify(project);
    var prod = slugify(producer);
    if (!metadata || !metadata.preferences){
        return  null;
    }

    let producerIndex = metadata.preferences.energyProducers.findIndex(p => p.name === prod);
    if (producerIndex === -1){
        return null;
    }
    
    let projectIndex = metadata.preferences.energyProducers[producerIndex].projects.findIndex(p => p.name === proj);
    if (projectIndex === -1){
        return null;
    }
    let userUOM = metadata.preferences.energyProducers[producerIndex].projects[projectIndex].uom;
    let userNaturalGasUnit = metadata.preferences.energyProducers[producerIndex].projects[projectIndex].naturalGasUnit;
    let userBTU = metadata.preferences.energyProducers[producerIndex].projects[projectIndex].btuHeatingvalue;
    let userNG = metadata.preferences.energyProducers[producerIndex].projects[projectIndex].ngHeatingValue;
    let userDailyCutoffTime = metadata.preferences.energyProducers[producerIndex].projects[projectIndex].dailyCutoffTime;
    let timezone = metadata.preferences.energyProducers[producerIndex].projects[projectIndex].timezone;
    let dailyCutoffHour = metadata.preferences.energyProducers[producerIndex].projects[projectIndex].dailyCutoffHour;
    let brakeHorsePower = metadata.preferences.energyProducers[producerIndex].projects[projectIndex].brakeHorsePower;
    let loadFactorPumping = metadata.preferences.energyProducers[producerIndex].projects[projectIndex].loadFactorPumping;
    let loadFactorIdle = metadata.preferences.energyProducers[producerIndex].projects[projectIndex].loadFactorIdle;
    let hoursPerDayIdle = metadata.preferences.energyProducers[producerIndex].projects[projectIndex].hoursPerDayIdle;
    let hoursPerDayWorking = metadata.preferences.energyProducers[producerIndex].projects[projectIndex].hoursPerDayWorking;
    let dieselCostPerGallon = metadata.preferences.energyProducers[producerIndex].projects[projectIndex].dieselCostPerGallon;
    let ngCostPerMCF = metadata.preferences.energyProducers[producerIndex].projects[projectIndex].ngCostPerMCF;
    let bifuelDailySetupCost = metadata.preferences.energyProducers[producerIndex].projects[projectIndex].bifuelDailySetupCost;
    let substitutionRate = metadata.preferences.energyProducers[producerIndex].projects[projectIndex].substitutionRate;
    
    var metadataValues = new MetaDataValues(userUOM, userNaturalGasUnit, userBTU, userNG, userDailyCutoffTime, timezone, dailyCutoffHour, 
                                            brakeHorsePower, loadFactorPumping, loadFactorIdle, hoursPerDayIdle, hoursPerDayWorking,
                                            dieselCostPerGallon, ngCostPerMCF, bifuelDailySetupCost, substitutionRate);
    return metadataValues;
}

export const newMetadataValues = (user, producer, project, UOM, naturalGasUnit, btuHeatingValue, ngHeatingValue, dailyCutoffTime, timezone, dailyCutoffHour,
                                  brakeHorsePower, loadFactorPumping, loadFactorIdle, hoursPerDayIdle, hoursPerDayWorking, dieselCostPerGallon,
                                  ngCostPerMCF, bifuelDailySetupCost, substitutionRate) => {
    var proj = slugify(project);
    var prod = slugify(producer);
    let metadata = getUserMetadata(user);
    if (!metadata || !metadata.preferences){
        return {
            preferences: {
                energyProducers: [{
                    name: prod,
                    projects: [{
                        name: proj,
                        uom: UOM,
                        naturalGasUnit: naturalGasUnit,
                        btuHeatingValue: btuHeatingValue,
                        ngHeatingValue: ngHeatingValue,
                        dailyCutoffTime: dailyCutoffTime,
                        timezone: timezone,
                        dailyCutoffHour: dailyCutoffHour,
                        brakeHorsePower: brakeHorsePower,
                        loadFactorPumping: loadFactorPumping,
                        loadFactorIdle: loadFactorIdle,
                        hoursPerDayIdle: hoursPerDayIdle,
                        hoursPerDayWorking: hoursPerDayWorking,
                        dieselCostPerGallon: dieselCostPerGallon,
                        ngCostPerMCF: ngCostPerMCF,
                        bifuelDailySetupCost: bifuelDailySetupCost,
                        substitutionRate: substitutionRate
                    }]
                }]
            }
        }
    }

    let producerIndex = metadata.preferences.energyProducers.findIndex(p => p.name === prod);
    if (producerIndex === -1){
        metadata.preferences.energyProducers.push({
            name: prod,
            projects: [{
                name: proj,
                uom: UOM,
                naturalGasUnit: naturalGasUnit,
                btuHeatingValue: btuHeatingValue,
                ngHeatingValue: ngHeatingValue,
                dailyCutoffTime: dailyCutoffTime,
                timezone: timezone,
                dailyCutoffHour: dailyCutoffHour,
                brakeHorsePower: brakeHorsePower,
                loadFactorPumping: loadFactorPumping,
                loadFactorIdle: loadFactorIdle,
                hoursPerDayIdle: hoursPerDayIdle,
                hoursPerDayWorking: hoursPerDayWorking,
                dieselCostPerGallon: dieselCostPerGallon,
                ngCostPerMCF: ngCostPerMCF,
                bifuelDailySetupCost: bifuelDailySetupCost,
                substitutionRate: substitutionRate
            }]
        })
        return metadata;
    }
    
    let projectIndex = metadata.preferences.energyProducers[producerIndex].projects.findIndex(p => p.name === proj);
    if (projectIndex === -1){
        metadata.preferences.energyProducers[producerIndex].projects.push({
            name: proj,
            uom: UOM,
            naturalGasUnit: naturalGasUnit,
            btuHeatingValue: btuHeatingValue,
            ngHeatingValue: ngHeatingValue,
            dailyCutoffTime: dailyCutoffTime,
            timezone: timezone,
            dailyCutoffHour: dailyCutoffHour,
            brakeHorsePower: brakeHorsePower,
            loadFactorPumping: loadFactorPumping,
            loadFactorIdle: loadFactorIdle,
            hoursPerDayIdle: hoursPerDayIdle,
            hoursPerDayWorking: hoursPerDayWorking,
            dieselCostPerGallon: dieselCostPerGallon,
            ngCostPerMCF: ngCostPerMCF,
            bifuelDailySetupCost: bifuelDailySetupCost,
            substitutionRate: substitutionRate
        })
        return metadata;
    }

    metadata.preferences.energyProducers[producerIndex].projects[projectIndex].uom = UOM;
    metadata.preferences.energyProducers[producerIndex].projects[projectIndex].naturalGasUnit = naturalGasUnit;
    metadata.preferences.energyProducers[producerIndex].projects[projectIndex].btuHeatingValue = btuHeatingValue;
    metadata.preferences.energyProducers[producerIndex].projects[projectIndex].ngHeatingValue = ngHeatingValue;
    metadata.preferences.energyProducers[producerIndex].projects[projectIndex].dailyCutoffTime = dailyCutoffTime;
    metadata.preferences.energyProducers[producerIndex].projects[projectIndex].timezone = timezone;
    metadata.preferences.energyProducers[producerIndex].projects[projectIndex].dailyCutoffHour = dailyCutoffHour;
    metadata.preferences.energyProducers[producerIndex].projects[projectIndex].brakeHorsePower = brakeHorsePower;
    metadata.preferences.energyProducers[producerIndex].projects[projectIndex].loadFactorPumping = loadFactorPumping;
    metadata.preferences.energyProducers[producerIndex].projects[projectIndex].loadFactorIdle = loadFactorIdle;
    metadata.preferences.energyProducers[producerIndex].projects[projectIndex].hoursPerDayIdle = hoursPerDayIdle ;
    metadata.preferences.energyProducers[producerIndex].projects[projectIndex].hoursPerDayWorking = hoursPerDayWorking ;
    metadata.preferences.energyProducers[producerIndex].projects[projectIndex].dieselCostPerGallon = dieselCostPerGallon;
    metadata.preferences.energyProducers[producerIndex].projects[projectIndex].ngCostPerMCF = ngCostPerMCF;
    metadata.preferences.energyProducers[producerIndex].projects[projectIndex].bifuelDailySetupCost = bifuelDailySetupCost;
    metadata.preferences.energyProducers[producerIndex].projects[projectIndex].substitutionRate = substitutionRate;
    
    return metadata;
}

class UserLanguagePreference{
    constructor(languagePreference)
    {
        this.languagePreference = languagePreference;
    }
}

export const getUserLanguagePreference = (user) => {
    let metadata = getUserMetadata(user);
    if (!metadata || !metadata.languagePreference){
        return null;
    }
    
    let userLanguagePreference = metadata.languagePreference;
    return new UserLanguagePreference(userLanguagePreference);
}

export const newUserLanguagePreference = (user, newLanguagePreference) => {
    let metadata = getUserMetadata(user);
    if (!metadata || !metadata.languagePreference){
    return {
            languagePreference: newLanguagePreference         
       }
    }
    
    if (metadata.languagePreference !== newLanguagePreference) {
        metadata.languagePreference = newLanguagePreference
    }
    return metadata;
}