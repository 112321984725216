import React, {Component, useState} from "react";
import { ProjectMenuContext } from "../Contexts/ProjectMenuContext";

export class ProjectMenuProvider extends Component {
    constructor(props){
        super(props);
        this.state = {
            activePage: "Dashboard",
            mobileText: "Dashboard",
            showMobileButton: true,
            showMobileMenu: false
        };
    }
    
    // Update single values in state
    setProjectMenu = (key, value) => {
        this.setState({ [key]: value })
    }

    removeMobileButton = () => {
        this.setState({
            showMobileButton: !this.showMobileButton,
            showMobileMenu: true
        })
    }

    closeMobileMenu = () => {
        this.setState({
            showMobileButton: true,
            showMobileMenu: false
        })
    }

    render() {
        return (
            <ProjectMenuContext.Provider
                value={{
                    projectMenu: this.state,
                    setProjectMenu: this.setProjectMenu,
                    removeMobileButton: this.removeMobileButton,
                    closeMobileMenu: this.closeMobileMenu
                }}
            >
                {this.props.children}
            </ProjectMenuContext.Provider>
        );
    }
}