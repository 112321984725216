import React, {useState} from "react";
import InfoIcon from "../images/icons8-info-16.png";
import {Tooltip} from "reactstrap";

export default function SubscriptionTooltip(props) {
    const [tooltipOpen, setTooltipOpen] = useState(false);
    
    const toggle = () => setTooltipOpen(!tooltipOpen);
    
    return (
        <>
            { props.title }
            <img src={InfoIcon} className="dashboard-info-icon" id={`infoIcon-${props.type}-subscription`} alt="info" />
            <Tooltip placement="right" isOpen={tooltipOpen} toggle={toggle} target={`infoIcon-${props.type}-subscription`} >
                { props.message }
            </Tooltip>
        </>
    )
}