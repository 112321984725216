import React, { Fragment, useEffect } from "react";
import Logo from "./../../../images/FracShackLogoStackedNoTm.svg";
import "./Loader.css";

function Loader() {
	useEffect(() => {
		document.body.classList.add("loading-body");
		return () => {
			document.body.classList.remove("loading-body");
		};
	});
	return (
		<Fragment>
		<svg
				className="loading-circle"
				viewBox="0 0 100 100"
				xmlns="http://www.w3.org/2000/svg"
			>
				<circle cx="50" cy="50" r="45" />
			</svg>
			<img src={Logo} className="loading" alt="Loading..." />
		</Fragment>
	);	
}

export default Loader;
