import React, {Fragment, useContext, useEffect, useState} from 'react';
import {ProjectContext} from "../Contexts/ProjectContext";
import {useIntl} from "react-intl";
import {setState} from "litsy";
import NgSideKickSummary from "../../NgSidekickSummary";

export default function NgSideKickPage(props) {
    const {project, setValue, setMobileText} = useContext(ProjectContext)
    const intl = useIntl();

    const requestNgSideKickWellStats = (well) => {
        if (well.stages !== undefined) {
            setValue('activeWell', well);
            setMobileText(well.name !== '' ?
                intl.formatMessage({id: "well.label", defaultMessage: "Well"}) + " " + well.wellName
                : 'Unknown Well');
        } else if (well.wells !== undefined) {
            setValue('activeWell', 'all');
            setMobileText(intl.formatMessage({id: "allWells.label", defaultMessage: "All Wells"}));
            setState("fracShack_currentProject", well, "volatile");
        }
    }

    return (
        <Fragment>
            {project.currentProject !== null && project.activePage === "NgSideKick" &&
            <Fragment>
                {project.activeWell !== null && project.activeWell !== 'all' &&
                <Fragment>
                    <NgSideKickSummary project={project.currentProject} well={project.activeWell}
                                       showSubRate={props.showSubstitutionRate} title="Stages"
                                       bifuel={project.currentProject.bifuel} type={'single'}/>
                </Fragment>
                }

                {(props.showProjectStats === true || props.showProjectStats === 'true') && project.activeWell === 'all' &&
                <Fragment>
                    <NgSideKickSummary project={project.currentProject}
                                       showSubRate={props.showSubstitutionRate} title="Wells"
                                       bifuel={project.currentProject.bifuel}
                                       requestWell={requestNgSideKickWellStats}
                                       type={'all'}/>
                </Fragment>
                }
            </Fragment>
            }
        </Fragment>
    )
}