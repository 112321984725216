import fetchWithTimeout from '../helpers/fetchWithTimeout';
import * as constants from './constants/admin';

const initialState = {
    overdueJobs: [],
    overdueJobSubscription: null,
    isLoading: false 
};

export const actionCreators = {
    updateOverdueJobSubscription: (token, adminSubscription) => async (dispatch, getState) => {
        dispatch({ type: constants.requestOverdueJobSubscriptionType });

        const url = global.apiEndpoint + `admin/overdue-job-subscriptions/update?adminSubscription=${JSON.stringify(adminSubscription)}`;
        if (global.apiEndpoint === undefined)
        {
            window.location.replace('/');
        }
        const requestOptions = {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`
            }
        };
        const response = await fetchWithTimeout(url, requestOptions);
        if(response.status === 401 || response.status === 403){
            dispatch({type: constants.UnauthorizedError})
        }
        if (response.status !== 200)
        {
            dispatch({type: constants.requestOverdueJobSubscriptionError });
        }
        try{
            const overdueJobSubscription = await response.json();
            dispatch({ type: constants.receiveOverdueJobSubscriptionType, overdueJobSubscription});

            return overdueJobSubscription;
        }
        catch(err){
            dispatch({type: constants.requestOverdueJobSubscriptionType });
        }
    },
    requestOverdueJobSubscription: (token, email) => async (dispatch, getState) => {
        dispatch({ type: constants.requestOverdueJobSubscriptionType });

        const url = global.apiEndpoint + `admin/overdue-job-subscriptions?email=${email}`;
        if (global.apiEndpoint === undefined)
        {
            window.location.replace('/');
        }
        const requestOptions = {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`
            }
        };
        const response = await fetchWithTimeout(url, requestOptions);
        if(response.status === 401 || response.status === 403){
            dispatch({type: constants.UnauthorizedError})
        }
        if (response.status !== 200)
        {
            dispatch({type: constants.requestOverdueJobSubscriptionError });
        }
        try{
            const overdueJobSubscription = await response.json();
            dispatch({ type: constants.receiveOverdueJobSubscriptionType, overdueJobSubscription});

            return overdueJobSubscription;
        }
        catch(err){
            dispatch({type: constants.requestOverdueJobSubscriptionType });
        }
    },
    updateOverdueJobs: (token, overdueJobs) => async (dispatch, getState) => {
        dispatch({ type: constants.requestOverdueJobsType });
        
        const url = global.apiEndpoint + `admin/overdue-jobs/update?overdueJobs=${JSON.stringify(overdueJobs)}`;
        if (global.apiEndpoint === undefined)
        {
            window.location.replace('/');
        }
        const requestOptions = {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`
            }
        };
        const response = await fetchWithTimeout(url, requestOptions);
        if(response.status === 401 || response.status === 403){
            dispatch({type: constants.UnauthorizedError})
        }
        if (response.status !== 200)
        {
            dispatch({type: constants.requestOverdueJobsError });
        }
        try{
            const overdueJobs = await response.json();
            dispatch({ type: constants.receiveOverdueJobsType, overdueJobs});
    
            return overdueJobs;
        }
        catch(err){
            dispatch({type: constants.requestOverdueJobsType });
        }
    },
    requestOverdueJobs: (token) => async (dispatch, getState) => {
        dispatch({ type: constants.requestOverdueJobsType });

        const url = global.apiEndpoint + 'admin/overdue-jobs/all';
        if (global.apiEndpoint === undefined)
        {
            window.location.replace('/');
        }
        const requestOptions = { 
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`
            }
        };
        const response = await fetchWithTimeout(url, requestOptions);
        if(response.status === 401 || response.status === 403){
            dispatch({type: constants.UnauthorizedError})
        }
        if (response.status !== 200)
        {
            dispatch({type: constants.requestOverdueJobsError });
        }
        try{
            const overdueJobs = await response.json();
            dispatch({ type: constants.receiveOverdueJobsType, overdueJobs});
            
            return overdueJobs;
        }
        catch(err){
            dispatch({type: constants.requestOverdueJobsType });
        }
    }
};

export const reducer = (state, action) => {
    state = state || initialState;

    if (action.type === constants.requestOverdueJobSubscriptionType) {
        return {
            ...state,
            overdueJobSubscription: null,
            isLoading: true,
            isError: false
        };
    }

    if (action.type === constants.receiveOverdueJobSubscriptionType) {
        return {
            ...state,
            overdueJobSubscription: action.overdueJobSubscription,
            isLoading: false,
            isError: false
        };
    }

    if (action.type === constants.requestOverdueJobSubscriptionError)
    {
        return {
            ...state,
            isError: true,
            isLoading: false
        }
    }

    if (action.type === constants.requestOverdueJobsType) {
        return {
            ...state,
            overdueJobs: [],
            isLoading: true,
            isError: false
        };
    }

    if (action.type === constants.receiveOverdueJobsType) {
        return {
            ...state,
            overdueJobs: action.overdueJobs,
            isLoading: false,
            isError: false
        };
    }

    if (action.type === constants.requestOverdueJobsError)
    {
        return {
            ...state,
            isError: true,
            isLoading: false
        }
    }
    
    if (action.type === constants.UnauthorizedError)
    {
        return {
            ...state,
            isError: true,
            isLoading: false,
            isUnauthorized:true
        }
    }

    return state;
};
