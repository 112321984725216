import React, {useEffect, Fragment, useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import ExportExcel from "./ExportExcel";
import { getState } from "litsy";
import Axios from "axios";

export default function ExcelReportConfig(props) {
    const [jobId, setJobId] = useState(null);

    const intl = useIntl();

    // Get and set FullProjectId
    useEffect( () => {
        const getJobId = async () => {
            const projectInfoArray = window.location.pathname.split('/');
            const projectStartTime = projectInfoArray[3];
            const projectName = projectInfoArray[2];
            const projectEnergyProducer = projectInfoArray[1];

            const url = `${getState("fracShack_apiEndpoint", "session")}project/${projectName}/jobId?producerName=${projectEnergyProducer}&startTime=${projectStartTime}`
            let result = (await Axios.get(url, {
                headers: {
                    "Authorization": `Bearer ${getState("authToken", "session")}`
                }
            })).data
            
            setJobId(result);
        };

        getJobId()
    },[])
    
    return (
        <Fragment>
            <div className="projectInfoContainer">
              <div className="projectInfoTitle d-flex flex-row">
                  <div>
                      <FormattedMessage
                          id="reporting.label"
                          defaultMessage="Reporting"
                      />
                      {" "}/{" "}
                      <FormattedMessage
                          id="excelReports.label"
                          defaultMessage="Excel Reports"
                      />
                  </div>
                  {jobId !== null && props.project !== null && props.token !== null &&
                    <ExportExcel jobId={jobId} project={props.project} token={props.token}/>
                  }
              </div>  
           
            </div>
            <div className="clearfix margin-bottom-20"></div>
        </Fragment>
    );
}
