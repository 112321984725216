import React, { useEffect, useState, Fragment } from 'react';
import { Bar, Chart } from 'react-chartjs-2';
import 'chartjs-plugin-stacked100';
import * as Formatting from '../helpers/formatting';
import { useIntl } from "react-intl";
// flow: component is mounted/props is changed -> data is set -> render component
export default function PumperChart(props) {
	const [data, setData] = useState(null) // data is set to null initially
	const [view, setView] = useState(props.view);
	const [pumpers, setPumpers] = useState(null);
	const intl = useIntl();

	// data is set when this component is mounted (first time visit to well info) or when props is changed (going from well info to well info, component if not unmounted, have to check change in props)
	useEffect(() => {
		setView(props.view);
		if (props.activePumper !== null)
		{
			setPumpers(props.project.pumpers.filter(x => x.name == props.activePumper.name));
		}
	}, [props])

	useEffect(() => {
			var pumperArray = [];
			if (props.view === 'Consumed Energy')
			{
				pumperArray.push({
					label: intl.formatMessage({ id: "totalBtu.label", defaultMessage: "Total BTU"}),
					data: Object.keys(props.project.allStages).map(i => props.project.allStages[i].totalBTU),	
					backgroundColor: '#5F8BFF',
					yAxisID: 'y-axis-1'
				})
			}else if (props.view === 'Natural Gas')
			{
				pumperArray.push({
					label: intl.formatMessage({ id: "totalDe.label", defaultMessage: "Total DE"}),
					data: Object.keys(props.project.allStages).map(i => props.project.uomString === "L" ? props.project.allStages[i].totalDE : props.project.allStages[i].totalDE),	
					backgroundColor: '#5F8BFF',
					yAxisID: 'y-axis-1'
				})
			}else if (props.view == 'Diesel')
			{
				pumperArray.push({
					label: intl.formatMessage({ id: "totalDiesel.label", defaultMessage: "Total Diesel"}),
					data: Object.keys(props.project.allStages).map(i => props.project.allStages[i].totalDiesel),	
					backgroundColor: '#5F8BFF',
					yAxisID: 'y-axis-1'
				})
			}else if (props.view == 'Substitution Rate')
			{
				pumperArray.push({
					label: intl.formatMessage({ id: "substitutionRate.label", defaultMessage: "Substitution Rate"}),
					data: Object.keys(props.project.allStages).map(i => props.project.allStages[i].subRate),	
					backgroundColor: '#FFC72F',
					fill: false,
					yAxisID: 'y-axis-0',
					lineTension: 0.1,
					borderColor: '#EDBC23',
					borderCapStyle: 'butt',
					borderDash: [],
					borderDashOffset: 0.0,
					borderJoinStyle: 'miter',
					pointBorderColor: '#EDBC23',
					pointBackgroundColor: '#EDBC23',
					pointBorderWidth: 1,
					pointHoverRadius: 1,
					pointHoverBackgroundColor: 'rgba(75,192,192,1)',
					pointHoverBorderColor: 'rgba(220,220,220,1)',
					pointHoverBorderWidth: 1,
					pointRadius: 1,
					pointHitRadius: 5,
					type: 'line'
				})
				pumperArray.push({
					label: intl.formatMessage({ id: "naturalGas.label", defaultMessage: "Natural Gas"}),
					data: Object.keys(props.project.allStages).map(i => props.project.uomString === "L" ? props.project.allStages[i].totalDE : props.project.allStages[i].totalDE),	
					backgroundColor: '#66B47D',
					yAxisID: 'y-axis-1', stacked: 'stack1'
				})

				pumperArray.push({
					label: intl.formatMessage({ id: "dieselPumped.label", defaultMessage: "Diesel Pumped"}),
					data: Object.keys(props.project.allStages).map(i => props.project.allStages[i].totalDiesel),	
					backgroundColor: '#5F8BFF',
					yAxisID: 'y-axis-1', stacked: 'stack1',
				})
			}

			var pumperData = {
				labels: Object.keys(props.project.allStages).map(i => intl.formatMessage({ id: "well.label", defaultMessage: "Well"}) + ' ' + props.project.allStages[i].wellName + ' ' + intl.formatMessage({ id: "stage.label", defaultMessage: "Stage"}) + ' '  + props.project.allStages[i].stageNumber),
				datasets: pumperArray
			}
	
			setData(pumperData);
	}, [pumpers, props]);

	// called when after data is updated
	useEffect(() => {
		Chart.helpers.each(Chart.instances, function (instance) {
			if (instance.canvas.id === 'pumperChart')
			{
				var chartOptions = {
					scales: {
						yAxes: [
							{
								type: 'linear',
								display: view === 'Substitution Rate' ? true : false,
								position: 'right',
								scaleLabel: {
									display: true,
									labelString: intl.formatMessage({ id: "subRate.label", defaultMessage: "Sub Rate"}) + ' %'
								},
								id: 'y-axis-0',
								gridLines: {
									display: false
								},
								labels: {
									show: true
								},
								ticks: {
									beginAtZero: true
								}							
							},
							{
								stacked: true,
						  		scaleLabel: {
									display: true,
									labelString: view === 'Consumed Energy' ? intl.formatMessage({ id: "consumptionBtu.label", defaultMessage: "Consumption (BTU)"})  
										: intl.formatMessage({ id: "consumption.label", defaultMessage: "Consumption"}) + '  (' + props.project.uomString + ')'
						  		}
						}],
						xAxes: [{
							stacked: true,
							scaleLabel: {
								display: true,
								labelString: intl.formatMessage({ id: "well.label", defaultMessage: "Well"}) + ' / ' + intl.formatMessage({ id: "stage.label", defaultMessage: "Stage"}) 
							}
						}]
					  },
					animation: {
						duration: 0
					},
					legend: {
						display: true,
						position: 'bottom',
						labels: {
							filter: function(item, chart) {
								return !item.text.includes('hide');
							}
						},
						onClick: (e) => e.stopPropagation()
					},
					plugins: {
					  stacked100: { enable: false, replaceTooltipLabel: false }
					},
					responsive: false,
					maintainAspectRatio: false,
					elements: {
						point:{
							radius: 0
						}
					},
					tooltips: {
						mode: 'index',
						intersect: false,
						position: 'nearest',
						backgroundColor: '#ffffff',
						titleFontColor: '#000000',
						bodyFontColor: '#000000',
						titleFontFamily: 'Calibri, Arial',
						titleFontSize: 12,
						bodyFontFamily: 'Calibri, Arial',
						bodyFontSize: 12,
						callbacks: {
							beforeLabel: (tooltipItem, data) => {
							},
							label: (tooltipItem, data) => {
								if (view === 'Natural Gas')
								{
									if (tooltipItem.datasetIndex === 1)
									{
										return intl.formatMessage({ id: "total.label", defaultMessage: "Total"}) + ": " + Formatting.numberWithCommas(tooltipItem.yLabel) + 'D' + props.project.uomStringShort + 'E';
									}
									else if (tooltipItem.datasetIndex === 0)
									{
										return intl.formatMessage({ id: "nG.label", defaultMessage: "NG"}) + ": " + Formatting.numberWithCommas(tooltipItem.yLabel) + 'D' + props.project.uomStringShort + 'E';
									}
								}
								else if (view == 'Consumed Energy')
								{
									if (tooltipItem.datasetIndex === 0)
									{
										return Formatting.numberWithCommas(tooltipItem.yLabel) + ' BTU ' + data.datasets[0].label;
									}
								}
								else if (view == 'Diesel')
								{
									if (tooltipItem.datasetIndex === 1)
									{
										return intl.formatMessage({ id: "total.label", defaultMessage: "Total"}) + ": " + Formatting.numberWithCommas(tooltipItem.yLabel) + props.project.uomString;
									}
									else if (tooltipItem.datasetIndex === 0)
									{
										return intl.formatMessage({ id: "diesel.label", defaultMessage: "Diesel"}) + ": " + Formatting.numberWithCommas(tooltipItem.yLabel) + props.project.uomString;
									}
								}else if (view == 'Substitution Rate')
								{
									if (tooltipItem.datasetIndex === 0)
									{
										return intl.formatMessage({ id: "subRate.label", defaultMessage: "Sub Rate"}) + ': ' + Formatting.numberWithCommas(tooltipItem.yLabel) + ' %';
									}else if (tooltipItem.datasetIndex === 1)
									{
										return intl.formatMessage({ id: "nG.label", defaultMessage: "NG"}) + ": " + Formatting.numberWithCommas(tooltipItem.yLabel) + ' D' + props.project.uomStringShort + 'E';
									}else if (tooltipItem.datasetIndex === 2)
									{
										return intl.formatMessage({ id: "diesel.label", defaultMessage: "Diesel"}) + ": " + Formatting.numberWithCommas(tooltipItem.yLabel) + props.project.uomString;
									}

								}
							},
							afterLabel: (tooltipItem, data) => {
								if ((tooltipItem.datasetIndex === 3 && data.datasets.length === 4) || (tooltipItem.datasetIndex === 1 && data.datasets.length === 2))
								{
									return intl.formatMessage({ id: "start.label", defaultMessage: "Start"}) + "/" + intl.formatMessage({ id: "end.label", defaultMessage: "End"}) + props.project.allStages[tooltipItem.index].startTimeString + ' / ' + props.project.allStages[tooltipItem.index].endTimeString;
								}
							}
						}
					}
				}

				instance.chart.data = data;
				instance.chart.options = chartOptions;
				instance.chart.canvas.parentNode.style.height = '300px';
				instance.chart.update();

			}
		});

	}, [data])

	// render bar chart when there is data
	return data && (
		<Fragment>
			<Bar className="barChart" id='pumperChart' />
		</Fragment>
	)
}