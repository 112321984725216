import React, { useState, useEffect, useContext, Fragment } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { NavLink, Container, Row } from "reactstrap";
import SmallLogo from "./../../../images/SmallLogo.png";
import ProfileDropdown from "../ProfileDropdown/ProfileDropdown";
import { useAuth0 } from "../../../auth/auth0-wrapper";
import NavMenu from "../NavMenu";
import AppInsights from "../../AppInsights.js";
import "./Header.css";

function Header(props) {
  const { getTokenSilently, user, logout} = useAuth0();
  const { project, showNavMenuButton } = props.header;
  const [settingsDropdownToggle, setSettingsDropdownToggle] = useState(false);
  const [token, setToken] = useState(null);
  
  const toggleSettings = () => {
    setSettingsDropdownToggle(!settingsDropdownToggle);
  };

  useEffect(() => {
    const fetchToken = async () => {
      const auth0Token = await getTokenSilently();
      setToken(auth0Token);
    };
    fetchToken();
  },[])
  
  useEffect(() => {
    let ai = new AppInsights();
    ai.initialize();
  });

  return (
      <Container fluid={true} className="header-wrapper">
        <Row className="header">
          <div className="smallLogoContainer">
            <img src={SmallLogo} className="smallLogo" alt="FracShack" />
          </div>
          {showNavMenuButton && <NavMenu />}
          {user && token && <div className="profileImageContainer" >
            <ProfileDropdown logout={logout}
                             username={user.email}
                             token={token}
                             isOpen={settingsDropdownToggle}
                             toggle={toggleSettings}
                             tag={NavLink}
                             to={""}/>
          </div>}
        </Row>
      </Container>
  );
}

const mapStateToProps = store => {
  return {
    header: store.header
  };
};
const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Header);
