import React, {useState} from 'react';
import "../css/Dashboard.css";
import {Dropdown, DropdownItem} from "reactstrap";
import DropdownMenu from "reactstrap/lib/DropdownMenu";
import DropdownToggle from "reactstrap/lib/DropdownToggle";

export default function DashboardDropdown(props)
{
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggle = () => setDropdownOpen(prevState => !prevState);
    
    return (
        <Dropdown className="dashboard-dropdown" isOpen={dropdownOpen} toggle={toggle} size="sm">
            <DropdownToggle className="dashboard-dropdown-toggle" caret>
                {props.selectedValue}
            </DropdownToggle>
            <DropdownMenu
                modifiers={{
                    setMaxHeight: {
                        enabled: true,
                        order: 890,
                        fn: (data) => {
                            return {
                                ...data,
                                styles: {
                                    ...data.styles,
                                    backgroundColor: "#6FCF97",
                                    borderColor: "#FFFFFF"
                                },
                            };
                        },
                    },
                }}
            >
                {Object.entries(props.options).map(([key, value]) => {
                    return <DropdownItem onClick={() => props.onSelect(key)}>{value}</DropdownItem>
                })}
            </DropdownMenu>
        </Dropdown>
    )
}