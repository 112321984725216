import React, {Fragment, useEffect, useState, useContext} from 'react';
import {useIntl} from "react-intl";
import {Bar, Chart} from 'react-chartjs-2';
import {ProjectContext} from "./common/Contexts/ProjectContext";
import * as Formatting from "../helpers/formatting";


export default function DailyByWellTotalsChart(props) {
    const {selectedDate} = props

    const {project} = useContext(ProjectContext)
    const intl = useIntl();

    const [data, setData] = useState(null) // data is set to null initially

    const allWells = project.currentProject.wells
    
    // bar graph with each well on the x and fuel on the y
    // changing the date will change the graph data

    useEffect(() => {
        buildChartData()
    }, [])

    // build list of wells for that date
    useEffect(() => {
        buildChartData()
    }, [selectedDate])

    useEffect(() => {
        buildChart()
    }, [data])


    function buildChartData() {
        const labels = allWells.map(well => well.name)
        let dataSet = [
            {
                label: intl.formatMessage({id: "fracDiesel.label", defaultMessage: "Frac Diesel"}),
                data: null,
                fill: true,
                yAxisID: 'y-axis-1',
                lineTension: 0.1,
                backgroundColor: '#5F8BFF',
                borderColor: 'rgba(75,192,192,1)',
                borderCapStyle: 'butt',
                borderDash: [],
                borderDashOffset: 0.0,
                borderJoinStyle: 'miter',
                pointBorderColor: 'rgba(75,192,192,1)',
                pointBackgroundColor: '#fff',
                pointBorderWidth: 1,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: 'rgba(75,192,192,1)',
                pointHoverBorderColor: 'rgba(220,220,220,1)',
                pointHoverBorderWidth: 2,
                pointRadius: 5,
                pointHitRadius: 10,
                type: 'bar', stacked: 'stack'
            },
            {
                label: intl.formatMessage({id: "nonFracDiesel.label", defaultMessage: "Non-Frac Diesel"}),
                data: null,
                fill: true,
                yAxisID: 'y-axis-1',
                lineTension: 0.1,
                backgroundColor: '#66B47D',
                borderColor: 'rgba(75,192,192,1)',
                borderCapStyle: 'butt',
                borderDash: [],
                borderDashOffset: 0.0,
                borderJoinStyle: 'miter',
                pointBorderColor: 'rgba(75,192,192,1)',
                pointBackgroundColor: '#fff',
                pointBorderWidth: 1,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: 'rgba(75,192,192,1)',
                pointHoverBorderColor: 'rgba(220,220,220,1)',
                pointHoverBorderWidth: 2,
                pointRadius: 5,
                pointHitRadius: 10,
                type: 'bar', stacked: 'stack'
            },
        ]

        if (selectedDate === null) {
            dataSet[0].data = allWells.map(well => well.dailyTotals.reduce((sum, next) => sum + next.fracDieselTotal, 0))
            dataSet[1].data = allWells.map(well => well.dailyTotals.reduce((sum, next) => sum + next.nonFracDieselTotal, 0))
        } else {
            dataSet[0].data = allWells.map(well => {
                let temp = well.dailyTotals.find(item => item.date.split('T')[0] === selectedDate.split('T')[0])
                return temp === undefined ? 0 : temp.fracDieselTotal
            })
            dataSet[1].data = allWells.map(well => {
                let temp = well.dailyTotals.find(item => item.date.split('T')[0] === selectedDate.split('T')[0])
                return temp === undefined ? 0 : temp.nonFracDieselTotal
            })
        }

        setData({
            labels: labels,
            datasets: dataSet
        })
    }

    function buildChart() {
        if (data === null)
            return
        Chart.helpers.each(Chart.instances, instance => {
            const chartOptions = {
                scales: {
                    xAxes: [{
                        stacked: true,
                        scaleLabel: {
                            display: true,
                            labelString: intl.formatMessage({id: "well.label", defaultMessage: "Well"})
                        }
                    }],
                    yAxes: [
                        {
                            stacked: true,
                            type: 'linear',
                            display: false,
                            position: 'right',
                            id: 'y-axis-0',
                            gridLines: {display: false},
                            labels: {show: true},
                            ticks: {beginAtZero: true}
                        },
                        {
                            stacked: true,
                            type: 'linear',
                            display: true,
                            position: 'left',
                            id: 'y-axis-1',
                            gridLines: {display: false},
                            labels: {show: false},
                            ticks: {beginAtZero: true},
                            scaleLabel: {
                                display: true,
                                labelString: intl.formatMessage({id: "consumption.label", defaultMessage: "Consumption"}) + '  (D' + project.currentProject.uomStringShort + 'E)'
                            }
                        }
                    ]
                },
                legend:
                    {
                        display: true,
                        position: 'bottom',
                        onClick: (e) => e.stopPropagation(),
                        labels: {
                            filter: (item, chart) => !item.text.includes('hide')
                        }
                    },
                tooltips: {
                    mode: 'index',
                    intersect: false,
                    position: 'nearest',
                    backgroundColor: '#ffffff',
                    titleFontColor: '#000000',
                    bodyFontColor: '#000000',
                    titleFontFamily: 'Calibri, Arial',
                    titleFontSize: 12,
                    bodyFontFamily: 'Calibri, Arial',
                    bodyFontSize: 12,
                    itemSort: (a, b) => a.datasetIndex - b.datasetIndex,
                    callbacks: {
                        label: (tooltipItem, data) => {
                            let label = Math.round(tooltipItem.yLabel * 100) / 100;
                            if (isNaN(label)) label = 0;
                            if (tooltipItem.datasetIndex === 0) {
                                return intl.formatMessage({id: "fracDiesel.label", defaultMessage: "Frac Diesel"}) + ': ' + Formatting.numberWithCommas(label) + project.currentProject.uomString;
                            } else if (tooltipItem.datasetIndex === 1) {
                                return intl.formatMessage({id: "nonFracDiesel.label", defaultMessage: "Non-Frac Diesel"}) + ': ' + Formatting.numberWithCommas(label) + project.currentProject.uomString;
                            } else {
                                return label;
                            }
                        },
                        title: (tooltipItems, data) => tooltipItems[0].label
                    }
                },
                responsive: false,
                maintainAspectRatio: false
            }
            instance.chart.data = data;
            instance.chart.options = chartOptions;
            instance.chart.canvas.parentNode.style.height = '300px';
            instance.chart.update();
        });
    }

    return <Bar className="barChart"/>
    
}