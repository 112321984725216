import React, {useState} from "react";
import {Button, Tooltip} from "reactstrap";
import {Link} from "react-router-dom";
import InfoIcon from "../../../images/icons8-info-16.png";
import {FormattedMessage} from "react-intl";

export default function DocumentLine(props) {
    const [tooltipOpen, setTooltipOpen] = useState(false);

    const toggle = () => setTooltipOpen(!tooltipOpen);
    return (
        <>
            {props.doc.metadata.pdF_Status === 'Completed' &&
            (<tr>
                <td>{props.doc.metadata.linkFileName}</td>
                <td>{props.doc.metadata.hsE_Rep}</td>
                <td>{props.doc.metadata.dateVisitedString}</td>
                <td>
                    <a href={props.doc.driveItem.downloadLink} download>
                        <Button
                            color="btn btn-outline-light">
                            PDF
                        </Button>
                    </a>
                </td>
            </tr>)
            }
            {props.doc.metadata.pdF_Status === 'In Review' &&
            (<tr>
                <td>{props.doc.metadata.linkFileName}</td>
                <td>{props.doc.metadata.hsE_Rep}</td>
                <td>{props.doc.metadata.dateVisitedString}</td>
                <td>
                    <FormattedMessage
                        id="inSupervisorReview.label"
                        defaultMessage="In Supervisor Review"
                    />
                    <img src={InfoIcon} className="dashboard-info-icon" id={`infoIcon-stage-subscription`} alt="info" />
                    <Tooltip placement="right" isOpen={tooltipOpen} toggle={toggle} target={`infoIcon-stage-subscription`} >
                        <FormattedMessage
                            id="inReviewMessage.label"
                            defaultMessage="This document is not available for download as it is still under review."
                        />
                    </Tooltip>
                </td>
            </tr>)
            }
        </>
    );
}
