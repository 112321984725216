// x is in terms of SCF
export function toDGE(x) {
    var ngBTUValue = (global.ngBTUValue !== null) && (global.ngBTUValue !== undefined) ? global.ngBTUValue : global.ngBTUValueDefault
    var dieselBTUValue = (global.dieselBTUValue !== null) && (global.dieselBTUValue !== undefined) ? global.dieselBTUValue : global.dieselBTUValueDefault
    
    return x * ngBTUValue / dieselBTUValue
}

export function toDLE(x) {
    var dge = toDGE(x);
    
    return dge / 0.264172
}

export function toBTU(x) {
    var ngBTUValue = (global.ngBTUValue !== null) && (global.ngBTUValue !== undefined) ? global.ngBTUValue : global.ngBTUValueDefault
    
    return x * ngBTUValue
}

export function toCelsius(x) {
    return (x - 32) * 5 / 9
}