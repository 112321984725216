import {NetworkRequestStatus, GetUsersTypes, SubmitUserTypes, GetUserRoles} from "../enums";
import {date} from "yup";

//none means that a user can only view/manage an object's children, not the object itself.
//For example, if an energy Producer's permission is "none" for a user, that means that the user should be able to view only the projects
//for which they have a "manage" or "view" permission. 
export enum PermissionsEnum {
    View = "view",
    Manage = "manage",
    None = "none"
}

export enum SubscriptionTierEnum {
    Free = "free",
    Silver = "silver",
    Gold = "gold"
}

export enum TokenTypeEnum {
    Project = "Project",
    Company = "Company"
}

export enum TokenTierEnum {
    Free = "Free",
    Silver = "Silver",
    Gold = "Gold"
}

export interface ProjectPermission {
    name: string,
    permission: PermissionsEnum
}

export interface EnergyProducerPermission {
    name: string,
    permission: PermissionsEnum,
    projects: ProjectPermission[],
    tier: SubscriptionTierEnum,
    expiryDate: Date | null,
    role: string | null
}

export interface UserAccess {
    energyProducers: EnergyProducerPermission[]
}

export interface AppMetadata {
    access: UserAccess
}

export interface SubmitUser {
    email: string,
    app_metadata: AppMetadata,
    token: string
}

export interface User {
    email: string,
    app_metadata: AppMetadata
}

export interface Role {
    authRoleId: number,
    role: string
}

export interface IGetUsersResult {
    users: User[]
    status: NetworkRequestStatus
    error: string
}

export interface IGetUserRolesResult {
    roles: Role[],
    status: NetworkRequestStatus
    error: string
}

export interface ISubmittedUserResult {
    user: User,
    status: NetworkRequestStatus
    error: string
}

export interface IAddUserParams {
    EnergyProducerName: string,
    ProjectName: string,
    token: string
}

export interface IGetUsersInitialAction {
    type: GetUsersTypes
}

export interface IGettingUsersAction {
    type: GetUsersTypes
}

export interface IGettingUserRolesAction {
    type: GetUserRoles
}

export interface IGotUsersAction {
    type: GetUsersTypes
    users: User[]
}

export interface IGotUserRolesAction {
    type: GetUserRoles,
    roles: Role[]
}

export interface IGettingUsersErrorAction {
    type: GetUsersTypes
    error: string
}

export interface IGettingUserRolesErrorAction {
    type: GetUserRoles,
    error: string
}

export interface ISubmittingUserAction {
    type: SubmitUserTypes;
}

export interface ISubmittedUserAction {
    type: SubmitUserTypes
    user: User
}

export interface ISubmittedUserErrorAction {
    type: SubmitUserTypes
    error: string
}
  