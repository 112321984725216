import React, {Fragment, useEffect, useState} from 'react';
import {FormattedMessage} from "react-intl";
import _ from "lodash";
import ExportUnit from "./ExportUnit";
import GreenhouseGasChartContainer from "./GreenhouseGasChartContainer";
import GreenhouseGasSummary from "./GreenhouseGasSummary";

export default function GreenhouseGases(props)
{
    const [gasTotals, setGasTotals] = useState(null);

    useEffect(() => {
        let gasTotals = props.gasTotals;
        
        gasTotals = _.map(_.groupBy(gasTotals, 'date'), function (g) {
            return {
                date: g[0].date,
                totalDieselLitres: _.sumBy(g, 'totalDieselLitres'),
                totalNGLitres: _.sumBy(g, 'totalNGLitres'),
                totalNonFracDieselLitres: _.sumBy(g, 'totalNonFracDieselLitres'),
                totalDieselGallons: _.sumBy(g, 'totalDieselGallons'),
                totalNGGallons: _.sumBy(g, 'totalNGGallons'),
                totalNonFracDieselGallons: _.sumBy(g, 'totalNonFracDieselGallons'),
                totalConsumedLitres: _.sumBy(g, 'totalConsumedLitres'),
                totalConsumedGallons: _.sumBy(g, 'totalConsumedGallons'),
                totalBTU: _.sumBy(g, 'totalBTU'),
                totalSCF: _.sumBy(g, 'totalSCF'),
                totalMCF: _.sumBy(g, 'totalMCF'),
                totalDEC: _.sumBy(g, 'totalDEC'),
                
                // Double check, make sure this is the DGE values from the total SCF
                // co2Emission: Math.round((_.sumBy(g, 'totalNGGallons') ) * 0.002 , 2)

            //     public double Co2AvgEmissionOfPollutantsLbsPerHour => (0.00247 * 2250 * 18 * (0.39 * 19 + 0.15 * 5))/24;
            // public double Co2EmissionReduction => Math.Round((Co2AvgEmissionOfPollutantsLbsPerHour * (_totalMins / 60)) / 2200 , 2);
                
                co2eReductionPerDay: _.sumBy(g, 'co2eReductionPerDay')
            }
        });

        gasTotals = _.orderBy(gasTotals, ['date'], ['asc']);

        setGasTotals(gasTotals);
    }, []);
    
    
    return (
        <Fragment>
            {
                props.gasTotals !== null && gasTotals !== null &&
                <Fragment>
                    <div className="pumperTitle">
                        <FormattedMessage id="greenhouseGases.label" defaultMessage="Greenhouse Gases" />
                    </div>
                    <GreenhouseGasChartContainer  bifuel={props.bifuel} gasTotals={gasTotals} project={props.project} />
                    <GreenhouseGasSummary project={props.project} gasTotals={gasTotals} />
                </Fragment>
            }
        </Fragment>
    );
}
