import React, {Fragment, useEffect, useState} from 'react';
import DailyTotalsChartContainer from './DailyTotalsChartContainer';
import ExportUnit from './ExportUnit';
import DailyTotalsSummary from './DailyTotalsSummary';
import _ from 'lodash';
import {FormattedMessage} from "react-intl";

export default function DailyTotalStats(props) {

    const [dailyTotals, setDailyTotals] = useState(null);

    useEffect(() => {
        let tempDailyTotals = props.dailyTotals;
        // Group it like this:
        tempDailyTotals = _.map(_.groupBy(tempDailyTotals, 'date'), g => ({
            date: g[0].date,
            totalDieselLitres: _.sumBy(g, 'totalDieselLitres'),
            totalNGLitres: _.sumBy(g, 'totalNGLitres'),
            totalNonFracDieselLitres: _.sumBy(g, 'totalNonFracDieselLitres'),
            totalDieselGallons: _.sumBy(g, 'totalDieselGallons'),
            totalNGGallons: _.sumBy(g, 'totalNGGallons'),
            totalNonFracDieselGallons: _.sumBy(g, 'totalNonFracDieselGallons'),
            totalSCF: _.sumBy(g, 'totalSCF'),
            totalMCF: _.sumBy(g, 'totalMCF'),
            totalDEC: _.sumBy(g, 'totalDEC'),
            totalConsumedLitres: _.sumBy(g, 'totalConsumedLitres'),
            totalConsumedGallons: _.sumBy(g, 'totalConsumedGallons'),
            totalBTU: _.sumBy(g, 'totalBTU'),
            stagesPerDay: _.sumBy(g, 'stagesPerDay'),
            subRate: Math.round((_.sumBy(g, 'totalNGLitres') / (_.sumBy(g, 'totalNGLitres') + _.sumBy(g, 'totalDieselLitres'))) * 100, 2),
            lineTotalGallons: _.sumBy(g, 'lineTotalGallons'),
            lineTotalLitres: _.sumBy(g, 'lineTotalLitres'),
            dieselTotalizerGallons: _.sumBy(g, 'dieselTotalizerGallons'),
            dieselTotalizerLitres: _.sumBy(g, 'dieselTotalizerLitres'),
            dieselVarianceVolumeGallons: _.sumBy(g, 'dieselVarianceVolumeGallons'),
            dieselVarianceVolumeLitres: _.sumBy(g, 'dieselVarianceVolumeLitres'),
            dieselVariancePercentage: _.sumBy(g, 'dieselVariancePercentage')
        }));

        tempDailyTotals = _.orderBy(tempDailyTotals, ['date'], ['asc']);

        setDailyTotals(tempDailyTotals);
    }, []);


    return (
        <Fragment>
            {
                props.dailyTotals !== null && dailyTotals !== null &&
                <Fragment>
                    <div className="pumperTitle">
                        <FormattedMessage id="dailyTotalsConsumed.label" defaultMessage="Daily Totals Consumed"/>
                    </div>
                    {
                        props.project !== null &&
                        <ExportUnit project={props.project} token={props.token} type="dailyTotals"/>
                    }
                    <DailyTotalsChartContainer bifuel={props.bifuel} dailyTotals={dailyTotals} project={props.project} type="consumed"/>
                    <DailyTotalsSummary project={props.project} dailyTotals={dailyTotals}/>
                </Fragment>
            }
        </Fragment>
    )

}