import React, {Fragment, useContext, useEffect, useState} from 'react';
import {ProjectContext} from "../Contexts/ProjectContext";
import {FormattedMessage, useIntl} from "react-intl";
import ExportUnit from "../../ExportUnit";
import EnergyToggle from "../../EnergyToggle";
import {Col, Container, Row} from "reactstrap";
import PumperChart from "../../PumperChart";
import NoData from "../../NoData";
import PumperSummary from "../../PumperSummary";
import PumperStats from "../../PumperStats";

export default function PumpersPage(props) {
    const {project, setValue, setMobileText} = useContext(ProjectContext)
    const [pumperChartView, setPumperChartView] = useState('Diesel');
    const [showPumperSummary, setShowPumperSummary] = useState(true);
    const intl = useIntl();

    useEffect(() => {
        if (project.activePumper === "all") {
            setShowPumperSummary(true);
        } else {
            setShowPumperSummary(false);
        }
    }, [project.activePumper])
    
    useEffect(() => {
        // Leaving page, reset variables
        if (project.activePage !== "Pumpers") {
            setShowPumperSummary(true);
            setValue("activePumper", "all");
        }
    }, [project.activePage])
    
    useEffect(() => {
        if (project.currentProject !== null && project.currentProject.bifuel) {
            setPumperChartView('Substitution Rate');
        }
    }, [project.currentProject])

    const requestPumperStats = () => {
        setValue("activePumper", "all");
        setShowPumperSummary(true);
        setMobileText(intl.formatMessage({id: "allPumpers.label", defaultMessage: "All Pumpers"}));
    }

    const requestPumperStageStats = (pumper) => {
        setValue("activePumper", pumper);
        setShowPumperSummary(false);
        setMobileText(pumper.name);
    }

    const changePumperChart = (title) => {
        setPumperChartView(title);
    }

    return (
        <Fragment>
            {project.currentProject !== null && project.activePage === "Pumpers" &&
            <Fragment>
                {showPumperSummary &&
                <Fragment>
                    <div className="pumperTitle">
                        <FormattedMessage id="allPumpers.label" defaultMessage="All Pumpers"/>
                    </div>

                    <ExportUnit project={project.currentProject} token={project.token} type="allPumpers"/>

                    {project.currentProject.bifuel &&
                    <EnergyToggle onClick={changePumperChart} title={intl.formatMessage({
                        id: "consumptionType.label",
                        defaultMessage: "Consumption Type"
                    })}
                                  bifuel={project.currentProject.bifuel} subRate={true}
                                  activeTitle='Substitution Rate' view='Substitution Rate'/>
                    }
                    {!project.currentProject.bifuel &&
                    <EnergyToggle onClick={changePumperChart} title={intl.formatMessage({
                        id: "consumptionType.label",
                        defaultMessage: "Consumption Type"
                    })}
                                  bifuel={project.currentProject.bifuel} subRate={false}
                                  activeTitle='Diesel' view='Diesel'/>
                    }
                    <Container className="wellStatsContainer" fluid={true}>
                        <Row className="margin-top-20">
                            <Col xs="12" md="12" className="pumperChartContainer">
                                {project.currentProject.pumpers.length > 0 &&
                                <Fragment>
                                    {project.currentProject.bifuel &&
                                    <PumperChart project={project.currentProject}
                                                 view={pumperChartView}
                                                 activePumper={project.activePumper}/>
                                    }
                                    {!project.currentProject.bifuel &&
                                    <PumperChart project={project.currentProject}
                                                 view={pumperChartView}
                                                 activePumper={project.activePumper}/>
                                    }
                                </Fragment>
                                }
                                {project.currentProject.pumpers.length === 0 &&
                                <Container className="wellStatsContainer" fluid={true}>
                                    <Row className="wellRow">
                                        <Col xs="12" md="12" className="barChartContainer">
                                            <NoData/>
                                        </Col>
                                    </Row>
                                </Container>
                                }
                            </Col>
                        </Row>
                    </Container>
                    {project.currentProject.pumpers.length > 0 &&
                    <PumperSummary project={project.currentProject}
                                   onPumperClick={requestPumperStats}
                                   onPumperStageClick={requestPumperStageStats}
                                   showSubRate={props.showSubstitutionRate}
                                   export={props.showExportData} token={project.token} title="Lines"
                                   pumperSelected={project.activePumper}/>
                    }
                </Fragment>
                }
                {!showPumperSummary && props.showPumperStats && project.activePumper !== null && project.activePumper !== 'all' &&
                <PumperStats project={project.currentProject}
                             onBackClick={requestPumperStats}
                             onStageClick={requestPumperStageStats}
                             showSubRate={props.showSubstitutionRate}
                             pumper={project.activePumper}
                             token={project.token}/>
                }
            </Fragment>
            }
        </Fragment>
    )
}