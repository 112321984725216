import React, {useRef, useState} from "react";
import {SidebarData} from "./content/component/SidebarData";
import {FormattedMessage} from "react-intl";

export default function ApiSidebar(props) {

    function handleOnclick(index) {
        props.scrollProp(index)
    }
    
    
    return (
        <div className="api-sidebar" >
            <ul className={"nav-menu-items"}>
                {SidebarData.map((item, index) => {
                    return (
                        <li key={index} className={item.cName} onClick={() => handleOnclick(index)}>
                            <span>
                                <FormattedMessage id={item.title} defaultMessage=""/>
                            </span>
                        </li>
                    )
                })}
                
            </ul>
        </div>
    )
}