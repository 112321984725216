import React from "react";


export const SidebarData = [
    {
        title : 'intro.header',
        cName : 'sidebar-text'
    },
    {
        title : 'auth.header',
        cName : 'sidebar-text'
    },
    {
        title : 'error.header',
        cName : 'sidebar-text'
    },
    {
        title : 'FreeTier.header',
        cName : 'sidebar-text',
    },
    {
        title : 'graphql.header',
        cName : 'sidebar-text'
    }
    
    
    
    
    
    
]


















