/** 
 * Format the number as a string with thousands separators, rounded to (default 2) decimal places.
 * @param {number|Number|string} x - the value to format
 * @param {number|false|undefined} [decimalPlaces=2] - how many decimal places to show.
 * (Integers will only display decimal parts when decimalPlaces is explicity provided.)
 */
export function numberWithCommas(x, decimalPlaces) {
    if (x === null || x === undefined) return x;

    if (decimalPlaces === undefined || typeof decimalPlaces === 'number') {
        if (typeof x === 'string') x = parseFloat(x);
        if (typeof x === 'number' || x instanceof Number) {
            x = decimalPlaces === undefined 
                ? (Number.isInteger(x) ? x.toString() : x.toFixed(2))
                : x.toFixed(decimalPlaces);
        } 
    }

    var parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
}

export function numberWithTwoSigDigs(x) {
    let y = x.split(' ');
    
    if (y[0] !== undefined && y[0] !== null && y[0] > 1000000)
    {
        y[0] = y[0]/1000000;
        y[0] = y[0].toFixed(2);
        return y[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' MM';
    } else if (y[0] !== undefined && y[0] !== null && y[0] > 1000000000) {
        y[0] = y[0]/1000000000;
        y[0] = y[0].toFixed(2);
        return y[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' BN';
    } else {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
}

export function numberWithCommasMillions(x) {
    if (isNaN(x))
    {
        let y = x.split(' ');
        if (y[0] !== undefined && y[0] !== null && y[0] > 1000000)
        {
            y[0] = y[0]/1000000;
            y[0] = y[0].toFixed(2);
            return y[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + 'MM ' + y[1];
        }else{
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
    }else if (!isNaN(x))
    {
        x = x/1000000;
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + 'MM';
    }
    else
    {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
}