import fetchWithTimeout from '../helpers/fetchWithTimeout';
const requestCompaniesType = 'REQUEST_COMPANIES';
const receiveCompaniesType = 'RECEIVE_COMPANIES';
const requestCompaniesError = 'COMPANIES_ERROR';
const UnauthorizedError = "UNAUTHORIZED";

const initialState = { companies: [], isLoading: false };
export const actionCreators = {
  requestCompanies: (token) => async (dispatch, getState) => {
      dispatch({ type: requestCompaniesType });

      const url = global.apiEndpoint + 'company/all';
      if (global.apiEndpoint === undefined)
      {
        window.location.replace('/');
      }
        const requestOptions = { method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`
        }
      };
      const response = await fetchWithTimeout(url, requestOptions);
      if(response.status === 401 || response.status === 403){
        dispatch({type: UnauthorizedError})
      }
    if (response.status !== 200)
      {
        dispatch({type: requestCompaniesError });
      }
      try{
          const companies = await response.json(); 
          dispatch({ type: receiveCompaniesType, companies });
      }
      catch(err){
        dispatch({type: requestCompaniesError });
      }
      }
};

export const reducer = (state, action) => {
  state = state || initialState;

  if (action.type === requestCompaniesType) {
    return {
      ...state,
      companies: [],
      isLoading: true,
      isError: false
    };
  }

  if (action.type === receiveCompaniesType) {
    return {
      ...state,
      companies: action.companies,
      isLoading: false,
      isError: false
    };
  }

  if (action.type === requestCompaniesError)
  {
    return {
      ...state,
      isError: true,
      isLoading: false
    }
  }
  if (action.type === UnauthorizedError)
  {
    return {
      ...state,
      isError: true,
      isLoading: false,
      isUnauthorized:true
    }
  }

  return state;
};
