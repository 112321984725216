import React, {useEffect, useState} from 'react';
import {Bar, Chart} from 'react-chartjs-2';
import * as Formatting from '../helpers/formatting';
import {useIntl} from 'react-intl';
import {NaturalGasUnit} from "../enums";

// flow: component is mounted/props is changed -> data is set -> render component
export default function WellChart(props) {
    const [data, setData] = useState(null) // data is set to null initially
    const intl = useIntl();
    
    // data is set when this component is mounted (first time visit to well info) or when props is changed (going from well info to well info, component if not unmounted, have to check change in props)
    useEffect(() => {

        const dataSets = [
            {
                label: props.type === 'productivity' ? intl.formatMessage({
                    id: "totalHours.label",
                    defaultMessage: "Total Hours"
                }) : intl.formatMessage({id: "totalDiesel.label", defaultMessage: "Total Diesel"}),
                data: props.type === 'productivity' ? Object.keys(props.well.stages).map(i => props.well.stages[i].totalHours) : Object.keys(props.well.stages).map(i => props.well.stages[i].totalPumped),
                fill: true,
                yAxisID: 'y-axis-1',
                lineTension: 0.1,
                backgroundColor: props.type === 'productivity' ? '#4D6969' : '#5F8BFF',
                borderColor: 'rgba(75,192,192,1)',
                borderCapStyle: 'butt',
                borderDash: [],
                borderDashOffset: 0.0,
                borderJoinStyle: 'miter',
                pointBorderColor: 'rgba(75,192,192,1)',
                pointBackgroundColor: '#fff',
                pointBorderWidth: 1,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: 'rgba(75,192,192,1)',
                pointHoverBorderColor: 'rgba(220,220,220,1)',
                pointHoverBorderWidth: 2,
                pointRadius: 5,
                pointHitRadius: 10,
                type: 'bar'
            }];

        if ((props.bifuel && props.type === 'consumption') || (props.type === 'productivity')) {
            dataSets.unshift(
                {
                    label: props.type === 'productivity' ? intl.formatMessage({
                        id: "pumpTime.label",
                        defaultMessage: "Pump Time"
                    }) + ' %' : intl.formatMessage({
                        id: "substitutionRate.label",
                        defaultMessage: "Substitution Rate"
                    }) + ' %',
                    data: props.type === 'productivity' ? Object.keys(props.well.stages).map(i => props.well.stages[i].productivity) : (props.bifuel === true ? Object.keys(props.well.stages).map(i => props.well.stages[i].substitutionRate) : null),
                    fill: false,
                    yAxisID: 'y-axis-0',
                    lineTension: 0.1,
                    backgroundColor: '#FFC72F',
                    borderColor: '#EDBC23',
                    borderCapStyle: 'butt',
                    borderDash: [],
                    borderDashOffset: 0.0,
                    borderJoinStyle: 'miter',
                    pointBorderColor: '#EDBC23',
                    pointBackgroundColor: '#EDBC23',
                    pointBorderWidth: 1,
                    pointHoverRadius: 1,
                    pointHoverBackgroundColor: 'rgba(75,192,192,1)',
                    pointHoverBorderColor: 'rgba(220,220,220,1)',
                    pointHoverBorderWidth: 1,
                    pointRadius: 1,
                    pointHitRadius: 5,
                    type: 'line'
                },
                {
                    label: props.type === 'productivity' ? intl.formatMessage({
                        id: "activeHours.label",
                        defaultMessage: "Active Hours"
                    }) : intl.formatMessage({id: "naturalGas.label", defaultMessage: "Natural Gas"}),
                    data: props.type === 'productivity' ? Object.keys(props.well.stages).map(i => props.well.stages[i].activeHours) : (props.bifuel === true ? Object.keys(props.well.stages).map(i => props.well.unitOfMeasure === "L" ? props.well.stages[i].totalDE : props.well.stages[i].totalDEGallons) : null),
                    fill: true,
                    yAxisID: 'y-axis-1',
                    lineTension: 0.1,
                    backgroundColor: '#66B47D',
                    borderColor: 'rgba(75,192,192,1)',
                    borderCapStyle: 'butt',
                    borderDash: [],
                    borderDashOffset: 0.0,
                    borderJoinStyle: 'miter',
                    pointBorderColor: 'rgba(75,192,192,1)',
                    pointBackgroundColor: '#fff',
                    pointBorderWidth: 1,
                    pointHoverRadius: 5,
                    pointHoverBackgroundColor: 'rgba(75,192,192,1)',
                    pointHoverBorderColor: 'rgba(220,220,220,1)',
                    pointHoverBorderWidth: 2,
                    pointRadius: 5,
                    pointHitRadius: 10,
                    type: 'bar'
                });
        }
        if ((props.bifuel && props.type === 'consumption')) {
            dataSets.push(
                {
                    label: intl.formatMessage({id: "fracDiesel.label", defaultMessage: "Frac Diesel"}),
                    data: Object.keys(props.well.stages).map(i => props.well.unitOfMeasure === "L" ? props.well.stages[i].dieselPumpedLitres : props.well.stages[i].dieselPumped),
                    fill: false,
                    yAxisID: 'y-axis-1',
                    lineTension: 0.1,
                    backgroundColor: '#8ad9e8',
                    borderColor: 'rgba(75,192,192,1)',
                    borderCapStyle: 'butt',
                    borderDash: [],
                    borderDashOffset: 0.0,
                    borderJoinStyle: 'miter',
                    pointBorderColor: 'rgba(75,192,192,1)',
                    pointBackgroundColor: '#fff',
                    pointBorderWidth: 1,
                    pointHoverRadius: 5,
                    pointHoverBackgroundColor: 'rgba(75,192,192,1)',
                    pointHoverBorderColor: 'rgba(220,220,220,1)',
                    pointHoverBorderWidth: 2,
                    pointRadius: 5,
                    pointHitRadius: 10,
                    type: 'bar'
                },
                {
                    label: intl.formatMessage({id: "nonFracDiesel.label", defaultMessage: "Non-Frac Diesel"}),
                    data: Object.keys(props.well.stages).map(i => props.well.stages[i].nonFracDieselPumped),
                    fill: false,
                    yAxisID: 'y-axis-1',
                    lineTension: 0.1,
                    backgroundColor: '#dae88a',
                    borderColor: 'rgba(75,192,192,1)',
                    borderCapStyle: 'butt',
                    borderDash: [],
                    borderDashOffset: 0.0,
                    borderJoinStyle: 'miter',
                    pointBorderColor: 'rgba(75,192,192,1)',
                    pointBackgroundColor: '#fff',
                    pointBorderWidth: 1,
                    pointHoverRadius: 5,
                    pointHoverBackgroundColor: 'rgba(75,192,192,1)',
                    pointHoverBorderColor: 'rgba(220,220,220,1)',
                    pointHoverBorderWidth: 2,
                    pointRadius: 5,
                    pointHitRadius: 10,
                    type: 'bar'
                });
        }


        setData({
            labels: Object.keys(props.well.stages).map(i => props.well.stages[i].stageNumber),
            datasets: dataSets
        })
    }, [props])

    // called when after data is updated
    useEffect(() => {
        Chart.helpers.each(Chart.instances, function (instance) {

            let xLabel = intl.formatMessage({id: "hours.label", defaultMessage: "Hours"});
            if (props.type !== 'productivity') {
                xLabel = intl.formatMessage({
                    id: "consumption.label",
                    defaultMessage: "Consumption"
                }) + '  (' + props.well.unitOfMeasure + ')';
            }
            if (instance.canvas.id === props.type + '_chart') {
                const chartOptions = {
                    scales: {
                        xAxes: [{
                            stacked: false,
                            scaleLabel: {
                                display: true,
                                labelString: intl.formatMessage({id: "stage.label", defaultMessage: "Stage"})
                            }
                        }],
                        yAxes: [
                            {
                                type: 'linear',
                                display: false,
                                position: 'right',
                                id: 'y-axis-0',
                                gridLines: {
                                    display: false
                                },
                                labels: {
                                    show: false
                                },
                                ticks: {
                                    beginAtZero: true
                                }
                            },
                            {
                                type: 'linear',
                                display: true,
                                position: 'left',
                                id: 'y-axis-1',
                                gridLines: {
                                    display: false
                                },
                                labels: {
                                    show: false
                                },
                                ticks: {
                                    beginAtZero: true
                                },
                                scaleLabel: {
                                    display: true,
                                    labelString: xLabel
                                }
                            }
                        ]
                    },
                    legend:
                        {
                            display: true,
                            position: 'bottom',
                            labels: {
                                filter: function (item, chart) {
                                    return !item.text.includes('hide');
                                }
                            },
                            onClick: (e) => e.stopPropagation()
                        },
                    tooltips: {
                        mode: 'index',
                        intersect: false,
                        position: 'nearest',
                        backgroundColor: '#ffffff',
                        titleFontColor: '#000000',
                        bodyFontColor: '#000000',
                        titleFontFamily: 'Calibri, Arial',
                        titleFontSize: 12,
                        bodyFontFamily: 'Calibri, Arial',
                        bodyFontSize: 12,
                        itemSort: function (a, b) {
                            if (a.datasetIndex === 0 && b.datasetIndex === 1) {
                                return 2;
                            } else if (a.datasetIndex === 0 && b.datasetIndex === 2) {
                                return 1;
                            } else if (a.datasetIndex === 1 && b.datasetIndex === 2) {
                                return 0;
                            }
                        },
                        callbacks: {
                            label: function (tooltipItem, data) {
                                var label;
                                if (tooltipItem.datasetIndex === 2 && (props.bifuel === false || props.type === 'productivity')) {
                                    label = Math.round(tooltipItem.yLabel * 100) / 100;
                                    return label + (props.type === 'productivity' ? ' ' + intl.formatMessage({
                                        id: "totalHours.label",
                                        defaultMessage: "Total Hours"
                                    }) : ' D' + props.well.unitOfMeasureShort + 'E ' + intl.formatMessage({
                                        id: "totalPumped.label",
                                        defaultMessage: "Total Pumped"
                                    }));
                                } else if (tooltipItem.datasetIndex === 0) {
                                    label = Math.round(tooltipItem.yLabel * 100) / 100;
                                    return label + (props.type === 'productivity' ? '% ' + intl.formatMessage({
                                        id: "pumpTime.label",
                                        defaultMessage: "Pump Time"
                                    }) : (props.bifuel === true ? '% ' + intl.formatMessage({
                                        id: "substitution.label",
                                        defaultMessage: "Substitution"
                                    }) : ' ' + props.well.unitOfMeasure + ' ' + intl.formatMessage({
                                        id: "diesel.label",
                                        defaultMessage: "Diesel"
                                    })));
                                } else if (tooltipItem.datasetIndex === 1) {
                                    label = Math.round(tooltipItem.yLabel * 100) / 100;
                                    if (props.type === 'productivity') {
                                        return label + ' ' + intl.formatMessage({
                                            id: "activeHours.label",
                                            defaultMessage: "Active Hours"
                                        });
                                    } else {
                                        switch (props.naturalGasUnit) {
                                            case NaturalGasUnit.E3M3:
                                                return (Formatting.numberWithCommas(props.well.stages[tooltipItem.index].totalDEC, 4) + ' E3M3 ' + intl.formatMessage({
                                                    id: "naturalGas.label",
                                                    defaultMessage: "Natural Gas"
                                                }));
                                            case NaturalGasUnit.MCF:
                                                return (Formatting.numberWithCommas(props.well.stages[tooltipItem.index].totalMCF) + ' MCF ' + intl.formatMessage({
                                                    id: "naturalGas.label",
                                                    defaultMessage: "Natural Gas"
                                                }));
                                            case NaturalGasUnit.SCF:
                                            default:
                                                return (Formatting.numberWithCommas(props.well.stages[tooltipItem.index].totalSCF) + ' SCF ' + intl.formatMessage({
                                                    id: "naturalGas.label",
                                                    defaultMessage: "Natural Gas"
                                                }));
                                        }
                                    }
                                } else if (tooltipItem.datasetIndex === 3) {
                                    label = Math.round(tooltipItem.yLabel * 100) / 100;
                                    return label + ' ' + props.well.unitOfMeasure + ' ' + intl.formatMessage({
                                        id: "dieselFrac.label",
                                        defaultMessage: "Diesel (Frac)"
                                    })
                                } else if (tooltipItem.datasetIndex === 4) {
                                    label = Math.round(tooltipItem.yLabel * 100) / 100;
                                    return label + ' ' + props.well.unitOfMeasure + ' ' + intl.formatMessage({
                                        id: "dieselNonFrac.label",
                                        defaultMessage: "Diesel (Non-Frac)"
                                    })
                                }
                            },
                            afterLabel: function (tooltipItem, data) {
                                if (tooltipItem.datasetIndex === 1 && props.type === 'consumption') {
                                    return '(' + (props.well.unitOfMeasure === "L" ? props.well.stages[tooltipItem.index].totalDE : props.well.stages[tooltipItem.index].totalDEGallons) + ' D' + props.well.unitOfMeasureShort + 'E)';
                                }
                                if (tooltipItem.datasetIndex === 0) {
                                    return intl.formatMessage({
                                        id: "start.label",
                                        defaultMessage: "Start"
                                    }) + "/" + intl.formatMessage({
                                        id: "end.label",
                                        defaultMessage: "End"
                                    }) + ": " + props.well.stages[tooltipItem.index].startTimeString + ' / ' + props.well.stages[tooltipItem.index].endTimeString;
                                }
                            },
                            title: function (tooltipItems, data) {
                                var label = tooltipItems[0].label;
                                return intl.formatMessage({id: "stage.label", defaultMessage: "Stage"}) + ' ' + label;
                            }
                        }
                    },
                    responsive: false,
                    maintainAspectRatio: false
                }

                instance.chart.data = data;
                instance.chart.options = chartOptions;
                instance.chart.canvas.parentNode.style.height = '300px';
                instance.chart.update();

            }
        })
    }, [data])

    // render bar chart when there is data
    return data && (<Bar className="barChart" id={props.type + '_chart'}/>)
}