import React, { useEffect,Fragment } from "react";
import { Route } from "react-router-dom";
import { useAuth0 } from "../auth/auth0-wrapper";

const PrivateRoute = ({ component: Component, provider: Provider, data, path, ...rest }) => {
  const { loading, isAuthenticated, loginWithRedirect } = useAuth0();

  useEffect(() => {
    if (loading || isAuthenticated) {
      return;
    }
    const fn = async () => {
      await loginWithRedirect({
        appState: { targetUrl: rest.location.pathname }
      });
    };
    fn();
  }, [loading, isAuthenticated, loginWithRedirect, path]);

  const render = (props) => {
    if (isAuthenticated === true && Provider !== undefined && data !== undefined) {
      return (
          <Provider page={data}>
            <Component {...props} />
          </Provider>
      )
    } else if (isAuthenticated === true && Provider === undefined) {
      return (
          <Component {...props} />
      )
    }
    return null;
  }

  return <Route path={path} render={render} {...rest} />;
};

export default PrivateRoute;