import React from 'react';
import {FormattedMessage} from "react-intl";
import * as Formatting from "../helpers/formatting";

export default function DashboardEnergyConsumedElement(props) {
    return (
        <div className="dashboard-column-view dashboard-consumed-element">
            <div className="dashboard-multi-title">
                <FormattedMessage id="totalEnergyConsumed.label" defaultMessage="Total Consumed Energy"/>
            </div>
            <div className="dashboard-multi-secondary-title">
                <FormattedMessage id="averagePerStage.label" defaultMessage="Average Per Stage"/>
            </div>
            
            <div className="dashboard-row-view">
                <div className="dashboard-column-view">
                    <div className="dashboard-multi-value">
                        {Formatting.numberWithCommas(props.project.totalConsumedWithNonFracDiesel)} D{props.project.uomStringShort}E
                    </div>
                    <div className="dashboard-multi-value-small">
                        <FormattedMessage id="includesNonFracDiesel.label" defaultMessage="Includes Non-Frac Diesel"/>
                    </div>
                </div>
                <div className="dashboard-multi-secondary-value-container">
                    <div className="dashboard-multi-secondary-value">
                        {Formatting.numberWithCommas(props.project.avgConsumedWithNonFracDieselPerStage)} D{props.project.uomStringShort}E
                    </div>
                </div>
            </div>
            
            {props.bifuel &&
                <div className="dashboard-row-view">
                    <div className="dashboard-column-view">
                        <div className="dashboard-multi-value">
                            {Formatting.numberWithCommas(props.project.totalConsumed)} D{props.project.uomStringShort}E
                        </div>
                        <div className="dashboard-multi-value-small">
                            <FormattedMessage id="notIncludingNonFracDiesel.label" defaultMessage="Not including Non-Frac Diesel"/>
                        </div>
                    </div>
                    {props.project.avgConsumedPerStage !== 'NaN' &&
                        <div className="dashboard-multi-secondary-value">
                            {Formatting.numberWithCommas(props.project.avgConsumedPerStage)} D{props.project.uomStringShort}E
                        </div>
                    }
                </div>
            }
        </div>
    )
}