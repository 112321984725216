import React, {useContext, useEffect, useState} from 'react';
import {FormattedMessage, useIntl} from "react-intl";
import {ProjectMenuContext} from "../Contexts/ProjectMenuContext";
import {ReactComponent as WhiteUp} from "../../../images/whiteUp.svg";
import {ReactComponent as WhiteDown} from "../../../images/whiteDown.svg";
import {ReactComponent as BlackRight} from "../../../images/arrowRightBlack.svg";
import {ProjectContext} from "../Contexts/ProjectContext";
import Collapsible from "react-collapsible";
import {categoryEnum, subCategoryEnum} from "../../../enums/SiteDocumentsEnums";

export default function SiteDocuments(props) {
    const {closeMobileMenu} = useContext(ProjectMenuContext);
    const {
        project,
        setActivePage,
        setActiveSubPage,
        setActiveSubCategoryPage,
        setMobileText
    } = useContext(ProjectContext);
    const [showSiteDocumentsDropdown, setShowSiteDocumentsDropdown] = useState(false);
    const [showSubCategoryDropdown, setShowSubCategoryDropdown] = useState(false);
    const intl = useIntl();

    useEffect(() => {
        if (project.activePage !== "SiteDocuments") {
            setShowSiteDocumentsDropdown(false);
        }
    }, [project.activePage])

    const activePage = () => {
        return project.activePage === "SiteDocuments" ? 'siteTitle siteTitleActive menuItem menuItemActive' : 'siteTitle menuItem'
    }

    const toggleSiteDocumentsDropdown = () => {
        switch (project.activePage) {
            case "SiteDocuments":
                setShowSiteDocumentsDropdown(!showSiteDocumentsDropdown);
                break;
            default:
                setActivePage("SiteDocuments");
                onCategoryChange(categoryEnum.HSE);
                setShowSiteDocumentsDropdown(true);
        }
    }

    const onCategoryChange = (activeCategory) => {
        switch (activeCategory === project.activeSubPage) {
            case true:
                setShowSubCategoryDropdown(!showSubCategoryDropdown);
                break;
            default:
                setActiveSubPage(activeCategory);
                switch (activeCategory) {
                    case activeCategory === categoryEnum.HSE:
                    default:
                        requestSubCategory(activeCategory, subCategoryEnum.HSE.SiteVisits);
                        break;
                }

                setShowSubCategoryDropdown(true);
                break;
        }
    }

    const requestSubCategory = (activeCategory, activeSubCategory) => {

        setActiveSubCategoryPage(activeSubCategory);
        
        // setMobileText(intl.formatMessage({id: `${categoryEnum.keys[activeCategory]}${subCategoryEnum[activeCategory].keys[activeSubCategory]}.label`, 
        //                                            defaultMessage:`${categoryEnum.keys[activeCategory]} ${subCategoryEnum[activeCategory].keys[activeSubCategory]}`}));

        if (project.activePage !== "SiteDocuments") {
            setActivePage("SiteDocuments");
        } else {
            closeMobileMenu();
        }

        // // Google Analytics
        // ReactGA.event({
        //     category: "Site Documents Selection",
        //     action: `Clicked on "Site Documents: ${Object.keys(categoryEnum)[activeCategory]}/${activeSubCategory}" on Project "${window.location.pathname}"`
        // })
        //
        // // Google Tag Manager
        // window.dataLayer.push({
        //     'event': "Pageview",
        //     'pagePath': `${window.location.pathname}/site-documents/${Object.keys(categoryEnum)[activeCategory]}/${activeSubCategory}`,
        //     'pageTitle': `Site Documents - ${Object.keys(categoryEnum)[activeCategory]} - ${activeSubCategory}`
        // })
    }

    return (
        <>
            {/*{props.mobile &&*/}
            {/*    <div className="entireSiteMobile" onClick={() => toggleSiteDocumentsDropdown()}>*/}
            {/*        <div className="mobileText">*/}
            {/*            <FormattedMessage id="siteDocuments.label" defaultMessage="Site Documents"/>*/}
            {/*        </div>*/}
            {/*        <div className="floatRight mobileArrow"><BlackRight/></div>*/}
            {/*    </div>*/}
            {/*}*/}

            {!props.mobile &&
            <div>
                <h1 className={activePage()} onClick={() => toggleSiteDocumentsDropdown()}>
                    <FormattedMessage id="siteDocuments.label" defaultMessage="Site Documents"/>
                    {showSiteDocumentsDropdown && <WhiteUp className="floatRight"/>}
                    {!showSiteDocumentsDropdown && <WhiteDown className="floatRight"/>}
                </h1>
                <Collapsible open={showSiteDocumentsDropdown} >
                    <div>
                        <div
                            className={project.activeSubPage === categoryEnum.HSE ? 'wellTitle wellTitleActive subMenuItem subMenuItemActive' : 'wellTitle subMenuItem'}
                            onClick={() => onCategoryChange(categoryEnum.HSE)}>
                            <FormattedMessage id="hse.label" defaultMessage="HSE"/>
                        </div>
                        <Collapsible open={showSubCategoryDropdown && project.activeSubPage === categoryEnum.HSE}>
                            <div
                                className={project.activeSubCategoryPage === subCategoryEnum.HSE.SiteVisits ? 'wellTitle wellTitleActive subCategoryMenuItem subCategoryMenuItemActive' : 'wellTitle subCategoryMenuItem'}
                                onClick={() => requestSubCategory(categoryEnum.HSE, subCategoryEnum.HSE.SiteVisits)}>
                                <FormattedMessage id="hseSiteVisits.label" defaultMessage="HSE Site Visits"/>
                            </div>
                        </Collapsible>
                    </div>
                </Collapsible>
            </div>
            }
        </>
    )
}