import React, {useState} from "react";
import {FormattedMessage} from "react-intl";

export default function Introduction(props) {
    const [show, setShow] = useState(false);

    return (
        <div className="api-content-container" id="Authentication" ref={props.refProp}>
            <div className="api-section-details half-section">
                <h1 className="api-section-header">
                    <FormattedMessage id="error.header" defaultMessage="Errors"/>
                </h1>
                <p>
                    <FormattedMessage id="error.message1"
                                      defaultMessage="Energera uses conventional HTTP response codes to indicate the success or failure of an API request. "/>
                </p>
                <p>
                    <FormattedMessage id="error.message2"
                                      defaultMessage="Codes in the 2xx range indicate success. Codes in the 4xx range indicate an error that failed given the information provided (e.g., a required parameter was omitted, a charge failed, etc.). Codes in the 5xx range indicate an error with Energera's servers."/>
                </p>

            </div>
            <div className="api-section-code half-section">
                <div className="api-section-code-topbar">
                    <FormattedMessage id="error.httpCode.topbar" defaultMessage="HTTP STATUS CODE SUMMARY"/>
                </div>
                <div className="api-section-code-bottom" >
                    <div style={{display: "flex"}}>
                        <div className={"third-section api-section-httpCode"}>
                            <FormattedMessage id="error.httpCode.code1" defaultMessage="200 - OK"/>
                        </div>
                        <div className={"two-third-section api-section-httpCode-description"}>
                            <FormattedMessage id="error.httpCode.description1" defaultMessage="Everything worked as expected."/>
                        </div>
                    </div>
                    <div style={{display: "flex"}}>
                        <div className={"third-section api-section-httpCode"}>
                            <FormattedMessage id="error.httpCode.code2" defaultMessage="204 - No Content"/>
                        </div>
                        <div className={"two-third-section api-section-httpCode-description"}>
                            <FormattedMessage id="error.httpCode.description2" defaultMessage="The API was successful but returned no results."/>
                        </div>
                    </div>
                    <div style={{display: "flex"}}>
                        <div className={"third-section api-section-httpCode"}>
                            <FormattedMessage id="error.httpCode.code3" defaultMessage="400 - Bad Request"/>
                        </div>
                        <div className={"two-third-section api-section-httpCode-description"}>
                            <FormattedMessage id="error.httpCode.description3" defaultMessage="The request was unacceptable, often due to missing a required parameter."/>
                        </div>
                    </div>
                    <div style={{display: "flex"}}>
                        <div className={"third-section api-section-httpCode"}>
                            <FormattedMessage id="error.httpCode.code4" defaultMessage="401 - Unauthorized"/>
                        </div>
                        <div className={"two-third-section api-section-httpCode-description"}>
                            <FormattedMessage id="error.httpCode.description4" defaultMessage="No valid API key provided."/>
                        </div>
                    </div>
                    <div style={{display: "flex"}}>
                        <div className={"third-section api-section-httpCode"}>
                            <FormattedMessage id="error.httpCode.code5" defaultMessage="402 - Request Failed"/>
                        </div>
                        <div className={"two-third-section api-section-httpCode-description"}>
                            <FormattedMessage id="error.httpCode.description5" defaultMessage="The parameters were valid but the request failed."/>
                        </div>
                    </div>
                    <div style={{display: "flex"}}>
                        <div className={"third-section api-section-httpCode"}>
                            <FormattedMessage id="error.httpCode.code6" defaultMessage="403 - Forbidden"/>
                        </div>
                        <div className={"two-third-section api-section-httpCode-description"}>
                            <FormattedMessage id="error.httpCode.description6" defaultMessage="The API key doesn't have permissions to perform the request."/>
                        </div>
                    </div>
                    <div style={{display: "flex"}}>
                        <div className={"third-section api-section-httpCode"}>
                            <FormattedMessage id="error.httpCode.code7" defaultMessage="404 - Not Found"/>
                        </div>
                        <div className={"two-third-section api-section-httpCode-description"}>
                            <FormattedMessage id="error.httpCode.description7" defaultMessage="The requested resource doesn't exist."/>
                        </div>
                    </div>
                    <div style={{display: "flex"}}>
                        <div className={"third-section api-section-httpCode"}>
                            <FormattedMessage id="error.httpCode.code8" defaultMessage="409 - Conflict"/>
                        </div>
                        <div className={"two-third-section api-section-httpCode-description"}>
                            <FormattedMessage id="error.httpCode.description8" defaultMessage="The request conflicts with another request (perhaps due to using the same idempotent key)."/>
                        </div>
                    </div>
                    <div style={{display: "flex"}}>
                        <div className={"third-section api-section-httpCode"}>
                            <FormattedMessage id="error.httpCode.code9" defaultMessage="429 - Too Many Requests"/>
                        </div>
                        <div className={"two-third-section api-section-httpCode-description"}>
                            <FormattedMessage id="error.httpCode.description9" defaultMessage="Too many requests hit the API too quickly. We recommend an exponential backoff of your requests."/>
                        </div>
                    </div>
                    <div style={{display: "flex"}}>
                        <div className={"third-section api-section-httpCode"}>
                            <FormattedMessage id="error.httpCode.code10" defaultMessage="500, 502, 503, 504 - Server Errors"/>
                        </div>
                        <div className={"two-third-section api-section-httpCode-description"}>
                            <FormattedMessage id="error.httpCode.description10" defaultMessage="Something went wrong on Energera's end."/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}