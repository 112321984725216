import React, {Fragment, useContext, useEffect, useState} from 'react';
import {FormattedMessage, useIntl} from "react-intl";
import {ProjectMenuContext} from "../Contexts/ProjectMenuContext";
import ReactGA from "react-ga";
import {ReactComponent as BlackRight} from "../../../images/arrowRightBlack.svg";
import PumperList from "../../PumperList";
import {ProjectContext} from "../Contexts/ProjectContext";

export default function Pumpers(props) {
    const {closeMobileMenu} = useContext(ProjectMenuContext)
    const {project, setActivePage, setValue, setMobileText} = useContext(ProjectContext);
    const [showPumperList, setShowPumperList] = useState(false);
    const intl = useIntl();

    const activePage = () => {
        return project.activePage === "Pumpers" ? 'siteTitle siteTitleActive menuItem menuItemActive' : 'siteTitle menuItem'
    }

    const togglePumperListDropdown = () => {
        switch (project.activePage) {
            case "Pumpers":
                setShowPumperList(!showPumperList);
                break;
            default:
                requestPumperStatsSummary();
                setShowPumperList(true);
        }
    }

    const requestPumperStatsSummary = () => {
        setValue("activePumper", "all");
        setMobileText(intl.formatMessage({id: "allPumpers.label", defaultMessage: "All Pumpers"}));

        if (project.activePage !== "Pumpers") {
            setActivePage("Pumpers");
        } else {
            closeMobileMenu();
        }

        // Google Analytics
        ReactGA.event({
            category: "Project Menu Selection",
            action: `Clicked on "All Pumpers" on Project "${window.location.pathname}"`
        })

        // Google Tag Manager
        window.dataLayer.push({
            'event': "Pageview",
            'pagePath': `${window.location.pathname}/pumpers/all-pumpers`,
            'pageTitle': `All Pumpers`
        })
    }

    const requestPumperStats = (pumper) => {
        setValue("activePumper", pumper);
        setMobileText(pumper.name);
        closeMobileMenu();
        
        // Google Analytics
        ReactGA.event({
            category: "Project Menu Selection",
            action: `Clicked on "Pumper ${pumper.name}" on Project "${window.location.pathname}"`
        })

        // Google Tag Manager
        window.dataLayer.push({
            'event': "Pageview",
            'pagePath': `${window.location.pathname}/pumpers/${pumper.name}}`,
            'pageTitle': `Pumper ${pumper.name}`
        })
    }

    return (
        <Fragment>
            {(props.pumperStats === 'true' || props.pumperStats === true) &&
            <Fragment>
                {!props.mobile &&
                <h1 onClick={() => requestPumperStatsSummary()} className={activePage()}>
                    <FormattedMessage id="pumpers.label" defaultMessage="Pumpers"/>
                </h1>
                }
                {props.mobile &&
                <Fragment>
                    <div className="entireSiteMobile" onClick={() => togglePumperListDropdown()}>
                        <div className="mobileText">
                            <FormattedMessage id="pumpers.label" defaultMessage="Pumpers"/>
                        </div>
                        <div className="floatRight mobileArrow"><BlackRight/></div>
                    </div>
                    {showPumperList &&
                    <PumperList pumpers={project.currentProject.pumpers} onClick={requestPumperStats}
                                onSummaryClick={requestPumperStatsSummary} activePumper={project.activePumper}/>
                    }

                </Fragment>
                }
            </Fragment>
            }
        </Fragment>
    )
}