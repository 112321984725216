import React, {useEffect, useState, Fragment} from 'react'
import './UnitsOfMeasureToggle.css'
import {useAuth0} from "../../../auth/auth0-wrapper"
import * as moment from 'moment'
import 'moment-timezone';
import {FormattedMessage} from "react-intl";
import {Button, Form} from "reactstrap";
import {Controller} from "react-hook-form"
import useProjectConfigurationForm from "./useProjectConfigurationForm";
import {NaturalGasUnit} from "../../../enums";

const ProjectConfiguration = (props: any) => {
    const globalDefaults = {
        dieselBTUValueDefault: props.dieselBTUValueDefault,
        ngBTUValueDefault: props.ngBTUValueDefault,
        brakeHorsePowerDefault: props.brakeHorsePowerDefault,
        loadFactorIdleDefault: props.loadFactorIdleDefault,
        loadFactorPumpingDefault: props.loadFactorPumpingDefault,
        hoursPerDayIdleDefault: props.hoursPerDayIdleDefault,
        hoursPerDayWorkingDefault: props.hoursPerDayWorkingDefault,
        dieselCostPerGallonDefault: props.dieselCostPerGallonDefault,
        ngCostPerMCFDefault: props.ngCostPerMCFDefault,
        bifuelDailySetupCostDefault: props.bifuelDailySetupCostDefault,
        substitutionRateDefault: props.substitutionRateDefault,
    }
    
    const {
        register,
        isLoading,
        errors,
        onSubmit,
        getValues,
        setValue,
        control
    } = useProjectConfigurationForm(globalDefaults);
    const [timeZonesList, setTimeZonesList] = useState<string []>();

    const [isMetric, setIsMetric] = useState<boolean>();
    const [selectedTimezone, setSelectedTimezone] = useState<string>();
    const [dailyCutOffTime, setDailyCutOffTime] = useState<string>();
    const [naturalGasUnit, setNaturalGasUnit] = useState<number>();

    useEffect(() => {
        // Theses are necessary since the form won't submit without all the required fields
        register("dailyCutOffTime");
        register("bifuelDailySetupCost");
        register("naturalGasUnit");

        if (!isLoading) {
            setTimeZonesList(moment.tz.names().sort());
            setIsMetric(getValues("unitOfMeasurement"))
            setSelectedTimezone(getValues("selectedTimezone"))
            setNaturalGasUnit(getValues("naturalGasUnit"));
        }
    }, [isLoading, register])

    // Update 'unitOfMeasurement' form values on unit of measurement switch
    useEffect(() => {
        if (isMetric) {
            setValue("unitOfMeasurement", true, {shouldDirty: true});
        } else {
            setValue("unitOfMeasurement", false, {shouldDirty: true});
        }
    }, [isMetric])

    // Update 'dailyCutOffTime' and 'selectedTimezone' form values on timezone dropdown selector
    const onDropdownChange = (option: React.ChangeEvent<HTMLSelectElement>) => {
        if (option.target.value.substr(0, 1) === '+') {
            setDailyCutOffTime(option.target.value.substr(1, 5));
            setSelectedTimezone(option.target.value.split('--')[1]);

            // Update form value
            setValue("dailyCutOffTime", option.target.value.substr(1, 5), {shouldDirty: true});
            setValue("selectedTimezone", option.target.value.split('--')[1], {shouldDirty: true});
        } else if (option.target.value.substr(0, 1) === '-') {
            let hours = parseInt(option.target.value.substr(1, 2));
            let minutes = parseInt(option.target.value.substr(4, 2));
            hours = 24 - hours;

            setDailyCutOffTime(hours + ':' + minutes);
            setSelectedTimezone(option.target.value.split('--')[1]);

            // Update form value
            setValue("dailyCutOffTime", hours + ':' + minutes, {shouldDirty: true});
            setValue("selectedTimezone", option.target.value.split('--')[1], {shouldDirty: true});
        }
    }

    const onNatGasUnitDropdownChange = (option: React.ChangeEvent<HTMLSelectElement>) => {
        setValue("naturalGasUnit", option.target.value, {shouldDirty: true});
    }

    if (isLoading) {
        return <div>Loading...</div>
    }
    return (
        <form onSubmit={onSubmit}>
            <div className="projectPreferences">
                <div className="projectInfoDataContainer">
                    <div className="projectInfoDataLabel">
                        <FormattedMessage id="unitOfMeasure.label" defaultMessage="Unit of Measure"/>
                    </div>
                    <div className="projectInfoValue">
                        <div className="input-container">
                            <label className="units-of-measure-toggle">
                                <input className="unitOfMeasurement" id="unitOfMeasurement" name="unitOfMeasurement" type="checkbox" ref={register} onChange={() => setIsMetric(!isMetric)}/>
                                <span className="units-of-measure-slider"/>

                                <div className="units-of-measure-text-container">
                                                    <span
                                                        className={`units-of-measure-text${!isMetric ? '--active' : ''}`}>
                                                        <FormattedMessage id="imperial.label" defaultMessage="Imperial"/>
                                                    </span>
                                    <span
                                        className={`units-of-measure-text${isMetric ? '--active' : ''}`}>
                                                        <FormattedMessage id="metric.label" defaultMessage="Metric"/>
                                                    </span>
                                </div>
                            </label>
                        </div>
                    </div>
                    <p className="project-info-error">{errors.unitOfMeasurement?.message}</p>
                </div>
                <div className="projectInfoDataContainer">
                    <div className="projectInfoDataLabel">
                        <FormattedMessage id="naturalGasUnit.label"
                                          defaultMessage="Natural Gas Unit"/>
                    </div>
                    <div className="projectInfoValue">
                        <select onChange={onNatGasUnitDropdownChange} style={{ width: '100%' }}>
                            {
                                (Object.keys(NaturalGasUnit) as (keyof typeof NaturalGasUnit)[])
                                    .filter(x => isNaN(Number(x)))
                                    .map((key) =>
                                        <option key={key}
                                                label={key}
                                                selected={naturalGasUnit === NaturalGasUnit[key]}
                                                value={NaturalGasUnit[key]}/>
                                    )
                            }
                        </select>
                    </div>
                    <p className="project-info-error">{errors.naturalGasUnit?.message}</p>
                </div>
                <div className="projectInfoDataContainer">
                    <div className="projectInfoDataLabel">
                        <FormattedMessage id="dieselHeatingValue.label" defaultMessage="Diesel Heating Value"/>
                    </div>
                    <div className="projectInfoValue project-info-flex">
                        <input className="project-input-box" name="btuHeatingValue" type="btuHeatingValue" ref={register}/>
                        <div className="project-unit"> BTU/US gal</div>
                    </div>
                    <p className="project-info-error">{errors.btuHeatingValue?.message}</p>
                </div>
                <div className="projectInfoDataContainer">
                    <div className="projectInfoDataLabel">
                        <FormattedMessage id="ngHeatingValue.label" defaultMessage="NG Heating Value"/>
                    </div>
                    <div className="projectInfoValue project-info-flex">
                        <input className="project-input-box" name="ngHeatingValue" type="ngHeatingValue" ref={register}/>
                        <div className="project-unit"> BTU/SCF</div>
                    </div>
                    <p className="project-info-error">{errors.ngHeatingValue?.message}</p>
                </div>
                <div className="projectInfoDataContainer">
                    <div className="projectInfoDataLabel">
                        <FormattedMessage id="dailyTotalCutOffTime.label"
                                          defaultMessage="Daily Total Cut off Time (UTC)"/>
                    </div>

                    <Controller name="selectedTimezone"
                                control={control}
                                render={() =>
                                    <div className="projectInfoValue">
                                        <select onChange={onDropdownChange}>
                                            {timeZonesList !== null && timeZonesList !== undefined && selectedTimezone !== null &&
                                            Object.keys(timeZonesList.sort()).map((x: any) =>
                                                <option key={x}
                                                        selected={selectedTimezone === timeZonesList[x]}
                                                        value={moment.tz(timeZonesList[x]).format('Z') + '--' + timeZonesList[x]}>
                                                    {timeZonesList[x]} GMT ({moment.tz(timeZonesList[x]).format('Z')})
                                                </option>)
                                            }
                                        </select>
                                    </div>
                                }/>
                    <p className="project-info-error">{errors.selectedTimezone?.message}</p>
                </div>
                <div className="projectInfoDataContainer">
                    <div className="projectInfoDataLabel">
                        <FormattedMessage id="dailyCutOffHour.label" defaultMessage="Daily Cut off Hour"/>
                    </div>
                    <div className="projectInfoValue project-info-flex">
                        <input className="project-input-box" name="dailyCutOffHour" type="dailyCutOffHour" ref={register}/>
                        <div className="project-unit">
                            <FormattedMessage id="hour.label" defaultMessage="Hour"/>
                        </div>
                    </div>
                    <p className="project-info-error">{errors.dailyCutOffHour?.message}</p>
                </div>
                <div className="projectInfoDataContainer">
                    <div className="projectInfoDataLabel">
                        <FormattedMessage id="engineHorsePower.label" defaultMessage="Engine Horse Power"/>
                    </div>
                    <div className="projectInfoValue project-info-flex">
                        <input className="project-input-box" name="brakeHorsePower" type="brakeHorsePower" ref={register}/>
                        <div className="project-unit"> Horse Power</div>
                    </div>
                    <p className="project-info-error">{errors.brakeHorsePower?.message}</p>
                </div>
                <div className="projectInfoDataContainer">
                    <div className="projectInfoDataLabel">
                        <FormattedMessage id="loadFactorPumping.label" defaultMessage="Load Factor Pumping"/>
                    </div>
                    <div className="projectInfoValue">
                        <input className="project-input-box max-width" name="loadFactorPumping" type="loadFactorPumping" ref={register}/>
                        <div className="project-unit"> </div>
                    </div>
                    <p className="project-info-error">{errors.loadFactorPumping?.message}</p>
                </div>
                <div className="projectInfoDataContainer">
                    <div className="projectInfoDataLabel">
                        <FormattedMessage id="loadFactorIdle.label" defaultMessage="Load Factor Idle"/>
                    </div>
                    <div className="projectInfoValue">
                        <input className="project-input-box max-width" name="loadFactorIdle" type="loadFactorIdle" ref={register}/>
                        <div className="project-unit"> </div>
                    </div>
                    <p className="project-info-error">{errors.loadFactorIdle?.message}</p>
                </div>
                <div className="projectInfoDataContainer">
                    <div className="projectInfoDataLabel">
                        <FormattedMessage id="hoursPerDayWorking.label" defaultMessage="Hours Per Day Working"/>
                    </div>
                    <div className="projectInfoValue project-info-flex">
                        <input className="project-input-box" name="hoursPerDayWorking" type="hoursPerDayWorking" ref={register}/>
                        <div className="project-unit">
                            <FormattedMessage id="hours.label" defaultMessage="Hours"/>
                        </div>
                    </div>
                    <p className="project-info-error">{errors.hoursPerDayWorking?.message}</p>
                </div>
                <div className="projectInfoDataContainer">
                    <div className="projectInfoDataLabel">
                        <FormattedMessage id="hoursPerDayIdle.label" defaultMessage="Hours Per Day Idle"/>
                    </div>
                    <div className="projectInfoValue project-info-flex">
                        <input className="project-input-box" name="hoursPerDayIdle" type="hoursPerDayIdle" ref={register}/>
                        <div className="project-unit">
                            <FormattedMessage id="hours.label" defaultMessage="Hours"/>
                        </div>
                    </div>
                    <p className="project-info-error">{errors.hoursPerDayIdle?.message}</p>
                </div>
                <div className="projectInfoDataContainer">
                    <div className="projectInfoDataLabel">
                        <FormattedMessage id="dieselCostPerGallon.label" defaultMessage="Diesel Cost Per Gallon"/>
                    </div>
                    <div className="projectInfoValue project-info-flex">
                        <input className="project-input-box" name="dieselCostPerGallon" type="dieselCostPerGallon" ref={register}/>
                        <div className="project-unit"> USD</div>
                    </div>
                    <p className="project-info-error">{errors.dieselCostPerGallon?.message}</p>
                </div>
                <div className="projectInfoDataContainer">
                    <div className="projectInfoDataLabel">
                        <FormattedMessage id="naturalGasCostPerMcf.label" defaultMessage="Natural Gas Cost Per MCF"/>
                    </div>
                    <div className="projectInfoValue project-info-flex">
                        <input className="project-input-box" name="ngCostPerMCF" type="ngCostPerMCF" ref={register}/>
                        <div className="project-unit"> USD</div>
                    </div>
                    <p className="project-info-error">{errors.ngCostPerMCF?.message}</p>
                </div>
                <div className="projectInfoDataContainer">
                    <div className="projectInfoDataLabel">
                        <FormattedMessage id="substitutionRate.label" defaultMessage="Substitution Rate"/>
                    </div>
                    <div className="projectInfoValue project-info-flex">
                        <input className="project-input-box" name="substitutionRate" type="substitutionRate" ref={register}/>
                        <div className="project-unit"> %</div>
                    </div>
                    <p className="project-info-error">{errors.substitutionRate?.message}</p>
                </div>
                <div className="projectInfoDataContainer"></div>
                {/*<div className="projectInfoDataContainer">*/}
                {/*    <div className="projectInfoDataLabel">*/}
                {/*        <FormattedMessage id="dailyBifuelSetupCost.label" defaultMessage="Daily Bifuel Setup Cost" />*/}
                {/*    </div>*/}
                {/*    <input className="projectInfoValue" name="bifuelDailySetupCost" type="bifuelDailySetupCost" ref={register}/>*/}
                {/*    <p className="project-info-error">{errors.bifuelDailySetupCost?.message}</p>*/}
                {/*</div>*/}
            </div>
            <Button
                type="submit"
                color="warning"
                className="float-right"
                outline
                size="md"
            >
                <FormattedMessage id="save.label" defaultMessage="Save"/>
            </Button>
        </form>
    )
}

export default ProjectConfiguration;