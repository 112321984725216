import React, {Fragment, useState, useEffect} from 'react';
import {Row, Col, Container} from 'reactstrap';
import HeaderStat from './HeaderStat';
import WellChart from './WellChart';
import * as Formatting from '../helpers/formatting';
import ChartSwitch from './ChartSwitch';
import NoData from './NoData.js';
import {useIntl} from "react-intl";
import {NaturalGasUnit} from "../enums";

export default function WellChartContainer(props) {

    const [stageDurationActive, setStageDurationActive] = useState(true);
    const [stageRateActive, setStageRateActive] = useState(false);
    const [showChart, setShowChart] = useState(true);
    const intl = useIntl();
    useEffect(() => {
        if (props.well.stages.length > 1) {
            setShowChart(true);
        } else {
            setShowChart(false);
        }
    });

    const setInactiveSwitch = () => {
        setStageDurationActive(!stageDurationActive);
        setStageRateActive(!stageRateActive);
    }

    return (
        <Fragment>
            {props.type === 'productivity' &&
                <Fragment>
                    {(props.well.totalHours === 0) &&
                        <Fragment>
                            <Container fluid={true}>
                                <Row>
                                    <ChartSwitch label={(intl.formatMessage({
                                        id: "stageDuration.label",
                                        defaultMessage: "STAGE DURATION"
                                    })).toUpperCase()} active={stageDurationActive} onClick={setInactiveSwitch}/>
                                    {/* <ChartSwitch label='Stage Rate' active={stageRateActive} onClick={setInactiveSwitch} /> */}
                                </Row>
                            </Container>
                            <Container className="wellStatsContainer" fluid={true}>
                                <Row className="wellRow">
                                    <Col xs="12" md="12" className="barChartContainer">
                                        <NoData/>
                                    </Col>
                                </Row>
                            </Container>
                        </Fragment>
                    }
                    {props.well.totalHours > 0 && !isNaN(props.well.productivity) &&
                        <Fragment>
                            <Container fluid={true}>
                                <Row>
                                    <ChartSwitch label={(intl.formatMessage({
                                        id: "stageDuration.label",
                                        defaultMessage: "STAGE DURATION"
                                    })).toUpperCase()} active={stageDurationActive} onClick={setInactiveSwitch}/>
                                    {/* <ChartSwitch label='Stage Rate' active={stageRateActive} onClick={setInactiveSwitch} /> */}
                                </Row>
                            </Container>
                            <Container className="wellStatsContainer" fluid={true}>

                                <Row className="wellRow">
                                    {showChart &&
                                        <Col xs="12" md="12" className="barChartContainer">
                                            <WellChart well={props.well} naturalGasUnit={props.naturalGasUnit} type="productivity" bifuel={props.bifuel}/>
                                        </Col>
                                    }
                                </Row>
                                <Row className="wellHeaderRow">
                                    <HeaderStat title={(intl.formatMessage({
                                        id: "totals.label",
                                        defaultMessage: "TOTALS"
                                    })).toUpperCase()}
                                                stat={Formatting.numberWithCommas(props.well.activeHours) + ' hrs'}
                                                smallStat={intl.formatMessage({
                                                    id: "activeStageTime.label",
                                                    defaultMessage: "Active Stage Time"
                                                })}/>
                                    <HeaderStat title="&nbsp;"
                                                stat={Formatting.numberWithCommas(props.well.totalHours) + ' hrs'}
                                                smallStat={intl.formatMessage({
                                                    id: "totalStageTime.label",
                                                    defaultMessage: "Total Stage Time"
                                                })}/>
                                    <HeaderStat title="&nbsp;"
                                                stat={Formatting.numberWithCommas(props.well.productivity) + '%'}
                                                smallStat={intl.formatMessage({
                                                    id: "pumpTime.label",
                                                    defaultMessage: "Pump Time %"
                                                }) + ' %'}/>
                                    <HeaderStat title="&nbsp;"
                                                stat={Formatting.numberWithCommas(props.well.stagesPerDay)}
                                                smallStat={intl.formatMessage({
                                                    id: "averageStagesPerDay.label",
                                                    defaultMessage: "Average Stages Per Day"
                                                })}/>
                                </Row>
                            </Container>
                        </Fragment>
                    }
                </Fragment>
            }
            {props.type === 'consumption' &&
                <Fragment>
                    {(props.well.totalPumped === 0) &&
                        <Fragment>
                            <Container fluid={true}>
                                <Row>
                                    <ChartSwitch label={(intl.formatMessage({
                                        id: "consumptionTotals.label",
                                        defaultMessage: "CONSUMPTION TOTALS"
                                    })).toUpperCase()} active={stageDurationActive} onClick={setInactiveSwitch}/>
                                    {/* <ChartSwitch label='Consumption Rate' active={stageRateActive} onClick={setInactiveSwitch} /> */}
                                </Row>
                            </Container>
                            <Container className="wellStatsContainer" fluid={true}>
                                <Row className="wellRow">
                                    <Col xs="12" md="12" className="barChartContainer">
                                        <NoData/>
                                    </Col>
                                </Row>
                            </Container>
                        </Fragment>
                    }
                    {props.well.totalPumped > 0 && !isNaN(props.well.substitutionRate) &&
                        <Fragment>
                            <Container fluid={true}>
                                <Row>
                                    <ChartSwitch label={(intl.formatMessage({
                                        id: "consumptionTotals.label",
                                        defaultMessage: "CONSUMPTION TOTALS"
                                    })).toUpperCase()} active={stageDurationActive} onClick={setInactiveSwitch}/>
                                    {/* <ChartSwitch label='Consumption Rate' active={stageRateActive} onClick={setInactiveSwitch} /> */}
                                </Row>
                            </Container>
                            <Container className="wellStatsContainer" fluid={true}>
                                <Row className="wellRow">
                                    {showChart &&
                                        <Col xs="12" md="12" className="barChartContainer">
                                            <WellChart well={props.well} naturalGasUnit={props.naturalGasUnit} type="consumption" bifuel={props.bifuel}/>
                                        </Col>
                                    }
                                </Row>
                                <Row className="wellHeaderRow">
                                    <HeaderStat title={(intl.formatMessage({
                                        id: "totals.label",
                                        defaultMessage: "TOTALS"
                                    })).toUpperCase()}
                                                stat={Formatting.numberWithCommas(props.well.totalPumped) + props.well.unitOfMeasure}
                                                smallStat={intl.formatMessage({
                                                    id: "diesel.label",
                                                    defaultMessage: "Diesel"
                                                })}/>
                                    {props.well.totalSCF > 0 && <HeaderStat title="&nbsp;"
                                                                            stat={props.naturalGasUnit === NaturalGasUnit.E3M3
                                                                                ? Formatting.numberWithCommas(props.well.totalDEC, 4) + ' E3M3 (' + Formatting.numberWithCommas(props.well.unitOfMeasure === "L" ? props.well.totalDE : props.well.totalDEGallons) + ' D' + props.well.unitOfMeasureShort + 'E)'
                                                                                : props.naturalGasUnit === NaturalGasUnit.MCF
                                                                                ? Formatting.numberWithCommas(props.well.totalMCF) + ' MCF (' + Formatting.numberWithCommas(props.well.unitOfMeasure === "L" ? props.well.totalDE : props.well.totalDEGallons) + ' D' + props.well.unitOfMeasureShort + 'E)'
                                                                                : Formatting.numberWithCommas(props.well.totalSCF) + ' SCF (' + Formatting.numberWithCommas(props.well.unitOfMeasure === "L" ? props.well.totalDE : props.well.totalDEGallons) + ' D' + props.well.unitOfMeasureShort + 'E)'
                                                                            }
                                                                            smallStat={intl.formatMessage({
                                                                                id: "naturalGas.label",
                                                                                defaultMessage: "Natural Gas"
                                                                            })}/>}
                                    {(props.bifuel === true || props.bifuel === 'true') && <HeaderStat title="&nbsp;"
                                                                                                       stat={Formatting.numberWithCommas(props.well.substitutionRate) + '%'}
                                                                                                       smallStat={intl.formatMessage({
                                                                                                           id: "substitution.label",
                                                                                                           defaultMessage: "Substitution"
                                                                                                       })}/>}</Row>
                            </Container>

                        </Fragment>
                    }
                </Fragment>
            }
        </Fragment>
    )

}

