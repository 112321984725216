import React, {useEffect, Fragment, useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {getState} from "litsy";
import Axios from "axios";
import {Button, Tooltip} from "reactstrap";
import {toast} from "react-toastify";
import InfoIcon from "../images/icons8-info-16.png";
import SubscriptionTooltip from "./SubscriptionTooltip";
import {useAuth0} from "../auth/auth0-wrapper";
import {getMetadataValues} from "../helpers/metadataHelper";
import {Dropdown} from "react-bootstrap";

const HOURS = [
    {key: "00:00", value: 0}, {key: "01:00", value: 1},
    {key: "02:00", value: 2}, {key: "03:00", value: 3},
    {key: "04:00", value: 4}, {key: "05:00", value: 5},
    {key: "06:00", value: 6}, {key: "07:00", value: 7},
    {key: "08:00", value: 8}, {key: "09:00", value: 9},
    {key: "10:00", value: 10}, {key: "11:00", value: 11},
    {key: "12:00", value: 12}, {key: "13:00", value: 13},
    {key: "14:00", value: 14}, {key: "15:00", value: 15},
    {key: "16:00", value: 16}, {key: "17:00", value: 17},
    {key: "18:00", value: 18}, {key: "19:00", value: 19},
    {key: "20:00", value: 20}, {key: "21:00", value: 21},
    {key: "22:00", value: 22}, {key: "23:00", value: 23}
]

export default function SubscriptionConfig(props) {
    const {user} = useAuth0();
    const intl = useIntl();

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [metadataValues, setMetadataValues] = useState(null);
    const [jobId, setJobId] = useState(null);
    const [stageSubscription, setStageSubscription] = useState(null);
    const [dailySubscription, setDailySubscription] = useState(null);
    const [excelStageSubscription, setExcelStageSubscription] = useState(null);
    const [wellTotalizerSubscription, setWellTotalizerSubscription] = useState(null);
    const [sideKickDailySubscription, setSideKickDailySubscription] = useState(null);

    const [selectedHours, setSelectedHours] = useState([])

    toast.configure();

    // Get and set FullProjectId
    useEffect(() => {
        const getJobId = async () => {
            const projectInfoArray = window.location.pathname.split('/');
            const projectStartTime = projectInfoArray[3];
            const projectName = projectInfoArray[2];
            const projectEnergyProducer = projectInfoArray[1];
            // const projectEnergyProducer = props.project.energyProducerName.replace(/\s/g, '-');

            const url = `${getState("fracShack_apiEndpoint", "session")}project/${projectName}/jobId?producerName=${projectEnergyProducer}&startTime=${projectStartTime}`
            let result = (await Axios.get(url, {
                headers: {
                    "Authorization": `Bearer ${getState("authToken", "session")}`
                }
            })).data

            // Get and set user metadata values
            setMetadataValues(getMetadataValues(user, projectEnergyProducer, projectName));
            setJobId(result);
        };

        getJobId()
    }, [])

    // Get and set subscriptions once fullProjectId loaded
    useEffect(() => {
        if (jobId) {
            // getStageSubscription();
            getDailySubscription();
            getExcelStageSubscriptions();
            getWellTotalizerSubscriptions();
            getSideKickDailySubscription();
        }
    }, [jobId])

    useEffect(() => {
        var subscription = {
            ...wellTotalizerSubscription,
            dailyCutOffTime: metadataValues?.dailyCutoffTime,
            dailyCutOffHour: metadataValues?.dailyCutoffHour,
            hours: selectedHours.flatMap(({value}) => value)
        }
        setWellTotalizerSubscription(subscription);
    }, [selectedHours])

    // Call API to get Stage Subscription
    const getStageSubscription = async () => {
        const url = `${getState("fracShack_apiEndpoint", "session")}subscription/stage-subscription?projectId=${jobId}&email=${props.user.email}`
        let result = (await Axios.get(url, {
            headers: {
                "Authorization": `Bearer ${getState("authToken", "session")}`
            }
        })).data

        setStageSubscription(result);
    };

    // Call API to get Daily Subscription
    const getDailySubscription = async () => {
        const url = `${getState("fracShack_apiEndpoint", "session")}subscription/daily-subscription?projectId=${jobId}&email=${props.user.email}`
        let result = (await Axios.get(url, {
            headers: {
                "Authorization": `Bearer ${getState("authToken", "session")}`
            }
        })).data

        if (result.dailyCutOffTime === null || result.dailyCutOffTime === undefined) {
            result.dailyCutOffTime = metadataValues.dailyCutoffTime;
            result.dailyCutOffHour = metadataValues.dailyCutoffHour;
        }

        setDailySubscription(result);
    };

    // Call API to get Excel Stage Subscription
    const getExcelStageSubscriptions = async () => {
        const url = `${getState("fracShack_apiEndpoint", "session")}subscription/excel-stage-subscription?projectId=${jobId}&email=${props.user.email}`
        let result = (await Axios.get(url, {
            headers: {
                "Authorization": `Bearer ${getState("authToken", "session")}`
            }
        })).data

        setExcelStageSubscription(result);
    }

    // Call API to get Well Totalizer Subscription
    const getWellTotalizerSubscriptions = async () => {
        const url = `${getState("fracShack_apiEndpoint", "session")}subscription/totalizer-subscription?projectId=${jobId}&email=${props.user.email}`
        let result = (await Axios.get(url, {
            headers: {
                "Authorization": `Bearer ${getState("authToken", "session")}`
            }
        })).data

        setWellTotalizerSubscription(result);
        if (result !== null) {
            var existingHours = HOURS.filter(x => result.hours.includes(x.value));
            setSelectedHours(existingHours);
        }
    }

    // Call API to get SideKick Daily Subscription
    const getSideKickDailySubscription = async () => {
        const url = `${getState("fracShack_apiEndpoint", "session")}subscription/sidekick-daily-subscription?projectId=${jobId}&email=${props.user.email}`
        let result = (await Axios.get(url, {
            headers: {
                "Authorization": `Bearer ${getState("authToken", "session")}`
            }
        })).data

        if (result.dailyCutOffTime === null || result.dailyCutOffTime === undefined) {
            result.dailyCutOffTime = metadataValues.dailyCutoffTime;
            result.dailyCutOffHour = metadataValues.dailyCutoffHour;
        }

        setSideKickDailySubscription(result);
    };

    // Call API to update/create Stage Subscription
    const updateStageSubscription = async () => {
        const url = `${getState("fracShack_apiEndpoint", "session")}subscription/stage-subscription/${jobId}/update`
        const result = (await Axios.post(url, stageSubscription, {
            headers: {
                "Authorization": `Bearer ${getState("authToken", "session")}`
            }
        })).data

        setStageSubscription(result);
    }

    // Call API to update/create Daily Subscription
    const updateDailySubscription = async () => {
        const url = `${getState("fracShack_apiEndpoint", "session")}subscription/daily-subscription/${jobId}/update`
        const result = (await Axios.post(url, dailySubscription, {
            headers: {
                "Authorization": `Bearer ${getState("authToken", "session")}`
            }
        })).data

        setDailySubscription(result);
    }

    // Call API to update/create Excel Stage Subscription
    const updateExcelStageSubscription = async () => {
        const url = `${getState("fracShack_apiEndpoint", "session")}subscription/excel-stage-subscription/${jobId}/update`
        const result = (await Axios.post(url, excelStageSubscription, {
            headers: {
                "Authorization": `Bearer ${getState("authToken", "session")}`
            }
        })).data

        setExcelStageSubscription(result);
    }

    // Call API to update/create Well Totalizer Subscription
    const updateWellTotalizerSubscription = async () => {
        const url = `${getState("fracShack_apiEndpoint", "session")}subscription/totalizer-subscription/${jobId}/update`
        const result = (await Axios.post(url, wellTotalizerSubscription, {
            headers: {
                "Authorization": `Bearer ${getState("authToken", "session")}`
            }
        })).data

        setWellTotalizerSubscription(result);
    }

    // Call API to update/create SideKick Daily Subscription
    const updateSideKickDailySubscription = async () => {
        const url = `${getState("fracShack_apiEndpoint", "session")}subscription/sidekick-daily-subscription/${jobId}/update`
        const result = (await Axios.post(url, sideKickDailySubscription, {
            headers: {
                "Authorization": `Bearer ${getState("authToken", "session")}`
            }
        })).data

        setSideKickDailySubscription(result);
    }

    const saveSubscriptionSettings = async () => {
        setIsSubmitting(true);
        await Promise.allSettled([
            // await updateStageSubscription(),
            updateDailySubscription(),
            updateExcelStageSubscription(),
            updateWellTotalizerSubscription(),
            updateSideKickDailySubscription()
        ]).then(() => {
            toast.success(intl.formatMessage({
                id: "subscriptionSaveSuccess.message",
                defaultMessage: "Success: Subscription settings saved"
            }), {
                position: toast.POSITION.TOP_RIGHT
            });

            setIsSubmitting(false);
        });
    }

    function onHoursDropdownSelect(selected) {
        if (selected === null)
            setSelectedHours([])
        else if (selectedHours.includes(selected) === false && selectedHours.length < 3)
            setSelectedHours([...selectedHours, selected])
        else
            setSelectedHours([...selectedHours].filter(item => item !== selected))
    }


    return (
        <Fragment>
            <div className="subscription-container">
                <div className="projectInfoContainer">
                    <div className="projectInfoTitle">
                        <FormattedMessage
                            id="reporting.label"
                            defaultMessage="Reporting"
                        />
                        {" "}/{" "}
                        <FormattedMessage
                            id="subscriptions.label"
                            defaultMessage="Subscriptions"
                        />
                    </div>

                    <div className="clearfix"/>
                    <div>
                        {/*{stageSubscription &&*/}
                        {/*    <div className="projectInfoDataContainer">*/}
                        {/*        <div className="projectInfoDataLabel">*/}
                        {/*            <SubscriptionTooltip title={<FormattedMessage id="stageSubscription.label" defaultMessage="Stage Subscription"/>}*/}
                        {/*                                 message={<FormattedMessage id="stageSubscriptionTooltip.message" defaultMessage="Stage subscription - Sends an email with the total consumed diesel and/or natural gas when the stage ends."/>}*/}
                        {/*                                 type={"stage"}*/}
                        {/*            />*/}
                        {/*        </div>*/}
                        {/*        <div className="projectInfoValue">*/}
                        {/*            <div className="input-container">*/}
                        {/*                <label className="units-of-measure-toggle">*/}
                        {/*                    <input type="checkbox" value={stageSubscription.isActive} onChange={() => {*/}
                        {/*                        let newStageSubscription = {*/}
                        {/*                            ...stageSubscription,*/}
                        {/*                            isActive: !stageSubscription.isActive*/}
                        {/*                        };*/}
                        {/*                        setStageSubscription(newStageSubscription);*/}
                        {/*                    }}/>*/}
                        {/*                    <span className="units-of-measure-slider"/>*/}

                        {/*                    <div className="units-of-measure-text-container">*/}
                        {/*                    <span*/}
                        {/*                        className={`units-of-measure-text${!stageSubscription.isActive ? '--active' : ''}`}>*/}
                        {/*                        <FormattedMessage id="unsubscribe.label" defaultMessage="Unsubscribe"/>*/}
                        {/*                    </span>*/}
                        {/*                        <span*/}
                        {/*                            className={`units-of-measure-text${stageSubscription.isActive ? '--active' : ''}`}>*/}
                        {/*                        <FormattedMessage id="subscribe.label" defaultMessage="Subscribe"/>*/}
                        {/*                    </span>*/}
                        {/*                    </div>*/}
                        {/*                </label>*/}
                        {/*            </div>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*}*/}
                        {excelStageSubscription &&
                            <div className="projectInfoDataContainer">
                                <div className="projectInfoDataLabel">
                                    <SubscriptionTooltip title={<FormattedMessage id="excelStageSubscription.label"
                                                                                  defaultMessage="Excel Stage Subscription"/>}
                                                         message={<FormattedMessage
                                                             id="excelStageSubscriptionTooltip.message"
                                                             defaultMessage="Excel stage subscription - Sends an email with an excel attachment breaking down the total consumed diesel and/or natural gas when a stage(s) ends."/>}
                                                         type={"excel-stage"}
                                    />
                                </div>
                                <div className="projectInfoValue">
                                    <div className="input-container">
                                        <label className="units-of-measure-toggle">
                                            <input type="checkbox" value={excelStageSubscription.isActive}
                                                   onChange={() => {
                                                       let newExcelStageSubscription = {
                                                           ...excelStageSubscription,
                                                           isActive: !excelStageSubscription.isActive
                                                       };
                                                       setExcelStageSubscription(newExcelStageSubscription);
                                                   }}/>
                                            <span className="units-of-measure-slider"/>

                                            <div className="units-of-measure-text-container">
                                            <span
                                                className={`units-of-measure-text${!excelStageSubscription.isActive ? '--active' : ''}`}>
                                                <FormattedMessage id="unsubscribe.label" defaultMessage="Unsubscribe"/>
                                            </span>
                                                <span
                                                    className={`units-of-measure-text${excelStageSubscription.isActive ? '--active' : ''}`}>
                                                <FormattedMessage id="subscribe.label" defaultMessage="Subscribe"/>
                                            </span>
                                            </div>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        }

                        {dailySubscription &&
                        <div className="projectInfoDataContainer" style={{paddingTop: "2em"}}>
                            <div className="projectInfoDataLabel">
                                <SubscriptionTooltip title={<FormattedMessage id="dailySubscription.label" defaultMessage="Daily Subscription"/>}
                                                     message={<FormattedMessage id="dailySubscriptionTooltip.message" defaultMessage="Daily subscription - Sends a daily email with the total consumed diesel and/or natural gas. Note: Time sent is based on daily total cut off time and daily cut off hour, this can be changed in project info -> configurations."/>}
                                                     type={"daily"}
                                />
                            </div>
                            <div className="projectInfoValue">
                                <div className="input-container">
                                    <label className="units-of-measure-toggle">
                                        <input type="checkbox" value={dailySubscription.isActive} onChange={() => {
                                            let newDailySubscription = {
                                                ...dailySubscription,
                                                isActive: !dailySubscription.isActive,
                                                dailyCutOffTime: metadataValues.dailyCutoffTime,
                                                dailyCutOffHour: metadataValues.dailyCutoffHour
                                            }

                                            setDailySubscription(newDailySubscription);
                                        }}/>
                                        <span className="units-of-measure-slider"/>

                                        <div className="units-of-measure-text-container">
                                                <span
                                                    className={`units-of-measure-text${!dailySubscription.isActive ? '--active' : ''}`}>
                                                    <FormattedMessage id="unsubscribe.label" defaultMessage="Unsubscribe"/>
                                                </span>
                                            <span
                                                className={`units-of-measure-text${dailySubscription.isActive ? '--active' : ''}`}>
                                                    <FormattedMessage id="subscribe.label" defaultMessage="Subscribe"/>
                                                </span>
                                        </div>
                                    </label>
                                </div>
                            </div>
                        </div>
                        }
                        
                        {wellTotalizerSubscription &&
                            <div className="projectInfoDataContainer" style={{paddingTop: "2em"}}>
                                <div className="projectInfoDataLabel">
                                    <SubscriptionTooltip title={<FormattedMessage id="wellTotalizerSubscription.label"
                                                                                  defaultMessage="Well Totalizer Subscription"/>}
                                                         message={<FormattedMessage
                                                             id="wellTotalizerSubscriptionTooltip.message"
                                                             defaultMessage="Select up to 3 hours of the day to receive totalizer reports by well"/>}
                                    />
                                </div>
                                <div className="projectInfoValue" style={{marginBottom: "1em"}}>
                                    <div className="input-container">
                                        <label className="units-of-measure-toggle">
                                            <input type="checkbox" value={wellTotalizerSubscription.isActive}
                                                   onChange={() => {
                                                       let newWellTotalizerSubscription = {
                                                           ...wellTotalizerSubscription,
                                                           isActive: !wellTotalizerSubscription.isActive,
                                                           dailyCutOffTime: metadataValues.dailyCutoffTime,
                                                           dailyCutOffHour: metadataValues.dailyCutoffHour
                                                       };
                                                       setWellTotalizerSubscription(newWellTotalizerSubscription);
                                                   }}/>
                                            <span className="units-of-measure-slider"/>

                                            <div className="units-of-measure-text-container">
                                            <span
                                                className={`units-of-measure-text${!wellTotalizerSubscription.isActive ? '--active' : ''}`}>
                                                <FormattedMessage id="unsubscribe.label" defaultMessage="Unsubscribe"/>
                                            </span>
                                                <span
                                                    className={`units-of-measure-text${wellTotalizerSubscription.isActive ? '--active' : ''}`}>
                                                <FormattedMessage id="subscribe.label" defaultMessage="Subscribe"/>
                                            </span>
                                            </div>
                                        </label>
                                    </div>
                                </div>
                                <Dropdown>
                                    <Dropdown.Toggle variant="secondary" size={"lg"} style={{marginLeft: "1em"}}>
                                        {selectedHours.length === 0 ?
                                            <FormattedMessage id="None.label" defaultMessage="None"/>
                                            : selectedHours.flatMap(({key}) => key).join(", ")}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu style={{maxHeight: "50vh", overflowY: "scroll"}} variant="dark">
                                        <Dropdown.Item onClick={() => onHoursDropdownSelect(null)}
                                                       active={selectedHours.length === 0}>
                                            <FormattedMessage id="None.label" defaultMessage="None"/>
                                        </Dropdown.Item>
                                        {HOURS.map(hour =>
                                            <Dropdown.Item onClick={() => onHoursDropdownSelect(hour)}
                                                           active={selectedHours.includes(hour)}>
                                                {hour.key}
                                            </Dropdown.Item>
                                        )}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        }
                        {sideKickDailySubscription &&
                            <div className="projectInfoDataContainer" style={{paddingTop: "2em"}}>
                                <div className="projectInfoDataLabel">
                                    <SubscriptionTooltip title={<FormattedMessage id="sideKickdailySubscription.label" defaultMessage="SideKick Daily Subscription"/>}
                                                         message={<FormattedMessage id="sideKickDailySubscriptionTooltip.message" defaultMessage="SideKick Daily Subscription - Sends a daily email with the total processed natural gas. Note: Time sent is based on daily total cut off time and daily cut off hour, this can be changed in project info -> configurations."/>}
                                                         type={"sidekick-daily"}
                                    />
                                </div>
                                <div className="projectInfoValue">
                                    <div className="input-container">
                                        <label className="units-of-measure-toggle">
                                            <input type="checkbox" value={sideKickDailySubscription.isActive} onChange={() => {
                                                let newSideKickDailySubscription = {
                                                    ...sideKickDailySubscription,
                                                    isActive: !sideKickDailySubscription.isActive,
                                                    dailyCutOffTime: metadataValues.dailyCutoffTime,
                                                    dailyCutOffHour: metadataValues.dailyCutoffHour
                                                }

                                                setSideKickDailySubscription(newSideKickDailySubscription);
                                            }}/>
                                            <span className="units-of-measure-slider"/>

                                            <div className="units-of-measure-text-container">
                                                <span
                                                    className={`units-of-measure-text${!sideKickDailySubscription.isActive ? '--active' : ''}`}>
                                                    <FormattedMessage id="unsubscribe.label" defaultMessage="Unsubscribe"/>
                                                </span>
                                                <span
                                                    className={`units-of-measure-text${sideKickDailySubscription.isActive ? '--active' : ''}`}>
                                                    <FormattedMessage id="subscribe.label" defaultMessage="Subscribe"/>
                                                </span>
                                            </div>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
                <Button
                    color="warning"
                    className="subscription-save-button"
                    disabled={isSubmitting}
                    outline
                    onClick={saveSubscriptionSettings}
                    size="md"
                >
                    <FormattedMessage id="save.label" defaultMessage="Save"/>
                </Button>
            </div>
            <div className="clearfix margin-bottom-20"/>
        </Fragment>
    );
}
