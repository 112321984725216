import React, {Fragment, useState} from 'react';
import * as Formatting from '../helpers/formatting';
import * as Conversion from '../helpers/Conversion';
import ConnectedEquipment from './ConnectedEquipment.js';
import FracShackEquipment from "./FracShackEquipment";
import ExportUnit from '../components/ExportUnit.js';
import {FormattedMessage} from "react-intl";
import {useIntl} from "react-intl";
import DashboardListView from "./DashboardListView";
import "../css/Dashboard.css";
import DashboardEnergyConsumedElement from "./DashboardEnergyConsumedElement";

import currencies from "../data/currency.json"
import {UnitOfMeasurement} from "../enums";
import ProjectNoData from "./ProjectNoData";

export default function Dashboard(props) {
    const intl = useIntl();

    // Determines the json object to use depending on project location
    const currencyJson = currencies.find(country => country.country === "Default")

    // Selections states
    const [dieselUnit, setDieselUnit] = useState("US G");
    const [natGasUnit, setNatGasUnit] = useState("SCF");
    const [pumpTimeUnit, setPumpTimeUnit] = useState("%Act");
    const [gasReductionUnit, setGasReductionUnit] = useState("CO2e");
    const [ngSidekickUnit, setNgSidekickUnit] = useState("SCF");
    const [fuelTicketUnit, setFuelTicketUnit] = useState("US G");

    /**
     * The follow objects are the enums / states possible for the respective cards they are used in, along with the translation if applicable.
     **/

        // Enums with translation
    const DieselUnits = {
            "US G": intl.formatMessage({id: "usGallons.label", defaultMessage: "US Gallons"}),
            "L": intl.formatMessage({id: "litres.label", defaultMessage: "Litres"}),
            "BTU": intl.formatMessage({id: "btu.label", defaultMessage: "BTU"})
        }

    const NatGasUnits = {
        "SCF": intl.formatMessage({id: "scf.label", defaultMessage: "SCF"}),
        "DGE": intl.formatMessage({id: "dge.label", defaultMessage: "DGE"}),
        "DLE": intl.formatMessage({id: "dle.label", defaultMessage: "DLE"}),
        "BTU": intl.formatMessage({id: "btu.label", defaultMessage: "BTU"}),
        "E3M3": intl.formatMessage({id: "e3m3.label", defaultMessage: "E3M3"}),
        "MCF": intl.formatMessage({id: "mcf.label", defaultMessage: "MCF"})
    }

    const PumpTimeUnits = {
        "%Act": intl.formatMessage({id: "%active.label", defaultMessage: "% Active"}),
        "%Inact": intl.formatMessage({id: "%inActive.label", defaultMessage: "% Inactive"}),
    }

    const GasReductionUnits = {
        "CO2e": intl.formatMessage({id: "co2Reduction.label", defaultMessage: "CO2 Reduction"}),
    }

    /**
     *  The following objects contains the data being displayed for the 3 (or 4) cards being displayed on the dashboard.
     *  The object contains the following info:
     *      - Title: Header name for the card.
     *      - Value: The Value in the Header.
     *      - Unit: The currently selected unit state of the card, this is determined from an onclick function in the child element which sets the state.
     *      - Badge: This is an object which contains the elements that have a "Badge / InfoIcon" along with the message of the "Badge / InfoIcon".
     *               It also contains a possible "Title / Description" if applicable.
     *      - elements: This is a list containing "tuples" of the sub-elements to be displayed in the card.
     **/

        // Contains the values related to Diesel
    const dieselDict = {
            title: intl.formatMessage({id: "totalDieselConsumed.label", defaultMessage: "Total Diesel Consumed"}),
            value: dieselUnit === "US G" ? Formatting.numberWithCommas(props.project.totalConsumedWithNonFracDieselGallons)
                : dieselUnit === "L" ? Formatting.numberWithCommas(props.project.totalConsumedWithNonFracDieselLitres)
                    : Formatting.numberWithCommas(props.project.totalConsumedWithNonFracDieselBTU),
            unit: dieselUnit,
            badge: {},
            elements: [
                {
                    title: [intl.formatMessage({id: "fracDiesel.label", defaultMessage: "Frac Diesel"})],
                    value: dieselUnit === "US G" ? Formatting.numberWithCommas(props.project.dieselPumpedGallons)
                        : dieselUnit === "L" ? Formatting.numberWithCommas(props.project.dieselPumpedLitres)
                            : Formatting.numberWithCommas(props.project.dieselPumpedBTU)
                },
                {
                    title: [intl.formatMessage({id: "nonFracDiesel.label", defaultMessage: "Non Frac Diesel"})],
                    value: dieselUnit === "US G" ? Formatting.numberWithCommas(props.project.totalNonFracDieselGallons)
                        : dieselUnit === "L" ? Formatting.numberWithCommas(props.project.totalNonFracDieselLitres)
                            : Formatting.numberWithCommas(props.project.totalNonFracDieselBTU)
                },
                {
                    title: [intl.formatMessage({
                        id: "avgFracDieselPerStage.label",
                        defaultMessage: "Avg Frac Diesel Per Stage"
                    })],
                    value: dieselUnit === "US G" ? Formatting.numberWithCommas(props.project.avgDieselPerStageGallons)
                        : dieselUnit == "L" ? Formatting.numberWithCommas(props.project.avgDieselPerStageLitres)
                            : Formatting.numberWithCommas(props.project.avgDieselPerStageBTU)
                },
                {
                    title: [intl.formatMessage({
                        id: "avgNonFracDieselPerStage.label",
                        defaultMessage: "Avg Non-Frac Diesel Per Stage"
                    })],
                    value: dieselUnit === "US G" ? Formatting.numberWithCommas(props.project.avgNonFracPerStageGallons)
                        : dieselUnit === "L" ? Formatting.numberWithCommas(props.project.avgNonFracPerStageLitres)
                            : Formatting.numberWithCommas(props.project.avgNonFracPerStageBTU)
                },
                {
                    title: [intl.formatMessage({
                        id: "avgConsumptionPerDay.label",
                        defaultMessage: "Avg Consumption Per Day"
                    })],
                    value: dieselUnit === "US G" ? Formatting.numberWithCommas(props.project.avgConsumedWithNonFracDieselPerDayGallons)
                        : dieselUnit === "L" ? Formatting.numberWithCommas(props.project.avgConsumedWithNonFracDieselPerDayLitres)
                            : Formatting.numberWithCommas(props.project.avgConsumedWithNonFracDieselPerDayBTU)
                }
            ]
        }

    // Contains the values related to Natural Gas
    const natGasDict = {
        title: intl.formatMessage({id: "totalNaturalGasConsumed.label", defaultMessage: "Total Natural Gas Consumed"}),
        value: natGasUnit === "SCF" ? Formatting.numberWithCommas(props.project.totalSCF)
            : natGasUnit === "DGE" ? Formatting.numberWithCommas(props.project.totalDEGallons)
                : natGasUnit === "DLE" ? Formatting.numberWithCommas(props.project.totalDELitres)
                    : natGasUnit === "E3M3" ? Formatting.numberWithCommas(props.project.totalDEC, 4)
                        : natGasUnit === "MCF" ? Formatting.numberWithCommas(props.project.totalMCF)
                            : Formatting.numberWithCommas(props.project.totalNatGasBTU),
        unit: natGasUnit,
        badge: {},
        elements: [
            {
                title: [intl.formatMessage({
                    id: "substitution%BifuelPumps.label",
                    defaultMessage: "Substitution % (Bifuel Pumps)"
                })],
                value: props.project.substitutionRateBiFuelPumper + '%'
            },
            {
                title: [intl.formatMessage({
                    id: "substitution%BifuelPumpsGen2.label",
                    defaultMessage: "Substitution % (Bifuel Pumps - Gen 2)"
                })],
                value: props.project.substitutionRateBiFuelPumperGen2 + '%'
            },
            {
                title: [intl.formatMessage({
                    id: "substitution%BifuelPumpsGen4.label",
                    defaultMessage: "Substitution % (Bifuel Pumps - Gen 4)"
                })],
                value: props.project.substitutionRateBiFuelPumperGen4 + '%'
            },
            {
                title: [intl.formatMessage({
                    id: "substitution%EntireSite.label",
                    defaultMessage: "Substitution % (Entire Site)"
                })],
                value: props.project.substitutionRate + '%'
            },
            {
                title: [intl.formatMessage({
                    id: "substitution%EntireSiteGen2.label",
                    defaultMessage: "Substitution % (Entire Site - Gen 2)"
                })],
                value: props.project.substitutionRateGen2 + '%'
            },
            {
                title: [intl.formatMessage({
                    id: "substitution%EntireSiteGen4.label",
                    defaultMessage: "Substitution % (Entire Site - Gen4)"
                })],
                value: props.project.substitutionRateGen4 + '%'
            },
            {
                title: [intl.formatMessage({
                    id: "avgNaturalGasPerStage.label",
                    defaultMessage: "Avg Natural Gas Per Stage"
                })],
                value: natGasUnit === "SCF" ? Formatting.numberWithCommas(props.project.avgSCFPerStage)
                    : natGasUnit === "DGE" ? Formatting.numberWithCommas(props.project.avgDGEPerStage)
                        : natGasUnit === "DLE" ? Formatting.numberWithCommas(props.project.avgDLEPerStage)
                            : natGasUnit === "E3M3" ? Formatting.numberWithCommas(props.project.avgDECPerStage, 4)
                                : natGasUnit === "MCF" ? Formatting.numberWithCommas(props.project.avgMCFPerStage)
                                    : Formatting.numberWithCommas(props.project.avgNatGasBTUPerStage)
            },
            {
                title: [intl.formatMessage({
                    id: "estimatedSavingsDisplacingDiesel.label",
                    defaultMessage: "Estimated Savings Displacing Diesel"
                })],
                value: '$ ' + Formatting.numberWithCommas(props.project.estimatedSavingsDisplacingDiesel)
            },
            {
                title: [intl.formatMessage({
                    id: "estimatedSavingsPerDay.label",
                    defaultMessage: "Estimated Savings Per Day"
                })],
                value: '$ ' + Formatting.numberWithCommas(props.project.estimatedSavingsPerDay)
            }
        ]
    }

    // Contains the values related to Pump Time
    const pumpTimeDict = {
        title: intl.formatMessage({id: "pumpTime.label", defaultMessage: "Pump Time"}),
        value: pumpTimeUnit === "%Act" ? props.project.productivity + '%' : props.project.unProductivity + "%",
        unit: pumpTimeUnit,
        badge: {},
        elements: [
            {
                title: [intl.formatMessage({id: "siteDuration.label", defaultMessage: "Site Duration"})],
                value: (props.project.activeHours + props.project.idleHours).toFixed(2) + " h"
            },
            {
                title: [intl.formatMessage({id: "hoursActive.label", defaultMessage: "Hours Active"})],
                value: props.project.activeHours + " h"
            },
            {
                title: [intl.formatMessage({id: "hoursInactive.label", defaultMessage: "Hours Inactive"})],
                value: props.project.idleHours + " h"
            },
            {
                title: [intl.formatMessage({id: "startDate.label", defaultMessage: "Start Date"})],
                value: props.project.startDateURL.replace('-', '/').replace('-', '/')
            },
            {
                title: [intl.formatMessage({id: "completedStagesLower.label", defaultMessage: "Completed Stages"})],
                value: props.project.totalStages
            },
            {
                title: [intl.formatMessage({
                    id: "averageStagesPerDay.label",
                    defaultMessage: "Average Stages Per Day"
                })],
                value: props.project.stagesPerDay
            }
        ]
    }

    // Contains the values related to Greenhouse Gases
    const greenhouseGasDict = {
        title: intl.formatMessage({id: "greenhouseGasReduction.label", defaultMessage: "GHG Reduction"}),
        value: Formatting.numberWithCommas(props.project.co2EmissionReduction) +
            " " +
            intl.formatMessage({id: "tonnes.label", defaultMessage: "Tonnes"}),
        unit: gasReductionUnit,
        badge: {
            // [intl.formatMessage({ id: "estimatedGHGReductionByReducedIdleStage.label", defaultMessage: "Estimated GHG Reduction by Reduced Idle (Stage)"})]: {
            //     message: intl.formatMessage({ id: "avgGHGSavedPerStageIdle.message", defaultMessage: "This calculation is an estimate on the average GHG's saved per stage utilizing the Frac Shack, Frac Fueller and reducing between stage idle time that allows for continuous operation. Avg GHG is calculated based on default values in the Project Info page. You may change theses values to more accurately reflect your GHG reduction."})
            // },
            // [intl.formatMessage({ id: "estimatedGHGReductionByReducedIdleDay.label", defaultMessage: "Estimated GHG Reduction by Reduced Idle (Stage)"})]: {
            //     message: intl.formatMessage({ id: "avgGHGSavedPerStageIdle.message", defaultMessage: "This calculation is an estimate on the average GHG's saved per day utilizing the Frac Shack, Frac Fueller and reducing between stage idle time that allows for continuous operation. Avg GHG is calculated based on default values in the Project Info page. You may change theses values to more accurately reflect your GHG reduction."})
            // },
            [intl.formatMessage({id: "avgGHGSavedPerStage.label", defaultMessage: "Avg GHG Saved Per Stage"})]: {
                title: [intl.formatMessage({
                    id: "bifuelFracFuellerNG.message",
                    defaultMessage: "Using a Bi-Fuel Frac Fueller to substitute Diesel with NG could have further reduced emissions by the following:"
                })],
                message: intl.formatMessage({
                    id: "avgGHGSavedPerStage.message",
                    defaultMessage: "This calculation is an estimate on the average GHG's saved per stage by utilizing the Bi-Fuel Frac Fueller and displacing Diesel with Natural Gas. Avg GHG is calculated based on default values in the Project Info page. You may change these values to more accurately reflect your potential GHG reduction."
                }),
                color: "green",
                bifuel: props.project.bifuel
            },
            [intl.formatMessage({id: "avgGHGSavedPerDay.label", defaultMessage: "Avg GHG Saved Per Day"})]: {
                message: intl.formatMessage({
                    id: "avgGHGSavedPerDay.message",
                    defaultMessage: "This calculation is an estimate on the average GHG's saved per day utilizing the Bi-Fuel Frac Fueller and displacing Diesel with Natural Gas. Avg GHG is calculated based on default values in the Project Info page. You may change these values to more accurately reflect your potential GHG reduction."
                }),
            }
        },
        elements: [
            // {
            //     title: [intl.formatMessage({ id: "estimatedGHGReductionByReducedIdleStage.label", defaultMessage: "Estimated GHG Reduction by Reduced Idle (Stage)"})],
            //     value: 0 + " " + intl.formatMessage({ id: "tonnes.label", defaultMessage: "Tonnes"})
            // },
            // {
            //     title: [intl.formatMessage({ id: "estimatedGHGReductionByReducedIdleDay.label", defaultMessage: "Estimated GHG Reduction by Reduced Idle (Day)"})],
            //     value: 0 + " " + intl.formatMessage({ id: "tonnes.label", defaultMessage: "Tonnes"})
            // },
            {
                title: [intl.formatMessage({
                    id: "avgGHGSavedPerStage.label",
                    defaultMessage: "Avg GHG Saved Per Stage"
                })],
                value: props.project.co2EmissionReductionPerStage +
                    " " +
                    intl.formatMessage({id: "tonnes.label", defaultMessage: "Tonnes"})
            },
            {
                title: [intl.formatMessage({id: "avgGHGSavedPerDay.label", defaultMessage: "Avg GHG Saved Per Day"})],
                value: props.project.co2EmissionReductionPerDay +
                    " " +
                    intl.formatMessage({id: "tonnes.label", defaultMessage: "Tonnes"})
            }
        ]
    }

    // Contains the values related to NG Sidekick
    const ngSidekickDict = {
        title: intl.formatMessage({
            id: "totalProcessedNaturalGas.label",
            defaultMessage: "Total Processed Natural Gas"
        }),
        value: ngSidekickUnit === "SCF" ? Formatting.numberWithCommas(props.project.ngSideKick.totalVolume)
            : ngSidekickUnit === "DGE" ? Formatting.numberWithCommas(Conversion.toDGE(props.project.ngSideKick.totalVolume))
                : ngSidekickUnit === "DLE" ? Formatting.numberWithCommas(Conversion.toDLE(props.project.ngSideKick.totalVolume))
                    : ngSidekickUnit === "E3M3" ? Formatting.numberWithCommas(props.project.ngSideKick.totalVolumeDEC)
                        : ngSidekickUnit === "MCF" ? Formatting.numberWithCommas(props.project.ngSideKick.totalVolumeMCF)
                            : Formatting.numberWithCommas(Conversion.toBTU(props.project.ngSideKick.totalVolume)),
        unit: natGasUnit,
        badge: {},
        elements: [
            {
                title: [intl.formatMessage({id: "avgFlowRate.label", defaultMessage: "Avg Flow Rate"})],
                value: ngSidekickUnit === "SCF" ? Formatting.numberWithCommas(props.project.ngSideKick.totalAvgFlowRate)
                    : ngSidekickUnit === "DGE" ? Formatting.numberWithCommas(Conversion.toDGE(props.project.ngSideKick.totalAvgFlowRate))
                        : ngSidekickUnit === "DLE" ? Formatting.numberWithCommas(Conversion.toDLE(props.project.ngSideKick.totalAvgFlowRate))
                            : ngSidekickUnit === "E3M3" ? Formatting.numberWithCommas(props.project.ngSideKick.totalAvgFlowRateDEC)
                                : ngSidekickUnit === "MCF" ? Formatting.numberWithCommas(props.project.ngSideKick.totalAvgFlowRateMCF)
                                    : Formatting.numberWithCommas(Conversion.toBTU(props.project.ngSideKick.totalAvgFlowRate)),
            }
            // {
            //     title: [intl.formatMessage( { id: "avgBTU", defaultMessage: "Avg BTU"})],
            //     value: 
            // }
        ]
    }

    // Contains the values related to Fuel Tickets
    const fuelTicketDict = {
        title: intl.formatMessage({id: "totalNetVolume.label", defaultMessage: "Total Net Volume"}),
        value: fuelTicketUnit === "US G" ? Formatting.numberWithCommas(
                props.project.fuelTicketsTotal !== undefined
                    ? props.project.fuelTicketsTotal.netVolumeTotalGallons
                    : 0)
            : fuelTicketUnit === "L" ? Formatting.numberWithCommas(
                    props.project.fuelTicketsTotal !== undefined
                        ? props.project.fuelTicketsTotal.netVolumeTotalLitres
                        : 0)
                : Formatting.numberWithCommas(
                    props.project.fuelTicketsTotal !== undefined
                        ? props.project.fuelTicketsTotal.netVolumeTotalBTU
                        : 0),
        unit: fuelTicketUnit,
        badge: {},
        elements: [
            {
                title: intl.formatMessage({id: "totalGrossVolume.label", defaultMessage: "Total Gross Volume"}),
                value: fuelTicketUnit === "US G" ? Formatting.numberWithCommas(
                        props.project.fuelTicketTotals !== undefined
                            ? props.project.fuelTicketsTotal.grossVolumeTotalGallons
                            : 0)
                    : fuelTicketUnit === "L" ? Formatting.numberWithCommas(
                            props.project.fuelTicketsTotals !== undefined
                                ? props.project.fuelTicketsTotal.grossVolumeTotalLitres
                                : 0)
                        : Formatting.numberWithCommas(
                            props.project.fuelTicketsTotal !== undefined
                                ? props.project.fuelTicketsTotal.grossVolumeTotalBTU
                                : 0),
            },
            {
                title: intl.formatMessage({id: "avgFlowRate.label", defaultMessage: "Avg Flow Rate"}),
                value: fuelTicketUnit === "US G" ? Formatting.numberWithCommas(
                        props.project.fuelTicketsTotal !== undefined
                            ? props.project.fuelTicketsTotal.avgFlowRateGallons
                            : 0)
                    : fuelTicketUnit === "L" ? Formatting.numberWithCommas(
                            props.project.fuelTicketsTotal !== undefined
                                ? props.project.fuelTicketsTotal.avgFlowRateLitres
                                : 0)
                        : Formatting.numberWithCommas(
                            props.project.fuelTicketsTotal !== undefined
                                ? props.project.fuelTicketsTotal.avgFlowRateBTU
                                : 0),
            },
            {
                title: intl.formatMessage({
                    id: "avgAmbientTemperature.label",
                    defaultMessage: "Avg Ambient Temperature"
                }),
                value: props.project.uom === UnitOfMeasurement.Imperial ? Formatting.numberWithCommas(
                    props.project.fuelTicketsTotal !== undefined
                        ? props.project.fuelTicketsTotal.avgAmbientTemperature : 0) + " °F"
                    : Formatting.numberWithCommas(
                    props.project.fuelTicketsTotal !== undefined
                        ? Conversion.toCelsius(props.project.fuelTicketsTotal.avgAmbientTemperature)
                        : 0) + " °C"
            }
        ]
    }

    // Todo: Figure out logic for showing and hiding the total savings based on bifuel while maintaining the other tooltips
    const totalSavingsDict = {
        title: intl.formatMessage({id: "totalSavings.label", defaultMessage: "Total Savings"}),
        value: `${currencyJson.symbol} ` + Formatting.numberWithCommas(props.project.totalSavings),
        unit: currencyJson.country,
        badge: !props.project.bifuel ? {
            [intl.formatMessage({
                id: "estimatedSavingsDisplacingDiesel.label",
                defaultMessage: "Estimated Savings Displacing Diesel"
            })]: {
                title: [intl.formatMessage({
                    id: "bifuelFracFuellerSavings.message",
                    defaultMessage: "Using a Bi-Fuel Frac Fueller to substitute Diesel with NG could have further reduced emissions by the following:"
                })],
                message: intl.formatMessage({
                    id: "estimatedSavingsDisplacingDiesel.message",
                    defaultMessage: "This is based on the substitution rate set in the Project Info page. If nothing is set, the default is 65%."
                }),
                bifuel: props.project.bifuel
            },
            [intl.formatMessage({id: "estimatedSavingsPerDay.label", defaultMessage: "Estimated Savings Per Day"})]: {
                message: intl.formatMessage({
                    id: "estimatedSavingsPerDay.message",
                    defaultMessage: "This is based on the substitution rate set in the Project Info page. If nothing is set, the default is 65%."
                }),
                bifuel: props.project.bifuel
            }
        } : {},
        elements: [
            // {
            //     title: [intl.formatMessage({ id: "estimatedFuelSavingsByReducedIdleDay.label", defaultMessage: "Estimated Fuel Savings by reduced Idle (Day)"})],
            //     value: '$ ' + Formatting.numberWithCommas(0)
            // },
            {
                title: [intl.formatMessage({
                    id: "estimatedSavingsDisplacingDiesel.label",
                    defaultMessage: "Estimated Savings Displacing Diesel"
                })],
                value: `${currencyJson.symbol} ` + Formatting.numberWithCommas(props.project.estimatedSavingsDisplacingDiesel)
            },
            {
                title: [intl.formatMessage({
                    id: "estimatedSavingsPerDay.label",
                    defaultMessage: "Estimated Savings Per Day"
                })],
                value: `${currencyJson.symbol} ` + Formatting.numberWithCommas(props.project.estimatedSavingsPerDay)
            }
        ]
    }

    if (props.project.resultCount <= 0)
        return <ProjectNoData/>

    return (
        <div className="dashboard">
            {props.project.resultCount > 0 &&
                <div className="dashboard-column-view">
                    <div className="dashboard-row-view dashboard-padding-bottom">
                        {!props.project.standalone.isStandalone &&
                            <Fragment>
                                <DashboardListView
                                    valuesDict={dieselDict}
                                    options={DieselUnits}
                                    selectedValue={DieselUnits[dieselUnit]}
                                    onSelect={setDieselUnit}
                                    parentIndex={1}/>
                                {props.project.bifuel &&
                                    <DashboardListView
                                        valuesDict={natGasDict}
                                        options={NatGasUnits}
                                        selectedValue={NatGasUnits[natGasUnit]}
                                        onSelect={setNatGasUnit}
                                        parentIndex={2}/>
                                }
                                <DashboardListView
                                    valuesDict={pumpTimeDict}
                                    options={PumpTimeUnits}
                                    selectedValue={PumpTimeUnits[pumpTimeUnit]}
                                    onSelect={setPumpTimeUnit}
                                    parentIndex={3}/>
                                {props.project.bifuel &&
                                    <DashboardListView
                                        valuesDict={greenhouseGasDict}
                                        options={GasReductionUnits}
                                        selectedValue={GasReductionUnits[gasReductionUnit]}
                                        onSelect={setGasReductionUnit}
                                        parentIndex={4}/>
                                }
                                <DashboardListView
                                    valuesDict={totalSavingsDict}
                                    selectedValue={intl.formatMessage({
                                        id: currencyJson.label,
                                        defaultMessage: currencyJson.defaultMessage
                                    })}
                                    ParentIndex={5}/>
                            </Fragment>
                        }
                        {props.project.standalone.isStandalone && (props.project.standalone.type == 4) &&
                            <DashboardListView
                                valuesDict={ngSidekickDict}
                                options={NatGasUnits}
                                selectedValue={NatGasUnits[ngSidekickUnit]}
                                onSelect={setNgSidekickUnit}
                                parentIndex={6}/>
                        }
                        {props.project.standalone.isStandalone && (props.project.standalone.type == 6) &&
                            <DashboardListView
                                valuesDict={fuelTicketDict}
                                options={DieselUnits}
                                selectedValue={DieselUnits[fuelTicketUnit]}
                                onSelect={setFuelTicketUnit}
                                parentIndex={7}/>
                        }
                    </div>

                    <div className="dashboard-column-view">
                        <div className="dashboard-row-view dashboard-shift-inner-left">
                            <div className="dashboard-row-view">
                                <div className="dashboardProjectInfo">
                                    <div className="dashboardMultiTitle">
                                        {
                                            <FormattedMessage id="projectInfo.label"
                                                              defaultMessage="Project Info"/>
                                        }
                                    </div>
                                    <div className="clearfix"></div>
                                    <div className="dashboardProjectInfo">
                                        <div className="dashboardProjectInfoLabel">
                                            {
                                                <FormattedMessage id="energyProducer.label"
                                                                  defaultMessage="Energy Producer"/>
                                            }
                                        </div>
                                        <div
                                            className="dashboardProjectInfoValue">{props.project.energyProducerName}</div>
                                    </div>
                                    <div className="clearfix"></div>
                                    <div className="dashboardProjectInfo">
                                        <div className="dashboardProjectInfoLabel">
                                            <FormattedMessage id="serviceProvider.label"
                                                              defaultMessage="Service Provider"/>
                                        </div>
                                        <div
                                            className="dashboardProjectInfoValue">{props.project.companyName}</div>
                                    </div>
                                    {props.project.fuelProviderName !== null &&
                                        <Fragment>
                                            <div className="clearfix"></div>
                                            <div className="dashboardProjectInfo">
                                                <div className="dashboardProjectInfoLabel">
                                                    <FormattedMessage id="fuelProvider.label"
                                                                      defaultMessage="Fuel Provider"/>
                                                </div>
                                                <div
                                                    className="dashboardProjectInfoValue">{props.project.fuelProviderName}</div>
                                            </div>
                                        </Fragment>
                                    }
                                    <div className="clearfix"></div>
                                    <div className="dashboardProjectInfo">
                                        <div className="dashboardProjectInfoLabel">
                                            {
                                                <FormattedMessage id="pad.label" defaultMessage="Pad"/>
                                            }
                                        </div>
                                        <div className="dashboardProjectInfoValue">{props.project.name}</div>
                                    </div>
                                    <div className="clearfix"></div>
                                </div>
                            </div>

                            <div className="dashboard-column-view dashboard-equipment-container">
                                <ConnectedEquipment pumperCounts={props.project.pumperCounts}/>
                                <FracShackEquipment shacks={props.project.uniqueUnitTypes}/>
                            </div>
                            {!props.project.standalone.isStandalone &&
                                <DashboardEnergyConsumedElement project={props.project} bifuel={props.bifuel}/>
                            }
                        </div>

                        <div className="dashboardProjectInfo">
                            <div className="dashboardMultiTitle">
                                {
                                    <FormattedMessage id="projectDownload.label"
                                                      defaultMessage="Project Download"/>
                                }
                            </div>
                            <div className="clearfix"></div>
                            {
                                props.project !== null &&
                                <ExportUnit project={props.project} token={props.token}/>
                            }
                        </div>

                    </div>

                    <div className="clearfix"></div>
                </div>
            }
        </div>
    )
}