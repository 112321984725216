import React from "react";
import {Table} from "reactstrap";
import DocumentLine from "./DocumentLine";
import {FormattedMessage} from "react-intl";

export default function DocumentTable(props) {
    return (
        <>
            <>
                <Table className="documentTable" responsive dark size="lg">
                    <thead>
                    <tr>
                        <th>
                            <FormattedMessage
                                id="fileName.label"
                                defaultMessage="File Name"
                            />
                        </th>
                        <th>
                            <FormattedMessage
                                id="hseRepresentative.label"
                                defaultMessage="HSE Representative"
                            />
                        </th>
                        <th>
                            <FormattedMessage
                                id="dateVisited.label"
                                defaultMessage="Date Visited"
                            />
                        </th>
                        <th>
                            <FormattedMessage
                                id="download.label"
                                defaultMessage="Download"
                            />
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <>
                        {props.Documents.map((doc, index) => {
                            return <DocumentLine doc={doc} index={index + 1}/>
                        })}
                    </>
                    </tbody>
                    <tfoot>
                    <tr>
                        <td/>
                        <td/>
                        <td/>
                        <td/>
                    </tr>
                    </tfoot>
                </Table>
            </>
        </>
    );
}
