import React from 'react';
import DashboardSingle from "./DashboardSingle";
import DashboardListElement from "./DashboardListElement";
import "../css/Dashboard.css";
import DashboardDropdown from "./DashboardDropdown";

export default function DashboardListView(props)
{
    return (
        <div className="dashboard-column-view">
            <React.Fragment>
                {props.options !== undefined && props.selectedValue !== undefined && props.onSelect !== undefined &&
                    <DashboardSingle title={props.valuesDict.title} value={props.valuesDict.value} options={props.options} selectedValue={props.selectedValue} onSelect={props.onSelect}/>
                }
                {props.options === undefined && props.selectedValue === undefined && props.onSelect === undefined &&
                    <DashboardSingle title={props.valuesDict.title} value={props.valuesDict.value}/>
                }
                {props.options === undefined && props.onSelect === undefined && props.selectedValue !== undefined &&
                    <DashboardSingle title={props.valuesDict.title} value={props.valuesDict.value} selectedValue={props.selectedValue}/>
                }
                
                {props.valuesDict.elements.map((listElementObject, index) => {
                        if (props.valuesDict.badge[listElementObject.title])
                            return <DashboardListElement title={listElementObject.title}
                                                         value={listElementObject.value}
                                                         message={props.valuesDict.badge[listElementObject.title]}
                                                         parentIndex={props.parentIndex}
                                                         childIndex={index}/>
                        else
                            return <DashboardListElement title={listElementObject.title} value={listElementObject.value} />
                })}
                
            </React.Fragment>
        </div>
    )
}