import React from 'react';
import {FormattedMessage} from "react-intl";
export default function ProjectInformation(props)
{
    return (
        <div className="projectInfoContainer">
            <div className="projectInfoTitle">
                <FormattedMessage id="projectInfo.label" defaultMessage="Project Info" />
                /
                <FormattedMessage id="generalInfo.label" defaultMessage="General Info" />
            </div>
            <div className="projectInfoDataContainer">
                <div className="projectInfoDataLabel">
                    <FormattedMessage id="energyProducer.label" defaultMessage="Energy Producer" />
                </div>
                <div className="projectInfoValue">
                    {props.project.energyProducerName}
                </div>
            </div>
            <div className="projectInfoDataContainer">
                <div className="projectInfoDataLabel">
                    <FormattedMessage id="serviceCompany.label" defaultMessage="Service Company" />
                </div>
                <div className="projectInfoValue">
                    {props.project.companyName}
                </div>
            </div>
            <div className="projectInfoDataContainer">
                <div className="projectInfoDataLabel">
                    <FormattedMessage id="projectName.label" defaultMessage="Project Name" />
                </div>
                <div className="projectInfoValue">
                    {props.project.name}
                </div>
            </div>
            <div className="projectInfoDataContainer">
                <div className="projectInfoDataLabel">
                    <FormattedMessage id="date.label" defaultMessage="Date" />
                </div>
                <div className="projectInfoValue">
                    {props.project.startDateURL}
                </div>
            </div>
        </div>
    );
}
