import React, {Fragment, useContext, useEffect, useState} from 'react';
import {FormattedMessage, useIntl} from "react-intl";
import {ProjectMenuContext} from "../Contexts/ProjectMenuContext";
import {ReactComponent as WhiteUp} from "../../../images/whiteUp.svg";
import {ReactComponent as WhiteDown} from "../../../images/whiteDown.svg";
import ReactGA from "react-ga";
import {ReactComponent as BlackRight} from "../../../images/arrowRightBlack.svg";
import {ProjectContext} from "../Contexts/ProjectContext";

export default function DailyTotals(props) {
    const {closeMobileMenu} = useContext(ProjectMenuContext);
    const {project, setMobileText, setActiveSubPage, setActivePage} = useContext(ProjectContext);
    const [showDailyTotalsDropdown, setShowDailyTotalsDropdown] = useState(false);
    const intl = useIntl();

    useEffect(() => {
        if (project.activePage !== "Daily Totals") {
            setShowDailyTotalsDropdown(false);
        }
    }, [project.activePage])

    const activePage = () => {
        return project.activePage === "Daily Totals" ? 'siteTitle siteTitleActive menuItem menuItemActive' : 'siteTitle menuItem'
    }

    const toggleDailyTotalsDropdown = () => {
        switch (project.activePage) {
            case "Daily Totals":
                setShowDailyTotalsDropdown(!showDailyTotalsDropdown);
                break;
            default:
                setShowDailyTotalsDropdown(true);

                if (!project.currentProject.standalone.isStandalone) {
                    setActiveSubPage("consumed");
                    requestDailyTotals();
                } else {
                    setActiveSubPage("processed");
                    requestNgDailyTotals();
                }
        }
    }

    const requestDailyTotals = () => {
        setActiveSubPage('consumed');
        setMobileText(intl.formatMessage({id: "dailyTotalsConsumed.label", defaultMessage: "Daily Totals Consumed"}));

        if (project.activePage !== "Daily Totals") {
            setActivePage("Daily Totals");
        } else {
            closeMobileMenu();
        }

        // Google Analytics
        ReactGA.event({
            category: "Project Menu Selection",
            action: `Clicked on "Daily Totals: Consumed" on Project "${window.location.pathname}"`
        })

        // Google Tag Manager
        window.dataLayer.push({
            'event': "Pageview",
            'pagePath': `${window.location.pathname}/daily-totals/consumed`,
            'pageTitle': `Daily Totals - Consumed`
        })
    }

    const requestNgDailyTotals = () => {
        setActiveSubPage('processed');
        setMobileText(intl.formatMessage({id: "dailyTotalsProcessed.label", defaultMessage: "Daily Totals Processed"}));

        if (project.activePage !== "Daily Totals") {
            setActivePage("Daily Totals");
        } else {
            closeMobileMenu();
        }

        // Google Analytics
        ReactGA.event({
            category: "Project Menu Selection",
            action: `Clicked on "Daily Totals: Processed" on Project "${window.location.pathname}"`
        })

        // Google Tag Manager
        window.dataLayer.push({
            'event': "Pageview",
            'pagePath': `${window.location.pathname}/daily-totals/processed`,
            'pageTitle': `Daily Totals - Processed`
        })
    }

    const requestFuelLevelDailyTotals = () => {
        setActiveSubPage('fuelLevel');
        setMobileText(intl.formatMessage({id: "dailyFuelLevelTotals.label", defaultMessage: "Daily Fuel Level Totals"}));
        closeMobileMenu();

        // Google Analytics
        ReactGA.event({
            category: "Project Menu Selection",
            action: `Clicked on "Daily Totals: Fuel Levels" on Project "${window.location.pathname}"`
        })

        // Google Tag Manager
        window.dataLayer.push({
            'event': "Pageview",
            'pagePath': `${window.location.pathname}/daily-totals/fuel-levels`,
            'pageTitle': `Daily Totals - Fuel Levels`
        })
    }

    const requestByWells = () => {
        setActiveSubPage('byWells');

        if (project.activePage !== "Daily Totals")
            setActivePage("Daily Totals");
        else
            closeMobileMenu();
    }

    return (
        <Fragment>
            {!props.mobile &&
            <h1 onClick={() => toggleDailyTotalsDropdown()} className={activePage()}>
                <FormattedMessage id="dailyTotals.label" defaultMessage="Daily Totals"/>
                {showDailyTotalsDropdown && <WhiteUp className="floatRight"/>}
                {!showDailyTotalsDropdown && <WhiteDown className="floatRight"/>}
            </h1>
            }
            
            {props.mobile &&
            <div className="entireSiteMobile" onClick={() => toggleDailyTotalsDropdown()}>
                <div className="mobileText">
                    <FormattedMessage id="dailyTotals.label" defaultMessage="Daily Totals"/>
                </div>
                <div className="floatRight mobileArrow"><BlackRight/></div>
            </div>
            }
            
            {showDailyTotalsDropdown &&
            <Fragment>
                <div className={`${project.activeSubPage === 'consumed' ? "wellTitleActive subMenuItemActive" : ""} wellTitle subMenuItem`}
                     onClick={() => requestDailyTotals()}>
                    <FormattedMessage id="dailyTotalsConsumed.label" defaultMessage="Daily Totals Consumed"/>
                </div>
                
                {project.currentProject.ngSideKick.ngSideKickWells.length > 0 &&
                <Fragment>
                    <div className={`${project.activeSubPage === 'processed' ? "wellTitleActive subMenuItemActive" : ""} wellTitle subMenuItem`}
                         onClick={() => requestNgDailyTotals()}>
                        <FormattedMessage id="dailyTotalsProcessed.label" defaultMessage="Daily Totals Processed"/>
                    </div>
                    {project.currentProject.fuelLevel.fuelLevelDailyTotals.length > 0 &&
                    <div className={`${project.activeSubPage === 'fuelLevel' ? "wellTitleActive subMenuItemActive" : ""} wellTitle subMenuItem`}
                         onClick={() => requestFuelLevelDailyTotals()}>
                        <FormattedMessage id="dailyFuelLevelTotals.label" defaultMessage="Daily Fuel Level Totals"/>
                    </div>
                    }
                </Fragment>
                }
                
                <div className={`${project.activeSubPage === 'byWells' ? "wellTitleActive subMenuItemActive" : ""} wellTitle subMenuItem`}
                     onClick={() => requestByWells()}>
                    <FormattedMessage id="bywells.label" defaultMessage="By Wells"/>
                </div>
            </Fragment>
            }
        </Fragment>
    )
}