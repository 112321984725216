import React, {useEffect, useState, Fragment, useContext} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {actionCreators} from '../store/Projects';
import Dashboard from "./common/ProjectMenu/Dashboard";
import NgSideKick from "./common/ProjectMenu/NgSideKick";
import FuelTicket from "./common/ProjectMenu/FuelTicket";
import GreenhouseGas from "./common/ProjectMenu/GreenhouseGas";
import Pumpers from "./common/ProjectMenu/Pumpers";
import Wells from "./common/ProjectMenu/Wells";
import SiteDocuments from "./common/ProjectMenu/SiteDocuments";
import Home from "./common/ProjectMenu/Home";
import ProjectInfo from "./common/ProjectMenu/ProjectInfo";
import DailyTotals from "./common/ProjectMenu/DailyTotals";
import {ProjectMenuContext} from "./common/Contexts/ProjectMenuContext";
import {ProjectContext} from "./common/Contexts/ProjectContext";
import Reporting from "./common/ProjectMenu/Reporting";
import OperatingUnit from "./common/ProjectMenu/OperatingUnit";
import FuelLevel from "./common/ProjectMenu/FuelLevel";
import Features from "./common/ProjectMenu/Features";

function ProjectMenu(props) {
    const {projectMenu, removeMobileButton, closeMobileMenu} = useContext(ProjectMenuContext)
    const {project} = useContext(ProjectContext);
    const [baseURL, setBaseURL] = useState('');

    useEffect(() => {
        var pathVars = window.location.pathname.split('/');
        setBaseURL('/' + pathVars[1] + '/' + pathVars[2] + '/' + pathVars[3]);
    }, []);

    return (
        <Fragment>
            {props.mobile === false && project.currentProject !== null &&
                <Fragment>
                    {/*<Home />*/}

                    <Dashboard mobile={props.mobile}
                               projectStats={props.projectStats}/>
                    
                    {props.project.resultCount !== 0 &&
                        <>
                            <DailyTotals mobile={props.mobile}/>

                            {!project.currentProject.standalone.isStandalone &&
                                <Fragment>
                                    <Pumpers pumperStats={props.pumperStats}
                                             mobile={props.mobile}/>
                                    <Wells projectStats={props.projectStats}
                                           mobile={props.mobile}/>
                                    <FuelTicket mobile={props.mobile}/>
                                    <FuelLevel projectStats={props.projectStats}
                                               mobile={props.mobile}/>
                                    <GreenhouseGas mobile={props.mobile}/>
                                </Fragment>
                            }
                            <NgSideKick projectStats={props.projectStats}
                                        mobile={props.mobile}/>

                            {/*<OperatingUnit units={props.units} */}
                            {/*               mobile={props.mobile}/>*/}

                            <Reporting mobile={props.mobile}/>

                            {props.showSiteDocuments &&
                                (<SiteDocuments mobile={props.mobile}
                                                showHseSiteVisits={props.showHseSiteVisits}
                                                serviceEndpoint={props.serviceEndpoint}
                                                baseURL={baseURL}/>)}

                            <Features modile={props.mobile}
                                      showConfiguration={props.showConfiguration}
                                      serviceEndpoint={props.serviceEndpoint}
                                      baseURL={baseURL}/>
                        </>
                    }

                    <ProjectInfo mobile={props.mobile}
                                 showConfiguration={props.showConfiguration}
                                 showUserManagement={props.showUserManagement}
                                 serviceEndpoint={props.serviceEndpoint}
                                 baseURL={baseURL}/>
                </Fragment>
            }
            {props.mobile === true && project.currentProject !== null &&
                <Fragment>
                    <div className="floatLeft whiteBackground">
                        {projectMenu.showMobileButton &&
                            <div className="mobileButton" onClick={removeMobileButton}>{project.mobileText} X</div>
                        }
                        <div className="wellListMobileContainer">
                            {projectMenu.showMobileMenu && (props.pumperStats === 'true' || props.pumperStats === true) &&
                                <Fragment>
                                    <div className="closeMobileMenu" onClick={closeMobileMenu}>Close Menu</div>

                                    <Dashboard mobile={props.mobile}
                                               projectStats={props.projectStats}/>
                                    
                                    {props.project.resultCount !== 0 &&
                                        <>
                                            <DailyTotals mobile={props.mobile}/>

                                            {!project.currentProject.standalone.isStandalone &&
                                                <Fragment>
                                                    <Pumpers pumperStats={props.pumperStats}
                                                             mobile={props.mobile}/>
                                                    <Wells projectStats={props.projectStats}
                                                           mobile={props.mobile}/>
                                                    <FuelTicket mobile={props.mobile}/>
                                                    <FuelLevel projectStats={props.projectStats}
                                                               mobile={props.mobile}/>
                                                    <GreenhouseGas mobile={props.mobile}/>
                                                </Fragment>
                                            }

                                            <NgSideKick projectStats={props.projectStats}
                                                        mobile={props.mobile}/>

                                            {/*<OperatingUnit units={props.units}*/}
                                            {/*               mobile={props.mobile}/>*/}
                                            {/*            */}
                                            {/*<Reporting mobile={props.mobile}/>*/}

                                            <SiteDocuments mobile={props.mobile}
                                                           serviceEndpoint={props.serviceEndpoint}
                                                           baseURL={baseURL}/>

                                            <Features modile={props.mobile}
                                                      showConfiguration={props.showConfiguration}
                                                      serviceEndpoint={props.serviceEndpoint}
                                                      baseURL={baseURL}/>
                                        </>
                                    }

                                    <ProjectInfo mobile={props.mobile}
                                                 showConfiguration={props.showConfiguration}
                                                 showUserManagement={props.showUserManagement}
                                                 serviceEndpoint={props.serviceEndpoint}
                                                 baseURL={baseURL}/>


                                </Fragment>
                            }
                        </div>
                    </div>
                    <div className="clearfix"></div>
                </Fragment>
            }
        </Fragment>
    )
}

export default connect(
    state => state.project,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(ProjectMenu);