import React from 'react';
import {FormattedMessage} from "react-intl";

export default function OverdueJobRow(props) {
    return (
        <tr>
            <td>{props.job.jobId}</td>
            <td>{props.job.jobCode}</td>
            <td>{props.job.jobName}</td>
            <td>{props.job.shackUnits}</td>
            <td>{props.job.startDateFormatted}</td>
            <td>
                <div className="projectInfoValue">
                    <div className="input-container">
                        <label className="units-of-measure-toggle">
                            <input type="checkbox" value={props.job.sendNotification} onChange={() => props.onClick(props.job.jobId)}/>
                            <span className="units-of-measure-slider"/>

                            <div className="units-of-measure-text-container">
                                            <span
                                                className={`units-of-measure-text${!props.job.sendNotification ? '--active' : ''}`}>
                                                <FormattedMessage id="unsubscribe.label" defaultMessage="Unsubscribe"/>
                                            </span>
                                <span
                                    className={`units-of-measure-text${props.job.sendNotification ? '--active' : ''}`}>
                                                <FormattedMessage id="subscribe.label" defaultMessage="Subscribe"/>
                                            </span>
                            </div>
                        </label>
                    </div>
                </div>
            </td>
        </tr>
    )
}
