import React, {Fragment} from 'react';
import {FormattedMessage} from "react-intl";

export default function NgSidekickWellList(props) {

    const requestNgSidekickWellStats = (well) => {
        props.onClick(well);
    }

    const requestProjectStats = () => {
        props.onProjectClick();
    }

    return (
        <Fragment>
            {
                <Fragment>
                    <Fragment>
                        {(props.allProjects === 'true' || props.allProjects === true) &&
                        <div
                            className={(props.activeWell != null && props.activeWell === 'all') ? 'wellTitle wellTitleActive subMenuItem subMenuItemActive' : 'wellTitle subMenuItem'}
                            onClick={() => requestProjectStats()}>
                            <FormattedMessage id="allWells.label" defaultMessage="All Wells"/>
                        </div>
                        }
                    </Fragment>
                    <Fragment>
                        {props.wells != null && props.wells.map(well =>
                            <Fragment key={well.wellName}>
                                <div
                                    className={props.activeWell != null && props.activeWell.wellName === well.wellName ? 'wellTitle wellTitleActive subMenuItem subMenuItemActive' : 'wellTitle subMenuItem'}
                                    onClick={() => requestNgSidekickWellStats(well)}>
                                    {well.wellName !== '' ? <span><FormattedMessage id="well.label" defaultMessage="Well"/> {well.wellName}</span> 
                                        : <i><FormattedMessage id="unknownWell.label" defaultMessage="Unknown Well"/></i>}
                                </div>
                            </Fragment>
                        )}
                    </Fragment>
                </Fragment>
            }
        </Fragment>
    );

}