import React, {useState, useEffect, Fragment, useContext} from "react";
import {
    Col
} from "reactstrap";
import {
    AppMetadata,
    IAddUserParams,
    EnergyProducerPermission
} from '../../../interfaces/User';
import {toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {useAuth0} from "../../../auth/auth0-wrapper";
import {
    getUsersActionCreator,
    checkAccessActionCreator,
    getUserRolesActionCreator
} from "../../../store/ManageUsers";
import {connect} from "react-redux";
import {actionCreators as actionCreatorsHeader} from "../../../store/Header";
import {actionCreators as actionCreatorsProducer} from "../../../store/Producers";
import Loader from "../../common/Loader/Loader";
import Unauthorized from "../../Unauthorized";
import slugify from '../../../helpers/slugify';
import {FormattedMessage, useIntl} from "react-intl";
import {getAppMetadata, getUserLanguagePreference} from "../../../helpers/metadataHelper";
import {LocalizationContext} from "../../common/Localization/LanguageWrapper";
import {setState, getState} from "litsy";
import Axios from "axios";
import ApiTokenTable from "./ApiTokenTable";
import UserManagement from "./UserManagement";
import {
    isITSiteAdmin
} from "../../../auth/accessControlService";

declare var global: any;
export const isValidEmail = (email: string) => {
    const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(email);
};

export interface ApiToken {
    value: string;
    tokenType: string;
    tokenTier: string;
    isActive: boolean;
    createdDate: string;
    expiryDate: string;
}

export enum UserManagementType {
    EnergyProducerUser,
    ProjectUser
}

const ManageUsers = (props: any) => {
    const energyProducerName = props.match.params.producer;
    const projectName = props.match.params.project;
    const startDate = props.match.params.startdate;
    const completeProjectName = startDate.length > 0 ? projectName + '/' + startDate : projectName;

    const {user, logout} = useAuth0();

    toast.configure();
    const {getTokenSilently, loading} = useAuth0();
    const [token, setToken] = useState(null);

    const [appSettingsComplete, setAppSettingsComplete] = useState(false);
    const [fullProjectId, setFullProjectId] = useState("");
    const localizationContext = useContext(LocalizationContext);

    const getFullProjectId = async () => {
        const url = `${getState("fracShack_apiEndpoint", "session")}project/${projectName}/fullProjectId?producerName=${energyProducerName}&startTime=${startDate}`
        let result: string = (await Axios.get(url, {
            headers: {
                "Authorization": `Bearer ${getState("authToken", "session")}`
            }
        })).data

        setFullProjectId(result);
    };

    useEffect(() => {
        appSettings();
        const fetchToken = async () => {
            const auth0Token = await getTokenSilently();
            setState("authToken", auth0Token, "session");
            setToken(auth0Token);
        };
        fetchToken();

        let metaDataValue = getUserLanguagePreference(user);
        let languagePreference = "en-US";

        if (metaDataValue !== null && metaDataValue !== undefined) {
            if (metaDataValue.languagePreference !== null) {
                languagePreference = metaDataValue.languagePreference;
            }
        } else {
            languagePreference = "en-US";
        }
        // @ts-ignore
        localizationContext.selectLang(languagePreference);
    }, []);

    useEffect(() => {
        getFullProjectId()
    }, [token, projectName, energyProducerName, startDate])

    useEffect(() => {
        if (!loading && token && appSettingsComplete) {
            //check if a user should have access to this page
            props.checkAccess(energyProducerName, completeProjectName, token)
            
            // Get roles set able by user
            isITSiteAdmin(getState("fracShack_apiEndpoint", "session"), getState("authToken", "session")).then(r => {
                if (r)
                    props.getUserRoles(getState("authToken", "session"), "Company Sales");
                else
                    getRoles();
            });
        }
    }, [token, appSettingsComplete]);
    useEffect(() => {
        if (!loading && token && appSettingsComplete && props.users.isUserProjectAdmin) {
            props.getUsers({
                EnergyProducerName: energyProducerName,
                ProjectName: completeProjectName,
                token: token
            });

            if ((!props.producers || !props.producers.producers || props.producers.length <= 0) || props.producers.length === undefined) {
                props.requestProducers(token);
            }


        }
    }, [token, appSettingsComplete, props.users.isUserProjectAdmin]);

    useEffect(() => {
        //find project in producer/project list (so we can get original formatting)
        if (props.producers.producers.length > 0) {
            let producer = props.producers.producers.find((x: any) => slugify(x.name ?? "") == energyProducerName);
            if (producer !== null && producer !== undefined) {
                let producerProject = producer.projects.find((x: any) => slugify(x.name ?? "") == projectName);
                if (producerProject !== undefined && producerProject !== null) {
                    props.setHeaderProject({
                        name: producerProject.name,
                        energyProducerName: producer.name,
                        startTime: startDate
                    })
                } else {
                    props.setHeaderProject({
                        name: projectName,
                        energyProducerName: energyProducerName,
                        startTime: startDate
                    })
                }
            } else {
                props.setHeaderProject({
                    name: projectName,
                    energyProducerName: energyProducerName,
                    startTime: startDate
                })
            }
            props.showHeaderNavMenuButton(true)
        }
    }, [props.producers.producers]);

    useEffect(() => {
        if (props.users && props.users.isError === true && props.users.loading === false && (props.users.isUnauthorized === false || props.users.isSubmissionUnauthorized === true)) {
            toast.error(props.users.error, {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }, [props.users]);

    const appSettings = () => {
        global.apiEndpoint = process.env.REACT_APP_API_URL;
        setState("fracShack_apiEndpoint", process.env.REACT_APP_API_URL, "session");
        setAppSettingsComplete(true);
    };

    const getRoles = async () => {
        const appMetadata: AppMetadata = getAppMetadata(user);

        if (appMetadata.access !== null && appMetadata.access !== undefined) {
            var energyProducerList: EnergyProducerPermission[] = appMetadata.access.energyProducers;
            var energyProducer = energyProducerList.find(energyProducer => {
                return energyProducer.name === energyProducerName
            })

            if (energyProducer !== null && energyProducer !== undefined) {
                if (energyProducer.role !== null && energyProducer.role !== undefined) {
                    props.getUserRoles(getState("authToken", "session"), energyProducer.role);
                }
            }
        }
    }

    const goBack = () => {
        window.location.assign(window.location.href.replace('/admin', ''));
    }

    if (props.users.loading === false && props.users.isUnauthorized === true || (props.users.checkingAuth !== undefined && props.users.checkingAuth === false && props.users.isUserProjectAdmin !== undefined && props.users.isUserProjectAdmin === false)) {
        return <Unauthorized/>
    } else {
        return (
            <Fragment>
                {props.users.isUserProjectAdmin && props.users.loading != true && (
                    <div className="padding-top-lg">
                        <div className="noLeftPadding noRightPadding projectMenu col-2"></div>
                        <Col md={{offset: 1, size: 10}} sm={12}>
                            <h2><FormattedMessage id="manageUsers.label" defaultMessage="Manage Users"/></h2>
                            <div className="backLinkAdmin" onClick={() => goBack()}>
                                <FormattedMessage id="backToProject.label" defaultMessage="Back to project"/>
                            </div>
                            <UserManagement token={token}
                                            energyProducerName={energyProducerName}
                                            projectName={completeProjectName}
                                            users={props.users}
                                            roles={props.roles}/>
                            <ApiTokenTable/>
                        </Col>
                    </div>
                )}
                {(props.users.loading === true) &&
                <div className="center">
                    <Loader/>
                </div>
                }
            </Fragment>
        )
    }
};

const mapStateToProps = (store: any) => {
    return {
        users: store.users,
        roles: store.roles.roles,
        usersLoading: store.loading,
        usersSubmitting: store.posting,
        producers: store.producers
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        getUsers: (project: IAddUserParams) => dispatch(getUsersActionCreator(project)),
        checkAccess: (energyProducerName: string, completeProjectName: string, token: string) =>
            dispatch(checkAccessActionCreator(energyProducerName, completeProjectName, token)),
        setHeaderProject: (project: { name: string, energyProducer: string, startTime: string }) =>
            dispatch(actionCreatorsHeader.setProject(project)),
        showHeaderNavMenuButton: (show: boolean) => dispatch(actionCreatorsHeader.setShowNavMenuButton(show)),
        requestProducers: (token: string) => dispatch(actionCreatorsProducer.requestProducers(token)),
        getUserRoles: (token: string, role: string) => dispatch(getUserRolesActionCreator(token, role))
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ManageUsers);
