import React, {Fragment, useState} from 'react';
import * as Formatting from '../helpers/formatting';
import { FormattedMessage } from "react-intl";
import ReactGA from "react-ga";
import {NaturalGasUnit} from "../enums";


export default function PumperLine(props)
{
    const requestPumper = (pumper) => {
        props.onPumperClick(pumper);
    };

    const requestPumperStage = (pumper) => {
        props.onStageClick(pumper);
    }

    const onPumperStageClick = () => {
        props.onPumperStageClick(props.pumper);
        
        // Google Analytics
        ReactGA.event({
            category: "Project Menu Selection",
            action: `Clicked on "Pumper ${props.pumper.name}" on Project "${window.location.pathname}"`
        })

        // Google Tag Manager
        window.dataLayer.push({
            'event': "Pageview",
            'pagePath': `${window.location.pathname}/pumpers/${props.pumper.name}`,
            'pageTitle': `Pumper ${props.pumper.name}`
        })
    }

    return(
        <Fragment>
            <tr>
                <td>
                    <div className={props.pumper === props.activeLine ? 'pumperLine pumperLineActive' : 'pumperLine'} onClick={() => props.single ? requestPumperStage(props.pumper) : onPumperStageClick(props.pumper)}>
                        {props.single === true && 
                            <Fragment>
                                <div className="pumperLineName">
                                    <FormattedMessage id="well.label" defaultMessage="Well" />
                                    {' ' + props.pumper.wellName + ' '}
                                    <FormattedMessage id="stage.label" defaultMessage="Stage" />
                                    {' ' + props.pumper.stageNumber + ' '}
                                </div>
                            </Fragment>
                        }
                        {props.single !== true && 
                            <Fragment>
                                <div className="pumperLineName">
                                    <div className="pumperLineMobileTitle">
                                        <FormattedMessage id="pumpers.label" defaultMessage="Pumper" />
                                    </div>
                                    <div className="pumperLineName1 pumperNameLink" onClick={onPumperStageClick}>{props.pumper.name}</div>
                                    <div className="pumperLineName2">{props.pumper.unitName}</div>
                                </div>
                            </Fragment>
                        }
                        <div className="pumperLineValue">
                            <div className="pumperLineMobileTitle">
                                <FormattedMessage id="diesel.label" defaultMessage="Diesel" />
                            </div>
                            <div className="pumperLineName1">{Formatting.numberWithCommas(props.pumper.totalDiesel)}{props.unit}</div>
                        </div>
                        {props.bifuel && 
                            <div className="pumperLineValueGas">
                                <div className="pumperLineMobileTitle">
                                    <FormattedMessage id="naturalGas.label" defaultMessage="Natural Gas" />
                                </div>
                                <div className="pumperLineName1">{Formatting.numberWithCommas(props.unit === "L" ? props.pumper.totalDE : props.pumper.totalDEGallons)} D{props.unitShort}E</div>
                                {props.naturalGasUnit === NaturalGasUnit.SCF && 
                                    <div className="pumperLineName2">{Formatting.numberWithCommas(props.pumper.totalSCF)} SCF</div>
                                }
                                {props.naturalGasUnit === NaturalGasUnit.MCF &&
                                    <div className="pumperLineName2">{Formatting.numberWithCommas(props.pumper.totalMCF)} MCF</div>
                                }
                                {props.naturalGasUnit === NaturalGasUnit.E3M3 &&
                                    <div className="pumperLineName2">{Formatting.numberWithCommas(props.pumper.totalDEC, 4)} E3M3</div>
                                }
                            </div>}
                        {props.bifuel && 
                            <div className="pumperLineEnergy">
                                <div className="pumperLineMobileTitle">
                                    <FormattedMessage id="consumedEnergy.label" defaultMessage="Consumed Energy" />
                                </div>
                                <div className="pumperLineName1">{Formatting.numberWithCommas(props.unit === "L" ? props.pumper.totalDLE : props.pumper.totalDGE)} D{props.unitShort}E</div>
                                <div className="pumperLineName2">{Formatting.numberWithCommas(props.pumper.totalBTU)} BTU</div>
                            </div>
                        }
                        {props.single !== true &&
                            <div className="pumperLineStages">
                            <div className="pumperLineMobileTitle">
                                <FormattedMessage id="activeNumberOfStages.label" defaultMessage="Active # of Stages" />
                            </div>
                                <div className="pumperLineName1 floatRightNoMargin">{props.pumper.stages.filter(x => x.totalDE > 0 || x.totalDiesel > 0).length}</div>
                            </div>
                        }
                        
                        {props.bifuel && <div className="pumperLineSubRate">
                            <div className="pumperLineMobileTitle">
                                <FormattedMessage id="substitutionRate.label" defaultMessage="Substitution Rate" />
                            </div>
                            <div className="pumperLineName1 floatRightNoMargin">{props.pumper.subRate}%</div>
                        </div>}
                        
                        {props.single !== true && props.bifuel && <div className="pumperLineSubRate">
                            <div className="pumperLineMobileTitle">
                                <FormattedMessage id="engineType.label" defaultMessage="Engine Type" />
                            </div>
                            <div className="pumperLineName1 floatRightNoMargin">{props.pumper.engineType}</div>
                        </div>}
                        
                        {props.single === true && 
                            <Fragment>
                                <div className="pumperLineFinishTime">
                                    <div className="pumperLineMobileTitle">
                                        <FormattedMessage id="finishTimeUTC.label" defaultMessage="Finish Time (UTC)" />
                                    </div>
                                    <div className="pumperLineName1 floatRightNoMargin">{props.pumper.endTimeString}</div>
                                </div>
                                <div className="pumperLineStartTime">
                                    <div className="pumperLineMobileTitle">
                                        <FormattedMessage id="startTimeUTC.label" defaultMessage="Start Time (UTC)" />
                                    </div>
                                    <div className="pumperLineName1 floatRightNoMargin">{props.pumper.startTimeString}</div>
                                </div>
                            </Fragment>
                        }
                    </div>
                </td>
            </tr>
            <div className="clearfix"></div>
        </Fragment>
    )
}