import React from "react";
import {FormattedMessage} from "react-intl";
import DocumentTable from "./common/DocumentTables/DocumentTable";

export default function HseSiteVisits(props) {
      return (
        <>
            <div className="projectInfoContainer" style={{width: '98%'}}>
                <div className="projectInfoTitle">
                    <FormattedMessage
                        id="hseSiteVisitsTitle.label"
                        defaultMessage="HSE Site Visits Documents"
                    />
                </div>
                    <DocumentTable
                        Documents={props.project.hseSiteVisitPDFs}
                    />
            </div>
        </>
    );
}
