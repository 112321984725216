import React, { Fragment } from 'react';
import ProjectChartContainer from './ProjectChartContainer';
import ExportUnit from './ExportUnit.js';
import {FormattedMessage} from "react-intl";

export default function ProjectStatsContainer(props) {

    return (
        <Fragment>
            {
                props.project !== null && 
                    <Fragment>
                        <div className="pumperTitle">
                            <FormattedMessage id="allWells.label" defaultMessage="All Wells" />
                        </div>
                        {
                        props.project !== null && 
                            <ExportUnit project={props.project} token={props.token} type="allWells" />
                        }

                        <ProjectChartContainer project={props.project} type="productivity" />
                        {(props.showSubRate === true || props.showSubRate === 'true') && 
                            <ProjectChartContainer project={props.project} type="consumption" />
                        }
                    </Fragment>
            }
        </Fragment>
    )
 
}