import React from "react";
import {FormattedMessage} from "react-intl";

export default function AdminToggle(props) {
    const onChange = () => {
        let newOverdueJobSubscription = {
            ...props.overdueJobSubscription,
            [props.objType]: !props.overdueJobSubscription[props.objType]
        };
        
        props.setOverdueJobSubscription(newOverdueJobSubscription);
    }

    return (
        <div className="flex-column">
            <h4>
                <FormattedMessage id={`${props.label}.label`} defaultMessage={props.defaultMessage} />
            </h4>
            <div className="projectInfoValue">
                <div className="input-container">
                    <label className="units-of-measure-toggle">
                        <input type="checkbox" value={props.overdueJobSubscription[props.objType]} onChange={() => onChange()}/>
                        <span className="units-of-measure-slider"/>

                        <div className="units-of-measure-text-container">
                                                <span
                                                    className={`units-of-measure-text${!props.overdueJobSubscription[props.objType] ? '--active' : ''}`}>
                                                    <FormattedMessage id="unsubscribe.label"
                                                                      defaultMessage="Unsubscribe"/>
                                                </span>
                            <span
                                className={`units-of-measure-text${props.overdueJobSubscription[props.objType] ? '--active' : ''}`}>
                                                    <FormattedMessage id="subscribe.label" defaultMessage="Subscribe"/>
                                                </span>
                        </div>
                    </label>
                </div>
            </div>
        </div>
    )
}