const setShowNavMenuButtonType = 'SET_SHOW_NAV_MENU_BUTTON'
const setProjectType = 'SET_PROJECT'
const projectErrorType = 'PROJECT_ERROR'

const initialState = {
	showNavMenuButton: false,
	project: null
}

export const actionCreators = {
	setShowNavMenuButton: (showNavMenuButton) => (dispatch) => {
		dispatch({ type: setShowNavMenuButtonType, showNavMenuButton })
	},
	setProject: (project) => (dispatch) => {
		dispatch({ type: setProjectType, project })
	}
}

export const reducer = (state, action) => {
	state = state || initialState;

	if (action.type === projectErrorType)
	{
		return {
			...state,
			showNavMenuButton: true
		}
	}

	if (action.type === setShowNavMenuButtonType) {
		return {
			...state,
			showNavMenuButton: true
		}
	}
	
	if (action.type === setProjectType) {
		return {
			...state,
			project: action.project
		}
	}

	return state
}