import React, { Fragment, useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap'
import InfoIcon from '../images/icons8-info-16.png';
import {UncontrolledTooltip} from "reactstrap";
import * as Formatting from '../helpers/formatting';
import {ReactComponent as WhiteUp} from '../images/whiteUp.svg';
import {ReactComponent as WhiteDown} from '../images/whiteDown.svg';
import WellLine from '../components/WellLine.js';
import { FormattedMessage, useIntl } from "react-intl";
import ConvertStringToLabel from "../helpers/ConvertStringToLabel";
import _ from "lodash";
import {NaturalGasUnit} from "../enums";

export default function PumperSummary(props)
{
    const intl = useIntl();
    const [activeSort, setActiveSort] = useState(null);
    const [sortAsc, setSortAsc] = useState(false);

    const [stages, setStages] = useState(null);
    const [wellList, setWellList] = useState(null);

    let _sortAsc = false;

    useEffect(() => {
        if (props.well !== undefined)
        {
            setStages(props.well.stages);
        }
        if (props.project.wells !== undefined)
        {
            setWellList(props.project.wells);
        }
    }, []);

    const requestWell = (well) => {
        props.requestWell(well);
    };

    useEffect(() => {
        if (props.well !== undefined)
        {
            setStages(props.well.stages);
        }
    }, [props.well]);

    const [showInfo, setShowInfo] = useState(false);
    
    const toggleInfoIcon = () =>
    {
        setShowInfo(!showInfo);
    }

    const sortData = (column) => {
        _sortAsc = sortAsc;

        if (column === activeSort)
        {
            setSortAsc(!sortAsc);
            _sortAsc = !_sortAsc;
        }

        setActiveSort(column);
        
        if (column === 'diesel')
        {
            setWellList(wellList.sort(dieselCompare).slice());
            if (stages !== null)
            {
                setStages(stages.sort(dieselCompare).slice());
            }
        }
        else if (column === 'Lines')
        {
            setWellList(wellList.sort(linesCompare).slice());
        }
        else if (column === 'Stages')
        {
            setStages(stages.sort(stageCompare).slice());
        }
        else if (column === 'ng')
        {
            setWellList(wellList.sort(ngCompare).slice());
            if (stages !== null)
            {
                setStages(stages.sort(dieselCompare).slice());
            }
        }else if (column === 'energy')
        {
            setWellList(wellList.sort(energyCompare).slice());
            if (stages !== null)
            {
                setStages(stages.sort(dieselCompare).slice());
            }
        }else if (column === 'endTime')
        {
            setWellList(wellList.sort(timeCompare).slice());
            if (stages !== null)
            {
                setStages(stages.sort(dieselCompare).slice());
            }
        }else if (column === 'subRate')
        {
            setWellList(wellList.sort(subrateCompare).slice());
            if (stages !== null)
            {
                setStages(stages.sort(dieselCompare).slice());
            }
        }else if (column === 'subRateGen2') {
            setWellList(wellList.sort(subrateCompareGen2).slice());
            if (stages !== null)
            {
                setStages(stages.sort(dieselCompare).slice());
            }
        }else if (column === 'subRateGen4') {
            setWellList(wellList.sort(subrateCompareGen4).slice());
            if (stages !== null)
            {
                setStages(stages.sort(dieselCompare).slice());
            }
        } else if (column === 'stageCount')
        {
            setWellList(wellList.sort(stageCountCompare).slice());
        }
    }

    const linesCompare = (a,b) => {
        if (_sortAsc)
        {
            if (a.name > b.name)
            {
                return 1;
            }else{
                return -1;
            }
        }else{
            if (a.name < b.name)
            {
                return 1;
            }else{
                return -1;
            }
        }
    }
    
    const stageCountCompare = (a,b) => {
        if (_sortAsc)
        {
            if (a.stages.filter(x => x.totalDE > 0 || x.totalDiesel > 0).length > b.stages.filter(x => x.totalDE > 0 || x.totalDiesel > 0).length)
            {
                return 1;
            }else{
                return -1;
            }
        }else{
            if (a.stages.filter(x => x.totalDE > 0 || x.totalDiesel > 0).length < b.stages.filter(x => x.totalDE > 0 || x.totalDiesel > 0).length)
            {
                return 1;
            }else{
                return -1;
            }
        }
    }

    const stageCompare = (a,b) => {
        if (_sortAsc)
        {
            if (a.wellName > b.wellName)
            {
                return 1;
            }else{
                return -1;
            }
        }else{
            if (a.wellName < b.wellName)
            {
                return 1;
            }else{
                return -1;
            }
        }
    }

    const dieselCompare = (a,b) => {
        if (_sortAsc)
        {
            if (a.totalDiesel > b.totalDiesel)
            {
                return 1;
            }else{
                return -1;
            }
        }else{
            if (a.totalDiesel < b.totalDiesel)
            {
                return 1;
            }else{
                return -1;
            }
        }
    }

    const ngCompare = (a,b) => {
        if (_sortAsc)
        {
            if (a.totalDE > b.totalDE)
            {
                return 1;
            }else{
                return -1;
            }
        }else{
            if (a.totalDE < b.totalDE)
            {
                return 1;
            }else{
                return -1;
            }   
        }
    }
    const energyCompare = (a,b) => {
        if (_sortAsc)
        {
            if (a.totalPumped > b.totalPumped)
            {
                return 1;
            }else{
                return -1;
            }
        }else
        {
            if (a.totalPumped < b.totalPumped)
            {
                return 1;
            }else{
                return -1;
            }
        }
    }
    const timeCompare = (a,b) => {
        if (_sortAsc)
        {
            if (a.endTime > b.endTime)
            {
                return 1;
            }else{
                return -1;
            }
        }else{
            if (a.endTime < b.endTime)
            {
                return 1;
            }else{
                return -1;
            }
        }
    }
    const subrateCompare = (a,b) => {
        if (_sortAsc)
        {
            if (a.subRate > b.subRate)
            {
                return 1;
            }else{
                return -1;
            }
        }else{
            if (a.subRate < b.subRate)
            {
                return 1;
            }else{
                return -1;
            }
        }
    }
    const subrateCompareGen2 = (a,b) => {
        if (_sortAsc)
        {
            if (a.subRateGen2 > b.subRateGen2)
            {
                return 1;
            }else{
                return -1;
            }
        }else{
            if (a.subRateGen2 < b.subRateGen2)
            {
                return 1;
            }else{
                return -1;
            }
        }
    }
    const subrateCompareGen4 = (a,b) => {
        if (_sortAsc)
        {
            if (a.subRateGen4 > b.subRateGen4)
            {
                return 1;
            }else{
                return -1;
            }
        }else{
            if (a.subRateGen4 < b.subRateGen4)
            {
                return 1;
            }else{
                return -1;
            }
        }
    }
    return (
        <Row>
            <Col sm="12">
            <div className="pumperSummary">
                <div className="pumperLinesHeader">
                    <div className="pumperLinesHeaderCell pumperLinesHeaderName pumperLinesHeaderCellSort" onClick={() => sortData(props.title)}> <FormattedMessage id= { ConvertStringToLabel(props.title) + '.label'} defaultMessage={ props.title } /> {(activeSort === 'Wells' || activeSort === 'Stages') && sortAsc &&  <WhiteUp />}{(activeSort === 'Wells' || activeSort === 'Stages') && !sortAsc && <WhiteDown />}</div>
                    <div className="pumperLinesHeaderCell pumperLinesHeaderValue pumperLinesHeaderCellSort" onClick={() => sortData('diesel')}> <FormattedMessage id="diesel.label" defaultMessage="Diesel" /> {activeSort === 'diesel' && sortAsc && <WhiteUp />}{activeSort === 'diesel' && !sortAsc && <WhiteDown />}</div>
                    {props.project.bifuel && <div className="pumperLinesHeaderCell pumperLinesHeaderValueGas pumperLinesHeaderCellSort" onClick={() => sortData('ng')}> <FormattedMessage id="naturalGas.label" defaultMessage="Natural Gas" /> {activeSort === 'ng' && sortAsc &&  <WhiteUp />}{activeSort === 'ng' && !sortAsc && <WhiteDown />}</div>}
                    {props.project.bifuel && <div className="pumperLinesHeaderCell pumperLinesHeaderEnergy pumperLinesHeaderCellSort" onClick={() => sortData('energy')}> <FormattedMessage id="consumedEnergy.label" defaultMessage="Consumed Energy" /> {activeSort === 'energy' && sortAsc && <WhiteUp />}{activeSort === 'energy' && !sortAsc && <WhiteDown />}</div>}
                    {props.project !== null && props.project.wells !== null && props.well === undefined && wellList !== null &&
                        <div className="pumperLinesHeaderCell pumperLinesHeaderStages pumperLinesHeaderCellSort" onClick={() => sortData('stageCount')}><FormattedMessage id="numberOfStages.label" defaultMessage="# of Stages" /> {activeSort === 'stageCount' && sortAsc && <WhiteUp />}{activeSort === 'stageCount' && !sortAsc && <WhiteDown />}</div>
                    }
                    {props.project.bifuel && 
                        <div id="infoIcon" className="pumperLinesHeaderCell pumperLinesHeaderSubRate pumperLinesHeaderCellSort" onClick={() => sortData('subRate')}><FormattedMessage id="subRate.label" defaultMessage="Sub Rate" /><img src={InfoIcon} className="infoIcon" onClick={() => toggleInfoIcon} alt="info" />
                            <UncontrolledTooltip placement="right" target="infoIcon">
                                <FormattedMessage id="substitutionRate.message" defaultMessage="Substitution rate is theoretical, calculated using the Net heating values." />
                            </UncontrolledTooltip>
                            {activeSort === 'subRate' && sortAsc && <WhiteUp />}
                            {activeSort === 'subRate' && !sortAsc && <WhiteDown />}
                        </div>
                    }
                    {props.project.bifuel &&
                        <div className="pumperLinesHeaderCell pumperLinesHeaderSubRate pumperLinesHeaderCellSort" onClick={() => sortData('subRateGen2')}>
                            <FormattedMessage id="subRateGen2.label" defaultMessage="Sub Rate (Gen 2)" />
                            {activeSort === 'subRateGen2' && sortAsc && <WhiteUp />}
                            {activeSort === 'subRateGen2' && !sortAsc && <WhiteDown />}
                        </div>
                    }
                    {props.project.bifuel &&
                        <div className="pumperLinesHeaderCell pumperLinesHeaderSubRate pumperLinesHeaderCellSort" onClick={() => sortData('subRateGen4')}>
                            <FormattedMessage id="subRateGen4.label" defaultMessage="Sub Rate (Gen 4)" />
                            {activeSort === 'subRateGen4' && sortAsc && <WhiteUp />}
                            {activeSort === 'subRateGen4' && !sortAsc && <WhiteDown />}
                        </div>
                    }
                    {props.well !== null && props.well !== undefined && 
                        <Fragment>
                            <div className="pumperLinesHeaderCell pumperLinesHeaderFinish pumperLinesHeaderCellSort" onClick={() => sortData('endTime')}> <FormattedMessage id="finishTimeUTC.label" defaultMessage="Finish Time (UTC)" />{activeSort === 'endTime' && sortAsc &&  <WhiteUp />}{activeSort === 'endTime' && !sortAsc && <WhiteDown />}</div>
                            <div className="pumperLinesHeaderCell pumperLinesHeaderStart pumperLinesHeaderCellSort" onClick={() => sortData('startTime')}> <FormattedMessage id="startTimeUTC.label" defaultMessage="Start Time (UTC)" /> {activeSort === 'startTime' && sortAsc &&  <WhiteUp />}{activeSort === 'startTime' && !sortAsc && <WhiteDown />}</div>
                        </Fragment>
                    }
                </div>
                <div className="clearfix"></div>
                <div className="pumperLinesData">
                    <table className="fullWidth" id="table">
                        {props.project !== null && props.project.wells !== null && props.well === undefined && wellList !== null && wellList.map(well => 
                            <WellLine naturalGasUnit={props.project.naturalGasUnit} unit={props.project.uomString} unitShort={props.project.uomStringShort} key={well.name} requestWell={requestWell} well={well} bifuel={props.project.bifuel}></WellLine>
                        )}
                        {props.project !== null && props.project.wells !== null && props.well === undefined && 
                            <tr><td>
                                <div className='pumperLine'>
                                    <div className="pumperLineName">
                                        {(intl.formatMessage({ id: "total.label", defaultMessage: "TOTAL"})).toUpperCase()}
                                    </div>
                                    <div className="pumperLineValue">
                                        <div className="pumperLineMobileTitle">
                                            <FormattedMessage id="diesel.label" defaultMessage="Diesel" />
                                        </div>
                                        <div className="pumperLineName1">{Formatting.numberWithCommas(props.project.totalDiesel)}{props.project.uomString}</div>
                                    </div>
                                    {props.project.bifuel && 
                                        <div className="pumperLineValueGas">
                                            <div className="pumperLineMobileTitle">
                                                <FormattedMessage id="naturalGas.label" defaultMessage="Natural Gas" />
                                            </div>
                                            <div className="pumperLineName1">{Formatting.numberWithCommas(props.project.totalDE)} D{props.project.uomStringShort}E</div>
                                            {props.project.naturalGasUnit === NaturalGasUnit.SCF &&
                                                <div className="pumperLineName2">{Formatting.numberWithCommas(props.project.totalSCF)} SCF</div>
                                            }
                                            {props.project.naturalGasUnit === NaturalGasUnit.MCF &&
                                                <div className="pumperLineName2">{Formatting.numberWithCommas(props.project.totalMCF)} MCF</div>
                                            }
                                            {props.project.naturalGasUnit === NaturalGasUnit.E3M3 &&
                                                <div className="pumperLineName2">{Formatting.numberWithCommas(props.project.totalDEC, 4)} E3M3</div>
                                            }
                                        </div>}
                                    {props.project.bifuel && 
                                        <div className="pumperLineEnergy">
                                            <div className="pumperLineMobileTitle">
                                                <FormattedMessage id="consumedEnergy.label" defaultMessage="Consumed Energy" />
                                            </div>
                                            <div className="pumperLineName1">{Formatting.numberWithCommas(props.project.totalPumped)} D{props.project.uomStringShort}E</div>
                                            <div className="pumperLineName2">{Formatting.numberWithCommas(props.project.totalBTU)} BTU</div>
                                        </div>
                                    }

                                    <div className="pumperLineStages">
                                        <div className="pumperLineMobileTitle">
                                            <FormattedMessage id="activeNumberOfStages.label" defaultMessage="Active # of Stages" />
                                        </div>
                                        <div className="pumperLineName1 floatRightNoMargin">{Formatting.numberWithCommas(_.sumBy(wellList, 'totalStages'))}</div>
                                    </div>

                                    {props.project.bifuel && wellList !== null && <div className="pumperLineSubRate">
                                        <div className="pumperLineMobileTitle">
                                            <FormattedMessage id="substitutionRate.label" defaultMessage="Substitution Rate" />
                                        </div>
                                        <div className="pumperLineName1 floatRightNoMargin">{Formatting.numberWithCommas( ((props.project.totalDE /
                                            (props.project.totalDiesel + props.project.totalDE)) * 100).toString())}%</div>
                                    </div>}
                                    
                                    <div className="clearfix"></div>
                                    <hr className="totalLine"/>
                                    <div className="pumperLineName">
                                        <FormattedMessage id="nonFracFuel.label" defaultMessage="NON-FRAC FUEL" />
                                    </div>
                                    <div className="pumperLineValue">
                                        <div className="pumperLineMobileTitle">
                                            <FormattedMessage id="nonFracFuel.label" defaultMessage="NON-FRAC FUEL" />
                                        </div>
                                        <div className="pumperLineName1">{Formatting.numberWithCommas(props.project.totalNonFracDiesel)}{props.project.uomString}</div>
                                    </div>

                                </div>
                            </td></tr>
                        }
                        {props.project !== null && props.well !== undefined && stages !== null && stages.map(stage => 
                            <WellLine naturalGasUnit={props.project.naturalGasUnit} unit={props.project.uomString} unitShort={props.project.uomStringShort} key={props.title & stage.wellName + stage.stageNumber} well={stage} bifuel={props.project.bifuel} single={true}></WellLine>
                        )}
                        {props.project !== null && props.well !== undefined && stages !== null &&
                            <Fragment>
                                <tr><td>
                                    <div className='pumperLine'>
                                        <div className="pumperLineName">
                                            {(intl.formatMessage({ id: "total.label", defaultMessage: "TOTAL"})).toUpperCase()}
                                        </div>
                                        <div className="pumperLineValue">
                                            <div className="pumperLineMobileTitle">
                                                <FormattedMessage id="diesel.label" defaultMessage="Diesel" />
                                            </div>
                                            <div className="pumperLineName1">
                                                {Formatting.numberWithCommas(props.well.dieselPumped)}{props.project.uomString}
                                            </div>
                                        </div>
                                            
                                        {props.project.bifuel && 
                                            <div className="pumperLineValueGas">
                                            <div className="pumperLineMobileTitle">
                                                <FormattedMessage id="naturalGas.label" defaultMessage="Natural Gas" />
                                            </div>
                                                <div className="pumperLineName1">{Formatting.numberWithCommas(props.project.uomString === "L" ? props.well.totalDE : props.well.totalDEGallons)} D{props.project.uomStringShort}E</div>
                                                {props.project.naturalGasUnit === NaturalGasUnit.SCF && 
                                                    <div className="pumperLineName2">{Formatting.numberWithCommas(props.well.totalSCF)} SCF</div>
                                                }
                                                {props.project.naturalGasUnit === NaturalGasUnit.MCF &&
                                                    <div className="pumperLineName2">{Formatting.numberWithCommas(props.well.totalMCF)} MCF</div>
                                                }
                                                {props.project.naturalGasUnit === NaturalGasUnit.E3M3 &&
                                                    <div className="pumperLineName2">{Formatting.numberWithCommas(props.well.totalDEC, 4)} E3M3</div>
                                                }
                                            </div>}
                                        {props.project.bifuel && 
                                            <div className="pumperLineEnergy">
                                            <div className="pumperLineMobileTitle">
                                                <FormattedMessage id="consumedEnergy.label" defaultMessage="Consumed Energy" />
                                            </div>
                                                <div className="pumperLineName1">{Formatting.numberWithCommas(props.well.totalPumped + (props.project.uomStringShort === 'L' ? props.well.totalDE : props.well.totalDEGallons))} D{props.project.uomStringShort}E</div>
                                                <div className="pumperLineName2">{Formatting.numberWithCommas(props.well.totalBTU)} BTU</div>
                                            </div>
                                        }
                                        
                                        {props.project.bifuel && <div className="pumperLineSubRate">
                                            <div className="pumperLineMobileTitle">
                                                <FormattedMessage id="substitutionRate.label" defaultMessage="Substitution Rate" />
                                            </div>
                                            <div className="pumperLineName1 floatRightNoMargin">{props.well.substitutionRate}%</div>
                                        </div>}

                                        {props.project.bifuel && <div className="pumperLineSubRate">
                                            <div className="pumperLineMobileTitle">
                                                <FormattedMessage id="substitutionRateGen2.label" defaultMessage="Substitution Rate (Gen 2)" />
                                            </div>
                                            <div className="pumperLineName1 floatRightNoMargin">{props.well.substitutionRateGen2}%</div>
                                        </div>}

                                        {props.project.bifuel && <div className="pumperLineSubRate">
                                            <div className="pumperLineMobileTitle">
                                                <FormattedMessage id="substitutionRateGen4.label" defaultMessage="Substitution Rate (Gen 4)" />
                                            </div>
                                            <div className="pumperLineName1 floatRightNoMargin">{props.well.substitutionRateGen4}%</div>
                                        </div>}
                                        
                                        <div className="clearfix"></div>
                                        <hr className="totalLine" />
                                        <div className="pumperLineName">
                                            <FormattedMessage id="nonFracFuel.label" defaultMessage="NON-FRAC FUEL" />
                                        </div>
                                        <div className="pumperLineValue">
                                            <div className="pumperLineMobileTitle">
                                                <FormattedMessage id="nonFracFuel.label" defaultMessage="NON-FRAC FUEL" />
                                            </div>
                                            <div className="pumperLineName1">{Formatting.numberWithCommas(props.well.nonFracDieselPumped)}{props.project.uomString}</div>
                                        </div>
                                    </div>    
                                </td></tr>
                            </Fragment>
                        }
                    </table>
                </div>
            </div>
            </Col>
        </Row>
    )
}