import React, {useState, useEffect} from 'react';

export default function ChartSwitch(props)
{
    const [activeToggle, setActiveToggle] = useState();
    const [activeClass, setActiveClass] = useState('chartSwitch');

    useEffect(() => {
        setActiveToggle(props.active);
    }, []);

    useEffect(() => {
        if (activeToggle)
        {
            setActiveClass('chartSwitch chartSwitchActive');
        }else
        {
            setActiveClass('chartSwitch');
        }
    });

    return(
        <div className={activeClass} onClick={props.onClick}>
            {props.label}
        </div>
    )
}