import {useCallback, useEffect, useMemo} from 'react';
import {SubmitHandler, useForm} from 'react-hook-form';
import { UserConfigurationFormData } from './UserConfigurationFormData';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';
import useProjectConfigurationFormData from "./useProjectConfigurationFormData";
import {NaturalGasUnit} from "../../../enums";

export default function useProjectConfigurationForm(globalDefaults: any) {
    const validationSchema = useMemo(() => (
        yup.object().shape({
            unitOfMeasurement: yup.boolean().required('Unit of Measurement required'),
            naturalGasUnit: yup.number().required('Natural Gas Unit required'),
            btuHeatingValue: yup.number().required('Diesel Heating Value required').min(0),
            ngHeatingValue: yup.number().required('NG Heating Value required').min(0),
            selectedTimezone: yup.string().required('Timezone Value required'),
            dailyCutOffHour: yup.number().integer().required('Daily Cut Off Value required').min(0).max(24),
            brakeHorsePower: yup.number().required('Brake Horse Power Value required').min(0),
            loadFactorPumping: yup.number().required('Load Factor Pumping Value required').min(0).max(1),
            loadFactorIdle: yup.number().required('Load Factor Idle Value required').min(0).max(1),
            hoursPerDayIdle: yup.number().integer().required('Hours Per Day Idle Value required').min(0).max(24),
            hoursPerDayWorking: yup.number().integer().required('Hours Per Day Working Value required').min(0).max(24),
            dieselCostPerGallon: yup.number().required('Diesel Cost Per Gallon Value required').min(0),
            ngCostPerMCF: yup.number().required('NG Cost Per MCF Value required').min(0),
            bifuelDailySetupCost: yup.number().required('Bifuel Daily Setup Cost Value required').min(0),
            substitutionRate: yup.number().integer().required('Substitution Rate Value required').min(0).max(100),
        })
    ), [])

    const { register, handleSubmit, reset, getValues, setValue, control, formState: { errors } } = useForm<UserConfigurationFormData>({
        resolver: yupResolver(validationSchema)
    });

    const handleDefaultValues = useCallback((userConfigurationFormData : any) => {
        if (userConfigurationFormData !== undefined) {
            const defaultValues: UserConfigurationFormData = {
                unitOfMeasurement: userConfigurationFormData.unitOfMeasurement !== undefined ?
                    userConfigurationFormData.unitOfMeasurement
                    : true,
                naturalGasUnit: userConfigurationFormData.naturalGasUnit !== undefined ?
                    userConfigurationFormData.naturalGasUnit
                    : NaturalGasUnit.SCF,
                btuHeatingValue: userConfigurationFormData.btuHeatingValue !== undefined ?
                    userConfigurationFormData.btuHeatingValue
                    : globalDefaults.dieselBTUValueDefault,
                ngHeatingValue: userConfigurationFormData.ngHeatingValue !== undefined ?
                    userConfigurationFormData.ngHeatingValue
                    : globalDefaults.ngBTUValueDefault,
                selectedTimezone: userConfigurationFormData.selectedTimezone !== undefined ?
                    userConfigurationFormData.selectedTimezone
                    : "Canada/Mountain",
                dailyCutOffTime: userConfigurationFormData.dailyCutOffTime !== undefined ?
                    `${userConfigurationFormData.dailyCutOffTime.hours}:${userConfigurationFormData.dailyCutOffTime.minutes}`
                    : "17:00",
                dailyCutOffHour: userConfigurationFormData.dailyCutOffHour !== undefined ?
                    userConfigurationFormData.dailyCutOffHour
                    : 0,
                brakeHorsePower: userConfigurationFormData.brakeHorsePower !== undefined ?
                    userConfigurationFormData.brakeHorsePower
                    : globalDefaults.brakeHorsePowerDefault,
                loadFactorPumping: userConfigurationFormData.loadFactorPumping !== undefined ?
                    userConfigurationFormData.loadFactorPumping
                    : globalDefaults.loadFactorPumpingDefault,
                loadFactorIdle: userConfigurationFormData.loadFactorIdle !== undefined ?
                    userConfigurationFormData.loadFactorIdle
                    : globalDefaults.loadFactorIdleDefault,
                hoursPerDayIdle: userConfigurationFormData.hoursPerDayIdle !== undefined ?
                    userConfigurationFormData.hoursPerDayIdle
                    : globalDefaults.hoursPerDayIdleDefault,
                hoursPerDayWorking: userConfigurationFormData.hoursPerDayWorking !== undefined ?
                    userConfigurationFormData.hoursPerDayWorking
                    : globalDefaults.hoursPerDayWorkingDefault,
                dieselCostPerGallon: userConfigurationFormData.dieselCostPerGallon !== undefined ?
                    userConfigurationFormData.dieselCostPerGallon
                    : globalDefaults.dieselCostPerGallonDefault,
                ngCostPerMCF: userConfigurationFormData.ngCostPerMCF !== undefined ?
                    userConfigurationFormData.ngCostPerMCF
                    : globalDefaults.ngCostPerMCFDefault,
                bifuelDailySetupCost: userConfigurationFormData.bifuelDailySetupCost !== undefined ?
                    userConfigurationFormData.bifuelDailySetupCost
                    : globalDefaults.bifuelDailySetupCostDefault,
                substitutionRate: userConfigurationFormData.substitutionRate !== undefined ?
                    userConfigurationFormData.substitutionRate
                    : globalDefaults.substitutionRateDefault,
            }
            
            reset(defaultValues);
        }
        // No configuration
        else {
            const defaultValues: UserConfigurationFormData = {
                unitOfMeasurement: true,
                naturalGasUnit: NaturalGasUnit.SCF,
                btuHeatingValue: globalDefaults.dieselBTUValueDefault,
                ngHeatingValue: globalDefaults.ngBTUValueDefault,
                selectedTimezone: "Canada/Mountain",
                dailyCutOffTime: "17:00",
                dailyCutOffHour: 0,
                brakeHorsePower: globalDefaults.brakeHorsePowerDefault,
                loadFactorPumping: globalDefaults.loadFactorPumpingDefault,
                loadFactorIdle: globalDefaults.loadFactorIdleDefault,
                hoursPerDayIdle: globalDefaults.hoursPerDayIdleDefault,
                hoursPerDayWorking: globalDefaults.hoursPerDayWorkingDefault,
                dieselCostPerGallon: globalDefaults.dieselCostPerGallonDefault,
                ngCostPerMCF: globalDefaults.ngCostPerMCFDefault,
                bifuelDailySetupCost: globalDefaults.bifuelDailySetupCostDefault,
                substitutionRate: globalDefaults.substitutionRateDefault,
            }
            reset(defaultValues);
        }
    }, [reset])

    const {formData, updateProjectConfigurationFormData, isLoading} = useProjectConfigurationFormData(handleDefaultValues);
    
    const onSubmit : SubmitHandler<UserConfigurationFormData> = useCallback((formValues) => {
        // updateSubscription(formValues);
        updateProjectConfigurationFormData(formValues);
    }, [formData]);

    return {
        register,
        isLoading,
        errors,
        getValues: getValues,
        setValue: setValue,
        control: control,
        onSubmit: handleSubmit(onSubmit)
    }
}
