import React, {Fragment, useState, useEffect} from 'react';
import {Row, Col, Container} from 'reactstrap';
import HeaderStat from './HeaderStat';
import ProjectChart from './ProjectChart';
import * as Formatting from '../helpers/formatting';
import NoData from './NoData.js';
import ChartSwitch from './ChartSwitch';
import {useIntl} from 'react-intl';
import {NaturalGasUnit} from "../enums";

export default function ProjectChartContainer(props) {

    const [showChart, setShowChart] = useState(true);
    const intl = useIntl();
    useEffect(() => {
        if (props.project.wells.length > 1) {
            setShowChart(true);
        } else {
            setShowChart(false);
        }
    });

    return (
        <Fragment>
            {props.type === 'productivity' &&
                <Fragment>
                    <Container fluid={true}>
                        <Row>
                            <ChartSwitch label={(intl.formatMessage({
                                id: "duration.label",
                                defaultMessage: "DURATION"
                            })).toUpperCase()} active={true}/>
                        </Row>
                    </Container>
                    {(props.project.totalHours === 0) &&
                        <Fragment>
                            <Container className="wellStatsContainer" fluid={true}>
                                <Row className="wellRow">
                                    <Col xs="12" md="12" className="barChartContainer">
                                        <NoData/>
                                    </Col>
                                </Row>
                            </Container>
                        </Fragment>
                    }
                    {props.project.totalHours > 0 && !isNaN(props.project.productivity) &&
                        <Fragment>
                            <Container className="wellStatsContainer" fluid={true}>
                                <Row className="wellRow">
                                    {showChart &&
                                        <Col xs="12" md="12" className="barChartContainer">
                                            <ProjectChart wells={props.project.wells} project={props.project}
                                                          type="productivity" bifuel={props.project.bifuel}/>
                                        </Col>
                                    }
                                </Row>
                                <Row className="wellHeaderRow">
                                    <HeaderStat title="Totals"
                                                stat={Formatting.numberWithCommas(props.project.activeHours) + ' hrs'}
                                                smallStat={intl.formatMessage({
                                                    id: "activeStageTime.label",
                                                    defaultMessage: "Active Stage Time"
                                                })}/>
                                    <HeaderStat title="&nbsp;"
                                                stat={Formatting.numberWithCommas(props.project.totalHours) + ' hrs'}
                                                smallStat={intl.formatMessage({
                                                    id: "totalStageTime.label",
                                                    defaultMessage: "Total Stage Time"
                                                })}/>
                                    <HeaderStat title="&nbsp;"
                                                stat={Formatting.numberWithCommas(props.project.productivity) + '%'}
                                                smallStat={intl.formatMessage({
                                                    id: "pumpTime.label",
                                                    defaultMessage: "Pump Time"
                                                }) + ' %'}/>
                                </Row>
                            </Container>
                        </Fragment>
                    }
                </Fragment>
            }
            {props.type === 'consumption' &&
                <Fragment>
                    <Container fluid={true}>
                        <Row>
                            <ChartSwitch label={(intl.formatMessage({
                                id: "consumption.label",
                                defaultMessage: "CONSUMPTION"
                            })).toUpperCase()} active={true}/>
                        </Row>
                    </Container>
                    {(props.project.totalPumped === 0) &&
                        <Fragment>
                            <Container className="wellStatsContainer" fluid={true}>
                                <Row className="wellRow">
                                    <Col xs="12" md="12" className="barChartContainer">
                                        <NoData/>
                                    </Col>
                                </Row>
                            </Container>
                        </Fragment>
                    }
                    {props.project.totalPumped > 0 && !isNaN(props.project.substitutionRate) &&
                        <Fragment>
                            <Container className="wellStatsContainer" fluid={true}>
                                <Row className="wellRow">
                                    {showChart &&
                                        <Col xs="12" md="12" className="barChartContainer">
                                            <ProjectChart wells={props.project.wells} type="consumption"
                                                          bifuel={props.project.bifuel} project={props.project}/>
                                        </Col>
                                    }
                                </Row>
                                <Row className="wellHeaderRow">
                                    <HeaderStat title={(intl.formatMessage({
                                        id: "totals.label",
                                        defaultMessage: "TOTALS"
                                    })).toUpperCase()}
                                                stat={Formatting.numberWithCommas(props.project.totalPumped) + props.project.uomString}
                                                smallStat={intl.formatMessage({
                                                    id: "totalPumped.label",
                                                    defaultMessage: "Total Pumped"
                                                })}/>
                                    {props.project.totalSCF > 0 && <HeaderStat title="&nbsp;"
                                                                               stat={props.project.naturalGasUnit === NaturalGasUnit.E3M3
                                                                                   ? Formatting.numberWithCommas(props.project.totalDEC, 4) + ' E3M3 (' + Formatting.numberWithCommas(props.project.uomString === "L" ? props.project.totalDE : props.project.totalDEGallons) + ' D' + props.project.uomStringShort + 'E)'
                                                                                   : Formatting.numberWithCommas(props.project.totalSCF) + ' SCF (' + Formatting.numberWithCommas(props.project.uomString === "L" ? props.project.totalDE : props.project.totalDEGallons) + ' D' + props.project.uomStringShort + 'E)'}
                                                                               smallStat={intl.formatMessage({
                                                                                   id: "naturalGas.label",
                                                                                   defaultMessage: "Natural Gas"
                                                                               })}/>}
                                    {(props.project.bifuel === true || props.project.bifuel === 'true') &&
                                        <HeaderStat title="&nbsp;"
                                                    stat={Formatting.numberWithCommas(props.project.substitutionRate) + '%'}
                                                    smallStat={intl.formatMessage({
                                                        id: "substitution.label",
                                                        defaultMessage: "Substitution"
                                                    })}/>}
                                    <HeaderStat stat={Formatting.numberWithCommas(props.project.stagesPerDay)}
                                                smallStat={intl.formatMessage({
                                                    id: "averageStagesPerDay.label",
                                                    defaultMessage: "Average Stages Per Day"
                                                })} title="&nbsp;"/>
                                </Row>
                            </Container>

                        </Fragment>
                    }
                </Fragment>
            }
        </Fragment>
    )

}

