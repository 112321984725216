import React from 'react';
import Error from './common/Error';
class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props);
		this.state = { hasError: false };
	}

	componentDidCatch(error, info) {
		console.error(error)
		console.error(info)
		// Display fallback UI
		this.setState({ hasError: true });
	}

	render() {
		if (this.state.hasError) {
			return <Error useStaticLink />
		}
		return this.props.children;
	}
}
export default ErrorBoundary;