import React, {Fragment, useContext} from 'react';
import {FormattedMessage, useIntl} from "react-intl";
import {ProjectMenuContext} from "../Contexts/ProjectMenuContext";
import ReactGA from "react-ga";
import {ReactComponent as BlackRight} from "../../../images/arrowRightBlack.svg";
import {ProjectContext} from "../Contexts/ProjectContext";

export default function FuelTicket(props) {
    const {closeMobileMenu} = useContext(ProjectMenuContext);
    const {project, setActivePage, setMobileText} = useContext(ProjectContext);
    const intl = useIntl();

    const activePage = () => {
        return project.activePage === "FuelTicket" ? 'siteTitle siteTitleActive menuItem menuItemActive' : 'siteTitle menuItem'
    }

    const requestFuelTickets = () => {
        setActivePage("FuelTicket")
        setMobileText(intl.formatMessage({id: "fuelTickets.label", defaultMessage: "Fuel Tickets"}));
        closeMobileMenu();

        // Google Analytics
        ReactGA.event({
            category: "Project Menu Selection",
            action: `Clicked on "Fuel Tickets" on Project "${window.location.pathname}"`
        })

        // Google Tag Manager
        window.dataLayer.push({
            'event': "Pageview",
            'pagePath': `${window.location.pathname}/fuel-tickets`,
            'pageTitle': `Fuel Tickets`
        })
    }
    
    return (
        <Fragment>
            {project.currentProject.fuelTickets !== undefined && project.currentProject.fuelTickets.length > 0 &&
            <Fragment>
                {!props.mobile &&
                <div>
                    <h1 onClick={() => requestFuelTickets()} className={activePage()}>
                        <FormattedMessage id="fuelTickets.label" defaultMessage="Fuel Tickets"/>
                    </h1>
                </div>
                }
                {props.mobile &&
                <div className="entireSiteMobile" onClick={() => requestFuelTickets()}>
                    <div className="mobileText">
                        <FormattedMessage id="fuelTickets.label" defaultMessage="Fuel Tickets"/>
                    </div>
                    <div className="floatRight mobileArrow"><BlackRight/></div>
                </div>
                }
            </Fragment>
            }
        </Fragment>
    )
}