import {useCallback, useEffect, useMemo, useState} from 'react';
import {UserConfigurationFormData} from './UserConfigurationFormData';
import {UserSubscription} from "./UserSubscription";
import {getState} from "litsy";

export default function useUserTotalizerSubscriptionData(email: string) {
    const [subscription, setSubscription] = useState<UserSubscription>();
    const [jobId, setJobId] = useState<number>();

    // Initial subscription fetch
    useEffect(() => {
        getJobId().then((jobId) => {
            fetchUserSubscription(email, jobId);
        })
    }, [])

    const getJobId = async () => {
        const projectInfoArray = window.location.pathname.split('/');
        const projectStartTime = projectInfoArray[3];
        const projectName = projectInfoArray[2];
        const projectEnergyProducer = projectInfoArray[1];
        // const projectEnergyProducer = props.project.energyProducerName.replace(/\s/g, '-');

        return fetch(`${getState("fracShack_apiEndpoint", "session")}project/${projectName}/jobId?producerName=${projectEnergyProducer}&startTime=${projectStartTime}`, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${getState('authToken', 'session')}`
            }
        }).then((response) => response.json())
            .then(jobIdData => {
                setJobId(jobIdData);
                return jobIdData;
            })
    }

    const fetchUserSubscription = (email: string, jobId: number) => {
        return fetch(`${getState("fracShack_apiEndpoint", "session")}subscription/totalizer-subscription?projectId=${jobId}&email=${email}`, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${getState('authToken', 'session')}`
            }
        }).then((response) => response.json())
            .then(subscriptionData => {
                setSubscription(subscriptionData)
            })
    };

    const updateUserSubscription = useCallback((newTotalizerSubscription: UserSubscription) => {
        return fetch(`${getState("fracShack_apiEndpoint", "session")}subscription/totalizer-subscription/${jobId}/patch`, {
            method: 'PATCH',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${getState('authToken', 'session')}`
            },
            body: JSON.stringify(newTotalizerSubscription)
        })
    }, []);

    const updateSubscription = useCallback((formValues: UserConfigurationFormData) => {
        if (subscription !== undefined) {
            let newTotalizerSubscription : UserSubscription = {
                email: subscription.email,
                jobId: subscription.jobId,
                isActive: subscription.isActive,
                dailyCutOffTime: formValues.dailyCutOffTime,
                dailyCutOffHour: formValues.dailyCutOffHour.toString()
            }

            updateUserSubscription(newTotalizerSubscription);
        }
    }, [subscription]);

    return {
        updateTotalizerSubscription: updateSubscription
    }
}
