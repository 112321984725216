import React, { Fragment, useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap'
import * as Formatting from '../helpers/formatting';
import {ReactComponent as WhiteUp} from '../images/whiteUp.svg';
import {ReactComponent as WhiteDown} from '../images/whiteDown.svg';
import * as Conversion from '../helpers/Conversion';
import _ from 'lodash';
import { FormattedMessage, useIntl } from "react-intl";
import DailyNgTotalLine from "./DailyNgTotalLine";
import {NaturalGasUnit} from "../enums";

export default function DailyNgTotalsSummary(props)
{

    const [activeSort, setActiveSort] = useState(null);
    const [sortAsc, setSortAsc] = useState(false);
    const [showInfo, setShowInfo] = useState(false);
    const [dailyTotalList, setDailyTotalList] = useState(null);

    const toggleInfoIcon = () =>
    {
        setShowInfo(!showInfo);
    }

    let _sortAsc = false;

    useEffect(() => {
        if (props.dailyTotals !== undefined)
        {
            setDailyTotalList(props.dailyTotals);
        }
    }, []);

    const sortData = (column) => {
        _sortAsc = sortAsc;

        if (column === activeSort)
        {
            setSortAsc(!sortAsc);
            _sortAsc = !_sortAsc;
        }

        setActiveSort(column);
        
        if (column === 'ng')
        {
            setDailyTotalList(dailyTotalList.sort(ngCompare).slice());
        }
        else if (column === 'date')
        {
            setDailyTotalList(dailyTotalList.sort(dateCompare).slice());
        }
        else if (column === 'stages')
        {
            setDailyTotalList(dailyTotalList.sort(stageCompare).slice());
        }
    }
    
    const stageCompare = (a,b) => {
        if (_sortAsc)
        {
            if (a.stagesCompleted * 1 > b.stagesCompleted * 1)
            {
                return 1;
            }else{
                return -1;
            }
        }else{
            if (a.stagesCompleted * 1 < b.stagesCompleted * 1)
            {
                return 1;
            }else{
                return -1;
            }
        }
    }
    
    const ngCompare = (a,b) => {
        if (_sortAsc)
        {
            if (a.volume > b.volume)
            {
                return 1;
            }else{
                return -1;
            }
        }else{
            if (a.volume < b.volume)
            {
                return 1;
            }else{
                return -1;
            }
        }
    }
    
    const dateCompare = (a,b) => {
        if (_sortAsc)
        {
            if (a.date > b.date)
            {
                return 1;
            }else{
                return -1;
            }
        }else
        {
            if (a.date < b.date)
            {
                return 1;
            }else{
                return -1;
            }
        }
    }
   
    const intl = useIntl();
    return (
        <Row>
            <Col sm="12">
                <div className="pumperSummary">
                    <div className="pumperLinesHeader">
                        <div className="pumperLinesHeaderCell pumperLinesHeaderName pumperLinesHeaderCellSort" onClick={() => sortData('date')}> <FormattedMessage id="date.label" defaultMessage="Date" />{(activeSort === 'date') && sortAsc &&  <WhiteUp />}{(activeSort === 'date') && !sortAsc && <WhiteDown />}</div>
                        <div className="pumperLinesHeaderCell pumperLinesHeaderValue pumperLinesHeaderCellSort" onClick={() => sortData('ng')}><FormattedMessage id="naturalGas.label" defaultMessage="Natural Gas" />{activeSort === 'ng' && sortAsc && <WhiteUp />}{activeSort === 'ng' && !sortAsc && <WhiteDown />}</div>
                        <div className="pumperLinesHeaderCell pumperLinesHeaderStages pumperLinesHeaderCellSort" onClick={() => sortData('stages')}><FormattedMessage id="numberOfStages.label" defaultMessage="# of Stages" />{activeSort === 'stages' && sortAsc && <WhiteUp />}{activeSort === 'stages' && !sortAsc && <WhiteDown />}</div>
                    </div>
                    <div className="clearfix"></div>
                    <div className="pumperLinesData">
                        <table className="fullWidth" id="table">
                            {props.project !== null && dailyTotalList !== null && dailyTotalList.map(dailyTotal =>
                                <DailyNgTotalLine dailyTotal={dailyTotal} naturalGasUnit={props.project.naturalGasUnit} unit={props.project.uomString} unitShort={props.project.uomStringShort} />
                            )}
                            {props.project !== null && dailyTotalList !== null &&
                            <tr><td>
                                <div className='pumperLine'>
                                    <div className="pumperLineName">
                                        {(intl.formatMessage({ id: "total.label", defaultMessage: "TOTAL"})).toUpperCase()}
                                    </div>
                                    <Fragment>
                                        <div className="pumperLineValue">
                                            <div className="pumperLineMobileTitle">
                                                <FormattedMessage id="naturalGas.label" defaultMessage="Natural Gas" />
                                            </div>
                                            <div className="pumperLineName1">{props.project.uomStringShort === 'L' ? Formatting.numberWithCommas(Conversion.toDLE(_.sumBy(dailyTotalList, 'volume'))) :
                                                Formatting.numberWithCommas(Conversion.toDGE(_.sumBy(dailyTotalList, 'volume')))} D{props.project.uomStringShort}E</div>
                                            {props.project.naturalGasUnit === NaturalGasUnit.SCF &&
                                                <div className="pumperLineName2">{Formatting.numberWithCommas(_.sumBy(dailyTotalList, 'volume'))} SCF</div>
                                            }
                                            {props.project.naturalGasUnit === NaturalGasUnit.MCF &&
                                            <div className="pumperLineName2">{Formatting.numberWithCommas(_.sumBy(dailyTotalList, 'volumeMCF'))} MCF</div>
                                            }
                                            {props.project.naturalGasUnit === NaturalGasUnit.E3M3 &&
                                                <div className="pumperLineName2">{Formatting.numberWithCommas(_.sumBy(dailyTotalList, 'volumeDEC'), 4)} E3M3</div>
                                            }
                                        </div>
                                    </Fragment>
                                    
                                    <div className="pumperLineStages">
                                        <div className="pumperLineMobileTitle"><FormattedMessage id="numberOfStages.label" defaultMessage="# of Stages" /></div>
                                        <div className="pumperLineName1  floatRightNoMargin">{Formatting.numberWithCommas(_.sumBy(dailyTotalList,  'stagesCompleted'))}</div>
                                    </div>
                                    <div className="clearfix"></div>
                                </div>
                            </td></tr>
                            }
                        </table>
                    </div>
                </div>
            </Col>
        </Row>
    )
}