import React, {Fragment, useContext, useEffect, useState} from 'react';
import {ProjectContext} from "../Contexts/ProjectContext";
import WellStats from "../../WellStats";
import ProjectSummary from "../../ProjectSummary";
import ProjectStats from "../../ProjectStats";
import {useIntl} from "react-intl";
import {setState} from "litsy";

export default function WellsPage(props) {
    const {project, setValue, setMobileText} = useContext(ProjectContext)
    const intl = useIntl();

    const requestWellStats = (well) => {
        if (well.stages !== undefined) {
            setValue('activeWell', well);
            setMobileText(well.name !== '' ?
                intl.formatMessage({id: "well.label", defaultMessage: "Well"}) + " " + well.name
                : 'Unknown Well');
        } else if (well.wells !== undefined) {
            setValue('activeWell', 'all');
            setMobileText(intl.formatMessage({id: "allWells.label", defaultMessage: "All Wells"}));
            setState("fracShack_currentProject", well, "volatile");
        }
    }

    return (
        <Fragment>
            {project.currentProject !== null && project.activePage === "Wells" &&
            <Fragment>
                {project.activeWell !== null && project.activeWell !== 'all' &&
                <Fragment>
                    <WellStats well={project.activeWell} 
                               project={project.currentProject}
                               showSubRate={props.showSubstitutionRate}
                               bifuel={project.currentProject.bifuel} 
                               token={project.token}/>
                    <ProjectSummary project={project.currentProject} 
                                    well={project.activeWell}
                                    showSubRate={props.showSubstitutionRate} 
                                    title="Stages"
                                    bifuel={project.currentProject.bifuel}/>
                </Fragment>
                }

                {(props.showProjectStats === true || props.showProjectStats === 'true') && project.activeWell === 'all' &&
                <Fragment>
                    <ProjectStats project={project.currentProject}
                                  showSubRate={props.showSubstitutionRate}
                                  token={project.token}/>
                    <ProjectSummary project={project.currentProject}
                                    showSubRate={props.showSubstitutionRate} 
                                    title="Wells"
                                    bifuel={project.currentProject.bifuel}
                                    requestWell={requestWellStats}/>
                </Fragment>
                }
            </Fragment>
            }
        </Fragment>
    )
}