import React from 'react';
import { FormattedMessage } from "react-intl";

export default function NoData()
{
    return (
        <div className="noDataContainer">
            <FormattedMessage id="noData.label" defaultMessage="No Data" />
        </div>
    )
}