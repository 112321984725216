﻿import { useAuth0 } from "../../auth/auth0-wrapper";

﻿import React, {useEffect, useState} from "react";
import {newUserLanguagePreference} from "../../helpers/metadataHelper";

import {Button} from "reactstrap";
import {saveUserLanguagePreferenceActionCreator} from "../../store/UserLanguagePreference";
import {connect} from "react-redux";

const ConfirmationPopup = (props) => {
    const { getTokenSilently, loading, user,  } = useAuth0();
    const [token, setToken] = useState(null);
    
    useEffect(() => {
        const fetchToken = async () => {
            const auth0Token = await getTokenSilently();
            setToken(auth0Token);
        };
        fetchToken();
    })
    
    const setStatus = () => {
        props.setStatus(false);
        props.lastFocus.focus();
    }
    
    const setLanguage = () => {
        // Save language here
        props.setStatus(false);

        let newMeta = newUserLanguagePreference(user, props.selectedFlag);
        props.saveUserMetadata(newMeta, token, true);
        
        props.close();
        document.body.style.pointerEvents = "all";
    }
    
    return <div className="confirmation-popup-container" style={{"pointer-events": props.status ? 'all' : 'none'}}>
        <div className="confirmation-popup-text-container">
            <div className="confirmation-popup-text">
                Are you sure you want to choose <strong>{props.selectedCountry}</strong>?
            </div>
            <div className="confirmation-popup-note">
                <strong>Note:</strong> Language setting can be changed at "Profile Dropdown {'->'} Account Settings {'->'}Language Preference"
            </div>
        </div>
        <div className="confirmation-button-container">
            <Button className="confirmation-confirm-button"
                    onClick={setLanguage}
                    color="warning"
                    outline
                    size="sm">
                Confirm
            </Button>
            <Button className="confirmation-back-button"
                    onClick={setStatus}
                    color="warning"
                    outline
                    size="sm">
                Back
            </Button>
        </div>
    </div>
}

const mapStateToProps = (store) => {
    return {
        userMetadata:store.languagePreference
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        saveUserMetadata: (userMetadata, token, reload) => dispatch(saveUserLanguagePreferenceActionCreator(userMetadata, token, reload)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ConfirmationPopup);
