import React, {Fragment, useContext, useEffect, useState} from 'react';
import {FormattedMessage, useIntl} from "react-intl";
import {ProjectMenuContext} from "../Contexts/ProjectMenuContext";
import {ReactComponent as WhiteUp} from "../../../images/whiteUp.svg";
import {ReactComponent as WhiteDown} from "../../../images/whiteDown.svg";
import NgSidekickWellList from "../../NgSidekickWellList";
import ReactGA from "react-ga";
import AccessControl from "../AccessControl/AccessControl";
import slugify from "../../../helpers/slugify";
import {AccessType} from "../../../auth/accessControlService";
import {Link} from "react-router-dom";
import {ReactComponent as BlackRight} from "../../../images/arrowRightBlack.svg";
import {ProjectContext} from "../Contexts/ProjectContext";
import {getState} from "litsy";

export default function Features(props) {
    const {closeMobileMenu} = useContext(ProjectMenuContext);
    const {project, setActivePage, setActiveSubPage, setMobileText} = useContext(ProjectContext);
    const [showSettingsDropdown, setShowSettingsDropdown] = useState(false);
    const intl = useIntl();

    useEffect(() => {
        if (project.activePage !== "Features") {
            setShowSettingsDropdown(false);
        }
    }, [project.activePage])

    const activePage = () => {
        return project.activePage === "Features" ? 'siteTitle siteTitleActive menuItem menuItemActive' : 'siteTitle menuItem'
    }

    const toggleSettingsDropdown = () => {
        switch (project.activePage) {
            case "Features":
                setShowSettingsDropdown(!showSettingsDropdown);
                break;
            default:
                requestApi();
                setShowSettingsDropdown(true);
        }
    }

    const requestConfiguration = () => {
        setActiveSubPage("configuration");
        setMobileText(intl.formatMessage({id: "configuration.label", defaultMessage: "Configuration"}));
        closeMobileMenu();

        // Google Analytics
        ReactGA.event({
            category: "Project Menu Selection",
            action: `Clicked on "Features: Configuration" on Project "${window.location.pathname}"`
        })

        // Google Tag Manager
        window.dataLayer.push({
            'event': "Pageview",
            'pagePath': `${window.location.pathname}/features/configuration`,
            'pageTitle': `Features - Configuration`
        })
    }

    const requestApi = () => {
        setActiveSubPage("api");
        setMobileText(intl.formatMessage({id: "api.label", defaultMessage: "Api"}));

        if (project.activePage !== "Features") {
            setActivePage("Features");
        } else {
            closeMobileMenu();
        }

        // Google Analytics
        ReactGA.event({
            category: "Project Menu Selection",
            action: `Clicked on "Features: Api" on Project "${window.location.pathname}"`
        })

        // Google Tag Manager
        window.dataLayer.push({
            'event': "Pageview",
            'pagePath': `${window.location.pathname}/features/api`,
            'pageTitle': `Features - Api`
        })
    }
    
    
    const requestCreateGraphQL = () => {
        setActiveSubPage("create graphql api");
        setMobileText(intl.formatMessage({id: "createGraphQLAPI.label", defaultMessage: "Create GraphQL API"}));

        if (project.activePage !== "Features") {
            setActivePage("Features");
        } else {
            closeMobileMenu();
        }
        
    }

    return (
        <Fragment>
            {!props.mobile &&
                <AccessControl
                    extraAccessData={{
                        serviceEndpoint: getState("fracShack_apiEndpoint", "session"),
                        token: project.token,
                        energyProducerName: `${slugify(project.currentProject.energyProducerName)}`
                    }}
                    accessType={AccessType.IsCustomerApiAdmin}
                    renderNoAccess={() => ''}>
                    <h1 onClick={() => toggleSettingsDropdown()} className={activePage()}>
                        <FormattedMessage id="features.label" defaultMessage="Features"/>
                        {showSettingsDropdown && <WhiteUp className="floatRight"/>}
                        {!showSettingsDropdown && <WhiteDown className="floatRight"/>}
                    </h1>
                </AccessControl>
            }
            {props.mobile &&
                <AccessControl
                    extraAccessData={{
                        serviceEndpoint: props.serviceEndpoint,
                        token: project.token,
                        completeProjectName: `${slugify(project.currentProject.name)}/${project.currentProject.startDateURL}`,
                        energyProducerName: `${slugify(project.currentProject.energyProducerName)}`
                    }}
                    accessType={AccessType.IsCustomerApiAdmin}
                    renderNoAccess={() => ''}>
                    <div className="entireSiteMobile" onClick={() => toggleSettingsDropdown()}>
                        <div className="mobileText">
                            <FormattedMessage id="features.label" defaultMessage="Features"/>
                        </div>
                        <div className="floatRight mobileArrow"><BlackRight/></div>
                    </div>
                </AccessControl>
            }
            {showSettingsDropdown &&
                <Fragment>
                    <div
                        className={project.activeSubPage === "api" ? 'wellTitle wellTitleActive subMenuItem subMenuItemActive' : 'wellTitle subMenuItem'}
                        onClick={() => requestApi()}>
                        <FormattedMessage id="api.label" defaultMessage="Api"/>
                    </div>
                    
                    <div className={project.activeSubPage === "create graphql api" ? 'wellTitle wellTitleActive subMenuItem subMenuItemActive' : 'wellTitle subMenuItem'}
                        onClick={() => requestCreateGraphQL()}>
                        <FormattedMessage id="createGraphQLAPI.label" defaultMessage="Create GraphQl API"/>
                    </div>
    
                    <Link to={'/docs/api'}>
                        <div
                            className={project.activeSubPage === "documentation" ? 'wellTitle wellTitleActive subMenuItem subMenuItemActive' : 'wellTitle subMenuItem'}>
                            <FormattedMessage id="documentation.label" defaultMessage="Documentation"/>
                        </div>
                    </Link>
                    
                    {(props.showConfiguration === true || props.showConfiguration === 'true') &&
                    <div
                        className={project.activeSubPage === "configuration" ? 'wellTitle wellTitleActive subMenuItem subMenuItemActive' : 'wellTitle subMenuItem'}
                        onClick={() => requestConfiguration()}>
                        <FormattedMessage id="configuration.label" defaultMessage="Configuration"/>
                    </div>
                    }
                </Fragment>
            }
        </Fragment>
    )
}