import React, {Fragment, useContext, useEffect, useState} from 'react';
import {FormattedMessage, useIntl} from "react-intl";
import {ProjectMenuContext} from "../Contexts/ProjectMenuContext";
import {ReactComponent as WhiteUp} from "../../../images/whiteUp.svg";
import {ReactComponent as WhiteDown} from "../../../images/whiteDown.svg";
import NgSidekickWellList from "../../NgSidekickWellList";
import ReactGA from "react-ga";
import AccessControl from "../AccessControl/AccessControl";
import slugify from "../../../helpers/slugify";
import {AccessType} from "../../../auth/accessControlService";
import {Link} from "react-router-dom";
import {ReactComponent as BlackRight} from "../../../images/arrowRightBlack.svg";
import {ProjectContext} from "../Contexts/ProjectContext";

export default function ProjectInfo(props) {
    const {closeMobileMenu} = useContext(ProjectMenuContext);
    const {project, setActivePage, setActiveSubPage, setMobileText} = useContext(ProjectContext);
    const [showSettingsDropdown, setShowSettingsDropdown] = useState(false);
    const intl = useIntl();

    useEffect(() => {
        if (project.activePage !== "ProjectInfo") {
            setShowSettingsDropdown(false);
        }
    }, [project.activePage])

    const activePage = () => {
        return project.activePage === "ProjectInfo" ? 'siteTitle siteTitleActive menuItem menuItemActive' : 'siteTitle menuItem'
    }

    const toggleSettingsDropdown = () => {
        switch(project.activePage) {
            case "ProjectInfo":
                setShowSettingsDropdown(!showSettingsDropdown);
                break;
            default:
                requestConfiguration();
                setShowSettingsDropdown(true);
        }
    }

    const requestConfiguration = () => {
        setActiveSubPage("configuration");
        setMobileText(intl.formatMessage({id: "configuration.label", defaultMessage: "Configuration"}));

        if (project.activePage !== "ProjectInfo") {
            setActivePage("ProjectInfo");
        } else {
            closeMobileMenu();
        }

        // Google Analytics
        ReactGA.event({
            category: "Project Menu Selection",
            action: `Clicked on "Project Info: Configuration" on Project "${window.location.pathname}"`
        })

        // Google Tag Manager
        window.dataLayer.push({
            'event': "Pageview",
            'pagePath': `${window.location.pathname}/project-info/configuration`,
            'pageTitle': `Project Info - Configuration`
        })
    }

    const requestPermissions = () => {
        setActiveSubPage("permissions");
        setMobileText(intl.formatMessage({id: "permissions.label", defaultMessage: "Permissions"}));
        closeMobileMenu();

        // Google Analytics
        ReactGA.event({
            category: "Project Menu Selection",
            action: `Clicked on "Project Info: Permissions" on Project "${window.location.pathname}"`
        })

        // Google Tag Manager
        window.dataLayer.push({
            'event': "Pageview",
            'pagePath': `${window.location.pathname}/project-info/permissions`,
            'pageTitle': `Project Info - Permissions`
        })
    }

    return (
        <Fragment>
            {!props.mobile &&
                <h1 onClick={() => toggleSettingsDropdown()} className={activePage()}>
                    <FormattedMessage id="projectInfo.label" defaultMessage="Project Info"/>
                    {showSettingsDropdown && <WhiteUp className="floatRight"/>}
                    {!showSettingsDropdown && <WhiteDown className="floatRight"/>}
                </h1>
            }
            {props.mobile &&
                <div className="entireSiteMobile" onClick={() => toggleSettingsDropdown()}>
                    <div className="mobileText">
                        <FormattedMessage id="projectInfo.label" defaultMessage="Project Info"/>
                    </div>
                    <div className="floatRight mobileArrow"><BlackRight/></div>
                </div>
            }
            {showSettingsDropdown &&
            <Fragment>
                {/* <div className={props.activeSetting != null && props.activeSetting === 'info' ? 'wellTitle wellTitleActive subMenuItem subMenuItemActive' : 'wellTitle subMenuItem'} onClick={() => requestGeneralInfo()}>General Info</div> */}
                {(props.showConfiguration === true || props.showConfiguration === 'true') &&
                <div className={project.activeSubPage === "configuration" ? 'wellTitle wellTitleActive subMenuItem subMenuItemActive' : 'wellTitle subMenuItem'} 
                     onClick={() => requestConfiguration()}>
                    <FormattedMessage id="configuration.label" defaultMessage="Configuration"/>
                </div>
                }

                {(props.showUserManagement === true || props.showUserManagement === 'true') &&
                <AccessControl
                    extraAccessData={{
                        serviceEndpoint: props.serviceEndpoint,
                        token: project.token,
                        completeProjectName: `${slugify(project.currentProject.name)}/${project.currentProject.startDateURL}`,
                        energyProducerName: `${slugify(project.currentProject.energyProducerName)}`
                    }}
                    accessType={AccessType.IsProjectAdmin}
                    renderNoAccess={() => ''}>
                    <Link to={props.baseURL + '/admin'}>
                        <div
                            className={project.activeSubPage === "permissions" ? 'wellTitle wellTitleActive subMenuItem subMenuItemActive' : 'wellTitle subMenuItem'}
                            onClick={() => requestPermissions()}>
                            <FormattedMessage id="permissions.label" defaultMessage="Permissions"/>
                        </div>
                    </Link>
                </AccessControl>
                }
            </Fragment>
            }
        </Fragment>
    )
}