import React, { Fragment, useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap'
import * as Formatting from '../helpers/formatting';
import {ReactComponent as WhiteUp} from '../images/whiteUp.svg';
import {ReactComponent as WhiteDown} from '../images/whiteDown.svg';
import DailyTotalLine from '../components/DailyTotalLine';
import InfoIcon from '../images/icons8-info-16.png';
import {UncontrolledTooltip} from "reactstrap";
import _ from 'lodash';
import { FormattedMessage, useIntl } from "react-intl";
import DailyFuelLevelLine from "./DailyFuelLevelLine";

export default function DailyFuelLevelTotalsSummary(props)
{
    const [activeSort, setActiveSort] = useState(null);
    const [sortAsc, setSortAsc] = useState(false);
    const [showInfo, setShowInfo] = useState(false);
    const [dailyTotalList, setDailyTotalList] = useState(null);

    const toggleInfoIcon = () =>
    {
        setShowInfo(!showInfo);
    }

    let _sortAsc = false;

    useEffect(() => {
        if (props.dailyTotals !== undefined)
        {
            setDailyTotalList(props.dailyTotals);
        }
    }, []);

    const sortData = (column) => {
        _sortAsc = sortAsc;

        if (column === activeSort)
        {
            setSortAsc(!sortAsc);
            _sortAsc = !_sortAsc;
        }

        setActiveSort(column);

        if (column === 'totalVolumeConsumed')
        {
            setDailyTotalList(dailyTotalList.sort(totalVolumeConsumedCompare).slice());
        }
        else if (column === 'totalVolumeFueled') {
            setDailyTotalList(dailyTotalList.sort(totalVolumeFueledCompare).slice());
        }
        else if (column === 'date')
        {
            setDailyTotalList(dailyTotalList.sort(dateCompare).slice());
        }
        else if (column === 'snapshots')
        {
            setDailyTotalList(dailyTotalList.sort(snapshotCompare).slice());
        }
    }

    const totalVolumeConsumedCompare = (a,b) => {
        if (_sortAsc) {
            if (a.totalVolumeConsumed * 1 > b.totalVolumeConsumed * 1) {
                return 1;
            } else {
                return -1;
            }
        } else {
            if (a.totalVolumeConsumed * 1 < b.totalVolumeConsumed * 1) {
                return 1;
            } else {
                return -1;
            }
        }
    }

    const totalVolumeFueledCompare = (a,b) => {
        if (_sortAsc) {
            if (a.totalVolumeFueled * 1 > b.totalVolumeFueled * 1) {
                return 1;
            } else {
                return -1;
            }
        } else {
            if (a.totalVolumeFueled * 1 < b.totalVolumeFueled * 1) {
                return 1;
            } else {
                return -1;
            }
        }
    }
    
    const dateCompare = (a,b) => {
        if (_sortAsc) {
            if (a.date > b.date) {
                return 1;
            } 
            else {
                return -1;
            }
        }
        else {
            if (a.date < b.date) {
                return 1;
            }
            else {
                return -1;
            }
        }
    }
    const snapshotCompare = (a,b) => {
        if (_sortAsc) {
            if (a.totalSnapshots > b.totalSnapshots) {
                return 1;
            } else {
                return -1;
            }
        } else {
            if (a.totalSnapshots < b.totalSnapshots) {
                return 1;
            } else {
                return -1;
            }
        }
    }
    
    const intl = useIntl();
    return (
        <Row>
            <Col sm="12">
                <div className="pumperSummary">
                    <div className="pumperLinesHeader">
                        <div className="pumperLinesHeaderCell pumperLinesHeaderName pumperLinesHeaderCellSort" onClick={() => sortData('date')}> <FormattedMessage id="date.label" defaultMessage="Date" />{(activeSort === 'date') && sortAsc &&  <WhiteUp />}{(activeSort === 'date') && !sortAsc && <WhiteDown />}</div>
                        <div className="pumperLinesHeaderCell pumperLinesHeaderName pumperLinesHeaderCellSort" onClick={() => sortData('totalVolumeConsumed')}> <FormattedMessage id="totalVolumeConsumed.label" defaultMessage="Total Volume Consumed" />{(activeSort === 'totalVolumeConsumed') && sortAsc &&  <WhiteUp />}{(activeSort === 'totalVolumeConsumed') && !sortAsc && <WhiteDown />}</div>
                        <div className="pumperLinesHeaderCell pumperLinesHeaderName pumperLinesHeaderCellSort" onClick={() => sortData('totalVolumeFueled')}> <FormattedMessage id="totalVolumeFueled.label" defaultMessage="Total Volume Fueled" />{(activeSort === 'totalVolumeFueled') && sortAsc &&  <WhiteUp />}{(activeSort === 'totalVolumeFueled') && !sortAsc && <WhiteDown />}</div>
                        <div className="pumperLinesHeaderCell pumperLinesHeaderStages pumperLinesHeaderCellSort" onClick={() => sortData('snapshots')}><FormattedMessage id="numberOfSnapshots.label" defaultMessage="# of Snapshots" />{activeSort === 'snapshots' && sortAsc && <WhiteUp />}{activeSort === 'snapshots' && !sortAsc && <WhiteDown />}</div>
                    </div>
                    <div className="clearfix"></div>
                    <div className="pumperLinesData">
                        <table className="fullWidth" id="table">
                            {props.project !== null && props.dailyTotals !== null && dailyTotalList !== null && dailyTotalList.map(dailyTotal =>
                                <DailyFuelLevelLine dailyTotal={dailyTotal} unit={props.project.uomString} />
                            )}
                            {props.project !== null && props.dailyTotals !== null && dailyTotalList !== null &&
                            <tr>
                                <td>
                                    <div className='pumperLine'>
                                        <div className="pumperLineName">
                                            {(intl.formatMessage({ id: "total.label", defaultMessage: "TOTAL"})).toUpperCase()}
                                        </div>
                                        <div className="pumperLineName">
                                            <div className="pumperLineMobileTitle">
                                                <FormattedMessage id="totalVolumeConsumed.label" defaultMessage="Total Volume Consumed" />
                                            </div>
                                            <div className="pumperLineName1">{Formatting.numberWithCommas(_.sumBy(dailyTotalList, "totalVolumeConsumed"))}{props.project.uomString}</div>
                                        </div>
                                        <div className="pumperLineName">
                                            <div className="pumperLineMobileTitle">
                                                <FormattedMessage id="totalVolumeFueled.label" defaultMessage="Total Volume Fueled" />
                                            </div>
                                            <div className="pumperLineName1">{Formatting.numberWithCommas(_.sumBy(dailyTotalList, "totalVolumeFueled"))}{props.project.uomString}</div>
                                        </div>
                                        <div className="pumperLineStages">
                                            <div className="pumperLineMobileTitle"><FormattedMessage id="numberOfSnapshots.label" defaultMessage="# of Snapshots" /></div>
                                            <div className="pumperLineName1  floatRightNoMargin">{Formatting.numberWithCommas(_.sumBy(dailyTotalList,  'totalSnapshots'))}</div>
                                        </div>
                                        
                                        <div className="clearfix"></div>
                                    </div>
                                </td>
                            </tr>
                            }
                        </table>
                    </div>
                </div>
            </Col>
        </Row>
    )
}