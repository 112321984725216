import React, {useEffect, useState} from 'react'
import {Pie, Chart} from 'react-chartjs-2';
import * as Formatting from '../helpers/formatting';
import { useIntl } from "react-intl";

export default function GasCompositionPieChart(props)
{
    const [data, setData] = useState(null); // data is set to null initially
    const chartRef = React.createRef();
    const intl = useIntl();
    
    const GetTopSevenGases = (gasComposition) => {
        // Create items array
        var items = Object.keys(gasComposition).map(function(key) {
            return [key, gasComposition[key]];
        });
        
        // Gas totals
        var gasTotal = 0;
        for (var i = 0; i < items.length; i++) {
            gasTotal += items[i][1];
        }

        // Sort the array based on the second element
        items.sort(function(first, second) {
            return second[1] - first[1];
        });

        // Create a new array with only the first 7 items
        var currentItems = items.slice(0, 7);
        var otherItems = items.slice(7, items.length);
        
        var otherTotal = 0;
        for (var i = 0; i < otherItems.length; i++) {
            otherTotal += otherItems[i][1];
        }
        
        currentItems.push(["Others", otherTotal])
        
        // Convert currentItem values to percentage
        for (var i = 0; i < currentItems.length; i++) {
            currentItems[i][1] =  ((currentItems[i][1] / gasTotal) * 100).toFixed(4);
        }
        
        return Object.fromEntries(currentItems);
    }
    
    useEffect(() => {
        let gasComposition = GetTopSevenGases(props.gasComposition);
        
        setData({
            labels: Object.keys(gasComposition),
            datasets: [{
                data: Object.values(gasComposition),
                backgroundColor: ['red', 'blue', 'orange', 'black', 'yellow', 'green', 'purple', 'grey']
            }]
        })
    }, [props.gasComposition])

    useEffect(() => {
        const myChartRef = chartRef.current.getContext("2d")
        
        new Chart(myChartRef, {
            type: "pie",
            data: data,
            options: {
                legend:
                    {
                        display: true,
                        position: 'left',
                        labels: {
                            fontColor: '#FFFFFF',
                            font: 'Roboto Condensed',
                            generateLabels: function(chart) {
                                var data = chart.data;
                                if (data.labels.length && data.datasets.length) {
                                    return data.labels.map(function (label, i) {
                                        var meta = chart.getDatasetMeta(0);
                                        var ds = data.datasets[0];
                                        var arc = meta.data[i];
                                        var custom = arc && arc.custom || {};
                                        var getValueAtIndexOrDefault = Chart.helpers.getValueAtIndexOrDefault;
                                        var arcOpts = chart.options.elements.arc;
                                        var fill = custom.backgroundColor ? custom.backgroundColor : getValueAtIndexOrDefault(ds.backgroundColor, i, arcOpts.backgroundColor);
                                        var stroke = custom.borderColor ? custom.borderColor : getValueAtIndexOrDefault(ds.borderColor, i, arcOpts.borderColor);
                                        var bw = custom.borderWidth ? custom.borderWidth : getValueAtIndexOrDefault(ds.borderWidth, i, arcOpts.borderWidth);
                                        
                                        // We get the value of the current label
                                        var value = chart.config.data.datasets[arc._datasetIndex].data[arc._index];

                                        return {
                                            // Instead of `text: label, We add the value to the string
                                            text: label + " : " + value,
                                            fillStyle: fill,
                                            strokeStyle: stroke,
                                            lineWidth: bw,
                                            hidden: isNaN(ds.data[i]) || meta.data[i].hidden,
                                            index: i
                                        };
                                    });
                                } else {
                                    return [];
                                }
                            }
                        }
                    }
            }
        })
    }, [data])

    return (
        <canvas
            id="gasComposition"
            ref={chartRef}
        />
    )
}