import React, { Fragment, useEffect, useState } from 'react';
import {Col, Row, UncontrolledTooltip} from 'reactstrap'
import * as Formatting from '../helpers/formatting';
import {ReactComponent as WhiteUp} from '../images/whiteUp.svg';
import {ReactComponent as WhiteDown} from '../images/whiteDown.svg';
import GreenhouseGasTotalLine from "./GreenhouseGasTotalLine";
import _ from 'lodash';
import { FormattedMessage, useIntl } from "react-intl";
import InfoIcon from "../images/icons8-info-16.png";
import {NaturalGasUnit} from "../enums";

export default function GreenhouseGasSummary(props)
{
    const [activeSort, setActiveSort] = useState(null);
    const [sortAsc, setSortAsc] = useState(false);
    const [showInfo, setShowInfo] = useState(false);
    const [gasTotalList, setGasTotalList] = useState(null);

    const toggleInfoIcon = () =>
    {
        setShowInfo(!showInfo);
    }
    
    let _sortAsc = false;

    useEffect(() => {
        if (props.gasTotals !== undefined)
        {
            setGasTotalList(props.gasTotals);
        }
    }, []);

    const sortData = (column) => {
        _sortAsc = sortAsc;

        if (column === activeSort)
        {
            setSortAsc(!sortAsc);
            _sortAsc = !_sortAsc;
        }

        setActiveSort(column);

        if (column === 'diesel')
        {
            setGasTotalList(gasTotalList.sort(dieselCompare).slice());
        }
        else if (column === 'ng')
        {
            setGasTotalList(gasTotalList.sort(ngCompare).slice());
        }
        else if (column === 'nonfrac')
        {
            setGasTotalList(gasTotalList.sort(nonfracCompare).slice());
        }
        else if (column === 'date')
        {
            setGasTotalList(gasTotalList.sort(dateCompare).slice());
        }
        else if (column === 'consumed')
        {
            setGasTotalList(gasTotalList.sort(consumedCompare).slice());
        }
        else if (column === 'emission')
        {
            setGasTotalList(gasTotalList.sort(emissionCompare).slice());
        }
        
    }

    const dieselCompare = (a,b) => {
        if (props.project.uom === "Litres") {
            if (_sortAsc) {
                if (a.totalDieselLitres * 1 > b.totalDieselLitres * 1) {
                    return 1;
                } else {
                    return -1;
                }
            } else {
                if (a.totalDieselLitres * 1 < b.totalDieselLitres * 1) {
                    return 1;
                } else {
                    return -1;
                }
            }
        } else {
            if (_sortAsc) {
                if (a.totalDieselGallons * 1 > b.totalDieselGallons * 1) {
                    return 1;
                } else {
                    return -1;
                }
            } else {
                if (a.totalDieselGallons * 1 < b.totalDieselGallons * 1) {
                    return 1;
                } else {
                    return -1;
                }
            }
        }
    }

    const consumedCompare = (a,b) => {
        if (props.project.uom === "Litres") {
            if (_sortAsc) {
                if (a.totalConsumedLitres * 1 > b.totalConsumedLitres * 1) {
                    return 1;
                } else {
                    return -1;
                }
            } else {
                if (a.totalConsumedLitres * 1 < b.totalConsumedLitres * 1) {
                    return 1;
                } else {
                    return -1;
                }
            }
        } else {
            if (_sortAsc) {
                if (a.totalConsumedGallons * 1 > b.totalConsumedGallons * 1) {
                    return 1;
                } else {
                    return -1;
                }
            } else {
                if (a.totalConsumedGallons * 1 < b.totalConsumedGallons * 1) {
                    return 1;
                } else {
                    return -1;
                }
            }
        }
    }

    const ngCompare = (a,b) => {
        if (props.project.uom === "Litres") {
            if (_sortAsc)
            {
                if (a.totalNGLitres > b.totalNGLitres)
                {
                    return 1;
                }else{
                    return -1;
                }
            }else{
                if (a.totalNGLitres < b.totalNGLitres)
                {
                    return 1;
                }else{
                    return -1;
                }
            }
        } else {
            if (_sortAsc)
            {
                if (a.totalNGGallons > b.totalNGGallons)
                {
                    return 1;
                }else{
                    return -1;
                }
            }else{
                if (a.totalNGGallons < b.totalNGGallons)
                {
                    return 1;
                }else{
                    return -1;
                }
            }
        }
    }
    const dateCompare = (a,b) => {
        if (_sortAsc)
        {
            if (a.date > b.date)
            {
                return 1;
            }else{
                return -1;
            }
        }else
        {
            if (a.date < b.date)
            {
                return 1;
            }else{
                return -1;
            }
        }
    }
    const nonfracCompare = (a,b) => {
        if (props.project.uom === "Litres") {
            if (_sortAsc) {
                if (a.totalNonFracDieselLitres > b.totalNonFracDieselLitres) {
                    return 1;
                } else {
                    return -1;
                }
            } else {
                if (a.totalNonFracDieselLitres < b.totalNonFracDieselLitres) {
                    return 1;
                } else {
                    return -1;
                }
            }
        } else {
            if (_sortAsc) {
                if (a.totalNonFracDieselGallons > b.totalNonFracDieselGallons) {
                    return 1;
                } else {
                    return -1;
                }
            } else {
                if (a.totalNonFracDieselGallons < b.totalNonFracDieselGallons) {
                    return 1;
                } else {
                    return -1;
                }
            }
        }
    }

    const emissionCompare = (a,b) => {
        if (_sortAsc)
        {
            if (a. co2eReductionPerDay * 1 > b. co2eReductionPerDay * 1)
            {
                return 1;
            }else{
                return -1;
            }
        }else{
            if (a.co2eReductionPerDay * 1 < b. co2eReductionPerDay * 1)
            {
                return 1;
            }else{
                return -1;
            }
        }
    }
    
    const intl = useIntl();
    return (
        <Row>
            <Col sm="12">
                <div className="pumperSummary">
                    <div className="pumperLinesHeader">
                        <div className="pumperLinesHeaderCell pumperLinesHeaderName pumperLinesHeaderCellSort" onClick={() => sortData('date')}> <FormattedMessage id="date.label" defaultMessage="Date" />{(activeSort === 'date') && sortAsc &&  <WhiteUp />}{(activeSort === 'date') && !sortAsc && <WhiteDown />}</div>
                        <div className="pumperLinesHeaderCell pumperLinesHeaderValue pumperLinesHeaderCellSort" onClick={() => sortData('diesel')}> <FormattedMessage id="fracDiesel.label" defaultMessage="Frac Diesel" /> {activeSort === 'diesel' && sortAsc && <WhiteUp />}{activeSort === 'diesel' && !sortAsc && <WhiteDown />}</div>
                        {props.project !== null && 
                        <Fragment>
                            <div className="pumperLinesHeaderCell pumperLinesHeaderValue pumperLinesHeaderCellSort" onClick={() => sortData('ng')}><FormattedMessage id="naturalGas.label" defaultMessage="Natural Gas" />{activeSort === 'ng' && sortAsc && <WhiteUp />}{activeSort === 'ng' && !sortAsc && <WhiteDown />}</div>
                            <div className="pumperLinesHeaderCell pumperLinesHeaderValue pumperLinesHeaderCellSort" onClick={() => sortData('consumed')}><FormattedMessage id="fracConsumedEnergy.label" defaultMessage="Frac Consumed Energy" />{activeSort === 'consumed' && sortAsc && <WhiteUp />}{activeSort === 'consumed' && !sortAsc && <WhiteDown />}</div>
                            <div id="infoIcon" className="pumperLinesHeaderCell pumperLinesHeaderValue pumperLinesHeaderCellSort" onClick={() => sortData('emission')}>
                                <FormattedMessage id="co2Reduction.label" defaultMessage="CO2 Reduction" />
                                {/*<img src={InfoIcon} className="infoIcon" onClick={() => toggleInfoIcon} alt="info" />*/}
                                {/*<UncontrolledTooltip placement="right" target="infoIcon">*/}
                                {/*    <FormattedMessage id="co2Emission.message" defaultMessage="Calculated by (((([Natural Gas Usage per Day] * [NG Heating Value]) / [Diesel Heating Value]) * [Diesel CO2 Emission Factor]) / 1000) - [Natural Gas CO2 Emission per Day], Where [NG Heating Value] and [Diesel Heating Value] are from project configuration and [Diesel CO2 Emission Factor] = 10.18 Kg/gal"/>*/}
                                {/*</UncontrolledTooltip>*/}
                                {activeSort === 'emission' && sortAsc && <WhiteUp />}
                                {activeSort === 'emission' && !sortAsc && <WhiteDown />}
                            </div>
                        </Fragment>
                        }
                        <div className="pumperLinesHeaderCell pumperLinesHeaderValueNonFrac pumperLinesHeaderCellSort" onClick={() => sortData('nonfrac')}><FormattedMessage id="nonFracDiesel.label" defaultMessage="Non-Frac Diesel" />{activeSort === 'nonfrac' && sortAsc && <WhiteUp />}{activeSort === 'nonfrac' && !sortAsc && <WhiteDown />}</div>
                    </div>
                    <div className="clearfix"></div>
                    <div className="pumperLinesData">
                        <table className="fullWidth" id="table">
                            {props.project !== null && props.gasTotals !== null && gasTotalList !== null && gasTotalList.map(gasTotal =>
                                <GreenhouseGasTotalLine naturalGasUnit={props.project.naturalGasUnit} bifuel={props.project.bifuel} gasTotal={gasTotal} unit={props.project.uomString} unitShort={props.project.uomStringShort} />
                            )}
                            {props.project !== null && props.project.gasTotals !== null && gasTotalList !== null &&
                            <tr><td>
                                <div className='pumperLine'>
                                    <div className="pumperLineName">
                                        {(intl.formatMessage({ id: "total.label", defaultMessage: "TOTAL"})).toUpperCase()}
                                    </div>
                                    <div className="pumperLineValue">
                                        <div className="pumperLineMobileTitle">
                                            <FormattedMessage id="fracDiesel.label" defaultMessage="Frac Diesel" />
                                        </div>
                                        <div className="pumperLineName1">{Formatting.numberWithCommas(_.sumBy(gasTotalList, props.project.uomStringShort === 'L' ? 'totalDieselLitres' : 'totalDieselGallons'))}{props.project.uomString}</div>
                                    </div>
                                    {/*{props.project.bifuel &&*/}
                                    <Fragment>
                                        <div className="pumperLineValue">
                                            <div className="pumperLineMobileTitle">
                                                <FormattedMessage id="naturalGas.label" defaultMessage="Natural Gas" />
                                            </div>
                                            <div className="pumperLineName1">{Formatting.numberWithCommas(_.sumBy(gasTotalList, props.project.uomStringShort === 'L' ? 'totalNGLitres' : 'totalNGGallons'))} D{props.project.uomStringShort}E</div>
                                            {props.project.naturalGasUnit === NaturalGasUnit.SCF &&
                                                <div className="pumperLineName2">{Formatting.numberWithCommas(_.sumBy(gasTotalList, 'totalSCF'))} SCF</div>
                                            }
                                            {props.project.naturalGasUnit === NaturalGasUnit.MCF &&
                                                <div className="pumperLineName2">{Formatting.numberWithCommas(_.sumBy(gasTotalList, 'totalMCF'))} MCF</div>
                                            }
                                            {props.project.naturalGasUnit === NaturalGasUnit.E3M3 &&
                                                <div className="pumperLineName2">{Formatting.numberWithCommas(_.sumBy(gasTotalList, 'totalDEC'), 4)} E3M3</div>
                                            }
                                        </div>
                                        <div className="pumperLineEnergy">
                                            <div className="pumperLineMobileTitle"><FormattedMessage id="fracConsumedEnergy.label" defaultMessage="Frac Consumed Energy" /></div>
                                            <div className="pumperLineName1">{Formatting.numberWithCommas(_.sumBy(gasTotalList, props.project.uomStringShort === 'L' ? 'totalConsumedLitres' : 'totalConsumedGallons'))} D{props.project.uomStringShort}E</div>
                                            <div className="pumperLineName2">{Formatting.numberWithCommas(_.sumBy(gasTotalList, 'totalBTU'))} BTU</div>
                                        </div>
                                        <div className="pumperLineValue">
                                            <div className="pumperLineName1">{Formatting.numberWithCommas(_.sumBy(gasTotalList, 'co2eReductionPerDay'))}
                                                &nbsp;
                                                <FormattedMessage id="tonnes.label" defaultMessage="Tonnes" />
                                            </div>
                                        </div>
                                    </Fragment>
                                    {/*}*/}

                                    <div className="pumperLineEnergy">
                                        <div className="pumperLineMobileTitle"><FormattedMessage id="nonFracDiesel.label" defaultMessage="Non-Frac Diesel" /></div>
                                        <div className="pumperLineName1">{Formatting.numberWithCommas(_.sumBy(gasTotalList, props.project.uomStringShort === 'L' ? 'totalNonFracDieselLitres' : 'totalNonFracDieselGallons'))}{props.project.uomString}</div>
                                    </div>
                                    <div className="clearfix"></div>
                                </div>
                            </td></tr>
                            }
                        </table>
                    </div>
                </div>
            </Col>
        </Row>
    )
}