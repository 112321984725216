import React, {Fragment, useState, useEffect} from "react";
import {
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    NavLink,
    Badge, Tooltip
} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEllipsisH, faCheckCircle} from "@fortawesome/free-solid-svg-icons";
import {FormattedMessage, useIntl} from "react-intl";
import {SubscriptionTierEnum} from "../../../interfaces/User";
import Calendar from "react-calendar";
import moment from "moment";
import cn from "classnames";
import {UserManagementType} from "../../Layouts/ManageUsers";
import {connect} from "react-redux";

function UserManagementRow(props) {
    const [optionsDropdownToggle, setOptionsDropdownToggle] = useState(false);
    const [isExpiryCalendarOpen, setIsExpiryCalendarOpen] = useState(false);
    const [isTierDropdownOpen, setIsTierDropdownOpen] = useState(false);
    const [isRoleDropdownOpen, setIsRoleDropdownOpen] = useState(false);
    const [isExpiryDateLocked, setIsExpiryDateLocked] = useState(false);
    const [tier, setTier] = useState(SubscriptionTierEnum.Free);
    const [expiryDate, setExpiryDate] = useState(null);
    const [role, setRole] = useState(null);

    const intl = useIntl();

    // Init
    useEffect(() => {
        getValues();
    }, [])

    const toggleOptions = () => setOptionsDropdownToggle(!optionsDropdownToggle);
    const toggleExpiryCalendar = () => setIsExpiryCalendarOpen(!isExpiryCalendarOpen);
    const toggleTierDropdown = () => setIsTierDropdownOpen(!isTierDropdownOpen);
    const toggleRoleDropdown = () => setIsRoleDropdownOpen(!isRoleDropdownOpen);

    // Retrieve tier from user app_metadata
    const getValues = () => {
        var energyProducerList = props.user.app_metadata.access.energyProducers;
        var energyProducer = energyProducerList.find(energyProducer => {
            return energyProducer.name === props.energyProducerName
        })

        // Checks if tier exists
        if (energyProducer.tier !== null && energyProducer.tier !== undefined) {
            setTier(energyProducer.tier);
            setExpiryDate(energyProducer.expiryDate);
        }

        // Check if role exists
        if (energyProducer.role !== null && energyProducer.role !== undefined) {
            setRole(energyProducer.role);
        }
    }

    const modifyUserTier = async (newTier) => {
        if (newTier === SubscriptionTierEnum.Free) {
            props.modifyUser(newTier, null, role, props.isAdmin, props.index);

            // Lock out subscription end date and set to null
            setIsExpiryDateLocked(true);
            setExpiryDate(null);
        } else {
            props.modifyUser(newTier, expiryDate, role, props.isAdmin, props.index);
            setIsExpiryDateLocked(false);
        }

        getValues();
    };

    const modifyExpiryDate = async (expiryDate) => {
        let formattedExpiryDate = moment(expiryDate).format("yyyy-MM-DDTHH:mm:ss");
        
        props.modifyUser(tier, formattedExpiryDate, role, props.isAdmin, props.index);
        getValues();

        setIsExpiryCalendarOpen(false);
    }

    const modifyUserRole = async (role) => {
        props.modifyUser(tier, expiryDate, role, props.isAdmin, props.index);
        getValues();
    }

    return (
        <Fragment>
            <tr className="user-management-row" key={props.user.email}>
                <td>{props.user.nickname ? props.user.nickname : props.user.name}</td>
                <td className={props.optionalClassName}>{props.user.email}</td>
                {props.isModifying === undefined || !props.isModifying &&
                <td>{tier}</td>
                }
                {props.isModifying !== undefined && props.isModifying &&
                <td>
                    <Dropdown isOpen={isTierDropdownOpen} toggle={toggleTierDropdown}>
                        <DropdownToggle caret>
                            {tier}
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-menu">
                            <DropdownItem className="dropdown-item"
                                          onClick={() => modifyUserTier(SubscriptionTierEnum.Free)}>
                                {SubscriptionTierEnum.Free}
                            </DropdownItem>
                            <DropdownItem className="dropdown-item"
                                          onClick={() => modifyUserTier(SubscriptionTierEnum.Silver)}>
                                {SubscriptionTierEnum.Silver}
                            </DropdownItem>
                            <DropdownItem className="dropdown-item"
                                          onClick={() => modifyUserTier(SubscriptionTierEnum.Gold)}>
                                {SubscriptionTierEnum.Gold}
                            </DropdownItem>
                        </DropdownMenu>
                    </Dropdown>
                </td>
                }
                {props.isModifying === undefined || !props.isModifying &&
                <td>
                    {expiryDate === null
                        ? intl.formatMessage({id: "none.label", defaultMessage: "None"})
                        : expiryDate
                    }
                </td>
                }
                {props.isModifying !== undefined && props.isModifying &&
                <td>
                    <span>
                        {expiryDate === null
                            ? intl.formatMessage({id: "none.label", defaultMessage: "None"})
                            : expiryDate
                        }
                    </span>
                    <span className={
                        cn("icon-effects", {
                            disabled: isExpiryDateLocked
                        })}
                          style={{marginLeft: "15px"}}>
                            <FontAwesomeIcon icon="calendar-alt" id={`infoIcon-user-${props.index}`}/>
                            <Tooltip placement="right"
                                     isOpen={isExpiryCalendarOpen}
                                     toggle={toggleExpiryCalendar}
                                     trigger="click"
                                     target={`infoIcon-user-${props.index}`}>
                                <Calendar value={expiryDate === null
                                    ? new Date(Date.now())
                                    : new Date(expiryDate)}
                                          onChange={modifyExpiryDate}/>
                            </Tooltip>
                    </span>
                </td>
                }
                {(props.isModifying === undefined || !props.isModifying) && props.type === UserManagementType.EnergyProducerUser &&
                <td>{role === null ? "None" : role}</td>
                }
                {props.isModifying !== undefined && props.isModifying && props.type === UserManagementType.EnergyProducerUser &&
                <td>
                    <Dropdown isOpen={isRoleDropdownOpen} toggle={toggleRoleDropdown}>
                        <DropdownToggle caret>
                            {role === null ? "None" : role}
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-menu">
                            <DropdownItem className="dropdown-item"
                                          onClick={() => modifyUserRole(null)}>
                                {"None"}
                            </DropdownItem>
                            {props.roles !== undefined && props.roles.map(role => (
                                <DropdownItem className="dropdown-item"
                                              onClick={() => modifyUserRole(role.role)}>
                                    {role.role}
                                </DropdownItem>
                            ))}
                        </DropdownMenu>
                    </Dropdown>
                </td>
                }
                <td>{props.isAdmin === true ?
                    <span><FormattedMessage id="administrator.label" defaultMessage="Administrator"/></span> :
                    <span><FormattedMessage id="viewer.label" defaultMessage="Viewer"/></span>}</td>
                <td>
                    {props.user.email_verified ? (
                        <span>
                            <Badge color="secondary">
                                <FormattedMessage id="verified.label" defaultMessage="Verified"/>
                                <FontAwesomeIcon icon={faCheckCircle}/>{' '}
                            </Badge>
                        </span>
                    ) : (
                        <a onClick={() => props.emailInvite(props.user.email)} className="custom-link dark">
                            <FormattedMessage id="resendInvite.label" defaultMessage="Resend Invite"/>
                        </a>
                    )}
                </td>
                <td>
                    <Dropdown
                        isOpen={optionsDropdownToggle}
                        toggle={toggleOptions}
                    >
                        <DropdownToggle tag={NavLink} className="custom-link dark">
                            <FontAwesomeIcon className="breadcrumb-wrapper-item" icon={faEllipsisH}/>
                        </DropdownToggle>
                        <DropdownMenu right>
                            <DropdownItem onClick={() => props.removeAccess(props.user)}>
                                <FormattedMessage id="removeAccess.label" defaultMessage="Remove Access"/>
                            </DropdownItem>

                            {props.enableElevationToEnergyProducer ?
                                <DropdownItem onClick={() => props.elevateToEnergyProducer(props.user)}>
                                    <FormattedMessage id="elevateAccessTo.label" defaultMessage="Elevate Access to "/>
                                    {props.energyProducerName}
                                </DropdownItem>

                                : ''}
                            {props.enableDemotionToProject ?
                                <DropdownItem onClick={() => props.demoteToProject(props.user)}>
                                    <FormattedMessage id="demoteAccessTo.label" defaultMessage="Demote Access to "/>
                                    {props.projectName}
                                </DropdownItem> : ''}
                            {props.enableElevationToAdmin ?
                                <DropdownItem onClick={() => props.elevateToAdmin(props.user)}>
                                    <FormattedMessage id="makeAdministrator.label"
                                                      defaultMessage="Make Administrator"/>
                                </DropdownItem> : <DropdownItem onClick={() => props.demoteToViewer(props.user)}>
                                    <FormattedMessage id="removeAdministrator.label"
                                                      defaultMessage="Remove Administrator"/>
                                </DropdownItem>}
                            <DropdownItem divider/>
                        </DropdownMenu>
                    </Dropdown>
                </td>
            </tr>
        </Fragment>
    )
}

const mapStateToProps = (store) => {
    return {
        roles: store.roles.roles
    };
};

export default connect(
    mapStateToProps
)(UserManagementRow);