import React, { Fragment } from 'react';
import {FormattedMessage} from "react-intl";
import FuelLevelChartContainer from "./FuelLevelChartContainer";

export default function FuelStats(props) {
    return (
        <Fragment>
            {props.well != null &&
                <Fragment>
                    <div className="pumperTitle">{props.well.wellId !== '' ?
                        <span><FormattedMessage id="well.label" defaultMessage="Well" /> {props.well.wellId}</span>
                        : <i><FormattedMessage id="unknownWell.label" defaultMessage="Unknown Well" /></i>}</div>
                    <FuelLevelChartContainer well={props.well} />
                </Fragment>
            }
            {(props.well === null || props.well === undefined) &&
                <Fragment>
                    <div className="pumperTitle">
                        <FormattedMessage id="allWells.label" defaultMessage="All Wells" />
                    </div>
                    <FuelLevelChartContainer />
                </Fragment>
            }
        </Fragment>
    )

}