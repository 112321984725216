import React, {Fragment, useContext, useEffect, useState} from 'react';
import {Button, Table} from "reactstrap";
import {useAuth0} from "../../../auth/auth0-wrapper";
import '../../../Site.css';
import '../AdminSettings/AdminSettings.css';
import { actionCreators as actionCreatorsHeader } from "../../../store/Header";
import { actionCreators as actionCreatorsAdmin } from '../../../store/Admin';
import {connect} from "react-redux";
import {FormattedMessage, useIntl} from "react-intl";
import Loader from "../Loader/Loader";
import {bindActionCreators} from "redux";
import {toast} from "react-toastify";
import OverdueJobRow from "./OverdueJobRow";
import Unauthorized from "../../Unauthorized";
import { isITSiteAdmin } from "../../../auth/accessControlService";
import PaginationBar from "./PaginationBar";
import AdminToggle from "./AdminToggle";
import {getUserLanguagePreference} from "../../../helpers/metadataHelper";
import {LocalizationContext} from "../Localization/LanguageWrapper";

function AdminSettings(props){
    const localizationContext = useContext(LocalizationContext);
    const { getTokenSilently, loading, user, } = useAuth0();
    const [hasAccess, setHasAccess] = useState(false);
    const [token, setToken] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [overdueJobs, setOverdueJobs] = useState([]);
    const [overdueJobSubscription, setOverdueJobSubscription] = useState(null)
    
    // Pagination variables
    const [currentPage, setCurrentPage] = useState(1);
    const [jobsPerPage, setJobsPerPage] = useState(5);
    const [indexOfLastJob, setIndexOfLastJob] = useState(currentPage * jobsPerPage);
    const [indexOfFirstJob, setIndexOfFirstJob] = useState(indexOfLastJob - jobsPerPage);
    const [currentJobs, setCurrentJobs] = useState([]);
    const [pageNumbers, setPageNumbers] = useState(new Set());
    
    const intl = useIntl();
    toast.configure();
    
    //Initial mount
    useEffect(() => {
        const fetchToken = async () => {
            const auth0Token = await getTokenSilently();
            setToken(auth0Token);
            
            return auth0Token;
        };
        
        fetchToken().then((token) => {
            const checkAccess = async () => {
                const access = await isITSiteAdmin(global.apiEndpoint, token);
                setHasAccess(access);
                setIsLoading(false);
            }
            
            checkAccess();
        })
        setLanguage();
    },[])
    
    // Grabs overdueJobs if user has access
    useEffect(() => {
        if (token && hasAccess) {
            // API call to retrieve all overdue jobs
            const getOverdueJobs = async() => {
                const overdueJobsData = await props.requestOverdueJobs(token);
                setOverdueJobs(overdueJobsData);
            }
            
            // API call to get the the users subscription status
            const getOverdueJobSubscription = async() => {
                const overdueJobSubscription = await props.requestOverdueJobSubscription(token, user.email)
                setOverdueJobSubscription(overdueJobSubscription);
            }
            
            getOverdueJobs();
            getOverdueJobSubscription();
        }
    }, [token, hasAccess])
    
    // // Determines the number of pages
    useEffect(() => {
        if (overdueJobs) {
            for (var i = 1; i <= Math.ceil(overdueJobs.length / jobsPerPage); i++) {
                pageNumbers.add(i)
            }

            setCurrentJobs(overdueJobs.slice(indexOfFirstJob, indexOfLastJob));
        }
    }, [overdueJobs])
    
    useEffect(() => {
        const indexOfLastJob = currentPage * jobsPerPage;
        const indexOfFirstJob = indexOfLastJob - jobsPerPage;
        const currentJobs = overdueJobs.slice(indexOfFirstJob, indexOfLastJob);
        
        // Update pagination variables
        setIndexOfLastJob(indexOfLastJob);
        setIndexOfFirstJob(indexOfFirstJob);
        setCurrentJobs(currentJobs);
    }, [currentPage])
    
    const setLanguage = () => {
        let metaDataValue = getUserLanguagePreference(user);
        let languagePreference = "en-US";

        if(metaDataValue !== null && metaDataValue !== undefined)
        {
            if(metaDataValue.languagePreference !== null)
            {
                languagePreference = metaDataValue.languagePreference;

                localizationContext.selectLang(languagePreference, "main");
            }
        }
    }
    
    const goBack = () => {
        window.location.assign(window.localStorage.getItem('prevPath'));
    }

    // API update request
    const saveAdminSettings = async () => {
        await props.updateOverdueJobSubscription(token, overdueJobSubscription);
        await props.updateOverdueJobs(token, overdueJobs);
        
        toast.success(intl.formatMessage({ id: "adminSaveSuccess.message", defaultMessage: "Success: Admin settings saved" }), {
            position: toast.POSITION.TOP_RIGHT
        });

        goBack();
    }
    
    // Maps changes to overdue job subscription to be saved 
    const changeJobSubscriptionState = async (jobId) => {
        var tempOverdueJobs = overdueJobs.map(job => ({
            ...job,
            sendNotification: job.jobId === jobId ? !job.sendNotification : job.sendNotification
        }))
        
        setOverdueJobs(tempOverdueJobs);
    }
    
    if (isLoading) {
        return  (
            <div className="center">
                <Loader/>
            </div>
        )
    } else {
        return(
            <Fragment>
                {!hasAccess &&
                   <Unauthorized/>
                }
                {hasAccess && overdueJobSubscription && overdueJobs &&
                    <div className="padding-top-lg">    
                        <div className="admin-content-settings col-sm-12 col-md-10 offset-md-1">
                            <h2>
                                <FormattedMessage id="adminSettings.label" defaultMessage="Admin Settings"/>
                            </h2>
    
                            <div className="admin-content-container d-flex flex-column">
                                <AdminToggle overdueJobSubscription={overdueJobSubscription}
                                             objType="isActive"
                                             setOverdueJobSubscription={setOverdueJobSubscription}
                                             label="overdueJobsSubscription"
                                             defaultMessage="Overdue Jobs Subscription"
                                />
                                <AdminToggle overdueJobSubscription={overdueJobSubscription}
                                             objType="sendJobNotification"
                                             setOverdueJobSubscription={setOverdueJobSubscription}
                                             label="jobCreationSubscription"
                                             defaultMessage="Job Creation Subscription"
                                />
                                <AdminToggle overdueJobSubscription={overdueJobSubscription}
                                             objType="dbSizeNotification"
                                             setOverdueJobSubscription={setOverdueJobSubscription}
                                             label="databaseAlertSubscription"
                                             defaultMessage="Database Alert Subscription"
                                />
    
                                <div className="overdue-job-container d-flex flex-column mb-5">
                                    <h4 className="overdue-jobs-header">
                                        <FormattedMessage id="overdueJobs.label" defaultMessage="Overdue Jobs"/>
                                    </h4>
                                    <Table dark responsive>
                                        <thead>
                                        <tr>
                                            <th><FormattedMessage id="jobId.label" defaultMessage="Job Id" /></th>
                                            <th><FormattedMessage id="jobCode.label" defaultMessage="Job Code"/></th>
                                            <th><FormattedMessage id="jobName.label" defaultMessage="Job Name"/></th>
                                            <th><FormattedMessage id="shacks.label" defaultMessage="Shacks"/></th>
                                            <th><FormattedMessage id="startDate.label" defaultMessage="Start Date" /></th>
                                            <th><FormattedMessage id="sendNotification.label" defaultMessage="Send Notification" /></th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {currentJobs.map((job) => (
                                            <OverdueJobRow job={job} onClick={changeJobSubscriptionState}/>
                                        ))}
                                        </tbody>
                                        <tfoot>
                                            <PaginationBar pageNumbers={pageNumbers} currentPage={currentPage} onPageClick={(page) => setCurrentPage(page)}/>
                                        </tfoot>
                                    </Table>
                                </div>

                                <div className="button-container">
                                    <Button
                                        color="warning"
                                        className="float-right"
                                        disabled={user.loading === true}
                                        outline
                                        onClick={saveAdminSettings}
                                        size="md"
                                    >
                                        <FormattedMessage id="save.label" defaultMessage="Save"/>
                                    </Button>
                                    <Button
                                        color="warning"
                                        className="float-right"
                                        disabled={user.loading === true}
                                        outline
                                        onClick={goBack}
                                        size="md"
                                    >
                                        <FormattedMessage id="back.label" defaultMessage="Back"/>
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </Fragment>
        )
    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({...actionCreatorsHeader, ...actionCreatorsAdmin}, dispatch)

export default connect(
    null,
    mapDispatchToProps
)(AdminSettings);