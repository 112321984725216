import React from 'react';
import "../css/Dashboard.css";
import DashboardDropdown from "./DashboardDropdown";
import {useIntl} from "react-intl";

export default function DashboardSingle(props)
{
    const intl = useIntl();
    
    return (
        <div className="dashboardSingle">
            <div className="dashboardSingleTitle">{props.title}</div>
            
            {props.title === intl.formatMessage({ id: "totalSavings.label", defaultMessage: "Total Savings"}) &&
                <div className="dashboardSingleValue" style={{color:'#36a558'}}>{props.value}</div>
            }
            {props.title !== intl.formatMessage({ id: "totalSavings.label", defaultMessage: "Total Savings"}) &&
                <div className="dashboardSingleValue">{props.value}</div>
            }
            
            {props.options !== undefined && props.selectedValue !== undefined && props.onSelect !== undefined &&
                <DashboardDropdown options={props.options} selectedValue={props.selectedValue} onSelect={props.onSelect}/>
            }
            {props.options === undefined && props.onSelect === undefined && props.selectedValue !== undefined &&
                <div className="dashboard-currency-label">{props.selectedValue}</div>
            }
        </div>
    )
}