import React, { Fragment } from 'react';
import {FormattedMessage} from "react-intl";

export default function WellList(props) {

    const requestWellStats = (well) => 
    {
        props.onClick(well);
    }

    const requestProjectStats = () =>
    {
        props.onProjectClick();
    }

    return (
        <Fragment>
            {
                <Fragment>
                    <Fragment>
                        {(props.allProjects === 'true' || props.allProjects === true) &&
                            <div className={(props.activeWell != null && props.activeWell === 'all') ? 'wellTitle wellTitleActive subMenuItem subMenuItemActive' : 'wellTitle subMenuItem'} onClick={() => requestProjectStats()}>
                                <FormattedMessage id="allWells.label" defaultMessage="All Wells" />
                            </div>
                        }
                    </Fragment>
                    <Fragment>
                        {props.wells != null && props.wells.map(well =>
                            <Fragment key={well.name}>
                                <div className={props.activeWell != null && props.activeWell.name === well.name ? 'wellTitle wellTitleActive subMenuItem subMenuItemActive' : 'wellTitle subMenuItem'} onClick={() => requestWellStats(well)}>{well.name !== '' ? <span>
                                    <FormattedMessage id="well.label" defaultMessage="Well" />  {well.name}
                                </span> : <i><FormattedMessage id="unknownWell.label" defaultMessage="Unknown Well" /> </i>}</div>
                            </Fragment>
                        )}
                    </Fragment>
                </Fragment>
            }
        </Fragment>
    );
 
}