import React, {useContext, useEffect, useState} from 'react';
import {Bar, Chart} from 'react-chartjs-2';
import * as Formatting from '../helpers/formatting';
import {useIntl} from 'react-intl';

export default function DailyFuelLevelTotalsChart(props) {
    const [data, setData] = useState(null) // data is set to null initially
    const [chartOptions, setChartOptions] = useState(null);
    const intl = useIntl();

    useEffect(() => {
        const dataSets = [
            {
                label: intl.formatMessage({id: "totalVolumeConsumed.label", defaultMessage: "Total Volume Consumed"}),
                data: Object.keys(props.dailyTotals).map(i => props.dailyTotals[i].totalVolumeConsumed),
                fill: true,
                lineTension: 0.1,
                backgroundColor: '#5F8BFF',
                borderColor: 'rgba(75,192,192,1)',
                borderCapStyle: 'butt',
                borderDash: [],
                borderDashOffset: 0.0,
                borderJoinStyle: 'miter',
                pointBorderColor: 'rgba(75,192,192,1)',
                pointBackgroundColor: '#fff',
                pointBorderWidth: 1,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: 'rgba(75,192,192,1)',
                pointHoverBorderColor: 'rgba(220,220,220,1)',
                pointHoverBorderWidth: 2,
                pointRadius: 5,
                pointHitRadius: 10,
                type: 'bar'
            },
            {
                label: intl.formatMessage({id: "totalVolumeFueled.label", defaultMessage: "Total Volume Fueled"}),
                data: Object.keys(props.dailyTotals).map(i => props.dailyTotals[i].totalVolumeFueled),
                fill: true,
                lineTension: 0.1,
                backgroundColor: '#CCCCCC',
                borderColor: 'rgba(75,192,192,1)',
                borderCapStyle: 'butt',
                borderDash: [],
                borderDashOffset: 0.0,
                borderJoinStyle: 'miter',
                pointBorderColor: 'rgba(75,192,192,1)',
                pointBackgroundColor: '#fff',
                pointBorderWidth: 1,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: 'rgba(75,192,192,1)',
                pointHoverBorderColor: 'rgba(220,220,220,1)',
                pointHoverBorderWidth: 2,
                pointRadius: 5,
                pointHitRadius: 10,
                type: 'bar'
            }
        ];

        const chartOptions = {
            scales: {
                xAxes: [{
                    stacked: false,
                    scaleLabel: {
                        display: true,
                        labelString: intl.formatMessage({id: "date.label", defaultMessage: "Date"})
                    }
                }],
                yAxes: [
                    {
                        display: true,
                        position: 'left',
                        gridLines: {
                            display: false
                        },
                        labels: {
                            show: true
                        },
                        ticks: {
                            beginAtZero: true
                        }
                        ,
                        scaleLabel: {
                            display: true,
                            labelString: `${intl.formatMessage({
                                id: "volume.label",
                                defaultMessage: "Volume"
                            })} (${props.project.fuelLevel.unitOfMeasurement})`
                        }
                    },
                ]
            }
            ,
            legend:
                {
                    display: true,
                    position: 'bottom',
                    onClick: (e) => e.stopPropagation(),
                    labels: {
                        filter: function (item, chart) {
                            return !item.text.includes('hide');
                        }
                    }
                },
            tooltips: {
                mode: 'index',
                intersect: false,
                position: 'nearest',
                backgroundColor: '#ffffff',
                titleFontColor: '#000000',
                bodyFontColor: '#000000',
                titleFontFamily: 'Calibri, Arial',
                titleFontSize: 12,
                bodyFontFamily: 'Calibri, Arial',
                bodyFontSize: 12,
                itemSort: function (a, b) {
                    return a.datasetIndex - b.datasetIndex;
                },
                callbacks: {
                    label: function (tooltipItem, data) {
                        var label = Math.round(tooltipItem.yLabel * 100) / 100;
                        if (isNaN(label)) label = 0;
                        if (tooltipItem.datasetIndex === 0) {
                            return `${intl.formatMessage({
                                    id: "totalVolumeConsumed.label",
                                    defaultMessage: "Total Volume Consumed"
                                })}: ${Formatting.numberWithCommas(label)} ${props.project.fuelLevel.unitOfMeasurement}`
                        } else if (tooltipItem.datasetIndex === 1) {
                            return `${intl.formatMessage({
                                id: "totalVolumeFueled.label",
                                defaultMessage: "Total Volume Fueled"
                            })}: ${Formatting.numberWithCommas(label)} ${props.project.fuelLevel.unitOfMeasurement}`
                        } else {
                            return label;
                        }
                    },
                    beforeLabel: function (tooltipItem, data) {
                    },
                    afterLabel: function (tooltipItem, data) {
                    },
                    title: function (tooltipItems, data) {
                        var label = tooltipItems[0].label;
                        return label;
                    }
                }
            },
            responsive: true,
            maintainAspectRatio: false
        }

        setData({
            labels: Object.keys(props.dailyTotals).map(i => props.dailyTotals[i].dateString),
            datasets: dataSets
        })
        setChartOptions(chartOptions);
    }, [props])

    // render bar chart when there is data
    return data && chartOptions && (<Bar className="barChart" data={data} options={chartOptions}/>)
}