import React, { useEffect, Fragment } from "react";
import UserConfiguration from "./common/UnitsOfMeasureToggle/config";
import { FormattedMessage } from "react-intl";

export default function ProjectConfig(props) {
  return (
    <Fragment>
      <div className="projectInfoContainer">
        <div className="projectInfoTitle">
          <FormattedMessage
            id="projectInfo.label"
            defaultMessage="Project Info"
          />
          {" "}/{" "}
          <FormattedMessage
            id="configuration.label"
            defaultMessage="Configuration"
          />
        </div>
        {props.showUnitOfMeasureToggle && (
          <UserConfiguration
            user={props.user}
            token={props.token}
            uom={props.project.uom}
            energyProducer={props.project.energyProducerName}
            projectName={props.project.name}
            dieselBTUValueDefault={global.dieselBTUValueDefault}
            ngBTUValueDefault={global.ngBTUValueDefault}
            brakeHorsePowerDefault={global.brakeHorsePowerDefault}
            loadFactorIdleDefault={global.loadFactorIdleDefault}
            loadFactorPumpingDefault={global.loadFactorPumpingDefault}
            hoursPerDayIdleDefault={global.hoursPerDayIdleDefault}
            hoursPerDayWorkingDefault={global.hoursPerDayWorkingDefault}
            dieselCostPerGallonDefault={global.dieselCostPerGallonDefault}
            ngCostPerMCFDefault={global.ngCostPerMCFDefault}
            bifuelDailySetupCostDefault={global.bifuelDailySetupCostDefault}
            substitutionRateDefault={global.substitutionRateDefault}
          />
        )}
        <div className="clearfix"></div>
        <div> 
          <FormattedMessage id="changingValues.message" defaultMessage="Changing these values will refresh the application." />
        </div>
        <div className="clearfix margin-bottom-20"></div>
      </div>
    </Fragment>
  );
}
