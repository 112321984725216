// Overdue Jobs
export const requestOverdueJobsType = 'REQUEST_OVERDUE_JOBS';
export const receiveOverdueJobsType = 'RECEIVE_OVERDUE_JOBS';
export const requestOverdueJobsError = 'OVERDUE_JOBS_ERROR';

// Overdue Job Subscriptions
export const requestOverdueJobSubscriptionType = 'REQUEST_OVERDUE_JOB_SUBSCRIPTION';
export const receiveOverdueJobSubscriptionType = 'RECEIVE_OVERDUE_JOB_SUBSCRIPTION';
export const requestOverdueJobSubscriptionError = 'OVERDUE_JOB_SUBSCRIPTION_ERROR';

// Unauthorized
export const UnauthorizedError = "UNAUTHORIZED";