import React, {useState, useEffect, Fragment, useRef} from "react";

// This component displays each flag with its name and language. It is being contained by FlagContainer which is shown in CountryPopup.
export default function Flag(props) {
    const inputRef = useRef();
    
    // Update selectedFlag & selectedCountry state in Grandparent component -> CountryPopup
    const setStates = () => {
        inputRef.current.focus();
        
        props.setFlag(`${props.language}-${props.code}`);
        props.setCountry(props.country);
        props.setLastFocus(document.activeElement);
    }
    
    return (
        <div className="flag">
            <img className={props.country + '-flag'} 
                 src={props.src} 
                 tabIndex={props.index} 
                 ref={inputRef}
                 onClick={setStates} 
                 alt={props.country + '-flag'}/>
            <div className="country">{props.country} - {props.language}</div>
        </div>
    )
}
