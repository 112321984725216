import React, { useState, useEffect, Fragment, useContext } from "react";
import {useAuth0} from "../auth/auth0-wrapper";
import {LocalizationContext} from "./common/Localization/LanguageWrapper";
import '../css/LandingPage.css'
import News from "./News";
import LandingPageBody from "./LandingPageBody";
import CountryPopup from "./PopupComponents/CountryPopup";
import {getUserLanguagePreference} from "../helpers/metadataHelper";

// This component is the wrapper for the entire landing page, it contains the landing page context, news sections, as well as the country popup on first login.
export default function LandingPage(props) {
    const [firstLogin, setFirstLogin] = useState(false);
    const { user } = useAuth0();
    const localizationContext = useContext(LocalizationContext);

    //Initial mount
    useEffect(() => {
        let metaDataValue = getUserLanguagePreference(user);
        let languagePreference = "en-US";

        if(metaDataValue !== null && metaDataValue !== undefined)
        {
            if(metaDataValue.languagePreference !== null)
            {
                languagePreference = metaDataValue.languagePreference;
                               
                localizationContext.selectLang(languagePreference, "main");
            } else {
                document.body.style.pointerEvents = "none";
                setFirstLogin(true);
            }
        }else {
            document.body.style.pointerEvents = "none";
            setFirstLogin(true);
        }
    }, []);
    
    return (
        <div className="landing-page-container">
            <LandingPageBody/>
            {firstLogin && 
                <CountryPopup firstLogin={firstLogin}/>
            }
            {/*<News/>*/}
        </div>
    )
}
