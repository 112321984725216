import React, {Fragment, useContext, useEffect, useState} from 'react';
import {FormattedMessage, useIntl} from "react-intl";
import {ProjectMenuContext} from "../Contexts/ProjectMenuContext";
import {ReactComponent as WhiteUp} from "../../../images/whiteUp.svg";
import {ReactComponent as WhiteDown} from "../../../images/whiteDown.svg";
import ReactGA from "react-ga";
import {ProjectContext} from "../Contexts/ProjectContext";
import {ReactComponent as BlackRight} from "../../../images/arrowRightBlack.svg";

export default function Reporting(props) {
    const {closeMobileMenu} = useContext(ProjectMenuContext);
    const {project, setActivePage, setActiveSubPage, setMobileText} = useContext(ProjectContext);
    const [showReportingDropdown, setShowReportingDropdown] = useState(false);
    const intl = useIntl();

    useEffect(() => {
        if (project.activePage !== "Reporting") {
            setShowReportingDropdown(false);
        }
    }, [project.activePage])

    const activePage = () => {
        return project.activePage === "Reporting" ? 'siteTitle siteTitleActive menuItem menuItemActive' : 'siteTitle menuItem'
    }

    const toggleReportingDropdown = () => {
        switch (project.activePage) {
            case "Reporting":
                setShowReportingDropdown(!showReportingDropdown);
                break;
            default:
                setShowReportingDropdown(true);
                requestSubscriptions();
        }
    }

    const requestExcelReports = () => {
        setActiveSubPage('reports');
        setMobileText(intl.formatMessage({id: "excelReports.label", defaultMessage: "Excel Reports"}));
        closeMobileMenu();

        // Google Analytics
        ReactGA.event({
            category: "Project Menu Selection",
            action: `Clicked on "Reporting: Reports" on Project "${window.location.pathname}"`
        })

        // Google Tag Manager
        window.dataLayer.push({
            'event': "Pageview",
            'pagePath': `${window.location.pathname}/reporting/reports`,
            'pageTitle': `Reporting - Reports`
        })
    }

    const requestSubscriptions = () => {
        setActiveSubPage('subscriptions');
        setMobileText(intl.formatMessage({id: "subscriptions.label", defaultMessage: "Subscriptions"}));

        if (project.activePage !== "Reporting") {
            setActivePage("Reporting");
        } else {
            closeMobileMenu();
        }

        // Google Analytics
        ReactGA.event({
            category: "Project Menu Selection",
            action: `Clicked on "Reporting: Subscriptions" on Project "${window.location.pathname}"`
        })

        // Google Tag Manager
        window.dataLayer.push({
            'event': "Pageview",
            'pagePath': `${window.location.pathname}/reporting/subscriptions`,
            'pageTitle': `Reporting - Subscriptions`
        })
    }

    return (
        <Fragment>
            {!props.mobile &&
            <Fragment>
                <h1 onClick={() => toggleReportingDropdown()} className={activePage()}>
                    <FormattedMessage id="reporting.label" defaultMessage="Reporting"/>
                    {showReportingDropdown && <WhiteUp className="floatRight"/>}
                    {!showReportingDropdown && <WhiteDown className="floatRight"/>}
                </h1>
                
                {showReportingDropdown &&
                <Fragment>
                    <div
                        className={project.activeSubPage === "subscriptions" ? 'wellTitle wellTitleActive subMenuItem subMenuItemActive' : 'wellTitle subMenuItem'}
                        onClick={() => requestSubscriptions()}>
                        <FormattedMessage id="subscriptions.label" defaultMessage="Subscriptions"/>
                    </div>
                    <div
                        className={project.activeSubPage === "reports" ? 'wellTitle wellTitleActive subMenuItem subMenuItemActive' : 'wellTitle subMenuItem'}
                        onClick={() => requestExcelReports()}>
                        <FormattedMessage id="excelReports.label" defaultMessage="Excel Reports"/>
                    </div>
                </Fragment>
                }
            </Fragment>
            }
            {props.mobile &&
            <Fragment>
                <div className="entireSiteMobile" onClick={() => toggleReportingDropdown()}>
                    <div className="mobileText">
                        <FormattedMessage id="reporting.label" defaultMessage="Reporting"/>
                    </div>
                    <div className="floatRight mobileArrow"><BlackRight/></div>
                </div>
                
                {showReportingDropdown &&
                <Fragment>
                    <div
                        className={project.activeSubPage === "subscriptions" ? 'wellTitle wellTitleActive subMenuItem subMenuItemActive' : 'wellTitle subMenuItem'}
                        onClick={() => requestSubscriptions()}>
                        <FormattedMessage id="subscriptions.label" defaultMessage="Subscriptions"/>
                    </div>
                    <div
                        className={project.activeSubPage === "reports" ? 'wellTitle wellTitleActive subMenuItem subMenuItemActive' : 'wellTitle subMenuItem'}
                        onClick={() => requestExcelReports()}>
                        <FormattedMessage id="excelReports.label" defaultMessage="Excel Reports"/>
                    </div>
                </Fragment>
                }
            </Fragment>
            }
        </Fragment>
    )
}