import React, { useState, useEffect, useContext, Fragment } from "react";
import createAuth0Client from "@auth0/auth0-spa-js";
import fetchWithTimeout from '../helpers/fetchWithTimeout';
import { setState } from "litsy";

const DEFAULT_REDIRECT_CALLBACK = () =>
	window.history.replaceState({}, document.title, window.location.pathname);

export const Auth0Context = React.createContext();
export const useAuth0 = () => useContext(Auth0Context);
export const Auth0Provider = ({
	children,
	onRedirectCallback = DEFAULT_REDIRECT_CALLBACK
}) => {
	const [isAuthenticated, setIsAuthenticated] = useState();
	const [user, setUser] = useState();
	const [auth0Client, setAuth0] = useState();
	const [loading, setLoading] = useState(true);
	const [popupOpen, setPopupOpen] = useState(false);
	const [isAzureLoaded, setAzureLoaded] = useState(false);

	const appSettings = () => {

		if (process.env.REACT_APP_LOCAL_CACHE_TIME !== undefined) {
			global.apiEndpoint = process.env.REACT_APP_API_URL;
			setState("fracShack_apiEndpoint", process.env.REACT_APP_API_URL, "session");
			global.auth0Domain = process.env.REACT_APP_AUTH0_DOMAIN;
			global.auth0ClientId = process.env.REACT_APP_AUTH0_CLIENTID;
			global.auth0RedirectUri = process.env.REACT_APP_AUTH0_REDIRECTURI;
			global.auth0Audience = process.env.REACT_APP_AUTH0_AUDIENCE;
			setAzureLoaded(true);
		} else if (global.auth0Domain === null || global.auth0Domain === undefined || global.auth0Domain === ''){
			global.apiEndpoint = process.env.REACT_APP_API_URL;
			setState("fracShack_apiEndpoint", process.env.REACT_APP_API_URL, "session");

			var myRequest = new Request(global.apiEndpoint + "settings/keys");

			fetchWithTimeout(myRequest).then(function (response) {
				if (response.status === 200) {
					return response.json();
				}
			}).then(function (jsonResponse) {
				global.auth0Domain = jsonResponse.auth0Domain;
				global.auth0RedirectUri = jsonResponse.auth0RedirectURI;
				global.auth0ClientId = jsonResponse.auth0ClientID;
				global.auth0Audience = jsonResponse.auth0Audience;
				global.projectStats = jsonResponse.showProjectStats;
				global.pumperStats = jsonResponse.showPumperStats;
				global.unitStats = jsonResponse.showUnitStats;
				global.substitutionRate = jsonResponse.showSubRate;
				global.userManagement = jsonResponse.showUserManagement;
				global.exportData = jsonResponse.exportData;
				global.unitOfMeasureToggle = jsonResponse.showUOMToggle;
				global.localCacheTime = jsonResponse.localCacheTime;
				global.dieselBTUValueDefault = jsonResponse.dieselBtuHeatingValue;
				global.ngBTUValueDefault = jsonResponse.ngBtuHeatingValue;
				global.brakeHorsePowerDefault = jsonResponse.brakeHorsePowerValue;
				global.loadFactorPumpingDefault = jsonResponse.loadFactorPumpingValue;
				global.loadFactorIdleDefault = jsonResponse.loadFactorIdleValue;
				global.hoursPerDayWorkingDefault = jsonResponse.hoursPerDayWorkingValue;
				global.hoursPerDayIdleDefault = jsonResponse.hoursPerDayIdleValue;
				global.dieselCostPerGallonDefault = jsonResponse.dieselCostPerGallonValue;
				global.ngCostPerMCFDefault = jsonResponse.ngCostPerMcfValue;
				global.bifuelDailySetupCostDefault = jsonResponse.bifuelDailySetupCostValue;
				global.substitutionRateDefault = jsonResponse.substitutionRateValue;
				
				setAzureLoaded(true);
			});
		}
	}

	useEffect(() => {
		appSettings();
	}, []);

	useEffect(() => {
		if (isAzureLoaded) {
			initAuth0();
			setAzureLoaded(false);
		}
	}, [isAzureLoaded]);

	const initAuth0 = async () => {
		const initOptions = {
			domain: global.auth0Domain,
			client_id: global.auth0ClientId,
			redirect_uri: global.auth0RedirectUri,
			audience: global.auth0Audience,
			leeway: 300
		}

		const auth0FromHook = await createAuth0Client(initOptions);

		setAuth0(auth0FromHook);

		if (window.location.search.includes("code=")) {
			const { appState } = await auth0FromHook.handleRedirectCallback();
			sessionStorage.clear();
			onRedirectCallback(appState);
		}

		const isAuthenticated = await auth0FromHook.isAuthenticated();

		setIsAuthenticated(isAuthenticated);

		if (isAuthenticated) {
			const user = await auth0FromHook.getUser();
			setUser(user);
		}

		setLoading(false);
	};

	const loginWithPopup = async (params = {}) => {
		setPopupOpen(true);
		try {
			await auth0Client.loginWithPopup(params);
		} catch (error) {
			console.error(error);
		} finally {
			setPopupOpen(false);
		}
		const user = await auth0Client.getUser();
		setUser(user);
		setIsAuthenticated(true);
	};

	const handleRedirectCallback = async () => {
		setLoading(true);
		await auth0Client.handleRedirectCallback();
		const user = await auth0Client.getUser();
		setLoading(false);
		setIsAuthenticated(true);
		setUser(user);
	};

	return (
		<Fragment>
			<Auth0Context.Provider
				value={{
					isAuthenticated,
					user,
					loading,
					popupOpen,
					loginWithPopup,
					handleRedirectCallback,
					getUser: (...p) => auth0Client.getUser(...p),
					getIdTokenClaims: (...p) => auth0Client.getIdTokenClaims(...p),
					loginWithRedirect: (...p) => auth0Client.loginWithRedirect(...p),
					getTokenSilently: (...p) => auth0Client.getTokenSilently(...p),
					getTokenWithPopup: (...p) => auth0Client.getTokenWithPopup(...p),
					logout: (...p) => auth0Client.logout(...p)
				}}
			>
				{children}
			</Auth0Context.Provider>
		</Fragment>

	);
};