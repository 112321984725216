import React from 'react';
import ExportImage from '../images/export.svg';
import slugify from "../helpers/slugify";
import downloadjs from 'downloadjs';
import axios from "axios";
import {useIntl, FormattedMessage} from "react-intl";
import ConvertStringToLabel from "../helpers/ConvertStringToLabel";
import ReactGA from 'react-ga';

export default function ExportUnit(props) {
    const intl = useIntl();

    const fetchData = async () => {

        ReactGA.event({
            category: 'Export Data',
            action: `Exported Data from project "${window.location.pathname}" on page "${!props.type ? "dashboard" : props.type}"`
        })

        var intUOM = 2;
        if (props.project.uomString === ' US gal') {
            intUOM = 1;
        }

        var dieselBTUValue = global.dieselBTUValue;
        var ngBTUValue = global.ngBTUValue;
        var dailyCutoffTime = global.dailyTotalCutoff;
        var dailyCutoffHour = global.dailyCutoffHour;
        var substitutionRate = global.substitutionRateValue;
        
        if (dieselBTUValue === undefined || dieselBTUValue === null) {
            dieselBTUValue = global.dieselBTUValueDefault;
        }
        if (ngBTUValue === undefined || ngBTUValue === null) {
            ngBTUValue = global.ngBTUValueDefault;
        }
        if (substitutionRate === undefined || substitutionRate === null) {
            substitutionRate = global.substitutionRateDefault;
        }

        await axios
            .get(global.apiEndpoint +
                'project/export?projectName=' + slugify(props.project.name) +
                '&companyName=' + slugify(props.project.companyName,) +
                '&producerName=' + slugify(props.project.energyProducerName) +
                '&startTime=' + props.project.startDateURL +
                '&uom=' + intUOM +
                '&type=' + props.type +
                '&pumperName=' + props.pumperName +
                '&wellName=' + props.wellName +
                '&dieselBTUValue=' + dieselBTUValue +
                '&ngBTUValue=' + ngBTUValue +
                '&substitutionRateValue=' + substitutionRate +
                '&dailyCutoffTime=' + dailyCutoffTime +
                '&dailyCutoffHour=' + dailyCutoffHour, {
                    headers: {
                        Authorization: `Bearer ${props.token}`
                    }
                }
            )
            .then(function (response) {
                if (response.status == 200) {
                    const labelString = response.data.split('\n')[0];
                    const labels = labelString.split(',');

                    const re = new RegExp('[a-zA-Z]+\\d+.*');
                    let newLabelString = "";

                    labels.forEach(label => {
                        // If label has numbers after a certain word, splice, translate and recombine
                        if (re.test(label)) {
                            const labelEnding = label.split(/((?:[a-zA-Z])(?=[0-9]))/).slice(-1)[0];
                            const labelBeginning = label.match(/[a-zA-Z]+(?=[0-9])/)[0];

                            newLabelString = newLabelString +
                                intl.formatMessage({
                                    id: (ConvertStringToLabel(labelBeginning) + '.label'),
                                    defaultMessage: (labelBeginning)
                                }) +
                                labelEnding +
                                ",";

                        } else {
                            newLabelString = newLabelString + intl.formatMessage({
                                id: (ConvertStringToLabel(label) + '.label'),
                                defaultMessage: (label)
                            }) + ",";
                        }
                    })

                    newLabelString = newLabelString.slice(0, -1) + "\n";

                    response.data = newLabelString + response.data.split("\n").slice(1).join("\n");

                    return response.data;
                }
            })
            .then(function (data) {
                downloadjs(data, props.project.energyProducerName + '-' + props.project.name + '-' + props.project.startDateURL + '.csv', 'text/csv');
            });
    }

    return (
        <div className="exportButtonContainer" onClick={fetchData}>
            <div className="exportImage">
                < img src={ExportImage} alt="Export Raw Data"/>
            </div>
            <div className="exportButtonText">
                <FormattedMessage id="rawData.label" defaultMessage="Raw Data(.csv)"/>
            </div>
        </div>
    );
}