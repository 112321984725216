import React, {Fragment, useContext, useEffect, useState} from 'react';
import {ProjectContext} from "../Contexts/ProjectContext";
import {useIntl} from "react-intl";
import {setState} from "litsy";
import FuelLevelSummary from "../../FuelLevelSummary";
import FuelStats from "../../FuelStats";

export default function FuelLevelPage(props) {
    const {project, setValue, setMobileText} = useContext(ProjectContext)
    const intl = useIntl();

    const requestFuelLevelWellStats = (well) => {
        if (well.fuelLevelSnapshot !== undefined) {
            setValue('activeWell', well);
            setMobileText(well.wellId !== '' ?
                intl.formatMessage({id: "well.label", defaultMessage: "Well"}) + " " + well.wellId
                : 'Unknown Well');
        } else if (well.fuelLevelWells !== undefined) {
            setValue('activeWell', 'all');
            setMobileText(intl.formatMessage({id: "allWells.label", defaultMessage: "All Wells"}));
            setState("fracShack_currentProject", well, "volatile");
        }
    }

    return (
        <Fragment>
            {project.currentProject !== null && project.activePage === "FuelLevel" &&
            <Fragment>
                {project.activeWell !== null && project.activeWell !== 'all' &&
                <Fragment>
                    <FuelStats well={project.activeWell}/>
                    <FuelLevelSummary project={project.currentProject} 
                                      well={project.activeWell} 
                                      title="Snapshots"/>
                </Fragment>
                }

                {(props.showProjectStats === true || props.showProjectStats === 'true') && project.activeWell === 'all' &&
                <Fragment>
                    <FuelStats />
                    <FuelLevelSummary project={project.currentProject}
                                      title="Wells"
                                      requestWell={requestFuelLevelWellStats}
                                      type={'all'}/>
                </Fragment>
                }
            </Fragment>
            }
        </Fragment>
    )
}