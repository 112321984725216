import React, {useEffect, useState} from 'react';
import Modal from 'react-bootstrap/Modal';
import GasCompositionPieChart from "./GasCompositionPieChart";
import {FormattedMessage} from "react-intl";
import * as Formatting from '../helpers/formatting';
import {Button} from "reactstrap";

export default function NgSidekickGasCompositionElement(props) {
    const [isOpen, setIsOpen] = useState(false);
    
    const showModal = () => {
        setIsOpen(true);
    };

    const hideModal = () => {
        setIsOpen(false);
    };
    
    return (
        <fragment>
            <div className="pumperLineName1">
                {/*<span className="gas-composition-text">*/}
                {/*    {Formatting.numberWithCommas(props.totalGasComposition.toString())} Mols*/}
                {/*</span>*/}
                <Button className="expand-button"
                        onClick={showModal}
                        color="warning"
                        outline
                        size="sm">
                    +
                </Button>
            </div>
            
            <Modal show={isOpen} onHide={hideModal}>
                <div className="gas-composition-modal">
                    <Modal.Header>
                        <Modal.Title id="gas-composition-modal-header">
                            <FormattedMessage id="gasCompositionPercentage.label" defaultMessage="Gas Composition (%)"/>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <GasCompositionPieChart gasComposition={props.gasComposition} />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="close-button"
                                onClick={hideModal}
                                color="warning"
                                outline
                                size="md">
                            <FormattedMessage id="close.label" defaultMessage="Close"/>
                        </Button>
                    </Modal.Footer>
                </div>
            </Modal>
        </fragment>
    );
}