﻿﻿import React, {useEffect, useState} from "react";
import {Button} from "reactstrap";

const NullPopup = (props) => {

    const setStatus = () => {
        props.setStatus(false);
    }

    return <div className="null-popup-container" style={{"pointer-events": props.status ? 'all' : 'none'}}>
        <div className="null-popup-text">Please choose a language to continue.</div>
        <Button className="confirm-button"
                onClick={setStatus}
                color="warning"
                outline
                size="sm">
            ok
        </Button>
    </div>
}

export default NullPopup;