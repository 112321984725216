import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {
    AccessType,
    isSiteAdmin,
    isEnergyProducerAdmin,
    isEnergyProducerViewer,
    isProjectAdmin,
    isProjectViewer,
    isITSiteAdmin,
    isCustomerApiAdmin
} from '../../../auth/accessControlService';
import {access, accessSync} from 'fs';


const AccessControl = ({
                           children,
                           renderNoAccess,
                           accessType,
                           extraAccessData
                       }) => {
    const [permitted, setPermitted] = useState(false);


    const accessCheck = (accessType, extraAccessData) => {
        let permitted;
        if (accessType === AccessType.IsITSiteAdmin) {
            return isITSiteAdmin(extraAccessData.serviceEndpoint, extraAccessData.token).then(
                function (c) {
                    setPermitted(c)
                }
            );
        } else if (accessType === AccessType.IsSiteAdmin) {
            return isSiteAdmin(extraAccessData.serviceEndpoint, extraAccessData.token).then(
                function (c) {
                    setPermitted(c)
                });
            ;
        } else if (accessType === AccessType.IsEnergyProducerAdmin) {
            return isEnergyProducerAdmin(extraAccessData.serviceEndpoint, extraAccessData.token, extraAccessData.energyProducerName).then(
                function (c) {
                    setPermitted(c)
                });
            ;
        } else if (accessType === AccessType.IsEnergyProducerViewer) {
            return isEnergyProducerViewer(extraAccessData.serviceEndpoint, extraAccessData.token, extraAccessData.energyProducerName).then(
                function (c) {
                    setPermitted(c)
                });
            ;
        } else if (accessType === AccessType.IsProjectAdmin) {
            return isProjectAdmin(extraAccessData.serviceEndpoint, extraAccessData.token, extraAccessData.energyProducerName, extraAccessData.completeProjectName)
                .then(
                    function (c) {
                        setPermitted(c)
                    });
        } else if (accessType === AccessType.IsProjectViewer) {
            return isProjectViewer(extraAccessData.serviceEndpoint, extraAccessData.token, extraAccessData.energyProducerName, extraAccessData.completeProjectName).then(
                function (c) {
                    setPermitted(c)
                });
        } else if (accessType === AccessType.IsCustomerApiAdmin) {
            return isCustomerApiAdmin(extraAccessData.serviceEndpoint, extraAccessData.token, extraAccessData.energyProducerName).then(
                function (c) {
                    setPermitted(c)
                });
        }
    };
    useEffect(() => {
        if (extraAccessData.token !== undefined && extraAccessData.serviceEndpoint !== undefined) {
            const fetchPermissions = (accessType, extraAccessData) => {
                accessCheck(accessType, extraAccessData)
            }
            fetchPermissions(accessType, extraAccessData);
        }

    }, [extraAccessData.token])

    if (permitted === true) {
        return children;
    }
    return renderNoAccess();
};

AccessControl.defaultProps = {
    renderNoAccess: () => null,
};

// Compose AccessControl component with redux

export default connect()(AccessControl);