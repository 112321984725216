import React, { Fragment, useState } from 'react';
import { FormattedMessage } from 'react-intl';

export default function PumperList(props) {

    const requestPumpersStats = (pumper) => 
    {
        props.onClick(pumper);
    }

    const requestPumperStatsSummary = () =>
    {
        props.onSummaryClick();
    }

    return (
        <Fragment>
            <div className={props.activePumper != null && props.activePumper === 'all' ? 'wellTitle wellTitleActive subMenuItem subMenuItemActive' : 'wellTitle subMenuItem'} onClick={() => requestPumperStatsSummary()}>
                <FormattedMessage id="allPumpers.label" defaultMessage="All Pumpers" />
            </div>
            <Fragment>
            {
                props.pumpers != null && props.pumpers.map(pumper =>
                    <Fragment key={pumper.name}>
                        <div className={props.activePumper != null && props.activePumper.name === pumper.name ? 'wellTitle wellTitleActive subMenuItem subMenuItemActive' : 'wellTitle subMenuItem'} onClick={() => requestPumpersStats(pumper)}>{pumper.name}</div>
                    </Fragment>
                )
            }
            </Fragment>
        </Fragment>
    );
 
}