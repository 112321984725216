import React, {useState} from 'react';
import {Tooltip} from "reactstrap";

import InfoIcon from "../images/icons8-info-16.png";

export default function DashboardListElement(props)
{
    const [tooltipOpen, setTooltipOpen] = useState(false);

    const toggle = () => setTooltipOpen(!tooltipOpen);

    return (
        <div className="dashboard-list-element">
            {props.message && props.message.title &&
                <fragment>
                    {!props.message.bifuel &&
                        <div className="dashboard-list-element-header" style={{ color: props.message.color !== undefined ? props.message.color : ""}}>
                            { props.message.title }
                        </div>
                    }
                    {props.message.message &&
                        <div className="dashboard-info-icon-wrapper">
                            <div className="dashboard-list-element-title">{props.title}</div>
                            <img src={InfoIcon} className="dashboard-info-icon" id={`infoIcon-${props.parentIndex}-${props.childIndex}`} alt="info" />
                            <Tooltip placement="right" isOpen={tooltipOpen} toggle={toggle} target={`infoIcon-${props.parentIndex}-${props.childIndex}`} >
                                { props.message.message }
                            </Tooltip>
                        </div>
                    }
                    {props.message.message === undefined &&
                        <div className="dashboard-list-element-title">{props.title}</div>
                    }
                </fragment>
            }
            {props.message && props.message.title === undefined &&
                <fragment>
                    {props.message.message &&
                            <div className="dashboard-info-icon-wrapper">
                                <div className="dashboard-list-element-title">{props.title}</div>
                                <img src={InfoIcon} className="dashboard-info-icon"
                                     id={`infoIcon-${props.parentIndex}-${props.childIndex}`} alt="info"/>
                                <Tooltip placement="right" isOpen={tooltipOpen} toggle={toggle}
                                         target={`infoIcon-${props.parentIndex}-${props.childIndex}`}>
                                    {props.message.message}
                                </Tooltip>
                            </div>
                    }
                    {props.message.message === undefined &&
                        <div className="dashboard-list-element-title">{props.title}</div>
                    }
                </fragment>
            }
            
            {!props.message &&
                <div className="dashboard-list-element-title">{props.title}</div>
            }
            
            <div className="dashboard-list-element-value">{props.value}</div>
        </div>
    )
}