import React from "react";


export const Params = [
    { type: "long", value: 'jobId', label: 'jobId' },
    { type: "dateTime", value: 'startTime', label: 'startTime' },
    { type: "dateTime", value: 'endTime', label: 'endTime' },
    { type: "string", value: 'unitName', label: 'unitName' },
    { type: "dateTime", value: 'dateTimeStamp', label: 'dateTimeStamp' },
    { type: "int", value: 'snapshotTypeId', label: 'snapshotTypeId' },
    { type: "dateTime", value: 'projectStart', label: 'projectStart' },
    { type: "dateTime", value: 'projectEnd', label: 'projectEnd' },
    { type: "string", value: 'wellName', label: 'wellName' },
    { type: "int", value: 'stageNum', label: 'stageNum' },
    { type: "int", value: 'transId', label: 'transId' },
    { type: "int", value: 'stageIdleMins', label: 'stageIdleMins' },
    { type: "int", value: 'activeMins', label: 'activeMins' },
    { type: "string", value: 'skidType', label: 'skidType' },
    { type: "int", value: 'lineCount', label: 'lineCount' },
    { type: "double", value: 'diesel', label: 'diesel' },
    { type: "double", value: 'fracDiesel', label: 'fracDiesel' },
    { type: "double", value: 'nonFracDiesel', label: 'nonFracDiesel' },
    { type: "double", value: 'pumpAutoVol', label: 'pumpAutoVol' },
    { type: "double", value: 'pumpManVol', label: 'pumpManVol' },
    { type: "double", value: 'pumpCombVol', label: 'pumpCombVol' },
    { type: "double", value: 'dayTank1', label: 'dayTank1' },
    { type: "double", value: 'dayTank2', label: 'dayTank2' },
    { type: "double", value: 'meterTotal', label: 'meterTotal' },
    { type: "string", value: 'countryName', label: 'countryName' },
    { type: "string", value: 'companyName', label: 'companyName' },
    { type: "string", value: 'energyProducerName', label: 'energyProducerName' },
    { type: "string", value: 'projectName', label: 'projectName' },
    { type: "string", value: 'stateProvName', label: 'stateProvName' },
    { type: "string", value: 'regionName', label: 'regionName' },
    { type: "string", value: 'fuelProviderName', label: 'fuelProviderName' },
    { type: "string", value: 'nGDistributorName', label: 'nGDistributorName' },
    { type: "doubled", value: 'volume', label: 'volume' },
    { type: "double", value: 'averageBTU', label: 'averageBTU' },
    { type: "double", value: 'averageFlowRate', label: 'averageFlowRate' },
    { type: "double", value: 'methane', label: 'methane' },
    { type: "double", value: 'ethane', label: 'ethane' },
    { type: "double", value: 'propane', label: 'propane' },
    { type: "double", value: 'butane', label: 'butane' },
    { type: "double", value: 'isoButane', label: 'isoButane' },
    { type: "double", value: 'pentane', label: 'pentane' },
    { type: "double", value: 'isoPentane', label: 'isoPentane' },
    { type: "double", value: 'hexane', label: 'hexane' },
    { type: "double", value: 'heptane', label: 'heptane' },
    { type: "double", value: 'octane', label: 'octane' },
    { type: "double", value: 'nonane', label: 'nonane' },
    { type: "double", value: 'decane', label: 'decane' },
    { type: "double", value: 'hydrogen', label: 'hydrogen' },
    { type: "double", value: 'water', label: 'water' },
    { type: "double", value: 'hydrogenSulfide', label: 'hydrogenSulfide' },
    { type: "double", value: 'helium', label: 'helium' },
    { type: "double", value: 'carbonMonoxide', label: 'carbonMonoxide' },
    { type: "double", value: 'carbonDioxide', label: 'carbonDioxide' },
    { type: "double", value: 'nitrogen', label: 'nitrogen' },
    { type: "double", value: 'oxygen', label: 'oxygen' },
    { type: "int", value: 'auxMeterId', label: 'auxMeterId' },
    { type: "double", value: 'eventFlowRate', label: 'eventFlowRate' },
    { type: "int", value: 'aFENumber', label: 'aFENumber' },
    { type: "double", value: 'tripVolume', label: 'tripVolume' },
    { type: "double", value: 'eventVolumeCorrected', label: 'eventVolumeCorrected' },
    { type: "double", value: 'temperature', label: 'temperature' },
    { type: "string", value: 'equipmentId', label: 'equipmentId' },
    { type: "double", value: 'meters1', label: 'meters1' },
    { type: "double", value: 'meters2', label: 'meters2' },
    { type: "double", value: 'meters3', label: 'meters3' },
    { type: "double", value: 'meters4', label: 'meters4' },
    { type: "double", value: 'meters5', label: 'meters5' },
    { type: "double", value: 'meters6', label: 'meters6' },
    { type: "double", value: 'meters7', label: 'meters7' },
    { type: "double", value: 'meters8', label: 'meters8' },
    { type: "double", value: 'meters9', label: 'meters9' },
    { type: "double", value: 'meters10', label: 'meters10' },
    { type: "double", value: 'meters11', label: 'meters11' },
    { type: "double", value: 'meters12', label: 'meters12' },
    { type: "double", value: 'meters13', label: 'meters13' },
    { type: "double", value: 'meters14', label: 'meters14' },
    { type: "double", value: 'meters15', label: 'meters15' },
    { type: "double", value: 'meters16', label: 'meters16' },
    { type: "double", value: 'meters17', label: 'meters17' },
    { type: "double", value: 'meters18', label: 'meters18' },
    { type: "double", value: 'meters19', label: 'meters19' },
    { type: "double", value: 'meters20', label: 'meters20' },
    { type: "double", value: 'meters21', label: 'meters21' },
    { type: "double", value: 'meters22', label: 'meters22' },
    { type: "double", value: 'meters23', label: 'meters23' },
    { type: "double", value: 'meters24', label: 'meters24' },
    { type: "double", value: 'meters25', label: 'meters25' },
    { type: "double", value: 'meters26', label: 'meters26' },
    { type: "double", value: 'meters27', label: 'meters27' },
    { type: "double", value: 'meters28', label: 'meters28' },
    { type: "string", value: 'equip1', label: 'equip1' },
    { type: "string", value: 'equip2', label: 'equip2' },
    { type: "string", value: 'equip3', label: 'equip3' },
    { type: "string", value: 'equip4', label: 'equip4' },
    { type: "string", value: 'equip5', label: 'equip5' },
    { type: "string", value: 'equip6', label: 'equip6' },
    { type: "string", value: 'equip7', label: 'equip7' },
    { type: "string", value: 'equip8', label: 'equip8' },
    { type: "string", value: 'equip9', label: 'equip9' },
    { type: "string", value: 'equip10', label: 'equip10' },
    { type: "string", value: 'equip11', label: 'equip11' },
    { type: "string", value: 'equip12', label: 'equip12' },
    { type: "string", value: 'equip13', label: 'equip13' },
    { type: "string", value: 'equip14', label: 'equip14' },
    { type: "string", value: 'equip15', label: 'equip15' },
    { type: "string", value: 'equip16', label: 'equip16' },
    { type: "string", value: 'equip17', label: 'equip17' },
    { type: "string", value: 'equip18', label: 'equip18' },
    { type: "string", value: 'equip19', label: 'equip19' },
    { type: "string", value: 'equip20', label: 'equip20' },
    { type: "string", value: 'equip21', label: 'equip21' },
    { type: "string", value: 'equip22', label: 'equip22' },
    { type: "string", value: 'equip23', label: 'equip23' },
    { type: "string", value: 'equip24', label: 'equip24' },
    { type: "string", value: 'equip25', label: 'equip25' },
    { type: "string", value: 'equip26', label: 'equip26' },
    { type: "string", value: 'equip27', label: 'equip27' },
    { type: "string", value: 'equip28', label: 'equip28' },
    { type: "string", value: 'serial1', label: 'serial1' },
    { type: "string", value: 'serial2', label: 'serial2' },
    { type: "string", value: 'serial3', label: 'serial3' },
    { type: "string", value: 'serial4', label: 'serial4' },
    { type: "string", value: 'serial5', label: 'serial5' },
    { type: "string", value: 'serial6', label: 'serial6' },
    { type: "string", value: 'serial7', label: 'serial7' },
    { type: "string", value: 'serial8', label: 'serial8' },
    { type: "string", value: 'serial9', label: 'serial9' },
    { type: "string", value: 'serial10', label: 'serial10' },
    { type: "string", value: 'serial11', label: 'serial11' },
    { type: "string", value: 'serial12', label: 'serial12' },
    { type: "string", value: 'serial13', label: 'serial13' },
    { type: "string", value: 'serial14', label: 'serial14' },
    { type: "string", value: 'serial15', label: 'serial15' },
    { type: "string", value: 'serial16', label: 'serial16' },
    { type: "string", value: 'serial17', label: 'serial17' },
    { type: "string", value: 'serial18', label: 'serial18' },
    { type: "string", value: 'serial19', label: 'serial19' },
    { type: "string", value: 'serial20', label: 'serial20' },
    { type: "string", value: 'serial21', label: 'serial21' },
    { type: "string", value: 'serial22', label: 'serial22' },
    { type: "string", value: 'serial23', label: 'serial23' },
    { type: "string", value: 'serial24', label: 'serial24' },
    { type: "string", value: 'serial25', label: 'serial25' },
    { type: "string", value: 'serial26', label: 'serial26' },
    { type: "string", value: 'serial27', label: 'serial27' },
    { type: "string", value: 'serial28', label: 'serial28' },

    
]

export const ComparisonParams = [
    { value: '==', label: '==' },
    { value: '!=', label: '!=' },
    { value: '>', label: '>' },
    { value: '>=', label: '>=' },
    { value: '<', label: '<' },
    { value: '<=', label: '<=' },

]
