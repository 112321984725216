import React, {useEffect, useState} from "react";
import _ from "lodash";
import {toast} from "react-toastify";
import {
    AppMetadata,
    IAddUserParams,
    PermissionsEnum,
    SubmitUser,
    SubscriptionTierEnum,
    UserAccess
} from "../../../interfaces/User";
import {setState} from "litsy";
import {isValidEmail, UserManagementType} from "./index";
import {FormattedMessage, useIntl} from "react-intl";
import {Button, Col, Input, UncontrolledTooltip} from "reactstrap";
import {
    checkAccessActionCreator,
    getUsersActionCreator, submitEnergyProducerUserActionCreator,
    submitProjectUserActionCreator
} from "../../../store/ManageUsers";
import {actionCreators as actionCreatorsHeader} from "../../../store/Header";
import {actionCreators as actionCreatorsProducer} from "../../../store/Producers";
import {connect} from "react-redux";
import UserManagementTable from "./UserManagementTable";
import axios from "axios";
import {NetworkRequestStatus} from "../../../enums";

declare var global: any;

function UserManagement(props: any) {
    const [emailAddress, setEmailAddress] = useState("");

    const [energyProducerViewUsers, setEnergyProducerViewUsers] = useState<SubmitUser[]>([]);
    const [energyProducerManageUsers, setEnergyProducerManageUsers] = useState<SubmitUser[]>([]);
    const [projectViewUsers, setProjectViewUsers] = useState<SubmitUser[]>([]);
    const [projectManageUsers, setProjectManageUsers] = useState<SubmitUser[]>([]);

    const intl = useIntl();

    useEffect(() => {
        if (props.users.users.length > 0) {
            setEnergyProducerViewUsers(_.filter(props.users.users, {
                "app_metadata": {
                    "access": {
                        "energyProducers": [{
                            "permission": PermissionsEnum.View,
                            "name": props.energyProducerName
                        }]
                    }
                }
            }));
            setEnergyProducerManageUsers(_.filter(props.users.users, {
                "app_metadata": {
                    "access": {
                        "energyProducers": [{
                            "permission": PermissionsEnum.Manage,
                            "name": props.energyProducerName
                        }]
                    }
                }
            }));
            setProjectViewUsers(_.filter(props.users.users, {
                "app_metadata": {
                    "access": {
                        "energyProducers": [{
                            "projects": [{
                                "permission": PermissionsEnum.View,
                                "name": props.projectName
                            }]
                        }]
                    }
                }
            }));
            setProjectManageUsers(_.filter(props.users.users, {
                "app_metadata": {
                    "access": {
                        "energyProducers": [{
                            "projects": [{
                                "permission": PermissionsEnum.Manage,
                                "name": props.projectName
                            }]
                        }]
                    }
                }
            }));
        }
    }, [props.users.users])

    const addViewersToProject = () => {
        let emails = emailAddress.split(',');
        if (_.some(emails, function (e) {
            return !isValidEmail(e.trim())
        })) {
            var invalidEmail = _.find(emails, function (e) {
                return !isValidEmail(e.trim())
            });
            toast.error(invalidEmail + intl.formatMessage({
                id: "invalidEmailAddress.message",
                defaultMessage: "is not valid. Please enter a valid Email Address."
            }), {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }

        for (var i = 0; i < emails.length; i++) {
            if (_.some(projectViewUsers, function (e) {
                return e.email === emails[i]
            })) {
                toast.error(intl.formatMessage({
                    id: "emailAddressExists.message",
                    defaultMessage: "User with the Email Address {email} already exists"
                }, {email: emails[i]}), {
                    position: toast.POSITION.TOP_RIGHT
                });
                return;
            }
            let access: UserAccess = {
                energyProducers: [
                    {
                        name: props.energyProducerName,
                        projects: [{name: props.projectName, permission: PermissionsEnum.View}],
                        permission: PermissionsEnum.None,
                        tier: SubscriptionTierEnum.Free,
                        expiryDate: null,
                        role: null
                    }
                ]
            };
            //check if user exists in state
            if (_.some(props.users.users, function (e) {
                return e.email === emails[i]
            })) {
                var existingUser = _.find(props.users.users, function (e) {
                    return e.email === emails[i]
                })
                //check if user has current energy Producer
                if (_.some(existingUser.app_metadata.access.energyProducers, function (e) {
                    return e.name == props.energyProducerName
                })) {
                    //add to projects
                    var currentEnergyProducer = _.find(existingUser.app_metadata.access.energyProducers, function (e) {
                        return e.name == props.energyProducerName
                    })
                    currentEnergyProducer.projects.push({name: props.projectName, permission: PermissionsEnum.View})
                    access = existingUser.app_metadata.access;
                }
            }

            let app_metadata: AppMetadata = {
                access: access
            };

            if (!props.users.loading && props.token) {
                props.submitProjectUser(
                    props.energyProducerName,
                    props.projectName,
                    {
                        email: (emails[i]).trim(),
                        app_metadata: app_metadata,
                        token: props.token
                    });
            }
            if (props.users.loading === false && props.users.posting !== undefined && props.users.posting === false && props.users.posted !== undefined && props.users.posted === true) {
                toast.success(intl.formatMessage({
                    id: "successfullyAdded.label",
                    defaultMessage: "Successfully added"
                }) + ` ${emails[i]}`, {
                    position: toast.POSITION.TOP_RIGHT
                })
            }
        }
        if (!props.users.posting && props.users.posted !== undefined && props.users.posted === true) {
            props.getUsers({
                EnergyProducerName: props.energyProducerName,
                ProjectName: props.projectName,
                token: props.token
            });
            setEmailAddress("");
        }
        setEmailAddress("");
    };

    const makeProjectAdmin = (user: any) => {
        for (var i = 0; i < user.app_metadata.access.energyProducers.length; i++) {
            if (user.app_metadata.access.energyProducers[i].name == props.energyProducerName) {
                for (var j = 0; j < user.app_metadata.access.energyProducers[i].projects.length; j++) {
                    if (user.app_metadata.access.energyProducers[i].projects[j].name == props.projectName && user.app_metadata.access.energyProducers[i].projects[j].permission == PermissionsEnum.View) {
                        user.app_metadata.access.energyProducers[i].projects[j].permission = PermissionsEnum.Manage;
                    }
                }
            }
        }
        if (!props.users.loading && props.token) {
            props.submitProjectUser(props.energyProducerName,
                props.projectName, {
                    email: user.email,
                    app_metadata: user.app_metadata,
                    token: props.token
                });
        }
    }
    const makeEnergyProducerAdmin = (user: any) => {
        for (var i = 0; i < user.app_metadata.access.energyProducers.length; i++) {
            if (user.app_metadata.access.energyProducers[i].name == props.energyProducerName) {
                user.app_metadata.access.energyProducers[i].permission = PermissionsEnum.Manage;
            }
        }
        if (!props.users.loading && props.token) {
            props.submitEnergyProducerUser(props.energyProducerName, {
                email: user.email,
                app_metadata: user.app_metadata,
                token: props.token
            });
        }
    }
    const removeEnergyProducerAdmin = (user: any) => {
        for (var i = 0; i < user.app_metadata.access.energyProducers.length; i++) {
            if (user.app_metadata.access.energyProducers[i].name == props.energyProducerName) {
                user.app_metadata.access.energyProducers[i].permission = PermissionsEnum.View;
            }
        }
        if (!props.users.loading && props.token) {
            props.submitEnergyProducerUser(props.energyProducerName, {
                email: user.email,
                app_metadata: user.app_metadata,
                token: props.token
            });
        }
    }
    const removeProjectAdmin = (user: any) => {
        for (var i = 0; i < user.app_metadata.access.energyProducers.length; i++) {
            if (user.app_metadata.access.energyProducers[i].name == props.energyProducerName) {
                for (var j = 0; j < user.app_metadata.access.energyProducers[i].projects.length; j++) {
                    if (user.app_metadata.access.energyProducers[i].projects[j].name == props.projectName && user.app_metadata.access.energyProducers[i].projects[j].permission == PermissionsEnum.Manage) {
                        user.app_metadata.access.energyProducers[i].projects[j].permission = PermissionsEnum.View;
                    }
                }
            }
        }
        if (!props.users.loading && props.token) {
            props.submitProjectUser(props.energyProducerName,
                props.projectName, {
                    email: user.email,
                    app_metadata: user.app_metadata,
                    token: props.token
                });
        }
    }
    const elevateToEnergyProducer = (user: any) => {
        for (var i = 0; i < user.app_metadata.access.energyProducers.length; i++) {
            if (user.app_metadata.access.energyProducers[i].name == props.energyProducerName) {
                user.app_metadata.access.energyProducers[i].projects = [];
                user.app_metadata.access.energyProducers[i].permission = PermissionsEnum.View
            }
        }
        if (!props.users.loading && props.token) {
            props.submitEnergyProducerUser(props.energyProducerName, {
                email: user.email,
                app_metadata: user.app_metadata,
                token: props.token
            });
        }
    }
    //remove EnergyProducer permissions
    const demoteToProject = (user: any) => {
        for (var i = 0; i < user.app_metadata.access.energyProducers.length; i++) {
            if (user.app_metadata.access.energyProducers[i].name == props.energyProducerName) {
                user.app_metadata.access.energyProducers[i].projects = [{
                    name: props.projectName,
                    permission: PermissionsEnum.View
                }];
                user.app_metadata.access.energyProducers[i].permission = PermissionsEnum.None
            }
        }
        if (!props.users.loading && props.token) {
            props.submitEnergyProducerUser(props.energyProducerName, {
                email: user.email,
                app_metadata: user.app_metadata,
                token: props.token
            });
        }
    }
    const removeProjectAccess = (user: any) => {
        for (var i = 0; i < user.app_metadata.access.energyProducers.length; i++) {
            if (user.app_metadata.access.energyProducers[i].name == props.energyProducerName) {
                for (var j = 0; j < user.app_metadata.access.energyProducers[i].projects.length; j++) {
                    if (user.app_metadata.access.energyProducers[i].projects[j].name == props.projectName) {
                        user.app_metadata.access.energyProducers[i].projects.splice(j, 1)
                        j--;
                    }
                }
            }
        }

        if (!props.users.loading && props.token && !props.users.posting) {
            props.submitProjectUser(props.energyProducerName,
                props.completeProjectName, {
                    email: user.email,
                    app_metadata: user.app_metadata,
                    token: props.token
                });
        }
    }

    const removeEnergyProducerAccess = (user: any) => {
        var energyProducerIndex: number | undefined;
        for (var i = 0; i < user.app_metadata.access.energyProducers.length; i++) {
            if (user.app_metadata.access.energyProducers[i].name == props.energyProducerName) {
                user.app_metadata.access.energyProducers.splice(i, 1)
                i--;
            }
        }
        if (!props.users.loading && props.token) {
            props.submitEnergyProducerUser(
                props.energyProducerName,
                {
                    email: user.email,
                    app_metadata: user.app_metadata,
                    token: props.token
                });
        }
    }

    const resendEmailInvite = (email: string) => {
        axios
            .post(
                global.apiEndpoint + "users/resend-invite",
                {
                    energyProducer: props.energyProducerName,
                    project: props.projectName,
                    email: email,
                    frontendClientId: global.auth0ClientId
                },
                {headers: {Authorization: `Bearer ${props.token}`}}
            )
            .then(function (response: any) {
                response.data.status == NetworkRequestStatus.Successful
                    ? toast.success(intl.formatMessage({
                        id: "emailInviteSuccess.message",
                        defaultMessage: "An Email invite has been successfully sent to {email}!"
                    }, {email: email}), {
                        position: toast.POSITION.TOP_RIGHT
                    })
                    : toast.error(response.data.error, {
                        position: toast.POSITION.TOP_RIGHT
                    });
            })
            .catch(function (error: any) {
                if (error.response !== undefined) {
                    toast.error(error.response.message, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                }

            });
    };

    return (
        <>
            <>
                <UncontrolledTooltip placement="right" target="email-addresses">
                    <FormattedMessage id="multipleEmailAddresses.message"
                                      defaultMessage="Use a ',' to enter multiple Email Addresses"/>
                </UncontrolledTooltip>
                <Input
                    type="textarea"
                    disabled={props.users.loading === true}
                    className="input-dark"
                    value={emailAddress}
                    id="email-addresses"
                    onChange={(e: any) => setEmailAddress(`${e.target.value}`)}
                    onKeyUp={(event: any) => {
                        if (event.key === 'Enter' || event.keyCode == 13) {
                            addViewersToProject()
                        }
                    }}
                    placeholder={intl.formatMessage({
                        id: "enterEmailAddress.message",
                        defaultMessage: "Enter Email Addresses separated by a ','"
                    })}
                ></Input>
                <Col sm={12}>
                    <Button
                        color="warning"
                        className="float-right"
                        disabled={props.users.loading === true}
                        outline
                        onClick={addViewersToProject}
                        size="sm"
                    >
                        <FormattedMessage id="sendProjectInvites.label"
                                          defaultMessage="Send Project Invites"/>
                    </Button>
                </Col>
            </>
            <UserManagementTable users={props.users}
                                 energyProducerName={props.energyProducerName}
                                 projectName={props.projectName}
                                 serviceEndpoint={global.apiEndpoint}
                                 type={UserManagementType.EnergyProducerUser}
                                 token={props.token}
                                 manageUsers={energyProducerManageUsers}
                                 viewUsers={energyProducerViewUsers}
                                 resendEmailInvite={resendEmailInvite}
                                 removeEnergyProducerAdmin={removeEnergyProducerAdmin}
                                 removeEnergyProducerAccess={removeEnergyProducerAccess}
                                 demoteToProject={demoteToProject}
                                 makeEnergyProducerAdmin={makeEnergyProducerAdmin}
                                 setManageUsers={setEnergyProducerManageUsers}
                                 setViewUsers={setEnergyProducerViewUsers}/>

            <UserManagementTable users={props.users}
                                 energyProducerName={props.energyProducerName}
                                 projectName={props.projectName}
                                 serviceEndpoint={global.apiEndpoint}
                                 type={UserManagementType.ProjectUser}
                                 token={props.token}
                                 manageUsers={projectManageUsers}
                                 viewUsers={projectViewUsers}
                                 resendEmailInvite={resendEmailInvite}
                                 removeProjectAdmin={removeProjectAdmin}
                                 removeProjectAccess={removeProjectAccess}
                                 removeAccess={removeProjectAccess}
                                 makeProjectAdmin={makeProjectAdmin}
                                 elevateToEnergyProducer={elevateToEnergyProducer}
                                 setManageUsers={setProjectManageUsers}
                                 setViewUsers={setProjectViewUsers}/>
        </>
    )
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        getUsers: (project: IAddUserParams) => dispatch(getUsersActionCreator(project)),
        submitProjectUser: (energyProducer: string, project: string, user: SubmitUser) => dispatch(submitProjectUserActionCreator(energyProducer, project, user)),
        submitEnergyProducerUser: (energyProducer: string, user: SubmitUser) => dispatch(submitEnergyProducerUserActionCreator(energyProducer, user)),
    };
};

export default connect(
    null,
    mapDispatchToProps
)(UserManagement);