import React, {useEffect, useState, Fragment, useContext} from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {actionCreators as actionCreatorsProjects} from "../store/Projects";
import {actionCreators as actionCreatorsUserCount} from "../store/UserCount";
import {actionCreators as actionCreatorsProducers} from '../store/Producers';
import {actionCreators as actionCreatorsHeader} from '../store/Header';
import {actionCreators as actionCreatorsUserMetadata} from '../store/UserMetadata';
import {Row, Container, Col} from "reactstrap";
import ProjectMenu from "./ProjectMenu";
import _ from 'lodash';
import fetchWithTimeout from "../helpers/fetchWithTimeout";
import {useAuth0} from "../auth/auth0-wrapper";
import Loader from "./common/Loader/Loader";
import Unauthorized from './Unauthorized';
import ProjectInformation from './ProjectInformation.js';
import Error from './common/Error/index';
import CookieUrl from './CookieUrl.js';
import NotFound from "./common/NotFound";
import ManageUsers from "../components/Layouts/ManageUsers/index.tsx";
import {getMetadataValues, getUserLanguagePreference, newMetadataValues} from '../helpers/metadataHelper'
import Dashboard from '../components/Dashboard.js';
import {LocalizationContext} from "./common/Localization/LanguageWrapper";
import {setState} from "litsy";
import ProjectNoData from "./ProjectNoData";
import {ProjectMenuProvider} from "./common/Providers/ProjectMenuProvider";
import {ProjectContext} from "./common/Contexts/ProjectContext";
import DashboardPage from "./common/Project/DashboardPage";
import DailyTotalsPage from "./common/Project/DailyTotalsPage";
import PumpersPage from "./common/Project/PumpersPage";
import WellsPage from "./common/Project/WellsPage";
import GreenhouseGasPage from "./common/Project/GreenhouseGasPage";
import FuelTicketPage from "./common/Project/FuelTicketPage";
import ReportingPage from "./common/Project/ReportingPage";
import OperatingUnitPage from "./common/Project/OperatingUnitPage";
import NgSideKickPage from "./common/Project/NgSideKickPage";
import ProjectInfoPage from "./common/Project/ProjectInfoPage";
import FuelLevelPage from "./common/Project/FuelLevelPage";
import SiteDocumentsPage from "./common/Project/SiteDocumentsPage";
import FeaturesPage from "./common/Project/FeaturesPage";
import {NaturalGasUnit} from "../enums";

function Project(props) {
    //const [recordArgs, setRecordArgs] = useState(null);
    const localizationContext = useContext(LocalizationContext);
    const {project, setProject, setToken} = useContext(ProjectContext);
    const {getTokenSilently, loading, user,} = useAuth0();

    const [isReady, setIsReady] = useState(false);
    const [projectLoaded, setProjectLoaded] = useState();
    const [showProjectStats, setShowProjectStats] = useState(false);
    const [showPumperStats, setShowPumperStats] = useState(false);
    const [showSubstitutionRate, setShowSubstitutionRate] = useState(false);
    const [showUnitStats, setShowUnitStats] = useState(false);
    const [showUserManagement, setShowUserManagement] = useState(false);
    const [appSettingsComplete, setAppSettingsComplete] = useState(false)
    const [showExportData, setShowExportData] = useState(false);
    const [showUnitOfMeasureToggle, setShowUnitOfMeasureToggle] = useState(false);
    const [showSiteDocuments, setShowSiteDocuments] = useState(false);

    const [statsBackgroundClass, setStatsBackgroundClass] = useState(
        "statsBackground"
    );

    const requestProject = (
        projectName,
        producerName,
        startTime,
        token,
        uom,
        naturalGasUnit,
        dieselBTUValue,
        ngBTUValue,
        dieselCostPerGallon,
        ngCostPerMCF,
        bifuelDailySetupCost,
        substitutionRate,
        dailyTotalCutoff,
        dailyCutoffHour,
    ) => {
        props.requestProject(
            projectName,
            producerName,
            startTime,
            token,
            uom,
            naturalGasUnit,
            dieselBTUValue,
            ngBTUValue,
            dieselCostPerGallon,
            ngCostPerMCF,
            bifuelDailySetupCost,
            substitutionRate,
            dailyTotalCutoff,
            dailyCutoffHour,
        );
        setProjectLoaded(true);
        setStatsBackgroundClass("statsBackground");
    };

    //Initial mount
    useEffect(() => {

        appSettings();
        const fetchToken = async () => {
            const auth0Token = await getTokenSilently();
            setState("authToken", auth0Token, "session");
            setState("userId", user["sub"], "session");
            setToken(auth0Token);
        };
        fetchToken();

        let metaDataValue = getUserLanguagePreference(user);
        let languagePreference = "en-US";

        if (metaDataValue !== null && metaDataValue !== undefined) {
            if (metaDataValue.languagePreference !== null) {
                languagePreference = metaDataValue.languagePreference;
            }
        } else {
            languagePreference = "en-US";
        }
        localizationContext.selectLang(languagePreference);
    }, []);

    useEffect(() => {
        if (!loading && project.token && appSettingsComplete === true) {
            props.requestProducers(project.token);
        }
    }, [project.token, appSettingsComplete]);

    useEffect(() => {
        if (isReady && !loading && project.token && appSettingsComplete === true) {
            var pathVars = props.location.pathname.split("/");
            var metadataValues = getMetadataValues(user, pathVars[1], pathVars[2]);
            if (pathVars.length === 4) {
                if (metadataValues !== null && metadataValues !== undefined && metadataValues.uom !== null) {
                    let dieselBTUValue = metadataValues.btuHeatingValue;
                    let ngBTUValue = metadataValues.ngHeatingValue;
                    let dailyTotalCutoff = metadataValues.dailyCutoffTime;
                    let timezone = metadataValues.timezone;
                    let dailyCutoffHour = metadataValues.dailyCutoffHour;
                    let brakeHorsePower = metadataValues.brakeHorsePower;
                    let loadFactorPumping = metadataValues.loadFactorPumping;
                    let loadFactorIdle = metadataValues.loadFactorIdle;
                    let hoursPerDayIdle = metadataValues.hoursPerDayIdle;
                    let hoursPerDayWorking = metadataValues.hoursPerDayWorking;
                    let dieselCostPerGallon = metadataValues.dieselCostPerGallon;
                    let ngCostPerMCF = metadataValues.ngCostPerMCF;
                    let bifuelDailySetupCost = metadataValues.bifuelDailySetupCost;
                    let naturalGasUnit = metadataValues.naturalGasUnit;
                    let substitutionRate = metadataValues.substitutionRate;

                    global.dieselBTUValue = dieselBTUValue;
                    global.ngBTUValue = ngBTUValue;
                    global.dailyTotalCutoff = dailyTotalCutoff;
                    global.timezone = timezone;
                    global.dailyCutoffHour = dailyCutoffHour;

                    if (naturalGasUnit === undefined || naturalGasUnit === null) {
                        naturalGasUnit = NaturalGasUnit.SCF;
                    }
                    if (dieselBTUValue === undefined || dieselBTUValue === null) {
                        dieselBTUValue = global.dieselBTUValueDefault;
                    }
                    if (ngBTUValue === undefined || ngBTUValue === null) {
                        ngBTUValue = global.ngBTUValueDefault;
                    }
                    if (dailyTotalCutoff === undefined || dailyTotalCutoff === null) {
                        dailyTotalCutoff = '17:00';
                    }
                    if (global.timezone === undefined || global.timezone === null) {
                        global.timezone = 'Canada/Mountain';
                    }
                    if (dailyCutoffHour === undefined || dailyCutoffHour === null) {
                        dailyCutoffHour = '0';
                    }
                    if (brakeHorsePower === undefined || brakeHorsePower === null) {
                        brakeHorsePower = global.brakeHorsePowerDefault;
                    }
                    if (hoursPerDayWorking === undefined || hoursPerDayWorking === null) {
                        hoursPerDayWorking = global.hoursPerDayWorkingDefault;
                    }
                    if (hoursPerDayIdle === undefined || hoursPerDayIdle === null) {
                        hoursPerDayIdle = global.hoursPerDayIdleDefault;
                    }
                    if (loadFactorIdle === undefined || loadFactorIdle === null) {
                        loadFactorIdle = global.loadFactorIdleDefault;
                    }
                    if (loadFactorPumping === undefined || loadFactorPumping === null) {
                        loadFactorPumping = global.loadFactorPumpingDefault;
                    }
                    if (dieselCostPerGallon === undefined || dieselCostPerGallon === null) {
                        dieselCostPerGallon = global.dieselCostPerGallonDefault;
                    }
                    if (ngCostPerMCF === undefined || ngCostPerMCF === null) {
                        ngCostPerMCF = global.ngCostPerMCFDefault;
                    }
                    if (bifuelDailySetupCost === undefined || bifuelDailySetupCost === null) {
                        bifuelDailySetupCost = global.bifuelDailySetupCostDefault;
                    }
                    if (substitutionRate === undefined || substitutionRate === null) {
                        substitutionRate = global.substitutionRateDefault;
                    }
                    global.brakeHorsePower = brakeHorsePower;
                    global.loadFactorIdle = loadFactorIdle;
                    global.loadFactorPumping = loadFactorPumping;
                    global.hoursPerDayIdle = hoursPerDayIdle;
                    global.hoursPerDayWorking = hoursPerDayWorking;
                    global.dieselCostPerGallon = dieselCostPerGallon;
                    global.ngCostPerMCF = ngCostPerMCF;
                    global.bifuelDailySetupCost = bifuelDailySetupCost;
                    global.substitutionRateValue = substitutionRate;

                    requestProject(pathVars[2], pathVars[1], pathVars[3], project.token, metadataValues.uom, naturalGasUnit, dieselBTUValue, ngBTUValue, dieselCostPerGallon, ngCostPerMCF, bifuelDailySetupCost, substitutionRate, dailyTotalCutoff, dailyCutoffHour);
                    //setRecordArgs([pathVars[2], pathVars[1], pathVars[3], token, metadataValues.uom]);
                } else {
                    // Save default metadata values to Auth0
                    let newMeta = newMetadataValues(user, pathVars[1], pathVars[2], 2, NaturalGasUnit.SCF,
                        global.dieselBTUValueDefault, global.ngBTUValueDefault, "17:00", 'Canada/Mountain', 0,
                        global.brakeHorsePowerDefault, global.loadFactorPumpingDefault, global.loadFactorIdleDefault, global.hoursPerDayIdleDefault,
                        global.hoursPerDayWorkingDefault, global.dieselCostPerGallonDefault, global.ngCostPerMCFDefault, global.bifuelDailySetupCostDefault, global.substitutionRateDefault);

                    global.dailyTotalCutoff = '17:00';
                    global.dailyCutOffHour = 0;

                    requestProject(pathVars[2], pathVars[1], pathVars[3], project.token, 2, NaturalGasUnit.SCF, global.dieselBTUValueDefault, global.ngBTUValueDefault, global.dieselCostPerGallonDefault, global.ngCostPerMCFDefault, global.bifuelDailySetupCostDefault, global.substitutionRateDefault, global.dailyTotalCutoff, global.dailyCutOffHour);
                    props.saveUserMetadata(newMeta, project.token, false);
                    //setRecordArgs([pathVars[2], pathVars[1], pathVars[3], token]);
                }
            }
            setIsReady(false);
        }
    });

    useEffect(() => {
        if (props.project) {
            //put project into header store and show project nav menu button
            props.setProject(props.project)
            if (document.title.indexOf(props.project.name) < 1) {
                document.title = props.project.name + ' - ' + document.title;
            }
            props.setShowNavMenuButton(true);
            setProjectLoaded(true);
            //clearInterval(global.recordInterval);
            //global.recordInterval = setInterval(checkRecordCount, 60000);
            setProject(props.project);

            setState("fracShack_currentProject", props.project, "volatile");

            if (!_.isEmpty(props.project['hseSiteVisitPDFs']))
                setShowSiteDocuments(true)

        } else {
            setProjectLoaded(false)
        }
    }, [props.project])

    const appSettings = () => {
        if (process.env.REACT_APP_LOCAL_CACHE_TIME !== undefined) {
            global.apiEndpoint = process.env.REACT_APP_API_URL;
            setState("fracShack_apiEndpoint", process.env.REACT_APP_API_URL, "session");
            global.projectStats = process.env.REACT_APP_PROJECT_STATS;
            global.substitutionRate = process.env.REACT_APP_SUBSTITUTION_RATE;
            global.userManagement = process.env.REACT_APP_USER_MANAGEMENT;
            global.exportData = process.env.REACT_APP_EXPORT_DATA;
            global.localCacheTime = process.env.REACT_APP_LOCAL_CACHE_TIME;
            global.pumperStats = process.env.REACT_APP_PUMPER_STATS;
            global.unitStats = process.env.REACT_APP_UNIT_STATS;
            global.unitOfMeasureToggle = process.env.REACT_APP_UNIT_OF_MEASURE_TOGGLE;
            global.naturalGasUnitDefault = process.env.REACT_APP_NATURAL_GAS_UNIT_DEFAULT;
            global.dieselBTUValueDefault = process.env.REACT_APP_DIESEL_BTU_HEATING_VALUE_DEFAULT;
            global.ngBTUValueDefault = process.env.REACT_APP_NG_BTU_HEATING_VALUE_DEFAULT;
            global.brakeHorsePowerDefault = process.env.REACT_APP_BRAKE_HORSE_POWER_DEFAULT;
            global.loadFactorPumpingDefault = process.env.REACT_APP_LOAD_FACTOR_PUMPING_DEFAULT;
            global.loadFactorIdleDefault = process.env.REACT_APP_LOAD_FACTOR_IDLE_DEFAULT;
            global.hoursPerDayIdleDefault = process.env.REACT_APP_HOURS_PER_DAY_IDLE_DEFAULT;
            global.hoursPerDayWorkingDefault = process.env.REACT_APP_HOURS_PER_DAY_WORKING_DEFAULT;
            global.dieselCostPerGallonDefault = process.env.REACT_APP_DIESEL_COST_PER_GALLON_DEFAULT;
            global.ngCostPerMCFDefault = process.env.REACT_APP_NG_COST_PER_MCF_DEFAULT;
            global.bifuelDailySetupCostDefault = process.env.REACT_APP_BIFUEL_DAILY_SETUP_COST_DEFAULT;
            global.substitutionRateDefault = process.env.REACT_APP_SUBSTITUTION_RATE_DEFAULT;
            setShowProjectStats(global.projectStats);
            setShowPumperStats(global.pumperStats);
            setShowUnitStats(global.unitStats);
            setShowSubstitutionRate(global.substitutionRate);
            setShowUserManagement(global.userManagement);
            setIsReady(true);
            setAppSettingsComplete(true);
            setShowExportData(global.exportData);
            setShowUnitOfMeasureToggle(global.unitOfMeasureToggle);
        } else if (global.auth0Domain === null || global.auth0Domain === undefined || global.auth0Domain === '') {

            global.apiEndpoint = process.env.REACT_APP_API_URL;
            setState("fracShack_apiEndpoint", process.env.REACT_APP_API_URL, "session");

            var myRequest = new Request(global.apiEndpoint + "settings/keys");

            fetchWithTimeout(myRequest)
                .then(function (response) {
                    if (response.status === 200) {
                        return response.json();
                    }
                })
                .then(function (jsonResponse) {
                    global.projectStats = jsonResponse.showProjectStats;
                    global.pumperStats = jsonResponse.showPumperStats;
                    global.unitStats = jsonResponse.showUnitStats;
                    global.substitutionRate = jsonResponse.showSubRate;
                    global.userManagement = jsonResponse.showUserManagement;
                    global.exportData = jsonResponse.exportData;
                    global.unitOfMeasureToggle = jsonResponse.showUOMToggle;
                    global.localCacheTime = jsonResponse.localCacheTime;
                    global.naturalGasUnitDefault = jsonResponse.naturalGasUnitDefault;
                    global.dieselBTUValueDefault = jsonResponse.dieselBtuHeatingValue;
                    global.ngBTUValueDefault = jsonResponse.ngBtuHeatingValue;
                    global.brakeHorsePowerDefault = jsonResponse.brakeHorsePowerValue;
                    global.loadFactorPumpingDefault = jsonResponse.loadFactorPumpingValue;
                    global.loadFactorIdleDefault = jsonResponse.loadFactorIdleValue;
                    global.hoursPerDayWorkingDefault = jsonResponse.hoursPerDayWorkingValue;
                    global.hoursPerDayIdleDefault = jsonResponse.hoursPerDayIdleValue;
                    global.dieselCostPerGallonDefault = jsonResponse.dieselCostPerGallonValue;
                    global.ngCostPerMCFDefault = jsonResponse.ngCostPerMcfValue;
                    global.bifuelDailySetupCostDefault = jsonResponse.bifuelDailySetupCostValue;
                    global.substitutionRateDefault = jsonResponse.substitutionRateValue;

                    setShowProjectStats(global.projectStats);
                    setShowPumperStats(global.pumperStats);
                    setShowUnitStats(global.unitStats);
                    setShowSubstitutionRate(global.substitutionRate);
                    setShowUserManagement(global.userManagement);
                    setShowExportData(global.exportData);
                    setShowUnitOfMeasureToggle(global.unitOfMeasureToggle);

                    setIsReady(true);
                    setAppSettingsComplete(true);
                });
        } else {
            setShowProjectStats(global.projectStats);
            setShowPumperStats(global.pumperStats);
            setShowUnitStats(global.unitStats);
            setShowSubstitutionRate(global.substitutionRate);
            setShowUserManagement(global.userManagement);
            setShowExportData(global.exportData);
            setShowUnitOfMeasureToggle(global.unitOfMeasureToggle);

            setIsReady(true);
            setAppSettingsComplete(true);
        }
    };

    if (props.isUnauthorized && props.isUnauthorized === true) {
        return (<Unauthorized/>)
    } else if (props.isLoading === false && props.isError === false && projectLoaded === false && project.token) {
        return (<NotFound/>)
    } else if (Date.parse(props.project.startTime) > Date.now() || props.project.resultCount === 0) {
        return (
            <Container fluid={true}>
                {props.isLoading === false && props.isError === false && projectLoaded && project.token && (
                    <Fragment>
                        <CookieUrl mode="set" project={props.project} token={project.token}/>
                        <ProjectMenuProvider>
                            <Row className="mainBackground">
                                <Container fluid={true}>
                                    <Row>
                                        <Col xs="2" className="noRightPadding noLeftPadding projectMenu">
                                            <ProjectMenu
                                                mobile={false}
                                                projectStats={showProjectStats}
                                                pumperStats={showPumperStats}
                                                units={props.project.operatingUnits}
                                                serviceEndpoint={global.apiEndpoint}
                                                showUserManagement={showUserManagement}
                                                showConfiguration={showUnitOfMeasureToggle}
                                                showSiteDocuments={showSiteDocuments}
                                            />
                                        </Col>
                                        <Col xs="12" lg="9" className={statsBackgroundClass}>
                                            <ProjectMenu
                                                mobile={true}
                                                projectStats={showProjectStats}
                                                pumperStats={showPumperStats}
                                                units={props.project.operatingUnits}
                                                serviceEndpoint={global.apiEndpoint}
                                                showUserManagement={showUserManagement}
                                                showConfiguration={showUnitOfMeasureToggle}
                                                showSiteDocuments={showSiteDocuments}
                                            />
                                            <DashboardPage/>
                                            <ProjectInfoPage showUnitOfMeasureToggle={showUnitOfMeasureToggle}
                                                             user={user}/>
                                        </Col>
                                    </Row>
                                </Container>
                            </Row>
                        </ProjectMenuProvider>
                    </Fragment>
                )}
                {props.isLoading === true && props.isError === false && (
                    <div className="center">
                        <Loader/>
                    </div>
                )}
                {props.isError && !props.isLoading && <Error/>}
            </Container>
        )
    } else {
        return (
            <Container fluid={true}>
                {props.isLoading === false && props.isError === false && projectLoaded && project.token && (
                    <Fragment>
                        <CookieUrl mode="set" project={props.project} token={project.token}/>
                        <ProjectMenuProvider>
                            <Row className="mainBackground">
                                <Container fluid={true}>
                                    <Row>
                                        <Col xs="2" className="noRightPadding noLeftPadding projectMenu">
                                            <ProjectMenu
                                                mobile={false}
                                                projectStats={showProjectStats}
                                                pumperStats={showPumperStats}
                                                units={props.project.operatingUnits}
                                                serviceEndpoint={global.apiEndpoint}
                                                showUserManagement={showUserManagement}
                                                showConfiguration={showUnitOfMeasureToggle}
                                                showSiteDocuments={showSiteDocuments}
                                            />
                                        </Col>
                                        <Col xs="12" lg="9" className={statsBackgroundClass}>
                                            <ProjectMenu
                                                mobile={true}
                                                projectStats={showProjectStats}
                                                pumperStats={showPumperStats}
                                                units={props.project.operatingUnits}
                                                serviceEndpoint={global.apiEndpoint}
                                                showUserManagement={showUserManagement}
                                                showConfiguration={showUnitOfMeasureToggle}
                                                showSiteDocuments={showSiteDocuments}
                                            />

                                            <WellsPage showSubstitutionRate={showSubstitutionRate}
                                                       showProjectStats={showProjectStats}/>

                                            <NgSideKickPage showSubstitutionRate={showSubstitutionRate}
                                                            showProjectStats={showProjectStats}/>

                                            <FuelLevelPage showProjectStats={showProjectStats}/>

                                            <OperatingUnitPage showUnitStats={showUnitStats}
                                                               showSubstitutionRate={showSubstitutionRate}
                                                               showExportData={showExportData}/>

                                            <PumpersPage showExportData={showExportData}
                                                         showSubstitutionRate={showSubstitutionRate}
                                                         showPumperStats={showPumperStats}/>

                                            <DashboardPage/>

                                            <DailyTotalsPage/>

                                            <FuelTicketPage/>

                                            {/*{showProjectInfo && currentProject !== null &&*/}
                                            {/*<ProjectInformation project={currentProject}/>*/}
                                            {/*}*/}

                                            <GreenhouseGasPage/>

                                            {showSiteDocuments &&
                                                (<SiteDocumentsPage/>)
                                            }

                                            <FeaturesPage showUnitOfMeasureToggle={showUnitOfMeasureToggle}
                                                          user={user}/>

                                            <ProjectInfoPage showUnitOfMeasureToggle={showUnitOfMeasureToggle}
                                                             user={user}/>

                                            <ReportingPage user={user}/>

                                            {/*{showManageUsers &&*/}
                                            {/*<ManageUsers/>*/}
                                            {/*}*/}
                                        </Col>
                                    </Row>
                                </Container>
                            </Row>
                        </ProjectMenuProvider>
                    </Fragment>
                )}
                {props.isLoading === true && props.isError === false && (
                    <div className="center">
                        <Loader/>
                    </div>
                )}
                {props.isError && !props.isLoading && <Error/>}
            </Container>
        )
    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({...actionCreatorsProjects, ...actionCreatorsUserCount, ...actionCreatorsProducers, ...actionCreatorsHeader, ...actionCreatorsUserMetadata}, dispatch)

export default connect(
    state => state.project,
    mapDispatchToProps
)(Project);