import React, { Fragment, useState, useEffect } from 'react';
import { useAuth0 } from '../auth/auth0-wrapper';
import { actionCreators as actionCreatorsHeader } from './../store/Header'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Error from './common/Error';
import {FormattedMessage, useIntl} from 'react-intl';

function Unauthorized(props) {
	//const { user, logout } = useAuth0();
	//const toggleSettings = () => {
		//setSettingsDropdownToggle(!settingsDropdownToggle);
	//}
	//const [settingsDropdownToggle, setSettingsDropdownToggle] = useState(false);

	// useEffect(() => {
	// 	props.setShowNavMenuButton(false)
	// }, [])
	const intl = useIntl();
	
	return (
		<Fragment>
			{/* <div className="breadcrumb-wrapper display-flex reverse">
				<div className="profileContainer">
					<ProfileDropdown
						logout={logout}
						username={user.email}
						isOpen={settingsDropdownToggle}
						toggle={toggleSettings}
						tag={NavLink}
						to={""}
					></ProfileDropdown>
				</div>
			</div> */}
			<Error title={intl.formatMessage({ id: "noGo.label", defaultMessage: "No Go"}) } pathText="" path="/requestAccess">
				<FormattedMessage id="permissions.message" defaultMessage="You do not have permissions to this project" />
			</Error>
		</Fragment>

	)
}

const mapStateToProps = () => { }
const mapDispatchToProps = (dispatch) => bindActionCreators(
	{ ...actionCreatorsHeader },
	dispatch
)
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Unauthorized)