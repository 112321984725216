import fetchWithTimeout from '../helpers/fetchWithTimeout'
import axios from 'axios';
const GetUserCountTypes = {
    Uninitialized : "UNINIT_GET_USER_COUNT",
    Getting : "GETTING_USER_COUNT",
    Got : "GOT_USER_COUNT",
    Error : "GET_USER_COUNT_ERROR",
    UnauthorizedError:"GET_USER_COUNT_UNAUTHORIZED"
  }

  const initialState = {isLoading: false, userCount:0, isError:false }; 
export const actionCreators = {
    getUserCount: (projectName, producerName, startTime, token) => {
        return async dispatch => {
            const gettingUserCountAction = { type: GetUserCountTypes.Getting }
            dispatch(gettingUserCountAction)
            await axios
                .get(global.apiEndpoint + 'users/count', {
                    params: {
                        project: startTime.length > 0 ? projectName + '/' + startTime : projectName,
                        energyProducer: producerName
                    },
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
                .then(function(response) {
                    if (response !== undefined && (response.status == 401 || response.status == 403)) {
                        dispatch({ type: GetUserCountTypes.UnauthorizedError })
                    }
                    if (response.status !== 200) {
                        const gettingUserCountErrorAction = {
                            type: GetUserCountTypes.Error,
                            error: 'A error occured while trying to get user count'
                        }
                        return dispatch(gettingUserCountErrorAction)
                    }
                    try {
                        const gotUserCount = { type: GetUserCountTypes.Got, userCount: response.data ? response.data : 0 }
                        return dispatch(gotUserCount)
                    } catch (err) {
                        if (err.response !== undefined && (err.response.status == 401 || err.response.status == 403)) {
                            dispatch({ type: GetUserCountTypes.UnauthorizedError })
                        } else {
                            const gettingUserCountErrorAction = { type: GetUserCountTypes.Error, error: err }
                            return dispatch(gettingUserCountErrorAction)
                        }
                    }
                })
                .catch(function(error) {
                    if (error.response !== undefined && (error.response.status == 401 || error.response.status == 403)) {
                        dispatch({ type: GetUserCountTypes.UnauthorizedError })
                    } else {
                        const gettingUserCountErrorAction = { type: GetUserCountTypes.Error, error: error.message }
                        return dispatch(gettingUserCountErrorAction)
                    }
                })
        }
    }
}
export const reducer = (state, action) => {
    state = state || initialState;
    if (action.type === GetUserCountTypes.UnauthorizedError)
    {
      return {
        ...state,
        isError: true,
        isLoading: false,
        isUnauthorized:true
      }
    }
    if(action.type == GetUserCountTypes.Getting) {
      return {
        ...state,
        isLoading: true,
      };
    }
    if(action.type == GetUserCountTypes.Got) {
      return {
        ...state,
        userCount:action.userCount,
        isLoading: false,
      };
    }
    if(action.type == GetUserCountTypes.Error) {
      return {
        ...state,
        isLoading: false,
        isError:true,
        error:action.error
        
      };
    }
  
    return state;
  };
  