import axios from 'axios';
import { NetworkRequestStatus } from '../enums';

export interface UserPermissionResponse{
    hasAccess: boolean,
    status:NetworkRequestStatus,
    error:string
}
export enum AccessType{
    IsITSiteAdmin,
    IsSiteAdmin,
    IsEnergyProducerAdmin,
    IsProjectAdmin,
    IsEnergyProducerViewer,
    IsProjectViewer,
    IsCustomerApiAdmin
}
 const  isProjectViewer = async (endpoint:string, token:string, energyProducerName:string, completeProjectName:string) =>{
   const permitted = await axios.get<UserPermissionResponse>(endpoint + 'auth/is-project-viewer', {
        params: {
          project: completeProjectName,
          energyProducer: energyProducerName
        },
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then(function (response) {
        if(response.status === 401 || response.status === 403){
          return false;
        }
        if (response.status !== 200)
        {
          return false;
        }
        else if(response.data && response.data.status !== NetworkRequestStatus.Successful){
          return false;
        }
        else if(response.status === 200 && response.data && response.data.status === NetworkRequestStatus.Successful){
          return response.data.hasAccess;
        }
        else{
          return false;
        }
        })
        .catch(function(e){
          return false;
        })
        return permitted;
    }
 const isEnergyProducerViewer = async (endpoint:string,token:string, energyProducerName:string) => {
  const permitted = await axios.get<UserPermissionResponse>(endpoint + 'auth/is-energy-producer-viewer', {
        params: {
          energyProducer: energyProducerName
        },
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then(function (response) {
        if(response.status === 401 || response.status === 403){
         return false;
        }
        if (response.status !== 200)
        {
          return false;
        }
        else if(response.data && response.data.status !== NetworkRequestStatus.Successful){
            return false;
        }
        else if(response.status === 200 && response.data && response.data.status === NetworkRequestStatus.Successful){
            return response.data.hasAccess;
        }
        else{
            return false;
        }
        })
        .catch(function(e){
            return false;
        })
        return permitted;
}
 const isProjectAdmin = async (endpoint:string,token:string, energyProducerName:string, completeProjectName:string) =>{
 const permitted = await axios.get<UserPermissionResponse>(endpoint + 'auth/is-project-admin', {
    params: {
      project: completeProjectName,
      energyProducer: energyProducerName
    },
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
  .then(function (response:any) {
    if(response.status === 401 || response.status === 403){
     return false;
    }
    if (response.status !== 200)
    {
      return false;
    }
    else if(response.data && response.data.status !== NetworkRequestStatus.Successful){
        return false;
    }
    else if(response.status === 200 && response.data && response.data.status === NetworkRequestStatus.Successful){
        return response.data.hasAccess;
    }
        return false;
    })
    .catch(function(e){
        return false;
    })
    return permitted;
};
 const isEnergyProducerAdmin = async(endpoint:string,token:string, energyProducerName:string)=>{
    const permitted = await axios.get<UserPermissionResponse>(endpoint + 'auth/is-energy-producer-admin', {
        params: {
          energyProducer: energyProducerName
        },
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then(function (response) {
        if(response.status === 401 || response.status === 403){
         return false;
        }
        if (response.status !== 200)
        {
          return false;
        }
        else if(response.data && response.data.status !== NetworkRequestStatus.Successful){
            return false;
        }
        else if(response.status === 200 && response.data && response.data.status === NetworkRequestStatus.Successful){
            return response.data.hasAccess;
        }
        else{
            return false;
        }
        })
        .catch(function(e){
            return false;
        })
        return permitted;
}
 const isSiteAdmin = async (endpoint:string,token:string) =>{
     const permitted = await axios.get<UserPermissionResponse>(endpoint + 'auth/is-site-admin', {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then(function (response) {
        if(response.status === 401 || response.status === 403){
         return false;
        }
        if (response.status !== 200)
        {
          return false;
        }
        else if(response.data && response.data.status != NetworkRequestStatus.Successful){
            return false;
        }
        else if(response.status === 200 && response.data && response.data.status === NetworkRequestStatus.Successful){
            return response.data.hasAccess;
        }
        else{
            return false;
        }
        })
        .catch(function(e){
            return false;
        })
        return permitted;
}

const isITSiteAdmin = async (endpoint:string,token:string) =>{
    const permitted = await axios.get<UserPermissionResponse>(endpoint + 'auth/is-it-site-admin', {
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
        .then(function (response) {
            if(response.status === 401 || response.status === 403){
                return false;
            }
            if (response.status !== 200)
            {
                return false;
            }
            else if(response.data && response.data.status != NetworkRequestStatus.Successful){
                return false;
            }
            else if(response.status === 200 && response.data && response.data.status === NetworkRequestStatus.Successful){
                return response.data.hasAccess;
            }
            else{
                return false;
            }
        })
        .catch(function(e){
            return false;
        })
    return permitted;
}

const isCustomerApiAdmin = async (endpoint: string, token: string, energyProducerName: string) => {
    const permitted = await axios.get<UserPermissionResponse>(endpoint + `auth/is-api-admin?energyProducer=${energyProducerName}`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
        .then(function (response) {
            if(response.status === 401 || response.status === 403){
                return false;
            }
            if (response.status !== 200)
            {
                return false;
            }
            else if(response.data && response.data.status != NetworkRequestStatus.Successful){
                return false;
            }
            else if(response.status === 200 && response.data && response.data.status === NetworkRequestStatus.Successful){
                return response.data.hasAccess;
            }
            else{
                return false;
            }
        })
        .catch(function(e){
            return false;
        })
    return permitted;
}

export {isProjectAdmin, isEnergyProducerAdmin, isSiteAdmin, isProjectViewer, isEnergyProducerViewer, isITSiteAdmin, isCustomerApiAdmin}