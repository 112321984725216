////Load Polyfills////
import includes from "core-js/features/string/virtual/includes";
import repeat from "core-js/features/string/virtual/repeat";
import assign from "core-js/features/object/assign";
import values from "core-js/features/object/values"
import "core-js/es/string";
import "core-js/es/array";
import "core-js/es/array";
import 'react-app-polyfill/ie11';
import { polyfill } from "es6-promise";
import "whatwg-fetch";
import "abortcontroller-polyfill/dist/polyfill-patch-fetch";
////Loaded Polyfills////
import React, {Fragment, useEffect} from "react";
import { Route } from "react-router";
import Layout from "./components/Layout";
import Home from "./components/Home";
import Project from "./components/Project";
import PrivateRoute from "./components/PrivateRoute";
import CacheBuster from "./components/CacheBuster";
import ErrorBoundary from "./components/ErrorBoundary";
import { BrowserRouter, Switch } from "react-router-dom";
import NotFound from "./components/common/NotFound/index.tsx";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./helpers/fontAwesomeIcons";
import "./Site.css";
import ManageUsers from "./components/Layouts/ManageUsers";
import { useAuth0 } from "./auth/auth0-wrapper";
import Loader from "./components/common/Loader/Loader";
import Unauthorized from './components/Unauthorized';
import Header from "./components/common/Header/Header";
import AccountSettings from "./components/common/AccountSettings/AccountSettings";

import ReactGA from 'react-ga';
import TagManager from 'react-gtm-module';
import AdminSettings from "./components/common/AdminSettings/AdminSettings";
import {ProjectProvider} from "./components/common/Providers/ProjectProvider";
import Api from "./components/docs/api/Api";

String.prototype.includes = includes;
String.prototype.repeat = repeat;
Object.assign = assign;
Object.values = values;
polyfill();

const App = props => {
	const { loading, user } = useAuth0();
	toast.configure();

	// Initialize Google Analytics and Google Tag Manager
	useEffect(() => {
		if (user !== undefined) {
			ReactGA.initialize('UA-179509712-1', {
				gaOptions: {
					userId: user.sub.split('|')[1]
				}});
			TagManager.initialize({
				gtmId: 'GTM-KS8329J'
			});
			
			// Google Tag Manager
			window.dataLayer.push({
				'userID': `${user.sub.split('|')[1]}`,
				'event': "Pageview",
				'pagePath': `${window.location.pathname}`,
				'pageTitle': "home"
			});

		}
	}, [user])
	
	if (loading) {
		return (
			<div className="center">
				<Loader/>
			</div>
		);
	}
	return (
		<CacheBuster>
			{({ loading, isLatestVersion, refreshCacheAndReload }) => {
				if (loading) return null;
				if (!loading && !isLatestVersion) {
					refreshCacheAndReload();
				}

				return (
					<Layout>
						<BrowserRouter>
							<ProjectProvider>
							<ErrorBoundary>
								<Header />
								<div className="body-wrapper">
								<Switch>
									<PrivateRoute exact path="/" component={Home} />
									<PrivateRoute exact path='/unauthorized' component={Unauthorized} />
									<PrivateRoute
										path="/:producer/:project/:startdate/admin"
										component={ManageUsers}
									/>
									<PrivateRoute
										path="/:producer/:project/:startdate"
										component={Project}
									/>
									<PrivateRoute
										path="/:producer/:project/:startdate/:unit"
										component={Project}
									/>
									<PrivateRoute
										path="/user/accountsettings"
										component={AccountSettings}
									/>
									<PrivateRoute
										path="/user/admin"
										component={AdminSettings}
									/>
									<PrivateRoute
										exact path="/docs/api"
										component={Api}
									/>
									<Route path="*" exact component={NotFound} status={404} />
								</Switch>
								</div>
							</ErrorBoundary>
							</ProjectProvider>
						</BrowserRouter>
					</Layout>
				);
			}}
		</CacheBuster>
	);
};

export default App;
