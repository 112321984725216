import fetchWithTimeout from '../helpers/fetchWithTimeout'
import {NaturalGasUnit, UnitOfMeasurement} from '../enums';
import Dexie from 'dexie';

const requestProjectType = 'REQUEST_PROJECT';
const receiveProjectType = 'RECEIVE_PROJECT';
const requestProjectError = 'PROJECT_ERROR';
const UnauthorizedError = "UNAUTHORIZED";
const initialState = {project: '', isLoading: false, projectName: ''};

export const actionCreators = {
    requestProject: (projectName, producerName, startTime, token, unitOfMeasurement = UnitOfMeasurement.Default, naturalGasUnit = NaturalGasUnit.SCF, dieselBTUValue = global.dieselBTUValueDefault,
                     ngBTUValue = global.ngBTUValueDefault,
                     dieselCostPerGallonValue = global.dieselCostPerGallonDefault, ngCostPerMCFValue = global.ngCostPerMCFDefault, bifuelDailySetupCostValue = global.bifuelDailySetupCostDefault, substitutionRateValue = global.substitutionRateDefault,
                     dailyTotalCutoff = null, dailyCutoffHour = null) => async (dispatch, getState) => {
        dispatch({
            type: requestProjectType,
            projectName,
            producerName,
            startTime,
            unitOfMeasurement,
            naturalGasUnit,
            dieselBTUValue,
            ngBTUValue,
            dieselCostPerGallonValue,
            ngCostPerMCFValue,
            bifuelDailySetupCostValue,
            substitutionRateValue,
            dailyTotalCutoff,
            dailyCutoffHour
        })

        const url =
            global.apiEndpoint +
            'project/' +
            projectName +
            '?producerName=' +
            producerName +
            '&startTime=' +
            startTime +
            '&uom=' + unitOfMeasurement +
            '&naturalGasUnit=' + naturalGasUnit +
            '&dieselBTUValue=' + dieselBTUValue +
            '&ngBTUValue=' + ngBTUValue +
            '&dieselCostPerGallonValue=' + dieselCostPerGallonValue +
            '&ngCostPerMCFValue=' + ngCostPerMCFValue +
            '&bifuelDailySetupCostValue=' + bifuelDailySetupCostValue +
            '&substitutionRateValue=' + substitutionRateValue +
            '&dailyCutoffTime=' + dailyTotalCutoff +
            '&dailyCutoffHour=' + dailyCutoffHour;

        if (global.apiEndpoint === undefined) {
            window.location.replace('/')
        }
        const requestOptions = {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`
            }
        }
        let response;
        var project;

        const db = new Dexie('projectsDB');
        db.version(1).stores({
            projects: 'url, data, timestamp'
        });

        db.projects.get(url).then(function (x) {
            if (Date.now() - x.timestamp > global.localCacheTime) {
                throw 'expired';
            } else {
                project = JSON.parse(x.data);
                return project;
            }
        }).then(function (project) {
            dispatch({type: receiveProjectType, projectName, project})
        }).catch(async function (err) {
            response = await fetchWithTimeout(url, requestOptions)

            if (response.status === 401 || response.status === 403) {
                dispatch({type: UnauthorizedError})
            }
            if (response.status !== 200) {
                dispatch({type: requestProjectError})
            } else {
                try {
                    project = await response.json()

                    if (project.name === null) {
                        dispatch({type: requestProjectError})
                    } else {
                        try {
                            db.projects.put({url: url, data: JSON.stringify(project), timestamp: Date.now()});
                        } catch (err) {

                        }

                        dispatch({type: receiveProjectType, projectName, project})
                    }
                } catch (err) {
                    dispatch({type: requestProjectError})
                }
            }
        });
    }
}

export const reducer = (state, action) => {
    state = state || initialState;

    if (action.type === requestProjectType) {
        return {
            ...state,
            projectName: action.projectName,
            isLoading: true,
            isError: false
        };
    }

    if (action.type === receiveProjectType) {
        return {
            ...state,
            projectName: action.projectName,
            project: action.project,
            isLoading: false,
            isError: false
        };
    }

    if (action.type === requestProjectError) {
        return {
            ...state,
            isLoading: false,
            isError: true
        };
    }
    if (action.type === UnauthorizedError) {
        return {
            ...state,
            isError: true,
            isLoading: false,
            isUnauthorized: true
        }
    }

    return state;
};
