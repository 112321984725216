import React, { useState, useEffect, Fragment } from "react";
import { connect } from "react-redux";
import { useAuth0 } from "../auth/auth0-wrapper";
import Unauthorized from "./Unauthorized";
import Loader from "./common/Loader/Loader";
import CookieUrl from '../components/CookieUrl.js';
import slugify from '../helpers/slugify';
import { bindActionCreators } from "redux";
import Error from '../components/common/Error/index';
import { actionCreators as actionCreatorsProducers } from '../store/Producers'
import {actionCreators as actionCreatorsHeader} from "../store/Header";
import {FormattedMessage,useIntl} from "react-intl";
import LandingPage from "./LandingPage";
import { setState } from "litsy";

function Home(props) {
	const { getTokenSilently } = useAuth0();
	const [token, setToken] = useState(null);
	const [noProjects, setNoProjects] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const [appSettingsComplete, setAppSettingsComplete] = useState(false);
	const [settingsDropdownToggle, setSettingsDropdownToggle] = useState(false);
	const toggleSettings = () => {
		setSettingsDropdownToggle(!settingsDropdownToggle);
	}
	const intl = useIntl();
	useEffect(() => {
		appSettings();
		const fetchToken = async () => {
			const auth0Token = await getTokenSilently();
			setState("authToken", auth0Token, "session");
			setToken(auth0Token);
		};
		fetchToken();
	}, []);

	useEffect(() => {
		if (token && appSettingsComplete === true) {
			props.requestProducers(token);
			setIsLoading(false)
		}
	}, [token, appSettingsComplete]);

	const requestLatestProject = () => 
	{
		props.requestProducers(token);
	}

	useEffect(() => {
		if (!isLoading)
		{
			if (props.producers.length > 0 && props.producers[0] !== undefined && props.producers[0].projects[0] !== undefined)
			{
				// let producerName = slugify(props.producers[0].name, { replacement: '-', lower: true });
				// let projectName = slugify(props.producers[0].projects[0].name, { replacement: '-', lower: true });
				// let projectStartDate = props.producers[0].projects[0].startDateURL;

				// Show project dropdown
				props.setShowNavMenuButton(true);
	
				// window.location.replace('/' + producerName + '/' + projectName + '/' + projectStartDate);
			}else{
				if (!props.isLoading)
				{
					setNoProjects(true);
				}
			}
		}
	}, [props.producers])

	const appSettings = () => {
		global.apiEndpoint = process.env.REACT_APP_API_URL;
		setState("fracShack_apiEndpoint", process.env.REACT_APP_API_URL, "session");
		setAppSettingsComplete(true);
	};

	if (!isLoading && noProjects && props.producers !== null)
	{
		return <Error pathText="" path="/requestAccess" title={intl.formatMessage({ id: "noGo.label", defaultMessage: "No Go"})}> 
			<FormattedMessage id="permissions.message" defaultMessage="You do not have permissions to this project" />
		</Error>
	}
	
	if (!isLoading && (token) && appSettingsComplete === true) {
		return (
			<Fragment>
				{/*<CookieUrl mode="get" token={token} handleRedirect={requestLatestProject} />*/}
				{/*<div className="center">*/}
				{/*	<Loader />*/}
				{/*</div>*/}
				<LandingPage/>
			</Fragment>
		)
	}

	if (!isLoading && (!token)) {
		return <Unauthorized> </Unauthorized>;
	}
	return (
		// <div className="center">
		// 	<Loader />
		// </div>
		<LandingPage/>
	);
}

const mapDispatchToProps = (dispatch) => bindActionCreators({ ...actionCreatorsProducers, ...actionCreatorsHeader }, dispatch)
export default connect(
	state => state.producers,
	mapDispatchToProps
)(Home);
